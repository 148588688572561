import React, { useEffect, useState } from "react"
import { Form, Button } from "react-bootstrap"
import { ApiClient } from "../ApiClient"
import Table from './components/Table'
import moment from "moment";
import "./Style/Style.scss";

export default function OnActivation(props) {

    const editors = ['daniellec@signapay.com', 'marianac@signapay.com']

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [hasError, sethasError] = useState(false)
    const [skipPageReset, setSkipPageReset] = useState(false)

    const columns =  [
            {
                Header: 'MID',
                accessor: 'mid',
            },
            {
                Header: 'DBA',
                accessor: 'dba_name',
            },
            {
                Header: 'ISO',
                accessor: 'iso_name',
            },
            {
                Header: 'Group ID',
                accessor: 'group_id',
            },
            {
                Header: 'RM',
                accessor: 'rm',
            },
            {
                Header: 'Sales Rep Name',
                accessor: 'sales_rep',
            },
            {
                Header: 'Profile',
                accessor: 'merchant_profile',
            },
            {
                Header: 'Date Opened',
                accessor: (d) => {
                    if (d.open_date) {
                      return moment.utc(d.open_date).format("YYYY-MM-DD");
                    }
                    return ""
                },
                filter: 'date'
            },
            {
                Header: 'Volume Threshold',
                accessor: 'volume_threshold',
            },
            {
                Header: 'Processed Volume',
                accessor: 'volume',
            },
            {
                Header: 'Remaining Days',
                accessor: (d) => {
                    return d.days < 0 ? 0 : d.days
                }
            },
            {
                Header: 'Status',
                accessor: 'activation_status'
            },
        ]

    useEffect(() => {
        setLoading(true)
        ApiClient.get({
            url: "/api/bonus/GetActivation"
        })
        .then((json) => {
            setData(json.data)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        });
    }, [])

    return (
        <>
        <Table className="BonusTable"
            columns={columns}
            data={data}
            skipPageReset={skipPageReset}
            hasError={hasError}
            loading={loading}
            setLoading={setLoading}
        />
        </>
    )
}

