import React, { useEffect, useState, useRef } from "react"
import { ApiClient } from "../ApiClient"
import moment from "moment";
import "./Style/Style.scss";
import { TextField, Button } from '@material-ui/core/'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import UploadFileIcon from '@material-ui/icons/InsertDriveFile'
import { PDFDocument, PDFTextField, PDFCheckBox } from 'pdf-lib'
import StepConnector from '@material-ui/core/StepConnector';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import clsx from 'clsx';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import StepButton from '@material-ui/core/StepButton';
import Chip from '@material-ui/core/Chip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import RefreshIcon from '@material-ui/icons/Refresh';
import CachedIcon from '@material-ui/icons/Cached';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import RadioButtonUncheckedSharpIcon from '@material-ui/icons/RadioButtonUncheckedSharp';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Alert from 'react-bootstrap/Alert'
import LinearProgress from "@material-ui/core/LinearProgress";


const useStyles = makeStyles((theme) => ({
    root: {
      padding: "20px",
      margingBottom: "20px"
    },
    textField: {
      marginRight: theme.spacing(2),
      width: '200px',
    },
    boxForm: {
        margin: "20px 0",
        padding: "20px",
        borderColor: "rgba(0, 0, 0, 0.13)",
        borderStyle: "solid",
        borderWidth: "1px",
        borderRadius: "6px",
    },
    boxFormTitle: {
        position: "absolute",
        marginTop: "-32px",
        padding: "0 5px",
        backgroundColor: "#fff",
        fontSize: "14px",
        color: "#666"
    },
    uploadFile: {
        display: "flex",
        alignItems: "center",
        margin: "20px 0",
        padding: "20px",
        borderColor: "rgba(0, 0, 0, 0.13)",
        borderStyle: "solid",
        borderWidth: "1px",
        borderRadius: "6px",
    },
    validationBox : {
        width: "100%",
        padding: "10px",
        margin: "0 5px"
    },
    errorMessage: {
        margin: "10px 0",
        fontSize: "12px",
        color: "#ef5350"
    },
    listItemText:{
        fontSize:'0.7em',
        letterSpacing: '0'
    },
    listItem: {
        paddingLeft: '0',
        paddingRight: '0'
    },
    listItemIcon: {
        minWidth: "30px"
    },
    formControl: {
        minWidth: 250,
    },
}));

const getChecksObject = () => {
    const CHECK_NAMES = ["DataX", "Match", "TinCheck", "Giact"]

    const checks = {}
    CHECK_NAMES.forEach(check => {
        checks[check] = {
            check_type: check,
            document_name: null,
            error_msg: null,
            runtime: moment().utc().toISOString(),
            runtime_status: "initiated"
        }
    })
    return checks
}

export default function Leads({props}) {
    const classes = useStyles();
    const [filename, setFilename] = useState("");
    const [fields, setFields] = useState()
    const [underwriters, setUnderwriters] = useState()
    const [lead, setLead] = useState()
    const [activeStep, setActiveStep] = useState();
    const [currentStep, setCurrentStep] = useState();
    const [currentStepCompleted, setCurrentStepCompleted] = useState();
    const [intervalId, setIntervalId] = useState()
    const [checksRunning, setChecksRunning] = useState(false)
    const form = useRef()
    const [checks, setChecks] = useState({...getChecksObject()})
    const [selectedUW, setSelectedUW] = useState()
    const [selectedCCUW, setSelectedCCUW] = useState()
    const [approval, setApproval] = useState()
    const [loading, setLoading] = useState(false)

    function loadPDF(event) {
        if (event.target.files.length == 0) return

        const file = event.target.files[0];
        const { name } = file;
        setFilename(name);

        const reader = new FileReader();
        reader.onload = function () {
            PDFDocument.load(reader.result, { ignoreEncryption: true }).then(pdf => {
                const pdfFields = pdf.getForm().getFields()
                let fields = {}
                pdfFields.forEach(field => {
                    const name = field.getName()
                    if(field instanceof PDFTextField) {
                        fields[name.trim().replaceAll(" ", "")] = {
                            type: "PDFTextField",
                            name: name,
                            value: field.getText()
                        }
                    }
                    if(field instanceof PDFCheckBox) {
                        fields[name.trim().replaceAll(" ", "")] = {
                            type: "PDFCheckBox",
                            name: name,
                            value: field.isChecked()
                        }
                    }
                })
                setFields(fields)
            })
        }

        reader.readAsArrayBuffer(file);
    }

    const handleSubmit = async (currentForm) => {
        
        const formData = new FormData(currentForm);

        const data = {
            lead_id: props.Id,
            owner_name: props.Real_Owner_del__c,
            first_name: formData.get("FirstName"),
            last_name: formData.get("LastName"),
            email: formData.get("Email"),
            phone: formData.get("Phone"),
            ssn: formData.get("SSN"),
            dob: formData.get("DOB"),
            home_address: formData.get("HomeAddress"),
            home_city: formData.get("City"),
            home_state: formData.get("State"),
            home_zip: formData.get("ZipCode"),
            dba: formData.get("DBA"),
            legal_name: formData.get("LegalName"),
            iso_split: formData.get("ISOSplit"),
            tin: formData.get("TIN"),
            business_type: formData.get("TypeofBusiness"),
            business_email: formData.get("BusinessEmail"),
            business_phone: formData.get("BusinessPhone"),
            business_address_1: formData.get("BusinessAddress1"),
            business_address_2: formData.get("BusinessAddress2"),
            business_city: formData.get("BusinessCity"),
            business_state: formData.get("BusinessState"),
            business_zip: formData.get("BusinessZipCode"),
            aba_number: formData.get("RoutingNumber"),
            dda_number: formData.get("DDANumber"),
            sales_agent: formData.get("NoofSalesAgents"),
            years_industry: formData.get("YearsInIndustry"),
        }

        return await ApiClient.post({
            url: "/api/leads/AddLead",
            body: data
        })
    }

    const saveForm = async (e) => {
        e.persist()
        if(!form.current.checkValidity()) return
        setLoading(true)
        await handleSubmit(form.current)
        setLoading(false)
    }

    const runChecks = async (e) => {
        if(!form.current.checkValidity()) return
        // setLoading(true)
        await handleSubmit(form.current)
        await changeCurrentStep(props.Id, 1, false)
        // await ApiClient.post({
        //     url: "/api/leads/RunChecks",
        //     body: { 
        //         lead_id: props.Id,
        //         check_name: null
        //     }
        // })
        // setChecks({...getChecksObject()})
        // setChecksRunning(true)
        // setLoading(false)
    }

    const reRunCheck = async (checkName) => {
        await changeCurrentStep(props.Id, 1, false)
        await ApiClient.post({
            url: "/api/leads/RunChecks",
            body: { 
                lead_id: props.Id,
                check_name: checkName
            }
        })
        setChecks({...checks, [checkName] : {
            check_type: checkName,
            document_name: null,
            error_msg: null,
            runtime: moment().utc().toISOString(),
            runtime_status: "started"
        }})
        setChecksRunning(true)
    }

    const changeCurrentStep = async (leadId, currentStep, completed) => {
        setCurrentStep(currentStep)
        setActiveStep(currentStep)
        setCurrentStepCompleted(completed)
        return await ApiClient.post({
            url: "/api/leads/AddCurrentStep",
            body: { 
                lead_id: leadId,
                current_step: currentStep,
                completed: completed
            }
        })
    }

    const getChecks = (leadId) => {
        ApiClient.post({
            url: "/api/leads/GetChecks",
            body: { lead_id: leadId }
        })
        .then((json) => {
            const newChecks = {...checks}
            json.data.forEach(check => {
                if(check.check_type === "LexisNexis")
                    newChecks[check.check_type] = {...check, runtime_status: check.document_name == null ? "nothing" : "uploaded"}
                else
                    newChecks[check.check_type] = {...check}
            })
            setChecks(newChecks)
            // setChecksRunning(true)
        }).catch(err => {
            console.log(err);
        });
    }

    const getApprovals = (leadId) => {
        ApiClient.post({
            url: "/api/leads/GetLeadApproval",
            body: { lead_id: leadId }
        })
        .then((json) => {
            setApproval(...json.data)
        }).catch(err => {
            console.log(err);
        });
    }

    const downloadDocument = (documentName) => {
        ApiClient.post({
            url: "/api/leads/DownloadDocument",
            body: { document_name: documentName }
        })
        .then((json) => {
            const element = document.createElement("a")
            element.href = 'data:application/pdf;base64,' + json.data
            element.download = documentName.slice(documentName.lastIndexOf('/') + 1)
            document.body.appendChild(element)
            element.click()
            document.body.removeChild(element)
        }).catch(err => {
            console.log(err);
        });
    }

    const sendForApproval = () => {
        setLoading(true)
        ApiClient.post({
            url: "/api/leads/SendForApproval",
            body: { 
                lead_id: props.Id,
                assigned_user: selectedUW, 
                cc_user: selectedCCUW
            }
        })
        .then(async (json) => {
            await changeCurrentStep(props.Id, 2, true)
            await changeCurrentStep(props.Id, 3, false)
            getApprovals(props.Id)
            setLoading(false)
        }).catch(err => {
            console.log(err);
            setLoading(false)
        });
    }

    const uploadDocument =  (event, checkName) => {
        if (event.target.files.length == 0) return
        setLoading(true)

        setChecks({...checks, [checkName] : {
            check_type: checkName,
            document_name: null,
            error_msg: null,
            runtime: moment().utc().toISOString(),
            runtime_status: "started"
        }})

        const file = event.target.files[0]
        const data = new FormData() 
        data.append('file', file)
        data.append('lead_id', props.Id)
        data.append('check_name', checkName)

        ApiClient.post({
            url: "/api/leads/UploadDocument",
            body: data,
            headers: { "Content-Type": "multipart/form-data" } 
        })
        .then((json) => {
            setChecks({...checks, [checkName] : {
                check_type: checkName,
                document_name: json.data.document_name,
                error_msg: null,
                runtime: moment().utc().toISOString(),
                runtime_status: "uploaded"
            }})
            setLoading(false)
        }).catch(err => {
            setChecks({...checks, [checkName] : {
                check_type: checkName,
                document_name: null,
                error_msg: null,
                runtime: moment().utc().toISOString(),
                runtime_status: "stopped"
            }})
            setLoading(false)
        });

    }

    useEffect(() => {
        if(checksRunning && !intervalId){
            const id = setInterval(() => {
                getChecks(props.Id)
            }, 2000);
            setIntervalId(id)
            return
        }
        if(!checksRunning){
            clearInterval(intervalId)
            setIntervalId()
            setLoading(false)
        }

        return () => {
            clearInterval(intervalId)
        }

    }, [checksRunning, intervalId])

    useEffect(() => {

        if(!checksRunning) return

        const checksArray = Object.values(checks).filter(check => check.check_type !== "LexisNexis").map(check => check)

        const initiated_count = checksArray?.filter(check => check.runtime_status === "initiated").length
        const finished_count = checksArray?.filter(check => check.runtime_status === "finished").length
        const stopped_count = checksArray?.filter(check => check.runtime_status === "stopped").length
        const running_count = checksArray?.filter(check => check.runtime_status === "started").length
        const running_times = checksArray?.filter(check => check.runtime_status === "started")?.map(check => check.runtime)

        const runtime = running_times.length > 0 && running_times?.reduce((a, b) => moment(a).isAfter(moment(b)) ? moment(a) : moment(b))
        if (finished_count == 4){
            if(currentStep == 1) changeCurrentStep(props.Id, 1, true)
            setChecksRunning(false)
            return
        }
        if(finished_count + stopped_count == 4){
            setChecksRunning(false)
            return
        }
        if(running_count > 0 && moment.utc().diff(moment(runtime).utc(), 'minutes') > 15){
            setChecksRunning(false)
            return
        }
        if (finished_count > 0 && finished_count + initiated_count === 4){
            if(currentStep == 1) changeCurrentStep(props.Id, 1, true)
            setChecksRunning(false)
            return
        }
        setChecksRunning(true)
    }, [checks, checksRunning])

    useEffect(() => {
        ApiClient.post({
            url: "/api/leads/GetLead",
            body: { lead_id: props.Id }
        })
        .then((json) => {
            setLead(json?.data[0] || {})
        }).catch(err => {
            console.log(err);
        });

        ApiClient.get({
            url: "/api/leads/GetUnderwriters"
        })
        .then((json) => {
            setUnderwriters(json.data)
        }).catch(err => {
            console.log(err);
        });

        ApiClient.post({
            url: "/api/leads/GetCurrentStep",
            body: { lead_id: props.Id }
        })
        .then((json) => {
            if(json.data.length > 0) {
                setCurrentStep(json.data[0].current_step)
                setCurrentStepCompleted(json.data[0].completed == 1)
                setActiveStep(json.data[0].current_step)
                getChecks(props.Id)
                getApprovals(props.Id)
            } else {
                setCurrentStep(0)
                setCurrentStepCompleted(false)
                setActiveStep(0)
            }
        }).catch(err => {
            console.log(err);
        });

    }, [props.Id])

    return (
        <>
        <div className={classes.root}>
            <Steps currentStep={currentStep} currentStepCompleted={currentStepCompleted} activeStep={activeStep} setActiveStep={setActiveStep} />
            {activeStep == 0 && 
            <>
            {/* <div className={classes.uploadFile}>
                <div className="mr-2 text-muted">Upload partner's agreement form to extract more information</div>
                <Button
                    component="label"
                    variant="outlined"
                    color="primary"
                    disableRipple 
                    startIcon={<UploadFileIcon />}
                    sx={{ marginRight: "1rem" }}
                >
                    Upload PDF
                    <input type="file" accept=".pdf" hidden onChange={e => loadPDF(e)} />
                </Button>
                <div className="ml-2 "><small>{filename}</small></div>
            </div> */}
                <form onSubmit={e => { e.persist(); e.preventDefault() }} ref={form}>
                    <CustomForm pdfData={fields} sfData={props} leadData={lead} />
                    {(!checksRunning || approval?.approved != 1) && <>
                    <Button variant="contained" color="primary" disableElevation className="mr-2" type="submit" onClick={(e) => saveForm(e)} disabled={loading}>Save Changes</Button>
                    <Button variant="contained" color="primary" disableElevation type="submit" onClick={(e) => runChecks(e)} disabled={loading}>Save & Run Validations</Button>
                    </>}
                </form>
            </>}
            {activeStep == 1 && 
            <>
                <div className="d-flex justify-content-between" >
                    {Object.values(checks)?.filter(check => check.check_type !== "LexisNexis")?.map(check => {
                        const runtime = moment.utc().diff(moment(check?.runtime).utc(), 'minutes')
                        let status = check?.runtime_status
                        if(check?.runtime_status === 'started' && runtime > 15) status = "stopped"
                        return <>
                            <Paper variant="outlined" className={classes.validationBox}>
                                <CustomChip label={check?.check_type} status={status} />
                                {(status === "initiated" || status === "finished") && <>
                                    <div className="float-right">
                                    <IconButton aria-label="Re-Run" color="primary" component="span" size="small" onClick={() => reRunCheck(check?.check_type)}>
                                        <CachedIcon fontSize="inherit" />
                                    </IconButton>
                                    </div>
                                </>}
                                {status === "stopped" && <>
                                    <div className="float-right">
                                    <IconButton aria-label="Re-Run" color="primary" component="span" size="small" onClick={() => reRunCheck(check?.check_type)}>
                                        <RefreshIcon fontSize="inherit" />
                                    </IconButton>
                                    </div>
                                    <div className={classes.errorMessage}>{check?.error_msg || "Unknown error"}</div>
                                </>}
                                <List dense={true}>
                                {check?.document_name?.split('|').map((document, i) => 
                                    <ListItem key={i} button onClick={() => downloadDocument(document)} classes={{root:classes.listItem}}>
                                        <ListItemIcon classes={{root: classes.listItemIcon}}>
                                            <PictureAsPdfIcon />
                                        </ListItemIcon>
                                        <ListItemText classes={{primary:classes.listItemText}} primary={document.slice(document.lastIndexOf('/') + 1)} />
                                    </ListItem>
                                )}
                                </List>
                            </Paper>
                        </>
                    })}
                    <Paper variant="outlined" className={classes.validationBox}>
                        <CustomChip label="LexisNexis" 
                            status={Object.values(checks)?.filter(check => check.check_type === "LexisNexis").map(check => check.runtime_status)[0] || ""} 
                        />
                        <div className="float-right">
                            <input accept=".pdf" id="icon-button-file" type="file" hidden  onChange={e => uploadDocument(e, "LexisNexis")} />
                            <label htmlFor="icon-button-file">
                            <IconButton aria-label="Upload" color="primary" size="small" component="span">
                                <UploadFileIcon fontSize="inherit" />
                            </IconButton>
                            </label>
                        </div>
                        {Object.values(checks)?.filter(check => check.check_type === "LexisNexis" && check.document_name != null)?.map((check, i) => ( 
                        <List dense={true}>
                            <ListItem key={i} button onClick={() => downloadDocument(check?.document_name)} classes={{root:classes.listItem}}>
                                <ListItemIcon classes={{root: classes.listItemIcon}}>
                                    <PictureAsPdfIcon />
                                </ListItemIcon>
                                <ListItemText classes={{primary:classes.listItemText}} primary={check?.document_name?.slice(check?.document_name?.lastIndexOf('/') + 1)} />
                            </ListItem>
                        </List>
                        ))}
                        </Paper>
                </div>
                {currentStep == 1  &&
                    <Button 
                        variant="contained" 
                        color="primary" 
                        disableElevation 
                        className="mt-3 ml-1"
                        onClick={async () => await changeCurrentStep(props.Id, 2, false)}
                    >
                        Send for approval
                    </Button>
                }
            </>}
            {activeStep == 2 && 
                <div className={classes.boxForm}>
                    <FormControl variant="outlined" className={classes.formControl} size="small">
                        <InputLabel id="demo-simple-select-outlined-label">Underwriter</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={(e) => setSelectedUW(e.target.value)}
                            value={selectedUW}
                            label="Underwriter"
                        >
                            {underwriters?.map((uw) => (
                                <MenuItem value={uw.email}>{uw.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" className={`${classes.formControl} ml-2`} size="small" disabled={!selectedUW}>
                        <InputLabel id="demo-simple-select-cc-outlined-label">CC</InputLabel>
                        <Select
                            labelId="demo-simple-cc-select-outlined-label"
                            id="demo-simple-cc-select-outlined"
                            onChange={(e) => setSelectedCCUW(e.target.value)}
                            value={selectedCCUW}
                            label="CC"
                        >
                            {underwriters?.filter((uw) => uw.email !== selectedUW).map((uw) => (
                                <MenuItem value={uw.email}>{uw.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {approval?.approved != 1 &&
                    <Button 
                        variant="contained" 
                        color="primary" 
                        disableElevation 
                        className="ml-2"
                        onClick={() => sendForApproval()}
                        disabled={loading}
                    >
                        Send for approval
                    </Button>}
                    <List dense={true}>
                    {Object.values(checks)?.map(check => {
                        return <>
                        {check?.document_name?.split('|').map((document, i) => (
                        <ListItem key={i} button onClick={() => downloadDocument(document)} classes={{root:classes.listItem}}>
                            <ListItemIcon classes={{root: classes.listItemIcon}}>
                                <PictureAsPdfIcon />
                            </ListItemIcon>
                            <ListItemText classes={{primary:classes.listItemText}} primary={document.slice(document.lastIndexOf('/') + 1)} />
                        </ListItem>
                        ))}</>
                    })}
                    </List>
                </div>
            }
            {activeStep == 3 && 
                <div className={classes.boxForm}>
                    <Grid container spacing={1} style={{fontSize: "13px"}}>
                        <Grid item xs={6}>
                            <div className="text-muted">Underwriter</div> {approval?.assigned_user}
                            <div className="text-muted mt-2">Sent by</div>{approval?.assigned_by}
                            <div  className="text-muted mt-2">Sent on</div>{moment.utc(approval?.created_on).local().format("MM/DD/YYYY h:mm a")}
                        </Grid>
                        <Grid item xs={6}>
                            <div className="text-muted">Status</div> {approval?.approved == null && <Alert variant="secondary" style={{width: "fit-content"}}>Pending</Alert>}
                            {approval?.approved == 1 && <Alert variant="success" style={{width: "fit-content"}}>Approved</Alert>}
                            {approval?.approved == 0 && <Alert variant="danger" style={{width: "fit-content"}}>Rejected</Alert>}
                            <div className="text-muted">Comment</div> {approval?.comment}
                        </Grid>
                    </Grid>
                </div>
            }
        </div>
        {loading && <div className="spinner">
            <LinearProgress  />
        </div>}
      </>
    )
}

const CustomChip = ({ label, status }) => {
    return (<Chip
        icon={
            (status === "finished" || status === "uploaded" ) && <CheckCircleIcon style={{ color: green[500] }} /> ||
            status === "started" && <CircularProgress style={{ width: "20px", height: "20px" }} /> ||
            status === "stopped" && <ErrorIcon style={{ color: red[500] }} /> ||
            status === "" && <RadioButtonUncheckedSharpIcon />
        }
        label={label}
        variant="outlined"
    />)
}

const Steps = ({ currentStep, activeStep, setActiveStep, currentStepCompleted }) => {
    const steps = ['Enter Information', 'Run Validations', 'Send for Approval', 'Decision']
    const [completedSteps, setCompletedSteps] = useState({});

    const QontoConnector = withStyles({
        alternativeLabel: {
          top: 10,
          left: 'calc(-50% + 16px)',
          right: 'calc(50% + 16px)',
        },
        active: {
          '& $line': {
            borderColor: '#784af4',
          },
        },
        completed: {
          '& $line': {
            borderColor: '#784af4',
          },
        },
        line: {
          borderColor: '#eaeaf0',
          borderTopWidth: 3,
          borderRadius: 1,
        },
    })(StepConnector);
    
    const useQontoStepIconStyles = makeStyles({
        root: {
          color: '#eaeaf0',
          display: 'flex',
          height: 22,
          alignItems: 'center',
        },
        active: {
          color: '#784af4',
        },
        circle: {
          width: 8,
          height: 8,
          borderRadius: '50%',
          backgroundColor: 'currentColor',
        },
        completed: {
          color: '#784af4',
          zIndex: 1,
          fontSize: 18,
        },
      });
    
    function QontoStepIcon(props) {
        const classes = useQontoStepIconStyles();
        const { active, completed } = props;

        return (
          <div
            className={clsx(classes.root, {
              [classes.active]: active,
            })}
          >
            {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
          </div>
        );
    }

    const handleStep = (step) => {
        if(step <= currentStep) {
            setActiveStep(step)
        }
    }

    useEffect(() => {
        if(currentStep == 0 && currentStepCompleted == false) return
        let newCompleted = completedSteps
        for (let index = 0; index <= currentStep; index++) {
            newCompleted[index] = index == currentStep ? currentStepCompleted : true

        }
        setCompletedSteps({...newCompleted})

    }, [activeStep, currentStep, currentStepCompleted])

    return <>
        <Stepper alternativeLabel nonLinear activeStep={activeStep} connector={<QontoConnector />}>
            {steps.map((label, index) => (
                <Step key={label} active={index <= activeStep}>
                    <StepButton onClick={() => handleStep(index)} completed={completedSteps[index]}>
                        <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                    </StepButton>
                </Step>
            ))}
        </Stepper>
    </>
}

const CustomForm = ({ sfData, pdfData, leadData }) => {
    const classes = useStyles();

    return (<>
        <div className={classes.boxForm}>
            <div className={classes.boxFormTitle}>Required information</div>
            <CustomTextField 
                name="First Name" 
                value={ leadData?.first_name || pdfData?.PrincipalName?.value?.substring(0, pdfData?.PrincipalName?.value?.lastIndexOf(" ")) || sfData?.FirstName } 
                required={true}
            />
            <CustomTextField 
                name="Last Name" 
                value={ leadData?.last_name || pdfData?.PrincipalName?.value?.substring(pdfData?.PrincipalName?.value?.lastIndexOf(" ") + 1) || sfData?.LastName } 
                required={true} 
            />
            <CustomTextField 
                name="SSN" 
                value={ leadData?.ssn || pdfData?.SSN?.value } 
                required={true}
            />
            <CustomTextField 
                name="DOB" 
                type="date" 
                value={ (leadData?.dob && new Date(leadData?.dob).toISOString().slice(0, 10)) ||  (pdfData?.DOB && new Date(pdfData?.DOB?.value).toISOString().slice(0, 10))}
                required={true}
            />
            <br />
            <CustomTextField 
                name="Home Address" 
                value={ leadData?.home_address || pdfData?.HomeAddress?.value || sfData?.Address?.street } 
                required={true} 
            />
            <CustomTextField 
                name="City" 
                value={ leadData?.home_city || pdfData?.City?.value || sfData?.Address?.city } 
                required={true} 
            />
            <CustomTextField 
                name="State" 
                value={ leadData?.home_state || pdfData?.State?.value || sfData?.Address?.state } 
                required={true} 
            />
            <CustomTextField 
                name="Zip Code" 
                value={ leadData?.home_zip || pdfData?.ZIP?.value || sfData?.Address?.postalCode } 
                required={true} 
            />
            <br />
            <br />
            <CustomTextField name="Legal Name" value={ leadData?.legal_name } required={true} />
            <br />
            <CustomTextField 
                name="Business Address 1" 
                value={ leadData?.business_address_1 || pdfData?.BusinessAddress1?.value } 
                required={true} 
            />
            <CustomTextField 
                name="Business Address 2" 
                value={ leadData?.business_address_2 || pdfData?.BusinessAddress2?.value } 
            />
            <CustomTextField 
                name="Business City" 
                value={ leadData?.business_city || pdfData?.City_2?.value } 
                required={true} 
            />
            <CustomTextField 
                name="Business State" 
                value={ leadData?.business_state || pdfData?.State_2?.value } 
                required={true} 
            />
            <CustomTextField 
                name="Business Zip Code" 
                value={ leadData?.business_zip || pdfData?.ZIP_2?.value } 
                required={true} 
            />
            <br />
            <br />
            <CustomTextField 
                name="Routing Number" 
                value={ leadData?.aba_number || pdfData?.RoutingNumber?.value } 
                required={true} 
            />
            <CustomTextField 
                name="DDA Number" 
                value={ leadData?.dda_number || pdfData?.DDANumber?.value }
                required={true} 
            />
        </div>
        <div className={classes.boxForm}>
            <div className={classes.boxFormTitle}>More information</div>
            <CustomTextField name="Email" value={ leadData?.email || pdfData?.PrincipalEmail?.value || sfData?.Email } />
            <CustomTextField name="Phone" value={ leadData?.phone || pdfData?.MobilePhone?.value || sfData?.Phone } />
            <br />
            <CustomTextField name="DBA" value={ leadData?.dba || pdfData?.ISODBAName?.value || sfData?.Company } />
            <CustomTextField type="number" name="ISO Split" value={ leadData?.iso_split || pdfData?.ISOSplit?.value } />
            <CustomTextField name="TIN" value={ leadData?.tin || pdfData?.TaxIDNumberTIN?.value } />
            <br />
            <CustomTextField 
                name="Type of Business" 
                value={ leadData?.business_type || 
                    (pdfData?.IndivSoleProp?.value && pdfData?.IndivSoleProp?.name) ||
                    (pdfData?.Corporation?.value && pdfData?.Corporation?.name) ||
                    (pdfData?.Partnership?.value && pdfData?.Partnership?.name) ||
                    (pdfData?.Other?.value && pdfData?.TypeofBusiness?.value)
                } 
            />
            <CustomTextField name="Business Phone" value={ leadData?.business_phone || pdfData?.BusinessPhone?.value } />
            <CustomTextField name="Business Email" value={ leadData?.business_email ||pdfData?.BusinessEmail?.value } />
            <CustomTextField name="No of Sales Agents" value={ leadData?.sales_agent ||pdfData?.NoofSalesAgents?.value } />
            <CustomTextField name="Years In Industry" value={ leadData?.years_industry ||pdfData?.YearsInthePaymentsIndustry?.value } />
        </div>
        </>)
}

const CustomTextField = ({name, value, width, type = "text", readOnly = false, required = false}) => {
    const classes = useStyles();
    const [txtValue, setTxtValue] = useState("")

    const handleChange = (event) => {
        setTxtValue(event.target.value)
    }

    useEffect(() => {
        setTxtValue(value || "")
    }, [value])

    return (
        <TextField
            label={name}
            id="outlined-margin-dense"
            name={name.replaceAll(" ", "")}
            value={txtValue}
            onChange={handleChange}
            required={required}
            className={classes.textField}
            style={{width: width}}
            inputProps={
                { 
                    readOnly: readOnly, 
                }
            }
            margin="dense"
            variant="outlined"
            type={type}
        />  
    )
}

