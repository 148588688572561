import React, { useEffect, useState } from "react"
import { withCookies } from "react-cookie"
import { ApiClient } from "../ApiClient"
import Table from './components/Table'
import moment from "moment";
import "./Style/Style.scss";

export default function Merchants(props) {

    const editors = ['daniellec@signapay.com', 'marianac@signapay.com']

    const [data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [hasError, sethasError] = React.useState(false)
    const [skipPageReset, setSkipPageReset] = React.useState(false)

    const columns =  [
            {
                Header: 'MID',
                accessor: 'mid',
            },
            {
                Header: 'DBA',
                accessor: 'dba_name',
            },
            {
                Header: 'ISO',
                accessor: 'iso_name',
            },
            {
                Header: 'Profile',
                accessor: 'merchant_profile',
            },
            {
                Header: 'Date Opened',
                accessor: (d) => {
                    if (d.open_date) {
                      return moment.utc(d.open_date).format("YYYY-MM-DD");
                    }
                    return ""
                },
                filter: 'date'
            },
            {
                Header: 'Compliance Status',
                accessor: (d) => {
                    if (d.pci_compliant == 1){
                      return "Complaint";
                    }
                    return "Non-Compliant"
                },
            },
            {
                Header: 'Date Achieved',
                accessor: (d) => {
                    if (d.achieved_date) {
                      return moment.utc(d.achieved_date).format("YYYY-MM-DD");
                    }
                    return ""
                },
                filter: 'date'
            },
            {
                Header: 'Due Date',
                accessor: (d) => {
                    if (d.due_date) {
                      return moment.utc(d.due_date).format("YYYY-MM-DD");
                    }
                    return ""
                },
                filter: 'date'
            },
            {
                Header: 'Billing Type',
                accessor: 'billing_type',
            },
            {
                Header: 'Comp Fee',
                accessor: 'pci_fee',
            },
            {
                Header: 'Non-Comp Fee',
                accessor: 'pci_noncomp_fee',
            },
            {
                Header: 'T/R',
                accessor: 'tr',
            },
            {
                Header: 'DDA',
                accessor: 'dda',
            },
        ]

    useEffect(() => {

        ApiClient.get({
            url: "/api/pci/GetMerchants",
        })
        .then((json) => {
            setData(json.data)
            setLoading(false)
        }).catch(err => {
            console.log(err);
            setLoading(false)
        });
    }, [])

    return (
        <div className="PCI">
            <Table className="PCITable"
                columns={columns}
                data={data}
                setData={setData}
                // updateMyData={updateMyData}
                skipPageReset={skipPageReset}
                hasError={hasError}
                loading={loading}
                setLoading={setLoading}
            />
        </div>
    )
}

