import React, { useEffect, useState } from "react"
import { Form, Button, Table } from "react-bootstrap"
import { ApiClient } from "../ApiClient"
import moment from "moment";
import "./Style/Style.scss";

export default function Logs(props) {

    const [logs, setLogs] = useState([])
    
    useEffect(() => {

        ApiClient.post({
            url: "/api/logs/GetActivityLog",
            body: {
                log_type: "BNS",
                row_count: 100,
                page_num: 100 * 0
            }
        }).then(json => {
            setLogs(json.data)
        }).catch(err => {
        });
    }, [])

    return (
        <>
        <div className="bonusLogs">
            <Table striped bordered hover size="sm" className="logsTable">
                <tbody>
                    {logs.map(log => (
                        <tr>
                            <td className="logsDateTime">
                                {moment(log.date_time).format("YYYY-MM-DD ddd HH:mm:ss")} {log.user}
                            </td>
                            <td>
                                {log.log_description}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
        </>
    )
}

