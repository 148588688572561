import React, { Component } from "react";
import { Pie } from "react-chartjs-2";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import "./Style/processing.scss";

class PieCard extends Component {

  render() {
    return (
      <Card className="processingCard">
        <Card.Body
          style={{
            position: "relative",
            textAlign: "center",
            margin: "auto"
          }}
        >
          <Card.Title style={{ textAlign: "left" }}>
            {this.props.title}
          </Card.Title>
          {this.props.loading === true ? (
            <Spinner animation="border" variant="success" />
          ) : (
            <Pie data={this.props.data} options={this.props.options} />
          )}
        </Card.Body>
      </Card>
    );
  }
}
export default PieCard;
