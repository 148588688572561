import React, { Component } from "react";
import Table from "react-bootstrap/Table";
// import ReactTable from "react-table";
import "react-table-6/react-table.css";

class CustomTable extends Component {
  render() {
    var tables = [];

    for (var j = 0; j < this.props.data.length; j++) {
      var rows = [];
      var head = [];

      if (this.props.data[j][0] != null) {
        var ky = Object.keys(this.props.data[j][0]);
        head = ky.map((key, index) => {
          return <th key={key}>{key.toUpperCase()}</th>;
        });

        for (var i = 0; i < this.props.data[j].length; i++) {
          var currentrow = [];
          Object.keys(this.props.data[j][i]).forEach((attr1) => {
            // console.log(this.props.data[j][i][attr1]);
            currentrow.push(
              <td style={{ color: "black" }}>{this.props.data[j][i][attr1]}</td>
            );
          });
          rows.push(<tr>{currentrow}</tr>);
        }
        tables.push(
          <div className="customTable" style={{ marginTop: "40px" }}>
            <Table responsive style={{ marginBottom: 0 }}>
              <thead>
                <tr>{head}</tr>
              </thead>
              <tbody style={{ position: "relative" }}>{rows}</tbody>
            </Table>
          </div>
        );
      }
    }
    return <div className="customTables">{tables}</div>;
  }
}

export default CustomTable;
