import React, { Component, useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import Menubar from "./Menubar/Menubar";
import Login from "./LoginAndChangePassword/Login";
import ForgotPassword from "./LoginAndChangePassword/ForgotPassword";
import ResetPassword from "./LoginAndChangePassword/ResetPassword";
import ChangePassword from "./LoginAndChangePassword/ChangePassword.js";
import Financial from "./FinancialDashboard/NFinancial";
import SalesCalculator from "./SalesCalculator/SalesCalculator";
import SalesDashboard from "./SalesDashboard/SalesDashboard";
import Register from "./Register/Register";
import Leaderboard from "./Leaderboard/Leaderboard";
import Exports from "./Export/Export";
import Partner from "./Partner/Partner";
import SelfServe from "./SelfServe/SelfServe";
import ResidualSelfServe from "./ResidualSelfServe/ResidualSelfServe";
import ACHReturns from "./ACHReturns/ACHReturns";
import Processing from "./Processing/Processing";
import UploadFiles from "./UploadFiles/UploadFiles";
import ISOAnalytics from "./ISOAnalytics/ISOAnalytics";
import ExecIso from "./Exec/ISO/Exec";
import NoMatch from "./NoMatch";
import PCI from "./PCI";
import Bonus from "./Bonus";
import Leads from "./Leads";
import Approval from "./Leads/Approval";
import ISOPerformance from "./ISOPerformance";
import MasterPlatformHierarchy from "./MasterPlatformHierarchy";
import { withCookies } from "react-cookie";
import { Auth } from "./Auth"
import IdleTimer from 'react-idle-timer'


class Content extends Component {

  render() {

     const PrivateRoute = ({component: Component, ...rest}) => {

      const history = useHistory()

      const handleOnAction = (event) => {
        if(this.idleTimer.getElapsedTime() > 1.2e+6){
          Auth.validateUser().then((json) => {
            if(!json.data.success) history.push("/login")
            else this.idleTimer.reset()
          }).catch((error) => {
            history.push("/login")
          });

        }
      }

      const handleOnIdle = (event) => {
        signOut()
      }

      const signOut = () => {
        Auth.signOut().then(() => {
          this.props.cookies.remove("login");
          this.props.cookies.remove("email");
          this.props.cookies.remove("dept");
          history.push("/login");
        })
      }

      useEffect(() => {
        Auth.validateUser().then((json) => {
          if(!json.data.success){
            history.push("/login");
          }
        }).catch((error) => {
          history.push("/login");
        });

        return () => {

        }
      });



      return (
        <>
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            timeout={2.4e+6}
            onIdle={handleOnIdle}
            onAction={handleOnAction}
            debounce={250}
          />
            <Route
                {...rest}
                render={props => {
                    return <>
                      <Menubar {...props} cookies={this.props.cookies} />
                      <Component {...props} cookies={this.props.cookies} />
                    </>
                }}
            >
            </Route>
          </>
      )

    }



    return (
      <>
        {/* <Route
          path={new RegExp("^(?!.*(?:/login)).*$")}
          render={(props) =>
            { return <Menubar {...props} cookies={this.props.cookies} /> }
          }
        /> */}
        <Switch>
          <PrivateRoute
            exact
            path="/"
            component={SalesDashboard}
          />
          <PrivateRoute
            exact
            path="/changePassword"
            component={ChangePassword}
          />
          <PrivateRoute
            exact
            path="/financial"
            component={Financial}
          />
          <PrivateRoute
            exact
            path="/salescalculator"
            component={SalesCalculator}
          />
          <PrivateRoute
            exact
            path="/sales"
            component={SalesDashboard}
          />
          <PrivateRoute
            exact
            path="/register"
            component={Register}
          />
          <PrivateRoute
            exact
            path="/leaderboard"
            component={Leaderboard}
          />
          <PrivateRoute
            exact
            path="/export"
            component={Exports}
          />
          <PrivateRoute
            exact
            path="/partner"
            component={MasterPlatformHierarchy}
          />
          <PrivateRoute
            exact
            path="/selfserve"
            component={SelfServe}
          />
          <PrivateRoute
            exact
            path="/residualselfserve"
            component={ResidualSelfServe}
          />
          <PrivateRoute
            exact
            path="/isoanalytics"
            component={ISOAnalytics}
          />
          <PrivateRoute
            exact
            path="/isoperformance"
            component={ISOPerformance}
          />
          <PrivateRoute
            exact
            path="/ach"
            component={ACHReturns}
          />
          <PrivateRoute
            exact
            path="/processing"
            component={Processing}
          />
          <PrivateRoute
            exact
            path="/uploadfiles"
            component={UploadFiles}
          />
          <PrivateRoute
            exact
            path="/bi"
            component={ExecIso}
          />
          <PrivateRoute
            exact
            path="/pci"
            component={PCI}
          />
          <PrivateRoute
            exact
            path="/bonus"
            component={Bonus}
          />
          <PrivateRoute
            exact
            path="/leads"
            component={Leads}
          />
          <Route
            exact
            path="/leads/app"
            render={(props) => (
              <Approval {...props} />
            )}
          />
          <Route
            path="/login"
            render={(props) => (
              <Login {...props} cookies={this.props.cookies} />
            )}
          />
          <Route
            path="/forgot"
            render={(props) => (
              <ForgotPassword {...props} cookies={this.props.cookies} />
            )}
          />
          <Route
            path="/reset"
            render={(props) => (
              <ResetPassword {...props} cookies={this.props.cookies} />
            )}
          />
          <Route
            render={(props) => (
              <NoMatch {...props} cookies={this.props.cookies} />
            )}
          />
        </Switch>
      </>
    );
  }
}

export default withCookies(Content);
