import React, { useEffect, useRef, useState } from 'react'
import { xlsxDownloader } from "../xlsx";
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox'
import PropTypes from 'prop-types'
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TablePaginationActions from './TablePaginationActions'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import InputBase from '@material-ui/core/InputBase'
import TableToolbar from './TableToolbar'
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Card from "react-bootstrap/Card";
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useFilters,
  state,
  onStateChange,
} from 'react-table'
import GlobalFilter, { DefaultColumnFilter, fuzzyTextFilterFn } from './GlobalFilter'
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddGroupModal from "../AddGroupModal";
import LastGroupIdsModal from "../LastGroupIdsModal";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import { ApiClient } from "../../ApiClient"
import FormControlLabel from '@material-ui/core/FormControlLabel';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <FormControlLabel
          control={<Checkbox icon={<KeyboardArrowRightIcon />} checkedIcon={<KeyboardArrowDownIcon />} name="checkedH" ref={resolvedRef} {...rest} />}
        />
      </>
    )
  }
)

const EnhancedTable = ({
  columns,
  data,
  xlsxData,
  defaultSorting,
  addGroup,
  updateRevShare,
  skipPageReset,
  canEdit
}) => {


  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const sortees = React.useMemo(
    () => defaultSorting,
    []
  );

  const EditableCell = ({
    value: initialValue,
    accountId,
    groupId,
    onUpdate
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)
    const [confirmedValue, setConfirmedValue] = React.useState(initialValue)
    const [isFocused, setIsFocused] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const onChange = e => {
      setValue(e.target.value)
    }


    const onCancel = () => {
      setValue(confirmedValue)
      setIsFocused(false)
    }

    const onConfirm = () => {
      setLoading(true)
      setError(false)
      ApiClient.post({
        url: "/api/partners/UpdateRevShare",
        body: {
          group_id: groupId,
          revshare: value,
        }
      })
        .then(json => {
          onUpdate({ accountid: accountId, groupid: groupId, revshare: value })
          setConfirmedValue(value)
          setIsFocused(false)
        }).catch(err => {
          setError(true)
          console.log(err)
        }).finally(() => {
          setLoading(false)
        });
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setConfirmedValue(initialValue)
    }, [initialValue])

    return <>
      <div className='mph-revshare'>
        <input type='number' required value={value} onChange={onChange} onFocus={() => setIsFocused(true)} disabled={loading || !canEdit} />
        {loading && <CircularProgress style={{ width: 20, height: 20 }} />}
        {error && <ErrorIcon style={{ width: 20, height: 20, color: 'red' }} />}
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={onConfirm}
          style={{ visibility: isFocused && !loading ? 'visible' : 'hidden', color: 'green' }}
        >
          <CheckIcon />
        </IconButton>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={onCancel}
          style={{ visibility: isFocused && !loading ? 'visible' : 'hidden' }}
        >
          <ClearIcon />
        </IconButton>
      </div>
    </>
  }



  function Row(props) {
    const { row} = props;
    const [showModal, setShowModal] = React.useState(false)
    const [showGroupModal, setShowGroupModal] = React.useState(false)

    return (
      <>
        <TableRow className={row.isSelected ? 'mph-enhanced-table-body-row' : ''}>
          {row.cells.map((cell, index) => {
            return (
              <TableCell {...cell.getCellProps([
                {
                  className: cell.column.className,
                  style: cell.column.style,
                },
              ])}>
                {index === 0 && <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />}
                {cell.render('Cell')}
              </TableCell>
            )
          })}
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={6}>
            <Collapse in={row.isSelected} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Card>
                  <Card.Body>
                    <div className='mph-group-card'>
                      <span>Recruiter: {row.original.recruiter}</span>
                      <span>ISC: {row.original.isc}</span>
                      <span>Liability: {row.original.liability}</span>
                      <span>Date Signed: {row.original?.datesigned?.slice(0, 10)}</span>
                      <span>Email: {row.original.processingemail}</span>
                      <span>Phone: {row.original.phone}</span>
                      <span>Address: {row.original.billingstreet}</span>
                      <span>City: {row.original.billingcity}</span>
                      <span>State: {row.original.billingstate}</span>
                      <span>Zip: {row.original.billingzip}</span>
                      <span>Operations Email: {row.original.operationemail}</span>
                      {canEdit && <div className='mph-group-add-button'>
                        <Button variant="contained" disableElevation color="primary" size="small" onClick={() => setShowModal(true)}>Add Group ID</Button>
                        <Button variant="outlined" disableElevation color="primary" size="small" onClick={() => setShowGroupModal(true)}>Last Utilized Group IDs</Button>
                      </div>}
                    </div>
                    <MaUTable size="small" className='mph-groups-table'>
                      <TableHead>
                        <TableRow>
                          <TableCell>Group Name</TableCell>
                          <TableCell>Group ID</TableCell>
                          <TableCell>Association</TableCell>
                          <TableCell>Rev Share</TableCell>
                          <TableCell>Bank</TableCell>
                          <TableCell>Processor</TableCell>
                          <TableCell>Effective Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.original.groups.map((group, index) => (
                          <TableRow key={index}>
                            <TableCell>{group.groupname}</TableCell>
                            <TableCell>{group.groupid}</TableCell>
                            <TableCell>{group.association}</TableCell>
                            <TableCell>
                              <EditableCell
                                accountId={row.original.accountid}
                                groupId={group.groupid}
                                value={!!group.rev_share ? group.rev_share * 100 : ''}
                                onUpdate={(values) => updateRevShare(values)}
                              />
                            </TableCell>
                            <TableCell>{group.bank}</TableCell>
                            <TableCell>{group.processor}</TableCell>
                            <TableCell>{group.effectivedate?.slice(0, 10)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </MaUTable>
                  </Card.Body>
                </Card>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
        {canEdit ? <>
          <AddGroupModal
          show={showModal}
          accountId={row.original.accountid}
          accountName={row.original.accountname}
          isc={row.original.isc}
          onHide={() => setShowModal(false)}
          onAdd={(values) => addGroup([values])}
        />
        <LastGroupIdsModal show={showGroupModal} onHide={() => setShowGroupModal(false)} />
        </>: <></>}
      </>
    );
  }




  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    nextPage,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter, },
    state,
    rows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        sortBy: sortees,
        pageSize: 15
      },
      autoResetPage: !skipPageReset,
      autoResetExpanded: !skipPageReset,
      autoResetGroupBy: !skipPageReset,
      autoResetSelectedRows: !skipPageReset,
      autoResetSortBy: !skipPageReset,
      autoResetFilters: !skipPageReset,
      autoResetRowState: !skipPageReset,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,

  )

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setPageSize(Number(event.target.value))
  }



  // Render the UI for your table
  return (
    <TableContainer className="mph-enhanced-table-container">
      {/* <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      /> */}
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()} className="mph-enhanced-table-header-row">
              {headerGroup.headers.map(column => (
                <TableCell className="mph-enhanced-table-header">
                  <div {...(column.getHeaderProps({ ...column.getSortByToggleProps() }))} className={`mph-enhanced-table-header-cell ${column.className}`}>
                    {column.render('Header')}
                    <span style={{ color: '#999999' }}>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <KeyboardArrowDownIcon style={{ width: 20, height: 20 }} />
                          : <KeyboardArrowUpIcon style={{ width: 20, height: 20 }} />
                        : <UnfoldMoreIcon style={{ width: 20, height: 20 }} />}
                    </span>
                  </div>
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <Row key={row.name} row={row} className="mph-enhanced-table-body-row" />
            )
          })}
        </TableBody>
        <div className="mph-enhanced-table-footer">
          <TableRow>
            <div className="mph-enhanced-table-footer-container">
              <div className='mph-enhanced-table-export'>
                <Button variant="outlined" color="primary" onClick={() => xlsxDownloader(xlsxData, 'Partners', `Partners.xlsx`)}>
                  Export
                </Button>
              </div>
              <div className="mph-enhanced-table-pagination">
                <TablePagination
                  rowsPerPageOptions={[
                    15,
                    25,
                    50,
                    { label: 'All', value: rows.length },
                  ]}
                  colSpan={columns.length}
                  count={rows.length}
                  rowsPerPage={pageSize}
                  page={pageIndex}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </div>
            </div>
          </TableRow>
        </div>
      </MaUTable>
    </TableContainer>
  )
}

EnhancedTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
}

export default EnhancedTable
