import React, { useEffect, useState } from "react"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { Form, Modal, Button, Col, InputGroup, Dropdown, Alert } from "react-bootstrap"
import { ApiClient } from "../ApiClient"
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper';

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

export default function LastGroupIdsModal(props) {

    const [data, setData] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (!props.show) return
        setLoading(true)
        ApiClient.get({
            url: "/api/partners/GetLastGroupIds",
        }).then(json => {
            setData(json.data)
        }).catch(err => {
            console.log(err)
        }).finally(() => setLoading(false));
    }, [props])

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <small>Last Utilized Group IDs</small>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="nav tabs example"
                >
                    {Object.keys(data)?.map((d, i) => (<Tab label={d} {...a11yProps(i)} />))}
                </Tabs>
                {Object.entries(data)?.map((d, i) => (
                    <TabPanel value={value} index={i}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Group Name</TableCell>
                                    <TableCell>Group ID</TableCell>
                                    <TableCell>Association</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {d[1]?.map(group => (
                                    <TableRow key={group.accountname}>
                                        <TableCell component="th" scope="row">
                                            {group.accountname}
                                        </TableCell>
                                        <TableCell>{group.group_id}</TableCell>
                                        <TableCell>{group.association_number}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TabPanel>
                ))}
            </Modal.Body>
        </Modal>
    )
}