import React from "react";
import { Card } from "react-bootstrap";
import "./Style/iso.scss";
import { shortIt, commaIt } from "./Utils"

export const TxnCard = ({ color, title, volume, txn, highest, lowest, profile, active_count, avg_active_count, tooltip, count, loading }) => {
    return <Card className="m-2 w-100 ">
                <Card.Header className={`py-2 pl-3 ${color}`}>
                    {title} 
                </Card.Header>
                <Card.Body className="pb-1">
                    {!loading ? <>
                        {volume && <h4 className="float-left">$ {shortIt(volume)}</h4>}
                        {count && <h4 className="float-left">{commaIt(count)}</h4>}
                        {txn && <h5 className="float-right text-muted">{((volume * 100) / txn).toFixed(2)}%</h5>}
                        {!isNaN(highest) && !isNaN(lowest) && 
                            <>
                            <span className="float-right text-success"><small>Highest: {shortIt(highest)}</small></span><br />
                            <span className="float-right text-danger"><small>Lowest: {shortIt(lowest)}</small></span>
                            </>
                        }
                    </> : <span className="float-left text-muted">Loading...</span>}
                </Card.Body>
                <Card.Body className="py-0 px-2">
                    {!loading && avg_active_count && <div className="alert alert-secondary mb-1 mx-1 py-1 px-2" role="alert">
                        <small>Avg # Merchants: {avg_active_count}</small>
                    </div>}
                </Card.Body>
                <Card.Body className="my-0 py-0 px-2">
                    {!loading && profile && <div className="d-flex justify-content-between">
                        {profile.pl_count > 0 && <div className="alert alert-primary mx-1 py-1 px-2" role="alert">
                            <small style={{ fontSize: ".7rem"}}>PayLo {Math.round((((profile.pl_count * 100) / active_count) + Number.EPSILON) * 100) / 100}%</small>
                        </div>}
                        {profile.tr_count > 0 && <div className="alert alert-warning mx-1 py-1 px-2" role="alert">
                            <small style={{ fontSize: ".7rem"}}>Traditional {Math.round((((profile.tr_count * 100) / active_count) + Number.EPSILON) * 100) / 100}%</small>
                        </div>}
                        {profile.hr_count > 0 && <div className="alert alert-info mx-1 py-1 px-2" role="alert">
                            <small style={{ fontSize: ".7rem"}}>High Risk {Math.round((((profile.hr_count * 100) / active_count) + Number.EPSILON) * 100) / 100}%</small>
                        </div>}
                        {profile.ns_count > 0 && <div className="alert alert-dark mx-1 py-1 px-2" role="alert">
                            <small style={{ fontSize: ".7rem"}}>Not Specified {Math.round((((profile.ns_count * 100) / active_count) + Number.EPSILON) * 100) / 100}%</small>
                        </div>}
                        
                    </div>}
                </Card.Body>
            </Card>
}