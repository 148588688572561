import React, { useEffect, useState } from "react"
import { Form, FormControl, Dropdown, ListGroup, OverlayTrigger, Tooltip } from "react-bootstrap"
import "../Style/Style.scss"
import { ApiClient } from "../../ApiClient"

export default function CustomSelect(props) {
    const [toggleDropDown, setToggleDropdown] = useState(false)
    const [selectedValues, setSelectedValues] = useState([])

    const onSelectHandle = (value, event) => {
        if(selectedValues?.includes(value)){
            const arr = [...selectedValues]
            arr.splice(arr.indexOf(value), 1)
            setSelectedValues([...arr])
            props.onSelect([...arr])
            return
        }
        setSelectedValues([value, ...selectedValues])
        props.onSelect([value, ...selectedValues])
    }

    const onToggleHandle = (v, e, s) => {
        if(!v && s.source === "select") return
        setToggleDropdown(v)
    }

    useEffect(() => {
        setSelectedValues(props.selectedValues || [])
    }, [props.selectedValues])

    return (
        <Dropdown onSelect={(v, e) => onSelectHandle(v, e)} onToggle={onToggleHandle} show={toggleDropDown}>
            <Dropdown.Toggle as={CustomToggle}>
                Select Group ID
            </Dropdown.Toggle>
            <Dropdown.Menu className="customSelectDropdownMenu">
                <ListGroup className="customSelectlistGroupMultiple">
                {props.data?.map((d, i) => 
                    <ListGroup.Item key={i} eventKey={d}>
                    <Form.Group>
                        <input className="mr-1" type="checkbox" onChange={() => {}} checked={selectedValues?.includes(d)} />
                        {d}
                    </Form.Group>
                    </ListGroup.Item>
                )}
                </ListGroup>
            </Dropdown.Menu>
        </Dropdown>
    )
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </a>
  ))
