export const commaIt = (num) => {
    if(num)
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const number_scales = ['B', 'M', 'K']

export const shortIt = (num, skip = undefined) => {
    if(Math.abs(num) >= 1000000000 && skip !== 'B')
        return commaIt(Math.sign(num)*((Math.abs(num)/1000000000).toFixed(1))) + 'B'
    else if(Math.abs(num) >= 1000000 && skip !== 'M')
        return commaIt(Math.sign(num)*((Math.abs(num)/1000000).toFixed(1))) + 'M'
    else if(Math.abs(num) >= 1000 && skip !== 'K')
        return commaIt(Math.sign(num)*((Math.abs(num)/1000).toFixed(1))) + 'K'
    else
        return Math.sign(num)*Math.abs(num).toFixed(2)
}