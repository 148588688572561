import React, { useEffect, useState, useContext } from "react";
import "./Style/iso.scss";
import { ApiClient } from "../../ApiClient"
import { Accordion, Spinner, Card, ProgressBar, useAccordionToggle, AccordionContext, Button } from "react-bootstrap";
import { ToggleButtonGroup, ToggleButton, DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap";
import { TxnPie } from "./TxnPie"
import { TxnCard } from "./TxnCard"
import { TxnLine } from "./TxnLine"
import { DealsCard } from "./DealsCard"
import { IncomeCard } from "./IncomeCard"
import moment from "moment";

export const ISO = (props) => {

    const [txnLoading, setTxnLoading] = useState(true);
    const [incomeLoading, setIncomeLoading] = useState(true);
    const [arraySize, setArraySize] = useState(13);
    const [txnData, setTxnData] = useState([]);
    const [incomeData, setIncomeData] = useState([]);
    const [isoMCC, setIsoMCC] = useState([]);
    const [selectedIso, setSelectedIso] = useState();
    const [pieData, setPieData] = useState();
    const [lineData, setLineData] = useState();
    const [totalTxnData, setTotalTxnData] = useState();
    const [totalIncomeData, setTotalIncomeData] = useState();
    const [filterLastValue, setFilterLastValue] = useState(0);
    const [filterYearValue, setFilterYearValue] = useState(-1);
    const [filterMonthValue, setFilterMonthValue] = useState(-2);
    const [filterDate, setFilterDate] = useState(moment().format("YYYY-MM-DD"));
    const [filterMCC, setFilterMCC] = useState(-2);

    const getTransactions = (monthly = true, date = undefined) => {
        setTxnLoading(true);
        ApiClient.post({
            url: "/api/exec/iso/GetTransactions",
            body: {
                groupIDs: selectedIso?.group_id?.split(','),
                date: date,
                monthly: monthly,
                mcc: filterMCC < 0 ? undefined : isoMCC[filterMCC].mcc_code
            }
        }).then(json => {
            setTxnData(json.data);
            setTxnLoading(false);
        }).catch(err => {
            setTxnLoading(false);
        });
    }

    const getIncome = (date = undefined) => {
        setIncomeLoading(true);
        ApiClient.post({
            url: "/api/exec/iso/isoGetIncome",
            body: {
                groupIDs: selectedIso?.group_id?.split(','),
                date: date,
                mcc: filterMCC < 0 ? undefined : isoMCC[filterMCC].mcc_code
            }
        }).then(json => {
            setIncomeData(json.data);
            setIncomeLoading(false);
        }).catch(err => {
            setIncomeLoading(false);
        });
    }

    const getMCC = () => {
        ApiClient.post({
            url: "/api/exec/iso/GetMCC",
            body: {
                groupIDs: selectedIso?.group_id?.split(',')
            }
        }).then(json => {
            setIsoMCC(json.data);
        }).catch(err => {
        });
    }

    const getLineData = () => {

        const volume = []
        const refunds = []
        const chargebacks = []

        const tempData = [...Array(arraySize).keys()].map(i => {
            
            const date = arraySize < 14 ? 
                                        moment.utc(filterDate).subtract((filterYearValue > -1 ? -1 : 1) * (i + (filterYearValue === -1 && moment().date() < 3 ? 1 : 0)), 'months').startOf('month').format("YYYY-MM-DD") : 
                                        moment.utc(filterDate).subtract((filterMonthValue >= 0 ? -1 : 1) * (i + (filterMonthValue >= 0 ? 0 : 2)), 'days').format("YYYY-MM-DD");
            const txn = txnData.find(d => moment.utc(d.txn_date).format("YYYY-MM-DD") === date)

            return txn || {
                txn_date: date, 
                txn_volume: 0, 
                txn_count: 0, 
                chbk_volume: 0, 
                chbk_count: 0, 
                ref_volume: 0, 
                ref_count: 0, 
                mid_count: 0,  
                active_count: 0,  
             }
        })
        tempData.sort((a, b) => moment(b.txn_date) > moment(a.txn_date) ? 1 : -1)

        tempData.reverse().forEach((d, i) => {
            volume.push({
                x: moment.utc(d.txn_date).format("YYYY-MM-DD"),
                y: d.txn_volume,
            });
            refunds.push({
                x: moment.utc(d.txn_date).format("YYYY-MM-DD"),
                y: d.ref_volume,
            });
            chargebacks.push({
                x: moment.utc(d.txn_date).format("YYYY-MM-DD"),
                y: d.chbk_volume,
            });
        });

        const data = [
            {
                id: "Volume",
                data: volume
            },
            // {
            //     id: "Refunds",
            //     data: refunds
            // },
            {
                id: "Chargebacks",
                data: chargebacks
            }
        ];
        setLineData(data);
    }

    const getPieData = () => {
        const data = [
            {
                id: "Volume",
                label: "Volume",
                value: totalTxnData.txn_volume,
                color: "hsl(170, 51%, 59%)"
            },
            // {
            //     id: "Refunds",
            //     label: "Refunds",
            //     value: totalTxnData.ref_volume,
            //     color: "hsl(53, 84%, 65%)"
            // },
            {
                id: "Chargebacks",
                label: "Chargebacks",
                value: totalTxnData.chbk_volume,
                color: "hsl(8, 87%, 66%)"
            }
        ];
        setPieData(data);
    }

    const getTotalTxnData = () => {
        setTotalTxnData({
            txn_volume: txnData.length !== 0 ? txnData.map(d => d.txn_volume).reduce((total, amt) => total + amt).toFixed(0) : 0,
            ref_volume: txnData.length !== 0 ? txnData.map(d => d.ref_volume).reduce((total, amt) => total + amt).toFixed(0) : 0,
            chbk_volume: txnData.length !== 0 ? txnData.map(d => d.chbk_volume).reduce((total, amt) => total + amt).toFixed(0) : 0,
            txn_count: txnData.length !== 0 ? txnData.map(d => d.txn_count).reduce((total, cnt) => total + cnt) : 0,
            ref_count: txnData.length !== 0 ? txnData.map(d => d.ref_count).reduce((total, cnt) => total + cnt) : 0,
            chbk_count: txnData.length !== 0 ? txnData.map(d => d.chbk_count).reduce((total, cnt) => total + cnt) : 0,
            mid_count: txnData.length !== 0 ? txnData.map(d => d.mid_count).reduce((total, cnt) => total + cnt) : 0,
            active_count: txnData.length !== 0 ? txnData.map(d => d.active_count).reduce((total, cnt) => total + cnt) : 0,
            profile:{
                tr_count: txnData.length !== 0 ? txnData.map(d => d.tr_count).reduce((total, cnt) => total + cnt) : 0,
                pl_count: txnData.length !== 0 ? txnData.map(d => d.pl_count).reduce((total, cnt) => total + cnt) : 0,
                hr_count: txnData.length !== 0 ? txnData.map(d => d.hr_count).reduce((total, cnt) => total + cnt) : 0,
                ns_count: txnData.length !== 0 ? txnData.map(d => d.ns_count).reduce((total, cnt) => total + cnt) : 0,
            },
            profile_txn:{
                tr_count: txnData.length !== 0 ? txnData.map(d => d.tr_txn).reduce((total, cnt) => total + cnt) : 0,
                pl_count: txnData.length !== 0 ? txnData.map(d => d.pl_txn).reduce((total, cnt) => total + cnt) : 0,
                hr_count: txnData.length !== 0 ? txnData.map(d => d.hr_txn).reduce((total, cnt) => total + cnt) : 0,
                ns_count: txnData.length !== 0 ? txnData.map(d => d.ns_txn).reduce((total, cnt) => total + cnt) : 0,
            },
            profile_chbk:{
                tr_count: txnData.length !== 0 ? txnData.map(d => d.tr_chbk).reduce((total, cnt) => total + cnt) : 0,
                pl_count: txnData.length !== 0 ? txnData.map(d => d.pl_chbk).reduce((total, cnt) => total + cnt) : 0,
                hr_count: txnData.length !== 0 ? txnData.map(d => d.hr_chbk).reduce((total, cnt) => total + cnt) : 0,
                ns_count: txnData.length !== 0 ? txnData.map(d => d.ns_chbk).reduce((total, cnt) => total + cnt) : 0,
            },
            profile_ref:{
                tr_count: txnData.length !== 0 ? txnData.map(d => d.tr_ref).reduce((total, cnt) => total + cnt) : 0,
                pl_count: txnData.length !== 0 ? txnData.map(d => d.pl_ref).reduce((total, cnt) => total + cnt) : 0,
                hr_count: txnData.length !== 0 ? txnData.map(d => d.hr_ref).reduce((total, cnt) => total + cnt) : 0,
                ns_count: txnData.length !== 0 ? txnData.map(d => d.ns_ref).reduce((total, cnt) => total + cnt) : 0,
            }
        });
    }

    const getTotalIncomeData = () => {

        if(filterLastValue === 0 || (filterYearValue > -1 && filterMonthValue < 0))
            setTotalIncomeData({
                gross_income: incomeData.length !== 0 ? incomeData.map(d => d.gross_income).reduce((total, amt) => total + amt).toFixed(0) : 0,
                net_income: incomeData.length !== 0 ? incomeData.map(d => d.net_income).reduce((total, amt) => total + amt).toFixed(0) : 0,
                expenses: incomeData.length !== 0 ? incomeData.map(d => d.expenses).reduce((total, amt) => total + amt).toFixed(0) : 0,
                payouts: incomeData.length !== 0 ? incomeData.map(d => d.payouts).reduce((total, amt) => total + amt).toFixed(0) : 0,
                mid_count: incomeData.length !== 0 ? incomeData.map(d => d.mid_count).reduce((total, cnt) => total + cnt) : 0,
            });
        else {
            setTotalIncomeData({
                gross_income: incomeData.length !== 0 ? incomeData.find(d => d.income_date.slice(0, 10) === moment(filterDate).startOf('month').format("YYYY-MM-DD"))?.gross_income : 0,
                net_income: incomeData.length !== 0 ? incomeData.find(d => d.income_date.slice(0, 10) === moment(filterDate).startOf('month').format("YYYY-MM-DD"))?.net_income : 0,
                expenses: incomeData.length !== 0 ? incomeData.find(d => d.income_date.slice(0, 10) === moment(filterDate).startOf('month').format("YYYY-MM-DD"))?.expenses : 0,
                payouts: incomeData.length !== 0 ? incomeData.find(d => d.income_date.slice(0, 10) === moment(filterDate).startOf('month').format("YYYY-MM-DD"))?.payouts : 0,
                mid_count: incomeData.length !== 0 ? incomeData.find(d => d.income_date.slice(0, 10) === moment(filterDate).startOf('month').format("YYYY-MM-DD"))?.mid_count : 0,
            });
        }
    }
    
    useEffect(() => {
        if(!totalTxnData) return;
        getPieData();
    }, [totalTxnData]);

    useEffect(() => {
        if(!selectedIso || filterLastValue < 0) return;
        setFilterYearValue(-1);
        setFilterMonthValue(-2);
        setArraySize(filterLastValue === 0 ? 13 : 31);
        setFilterDate(moment().format("YYYY-MM-DD"))
        getTransactions(filterLastValue === 0);
        getIncome()
    }, [selectedIso, filterLastValue, filterMCC]);

    useEffect(() => {
        if(!selectedIso || filterYearValue < 0) return;
        setFilterLastValue(-1);
        if(filterMonthValue === -2) setFilterMonthValue(-1);
        const date = moment(`${filterYearValue}-${filterMonthValue < 0 ? '01' : String(filterMonthValue + 1).padStart(2, '0')}-01`).format("YYYY-MM-DD");
        setArraySize(filterMonthValue === -1 ? 12 : moment().month(filterMonthValue).daysInMonth());
        setFilterDate(date);
        getTransactions(filterMonthValue < 0, date);
        getIncome(date)
    }, [selectedIso, filterYearValue, filterMonthValue, filterMCC]);

    useEffect(() => {
        if(!selectedIso) return;
        setFilterMCC(-2);
        getMCC();
    }, [selectedIso]);

    useEffect(() => {
        if(!txnData.length === 0) return;
        getLineData();
        getTotalTxnData();
    }, [txnData]);

    useEffect(() => {
        if(!incomeData.length === 0) return;
        getTotalIncomeData();
    }, [incomeData]);

    useEffect(() => {
        if(!props.name) return;
        setSelectedIso(props.name);
    }, [props.name]);

    function ContextAwareToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext);
      
        const decoratedOnClick = useAccordionToggle(
          eventKey,
          () => callback && callback(eventKey),
        );
      
        const isCurrentEventKey = currentEventKey === eventKey;
      
        return (
          <Button
            className='accordianToggle'
            onClick={decoratedOnClick}
            variant="outline-secondary"
            size="sm"
            block
          >
            {!isCurrentEventKey ? 'Show more...' : 'Show less...'}
          </Button>
        );
    }

    return (
    <>
        {txnLoading && <div className="isoLoading">
            <Spinner animation="grow" variant="info" size="lg" className="isoSpinner" />
        </div>}
        <div className="merchFilter">
            <ToggleButtonGroup className="mr-2" type="radio" size='sm' name="options" defaultValue={1} 
                               value={filterLastValue} onChange={(v) => setFilterLastValue(parseInt(v))} disabled={txnLoading}
            >
                <ToggleButton variant="outline-primary" value={0}>Last 12 Months</ToggleButton>
                <ToggleButton variant="outline-primary" value={1}>Last 30 Days</ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup className="mr-2" type="radio" size='sm' name="options" disabled={txnLoading}>
                <DropdownButton size='sm' as={ButtonGroup} variant={filterYearValue === -1 ? 'outline-primary' : 'primary'}
                                title={filterYearValue < 0 ? "Year" : filterYearValue} 
                                onSelect={(v) => setFilterYearValue(parseInt(v))} disabled={txnLoading}>
                    <Dropdown.Item eventKey={-1} disabled={true} className="active">Year</Dropdown.Item>
                    {[...Array(moment().year() - 2016).keys()].reverse().map(y => (
                        <Dropdown.Item key={y} eventKey={y + 2017} active={y + 2017 === filterYearValue}>{y + 2017}</Dropdown.Item>
                    ))}
                </DropdownButton>
                <DropdownButton size='sm' as={ButtonGroup} variant={filterMonthValue === -2 ? 'outline-primary' : 'primary'}
                                title={filterMonthValue < 0 ? ['Month', 'All'][filterMonthValue + 2] : moment().month(filterMonthValue).format("MMMM")} 
                                onSelect={(v) => setFilterMonthValue(parseInt(v))} disabled={txnLoading || filterYearValue < 0} 
                >
                    <Dropdown.Item eventKey={-2} disabled={true}>Month</Dropdown.Item>
                    <Dropdown.Item eventKey={-1} active={filterMonthValue === -1}>All</Dropdown.Item>
                    {[...Array(12).keys()].map(i => (
                        <Dropdown.Item key={i} eventKey={i} active={i === filterMonthValue}>{moment().month(i).format("MMMM")}</Dropdown.Item>
                    ))}
                </DropdownButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup type="radio" size='sm' name="options" disabled={txnLoading}>
                <DropdownButton size='sm' as={ButtonGroup} variant={filterMCC === -2 ? 'outline-primary' : 'primary'}
                                title={filterMCC < 0 ? ['MCC', 'All'][filterMCC + 2] : isoMCC[filterMCC].mcc_desc.substring(0, 35) + (isoMCC[filterMCC].mcc_desc.length > 35 ? '...' : '')} 
                                onSelect={(v) => setFilterMCC(parseInt(v))} disabled={txnLoading} 
                >
                    <Dropdown.Item eventKey={-2} disabled={true}>MCC</Dropdown.Item>
                    <Dropdown.Item eventKey={-1} active={filterMCC === -1}>All</Dropdown.Item>
                    {isoMCC.map((mcc, i) => (
                        <Dropdown.Item key={i} eventKey={i} active={i === filterMCC}>{mcc.mcc_code} - {mcc.mcc_desc}</Dropdown.Item>
                    ))}
                </DropdownButton>
            </ToggleButtonGroup>
        </div>
        <div className="isoTxn">
            <div className="d-flex justify-content-between">
                <TxnCard color="bg-txn" title="Volume"
                    volume={totalTxnData?.txn_volume}
                    profile={totalTxnData?.profile_txn}
                    active_count={totalTxnData?.active_count}
                    loading={txnLoading}
                />
                <TxnCard color="bg-ref" title="Transactions" 
                    count={totalTxnData?.txn_count} 
                    loading={txnLoading}
                />
                <TxnCard color="bg-tick" title="Avg Ticket" 
                    volume={(totalTxnData?.txn_volume / totalTxnData?.txn_count) || 0} 
                    loading={txnLoading}
                />
                <TxnCard color="bg-chbk" title="Chargebacks"
                    volume={totalTxnData?.chbk_volume} 
                    txn={totalTxnData?.txn_volume} 
                    profile={totalTxnData?.profile_chbk}
                    active_count={totalTxnData?.chbk_count}
                    loading={txnLoading}
                />
                <IncomeCard color="bg-income" title="SignaPay Income" 
                    gross={totalIncomeData?.gross_income || 0} 
                    net={totalIncomeData?.net_income || 0} 
                    expenses={totalIncomeData?.expenses || 0}
                    payouts={totalIncomeData?.payouts || 0}
                    loading={incomeLoading}
                />
                
            </div>
        </div>
        <div className="isoPie">
            {pieData && <TxnPie data={pieData} />}
        </div>
        <div className="isoLine">
            {lineData && <TxnLine data={lineData} />}
        </div>
        <div className="isoDeals">
            {selectedIso && <DealsCard data={selectedIso} />}
        </div>
        <div className="isoMCC">
            <Card className="m-2 p-0">
                <Card.Header className="bg-info text-light">Merchant Category Codes</Card.Header>
                <Accordion>
                    <Card.Body>
                            <div className="mb-1 text-left">
                                {isoMCC.slice(0, 6).map((mcc, i) => 
                                    <div className="mb-3" key={i}>
                                        <small>{mcc.mcc_desc} <strong>{((mcc.mcc_count * 100) / selectedIso.approved_deals).toFixed(2)}%</strong> <ProgressBar style={{height: "2px"}} variant="info" now={Math.round((mcc.mcc_count * 100) / selectedIso.approved_deals)} /></small>
                                    </div>
                                )}
                                <Accordion.Collapse eventKey="0">
                                <div>
                                {isoMCC.slice(6).map((mcc, i) => 
                                    <div className="mb-3" key={i}>
                                        <small>{mcc.mcc_desc} <strong>{((mcc.mcc_count * 100) / selectedIso.approved_deals).toFixed(2)}%</strong> <ProgressBar style={{height: "2px"}} variant="info" now={Math.round((mcc.mcc_count * 100) / selectedIso.approved_deals)} /></small>
                                    </div>
                                )}
                                </div>
                                </Accordion.Collapse>
                            </div>
                            {isoMCC.length > 6 &&
                            <ContextAwareToggle eventKey="0">
                                Show more...
                            </ContextAwareToggle>
                            }
                    </Card.Body>
                </Accordion>
            </Card>
        </div>
    </>
    )
}