import React, { useMemo, useState, useEffect } from "react";
import { ResponsiveLine } from '@nivo/line'
import { shortIt } from "./Utils"
import moment from "moment";

export const TxnLine = (props) => {

    const TREND_OFFSET = 2;
    // const keys = ["Transactions", "Refunds", "Chargebacks"]
    // const colors = ["hsl(170, 51%, 59%)", "hsl(53, 84%, 65%)", "hsl(8, 87%, 66%)"]
    const colors = ["hsl(170, 51%, 59%)", "hsl(8, 87%, 66%)"]
    const [hideTrend, setHideTrend] = useState(false);
    const [data, setData] = useState([]);

    const lineData = useMemo(
        () => hideTrend ? data.filter((item) => item.id !== 'Trend') : data,
        [['Trend'], data]
      );

    const Line = ({ series, lineGenerator, xScale, yScale }) => {
        const ser = series.map(({ id, data, color }, i) => (
            <path
                key={id}
                d={lineGenerator(
                    data.map(d => ({
                        x: xScale(d.data.x),
                        y: yScale(d.data.y),
                    }))
                )}
                fill="none"
                stroke={color}
                style={id === "Trend" ?  { strokeDasharray: '6, 6', strokeWidth: 3 } : { strokeWidth: 2 }}
            />
        ))
        return ser
    }

    useEffect(() => {
        if(!props.data[0] || 
            props.data[0].data.length < 2 ||
            moment(props.data[0].data[props.data[0].data.length - 1].x).year() !== moment().year() ||
            moment(props.data[0].data[props.data[0].data.length - 1].x).month() !== moment().month() || 
            props.data[0].data.length > 13 ||
            moment().date() < 3) {
                setData(props.data);
                return;
        }
        
        const trend = [
            {
                x: props.data[0].data[props.data[0].data.length - 2].x,
                y: props.data[0].data[props.data[0].data.length - 2].y
            },
            {
                x: props.data[0].data[props.data[0].data.length - 1].x,
                y: (props.data[0].data[props.data[0].data.length - 1].y / (moment().date() - TREND_OFFSET)) * moment().endOf('month').date()
            }
        ];
        const data = props.data.map(d => {
                return {
                    id: d.id,
                    data: d.data.slice(0, -1)  
                }
            })
        data.push(            {
            id: "Trend",
            data: trend
        });
        setData(data);
    }, [props.data])

    return <ResponsiveLine
    data={lineData}
    margin={{ top: 15, right: 110, bottom: 50, left: 45 }}
    xScale={{ 
        type: 'time',
        format: '%Y-%m-%d',
        useUTC: false,
        precision: 'day',
    }}
    yScale={{ type: 'linear', min: 0, max: 'auto', stacked: false, reverse: false }}
    yFormat=">-0,.2~f"
    axisTop={null}
    axisRight={null}
    colors={colors}
    areaOpacity={0}
    axisLeft={{
        format: value => `${shortIt(value)}`,
    }}
    axisBottom={{
        format: `${data[0] && data[0].data.length > 13 ? '%d' : '%b'}`,
        tickValues: `every 1 ${data[0] && data[0].data.length > 13 ? 'days' : 'months'}`
    }}
    layers={['grid', 'markers', 'areas', Line, 'slices', 'points', 'axes', 'legends', 'mesh', 'crosshair']}
    legends={[
        {
            dataFrom: "keys",
            data: props.data.map((d, index) => ({
                id: d.id,
                label: d.id,
                color: colors[index]
            })),
            toggleSerie: true,
            anchor: 'top-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
                {
                    on: 'hover',
                    style: {
                        itemBackground: 'rgba(0, 0, 0, .03)',
                        itemOpacity: 1
                    }
                }
            ],
            onClick: (datum) => {
                if(datum.id === "Volume")
                    setHideTrend(!hideTrend)
            }
        }
    ]}
    pointSize={10}
    pointColor={{ from: 'color' }}
    pointBorderWidth={0}
    pointBorderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
    pointLabelYOffset={-12}
    useMesh={true}
    enableCrosshair={true}
    enableSlices="x"
    sliceTooltip={({ slice }) => {
        return (
            <div
                style={{
                    background: 'white',
                    padding: '9px 12px',
                    border: '1px solid #ccc',
                }}
            >
            {slice.points.map((point) => (
                point.serieId === 'Trend' && slice.points.length > 1 ?
                <div key={point.id}></div> :
                <div
                    key={point.id}
                    style={{
                        // color: point.serieColor,
                        padding: '3px 0',
                    }}
                >
                    <small>{point.serieId}: <strong>${point.data.yFormatted}</strong></small>
                </div>
            )).reverse()}
            </div>
        )
    }}
/>

}