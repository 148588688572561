import React, { useContext, useState, useEffect } from 'react';
import { ApiClient } from "../../ApiClient"

const FeesContext = React.createContext();

export function useException(){
    return useContext(FeesContext);
}

export function FeesProvider({ children }) {

    const [exceptions, setExceptions] = useState()
    
    const setExceptionValue = (id, value) => {
        setExceptions(exceptions =>
            exceptions.map((exception) => 
                [
                    ...exception.map((exc) =>
                        exc.id === id ? {...exc, filter_value: value} : {...exc}    
                    )
                    
                ]
            )
        )
    }

    const setExceptionFilter = (id, value) => {
        setExceptions(exceptions =>
            exceptions.map((exception) => 
                [
                    ...exception.map((exc) =>
                        exc.id === id ? {...exc, filter_id: value, filter_operator_id: null} : {...exc}    
                    )
                    
                ]
            )
        )
    }

    const setExceptionOperator = (id, value) => {
        setExceptions(exceptions =>
            exceptions.map((exception) => 
                [
                    ...exception.map((exc) =>
                        exc.id === id ? {...exc, filter_operator_id: value} : {...exc}    
                    )
                    
                ]
            )
        )
    }

    const setExceptionFee = (index, fee_group, value) => {
        setExceptions(exceptions =>
            exceptions.map((exception, i) => 
                i === index ?
                [
                    ...exception.map((exc) =>
                        exc.fee_group === fee_group ? {
                                                        ...exc, 
                                                        ...value
                                                    } : {...exc}    
                )
                    
                ] : [...exception]
            )
        )
    }

    const addException = (id, fee_group) => {
        setExceptions(exceptions => 
            [...exceptions, 
            [{
                id: id,
                fee_flag: null,
                amount: null,
                iso_cost: null,
                filter_id: null,
                filter_value: null,
                filter_operator_id: null,
                fee_group: fee_group
            }]]
        )
    }

    const removeFilter = (index, id) => {
        setExceptions(exceptions =>
            exceptions.map((exception, i) => 
                index === i    
                ?   [ ...exception.filter(exc => exc.id !== id) ]
                :   [ ...exception ]
            ).filter(exception => exception.length !== 0)
        )
    }

    const addFilter = (index, fee_group, fee_flag, amount, iso_cost, id) => {
        setExceptions(exceptions =>
            exceptions.map((exception, i) => 
                index === i
                ?   [
                        ...exception,
                        {
                            id: id,
                            fee_flag: fee_flag,
                            amount: amount,
                            iso_cost: iso_cost,
                            filter_id: null,
                            filter_value: null,
                            filter_operator_id: null,
                            fee_group: fee_group
                        }
                    ]
                :   [ ...exception ]
            )
        )
    }

    const saveExceptions = async () => {
        let found_null = false
        exceptions.forEach(exception => {
            exception.forEach(el => {
                Object.keys(el).forEach(key => {
                     if(!found_null && el[key] == null){
                        found_null = true
                    }
                })
            })
        })
        console.log(exceptions)
        if(found_null) return false

        await ApiClient.post({
            url: "/api/pci/InsertFee",
            body: exceptions
        })

        return true
    }

    const value = {
        setExceptionValue: setExceptionValue,
        setExceptionFilter: setExceptionFilter,
        setExceptionOperator: setExceptionOperator,
        setExceptionFee: setExceptionFee,
        addNewException: addException,
        removeExceptionFilter: removeFilter,
        addNewExceptionFilter: addFilter,
        setExceptionList: setExceptions,
        saveExceptions: saveExceptions
    }

    return (
        <FeesContext.Provider value={value}>
            { children }
        </FeesContext.Provider>
    )
}