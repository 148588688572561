import React, { useEffect, useState } from "react"
import { Form, Modal, Button, Col, FormControl, Dropdown, Alert } from "react-bootstrap"
import { ApiClient } from "../../ApiClient"

export default function AddExcludeISOModal(props) {

    const [isoList, setIsoList] = useState([])
    const [selectedIndex, setSelectedIndex] = useState()
    const [warningMessage, setWarningMessage] = useState()
    const FLAG_NAMES = {
        W: "Weekly",
        M: "Monthly",
        PL: "PayLo",
        TR: "Traditional"
    }

    const onClose = () => {
        setSelectedIndex()
        setWarningMessage()
    }

    const onSave = () => {
        if(!selectedIndex) return

        const group_id = isoList[selectedIndex].group_id.replaceAll(' ', '').split(',').filter(gid => gid !== null || gid !== "")

        ApiClient.post({
            url: "/api/bonus/AddExcludeBonusISO",
            body: {
                profile: props.profile,
                period: props.period,
                group_id: group_id,
            }
        }).then(json => {
            if(json.data.success){
                props.onHide()
                props.onAdd()
                return
            }
            setWarningMessage(json.data.message)
        }).catch(err => {
            setWarningMessage(err)
        });
    }

    useEffect(() => {
        ApiClient.get({
            url: "/api/bonus/GetIsoList",
        }).then(json => {
            setIsoList(json.data);
        }).catch(err => {
        });

    }, [])

    return (
      <Modal
      show={props.show}
      onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onExited={onClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <small>Add {FLAG_NAMES[props.period]} {FLAG_NAMES[props.profile]} to Exclude List</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Group>
            <Form.Row>
            <Form.Label column="md" md={2} className="text-right">
                <Dropdown onSelect={setSelectedIndex}>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                        Select ISO
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu}>
                        {isoList.map((iso, i) => (
                            <Dropdown.Item key={i} eventKey={i}>{iso.iso_name}</Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
                </Form.Label>
                <Col>
                    <FormControl value={isoList[selectedIndex]?.iso_name || ""} disabled={true} />
                </Col>
            </Form.Row>
            <br />
            <Form.Row>
                <Form.Label column="md" md={2} className="text-right">
                    Group ID
                </Form.Label>
                <Col>
                    <FormControl value={isoList[selectedIndex]?.group_id} disabled={true} />
                </Col>
            </Form.Row>
        </Form.Group>
        {warningMessage && <Alert variant="danger" className="text-center">
            <small>{warningMessage}</small>
        </Alert>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>Close</Button>
          <Button onClick={onSave}>Save</Button>
        </Modal.Footer>
      </Modal>
    )
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </a>
  ))

const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');
  
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
        </div>
      );
    },
  );

