import React, { useState } from "react";
import PropTypes from "prop-types";
import { Filter, DefaultColumnFilter } from "../SelfServe/Filters";
import { useTable, useFilters, useSortBy } from "react-table";
import "react-table-6/react-table.css";
import DownloadModal from "../DownloadModal/DownloadModal";
import Button from "@material-ui/core/Button";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import MaUTable from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));
  
  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };
  
  const useStylesCusTable = makeStyles((theme) => ({
    spinner: {
      color: "#00a0dd",
      position: "center",
    },
    buttonText: {
      color: "#00a0dd",
    },
  }));

export default function CusTable({ columns, data, email, getData, loading }) {
    const classes = useStylesCusTable();
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      headerGroups,
      rows,
      prepareRow,
      allColumns,
      setAllFilters,
    } = useTable(
      {
        columns,
        data,
        initialState: {
          hiddenColumns: columns
            .filter((col) => col.visible === false)
            .map((col) => col.id),
        },
        defaultColumn: { Filter: DefaultColumnFilter },
        autoResetPage: false,
        autoResetExpanded: false,
        autoResetGroupBy: false,
        autoResetSelectedRows: false,
        autoResetSortBy: false,
        autoResetFilters: false,
        autoResetRowState: false,
      },
      useFilters,
      useSortBy
    );
  
    //const [filterInput, setFilterInput] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    // const [showSaveTextbox, setShowSaveTextbox] = useState(false);
    // const [saveTextboxVal, setSaveTextboxVal] = useState("");
    // const [snackbarText, setSnackbarText] = useState("");
    // const [open, setOpen] = useState(false);
    // const [reports, setReports] = useState();
    // const [reportCurVal, setReportCurVal] = useState("");
    // const [showReports, setShowReports] = useState(false);
    const months = Array.from({ length: 12 }, (item, i) => {
      return new Date(0, i).toLocaleString("en-US", { month: "short" });
    });
    const monthsIndex = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    var years = [];
    // CHANGE THIS TO THE DATE FROM WHICH YOU WANT THE MTH-YR DROPDOWN
    // var s = new Date("2021-01-02");
    var s = new Date("2016-05-02");
    var d = new Date();
  
    // const monthsYTD = Array.from({ length: d.getMonth() + 1 }, (item, i) => {
    //   return new Date(0, i).toLocaleString("en-US", { month: "short" });
    // });
    let yearsDiff = d.getFullYear() - s.getFullYear();
    for (var i = 0; i <= yearsDiff; i++)
      years.push((s.getFullYear() + i).toString());
    const [mthYr, setMthYr] = useState(
      d.getFullYear().toString() + "-" + monthsIndex[months[d.getMonth()]] + "-01"
    );
  
    // const emptyRows =
    //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangePageToZero = (event) => {
      setPage(0);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    // const handleFilterChange = (e) => {
    //   setFilterInput(e.target.value || undefined);
    //   // always go to the first page when using search bar or else results might not show up if not on first page
    //   // setPage(0);
    // };
  
    const generateSortingIndicator = (column) => {
      return column.isSorted ? (
        column.isSortedDesc ? (
          <ExpandMoreIcon />
        ) : (
          <ExpandLessIcon />
        )
      ) : (
        ""
      );
    };
  
    const toggleDownloadModal = () => {
      setShowDownloadModal(showDownloadModal ? false : true);
    };
  
    // const handleClose = (event, reason) => {
    //   if (reason === "clickaway") {
    //     return;
    //   }
    //   setOpen(false);
    // };
  
    // const toggleSaveTextbox = () => {
    //   if (
    //     showSaveTextbox === true &&
    //     saveTextboxVal !== "" &&
    //     saveTextboxVal !== undefined
    //   ) {
    //     // console.log(saveTextboxVal);
    //     // console.log(allColumns);
  
    //     //MAKE SURE IF ALL FILTERS ARE JUST EQUAL TYPE OR ELSE IT WILL NOT WORK
    //     var filtr = JSON.stringify(
    //       allColumns
    //         .filter((col) => col.filterValue !== undefined)
    //         .map((col) => ({
    //           [col.id]: col.filterValue,
    //         }))
    //     );
    //     // .join();
  
    //     var visCol = JSON.stringify(
    //       allColumns.filter((col) => col.isVisible === true).map((col) => col.id)
    //     );
    //     // .join();
  
    //     // console.log(filtr);
    //     // console.log(visCol);
  
    //     ApiClient
    //       .post({
    //         url: "/api/savereport",
    //         body: {
    //           email,
    //           saveTextboxVal,
    //           filtr,
    //           visCol,
    //         }
    //       })
    //       .then((json) => {
    //         if (Object.keys(json.data).length === 0) {
    //           //ERROR
    //           setSnackbarText("Fail!");
    //           setOpen(true);
    //         } else {
    //           setSnackbarText("Success");
    //           setOpen(true);
    //           getAllReports();
    //         }
    //       }).catch(err => {
    //         console.log(err);
    //       });
    //   }
    //   setShowSaveTextbox(showSaveTextbox ? false : true);
    // };
  
    const exportData = () => {
      // REMOVE hidden columns from the rows
      var visCol = allColumns
        .filter((col) => col.isVisible === false)
        .map((col) => col.id);
  
      return rows
        .map((e) => e.values)
        .map((obj) => {
          return Object.assign(
            {},
            ...Object.entries(obj).map(([key, value]) => {
              if (!visCol.includes(key)) {
                return { [key]: value };
              }
              return {};
            })
          );
        });
    };
  
    // const getAllReports = () => {
    //   ApiClient
    //     .post({
    //       url: "/api/getallreports",
    //       body: {
    //         email,
    //       }
    //     })
    //     .then((json) => {
    //       if (Object.keys(json.data).length === 0) {
    //         setShowReports(false);
    //       } else {
    //         setReports(json.data);
    //         setShowReports(true);
    //       }
    //     }).catch(err => {
    //       console.log(err);
    //     });
    // };
  
    //same as getAllReports except it runs in beginning only
    // useEffect(() => {
    //   getAllReports();
    // }, []);
  
    // useEffect(() => {
    //   async function newData() {
    //     await getData(
    //       mthYr,
    //       allColumns.filter((col) => col.isVisible === true).map((col) => col.id)
    //     );
    //   }
    //   newData();
    // }, [mthYr]);
  
    // const deleteReport = (name) => {
    //   ApiClient
    //     .post({
    //       url: "/api/deletereport",
    //       body: {
    //         email,
    //         name,
    //       }
    //     })
    //     .then((json) => {
    //       if (Object.keys(json.data).length === 0) {
    //         getAllReports();
    //       } else {
    //         getAllReports();
    //         resetFilters();
    //       }
    //     }).catch(err => {
    //       console.log(err);
    //     });
    // };
  
    // const getReport = (event) => {
    //   var name = event.target.value;
    //   // setReportCurVal(name);
    //   resetFilters(true);
    //   if (name !== "None" || name !== undefined) {
    //     ApiClient
    //       .post({
    //         url: "/api/getreport",
    //         body: {
    //           email,
    //           name,
    //         }
    //       })
    //       .then((json) => {
    //         if (Object.keys(json.data).length === 0) {
    //           //ERROR
    //         } else {
    //           setCols(
    //             JSON.parse(json.data[0].filters),
    //             JSON.parse(json.data[0].columns)
    //           );
    //         }
    //       }).catch(err => {
    //         console.log(err);
    //       });
    //   }
    // };
  
    // const setCols = (flt, cl) => {
    //   if (flt !== undefined && cl !== undefined) {
    //     for (var i = 0; i < allColumns.length; i++) {
    //       if (cl.includes(allColumns[i].id)) {
    //         allColumns[i].toggleHidden(false);
    //       } else {
    //         allColumns[i].toggleHidden(true);
    //       }
    //     }
    //     for (var i = 0; i < allColumns.length; i++) {
    //       for (var j = 0; j < flt.length; j++) {
    //         if (allColumns[i].id === Object.keys(flt[j])[0]) {
    //           allColumns[i].filterValue = Object.values(flt[j])[0];
    //           allColumns[i].setFilter(Object.values(flt[j])[0]);
    //           continue;
    //         }
    //       }
    //     }
    //   }
    // };
  
    const resetFilters = (...args) => {
      let isReport = false;
      if (args.length > 0 && args[0] === true)
        [isReport] = args;
      setAllFilters([]);
      // change reports dropdown to None
      // if (!isReport)
        // setReportCurVal("None");
    };
  
    // Render the UI for your table
    return (
      <>
        <div>
          <Button
            className={classes.buttonText}
            onClick={toggleDownloadModal}
            startIcon={<CloudDownloadIcon className={"selfserveIcon"} />}
          >
            Export
          </Button>
          <Button
            className={classes.buttonText}
            onClick={resetFilters}
            startIcon={<SettingsBackupRestoreIcon className={"selfserveIcon"} />}
          >
            Reset Filters
          </Button>
          {showDownloadModal ? (
            <DownloadModal
              data={exportData()}
              toggle={toggleDownloadModal}
              show={showDownloadModal}
              filename={`ISOStats_${mthYr}_`}
            />
          ) : null}
        </div>
        <Paper>
          {loading ? (
            <CircularProgress
              disableShrink
              className={classes.spinner}
              style={{
                width: "50",
                height: "50",
                display: "block",
                margin: "auto",
              }}
            />
          ) : (
            <TableContainer>
              <MaUTable {...getTableProps()}>
                <TableHead>
                  {headerGroups.map((headerGroup) => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <TableCell {...column.getHeaderProps()} style={{ minWidth: 100 }} align="center">
                          <div {...column.getSortByToggleProps()}>
                            {column.render("Header")}
                            {generateSortingIndicator(column)}
                          </div>
                          <Filter
                            column={column}
                            pagezero={handleChangePageToZero}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
  
                <TableBody>
                  {(rowsPerPage > 0
                    ? rows.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    : rows
                  ).map((row, i) => {
                    prepareRow(row);
                    return (
                      <TableRow {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <TableCell
                              {...cell.getCellProps()}
                              style={{ minWidth: 100 }}
                              align="center"
                            >
                              {cell.render("Cell")}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </MaUTable>
            </TableContainer>
          )}
  
          <TablePagination
            component="div"
            rowsPerPageOptions={[15, 50, 1000]}
            colSpan={3}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              native: true,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
      </>
    );
  }