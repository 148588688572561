import React, { Component } from "react";
import CardDeck from "react-bootstrap/CardColumns";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import moment from "moment";
import "./Style/processing.scss";
import { withCookies } from "react-cookie";
import BarCard from "./BarCard";
import TextCard from "./TextCard";
import PieCard from "./PieCard";
import { ApiClient } from "../ApiClient"

// // label_enable is custom field in the datasets (setBankTemplate)
// Chart.helpers.merge(Chart.defaults.global.plugins.datalabels, {
//   formatter: function (value, context) {
//     console.log(context.dataset);
//     if (!context.dataset.label_enable) {
//       return "";
//     }
//     return value;
//   },
// });

Chart.plugins.unregister(ChartDataLabels);

class Processing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      screenwidth: 0,
      aspectratio: 4,
      vol_mtd: 0,
      budget_mtd: 0,
      trendV: 0,
      trendP: 0,
      activationByMonth: "",
      activationData: [],
      showDownloadModal: false,
      vol_ct: 0,
      vol: 0,
      volByBank: 0,
      volByProfile: 0,
      cbcal_vol: 0,
      cbcal_ct: 0,
      esq_vol: 0,
      esq_ct: 0,
      pps_vol: 0,
      pps_ct: 0,
      chspk_vol: 0,
      chspk_ct: 0,
      hr_vol: 0,
      trad_vol: 0,
      paylo_vol: 0,
      none_vol: 0,
      daily_vol: 0,
      daily_vol_bench: 0,
      daily_vol_benchbymerch: 0,
      daily_vol_budget: 0,
      cbamt_data: "",
      volamt_data: "",
      avgvolamt_data: "",
      volamt_day_data: "",
      volamt_ytd: "",
      options: {
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var value =
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                if (parseInt(value) >= 1000) {
                return (
                  "$" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                );
              } else {
                return "$" + value;
              }
            },
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                precision: 0,
                scaleIntegersOnly: true,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                maxTicksLimit: 8,
                // suggestedMax: 650e6,
                beginAtZero: true,
                precision: 0,
                scaleIntegersOnly: true,
                callback: function (value, index, values) {
                  var maxVal = Math.max(...values);
                  if (maxVal <= 1000) {
                    return value;
                  } else if (maxVal > 1000 && maxVal < 1000000) {
                    return value / 1000 + "k";
                  } else if (maxVal >= 1000000) {
                    return value / 1e6 + "M";
                  }
                },
              },
            },
          ],
        },
        legend: {
          display: true,
          labels: {
            usePointStyle: true,
          }
        },
        plugins: {
          datalabels: {
            anchor: "end",
            align: "top",
            formatter: function (value, context) {
              var index = context.dataIndex;
              var val = "";
              // only print labels for monthly total i.e. PPS (top most bar)
              if (context.dataset.label === "PPS") {
                val = context.dataset.mth_total[index];
              }
              if (val < 1000) {
                return val;
              } else if (val >= 1000 && val < 1000000) {
                return (val / 1000).toFixed(0);
              } else if (val >= 1000000) {
                return (val / 1e6).toFixed(0);
              }
            },
            labels: {
              title: {
                color: "grey",
              },
              font: {
                weight: "bold",
              },
            },
            
          },
          
        },
      },
      options_avg: {
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              var value =
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              if (parseInt(value) >= 1000) {
                return (
                  "$" + value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                );
              } else {
                return "$" + value.toFixed(2);
              }
            },
          },
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                precision: 0,
                scaleIntegersOnly: true,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                maxTicksLimit: 10,
                suggestedMax: 25e6,
                beginAtZero: true,
                precision: 0,
                scaleIntegersOnly: true,
                callback: function (value, index, values) {
                  var maxVal = Math.max(...values);
                  if (maxVal <= 1000) {
                    return value;
                  } else if (maxVal > 1000 && maxVal < 1000000) {
                    return value / 1000 + "k";
                  } else if (maxVal >= 1000000) {
                    return value / 1e6 + "M";
                  }
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            anchor: "end",
            align: "top",
            formatter: function (value, context) {
              var index = context.dataIndex;
              var val = value;
              // only print labels for monthly total i.e. PPS (top most bar)
              // if (context.dataset.label === "PPS") {
              //   val = context.dataset.mth_total[index];
              // }
              if (val < 1000) {
                return val;
              } else if (val >= 1000 && val < 1000000) {
                return (val / 1000).toFixed(2);
              } else if (val >= 1000000) {
                return (val / 1e6).toFixed(2);
              }
            },
            labels: {
              title: {
                color: "grey",
              },
              font: {
                weight: "bold",
              },
            },
          },
        },
      },
      momentVal: moment().startOf('day'),
      historicData: false,
      options_daily: 0,
      options_pie: {
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var value =
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              if (parseInt(value) >= 1000) {
                return (
                  "$" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                );
              } else {
                return "$" + value;
              }
            },
          },
        },
      },
      options_activation: 0,
    };
  }

  getWindowDimensions = () => {
    this.setState({ screenwidth: window.innerWidth }, function () {
      if (this.state.screenwidth < 500) {
        this.setState({ aspectratio: 2 }, function () {
          this.setDailyChartOptions();
        });
      } else {
        this.setState({ aspectratio: 4 }, function () {
          this.setDailyChartOptions();
        });
      }
    });
  };

  setDailyChartOptions = () => {
    this.setState({
      options_daily: {
        aspectRatio: this.state.aspectratio,
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var value =
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                  .y;

              if (parseInt(value) >= 1000) {
                return (
                  "$" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                );
              } else {
                return "$" + value;
              }
            },
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                min: moment().subtract(1, "days").subtract(1, "months"),
                max: moment().subtract(2, "days"),
              },
              offset: true,
              type: "time",
              distribution: "linear",
              bounds: "data",
              time: {
                unit: "day",
                round: "day",
                displayFormats: {
                  day: "MMM D",
                },
                tooltipFormat: "YYYY-MM-DD",
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                maxTicksLimit: 6,
                beginAtZero: true,
                precision: 0,
                scaleIntegersOnly: true,
                callback: function (value, index, values) {
                  var maxVal = Math.max(...values);
                  if (maxVal <= 1000) {
                    return value;
                  } else if (maxVal > 1000 && maxVal < 1000000) {
                    return value / 1000 + "k";
                  } else if (maxVal >= 1000000) {
                    return value / 1e6 + "M";
                  }
                },
              },
            },
          ],
        },
        plugins: {
          zoom: {
            // Container for pan options
            pan: {
              enabled: true,
              mode: "x",
              // speed: 20,
              // threshold: 10,
            },
            // Container for zoom options
            zoom: {
              enabled: true,
              drag: false,
              mode: "x",
              speed: 0.1,
              sensitivity: 2,
            },
          },
          datalabels: {
            formatter: function (value, context) {
              if (context.dataset.label === "Volume") {
                let bm = context.dataset.percentdiff[context.dataIndex].y;
                return ((value.y / bm - 1) * 100).toFixed(1) + "%";
              } else {
                return "";
              }
            },
            labels: {
              title: {
                color: "white",
              },
            },
          },
        },
      },
    });
  };

  updatePrevCounter = () => {
    if (this.state.momentVal.format("YYYY") > 2020) {
      this.setState(
        {
          historicData: true,
          momentVal: this.state.momentVal.subtract(1, "years").endOf('year'),
        },
        function () {
          this.getData();
        }
      );
    }
  };

  updateNextCounter = () => {

    if (this.state.momentVal.format("YYYY") !== moment().format("YYYY")) {
      this.setState(
        {
          historicData: this.state.momentVal.year() + 1 < moment().year(),
          momentVal: this.state.momentVal.year() + 1 === moment().year() ? moment() : this.state.momentVal.add(1, "years").endOf('year'),
        },
        function () {
          this.getData();
        }
      );
    }
  };

  setBankTemplate = (data1, data2, data3, data4, data5) => {
    return {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "CBCAL",
          fill: true,
          backgroundColor: "rgba(88, 214, 141,1)",
          borderColor: "rgba(88, 214, 141,1)",
          data: data1,
          stack: 'bank',
        type: "bar",
        pointStyle: 'rect',
        order: 2
        // barThickness: 20
        },
        {
          label: "Esquire",
          fill: true,
          backgroundColor: "rgba(244, 208, 63,1)",
          borderColor: "rgba(244, 208, 63,1)",
          data: data2,
          stack: 'bank',
        type: "bar",
        pointStyle: 'rect',
        order: 2
        // barThickness: 20

        },
        {
          label: "Chesapeake",
          fill: true,
          backgroundColor: "rgba(191, 141, 242,1)",
          borderColor: "rgba(191, 141, 242,1)",
          data: data4,
          stack: 'bank',
        type: "bar",
        pointStyle: 'rect',
        order: 2
        // barThickness: 20

        },
        {
          label: "PPS",
          fill: true,
          backgroundColor: "rgba(93, 173, 226,1)",
          borderColor: "rgba(93, 173, 226,1)",
          data: data3,
          mth_total: data1.length && data1
            .map((v, i) => v + data2[i])
            .map((v, i) => v + data4[i])
            .map((v, i) => v + data3[i])
            .map((v, i) => 
            (v - (!this.state.historicData && this.state.data3.length > 0 && moment().month() === i && moment().date() > 2 ? 
              this.state.data3[this.state.data3.length - 1].txamt : 0))
            ),
            stack: 'bank',
        type: "bar",
        pointStyle: 'rect',
        order: 2
        // barThickness: 20
      },
        {
          label: "Budget",
          fill: true,
          backgroundColor: "rgba(235, 40, 40,0.5)",
          borderColor: "rgba(235,40,40,0)",
          // data: this.state.salesYTDGoals
          data: data5,
        type: "scatter",
        order: 1,
        pointStyle: 'rectRot',
      pointRadius: 5,
      pointHoverRadius: 5,
      },
        // {
        //   label: "",
        //   fill: false,
        //   backgroundColor: "rgba(255, 255, 255,1)",
        //   borderColor: "rgba(255, 255, 255,1)",
        //   hoverBackgroundColor: "rgba(255, 255, 255,1)",
        //   data: data1
        //     .map((v, i) => v + data2[i])
        //     .map((v, i) => (v + data3[i]).toFixed(2)),
        // },
      ],
    };
  };

  setAVgVolTemplate = (data1, data2, data3, data4, data5) => {
    return  {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          fill: false,
          backgroundColor: "rgba(93, 173, 226,1)",
          borderColor: "rgba(93, 173, 226,1)",
          data: data1.length && data1
          .map((v, i) => v + data2[i])
          .map((v, i) => v + data4[i])
          .map((v, i) => v + data3[i])
          .map((v, i) => 
            (v - (!this.state.historicData && this.state.data3.length > 0 && moment().month() === i && moment().date() > 2 ? 
              this.state.data3[this.state.data3.length - 1].txamt : 0)) / (!this.state.historicData && moment().month() === i ? Math.abs(data5[i] - 1) || 1 
              : 
              data5[i])
            ),
          
        }
      ],
    };
  };

  setVolByDay = (data1, data2, data3) => {
    return {
      datasets: [
        {
          label: "Volume",
          fill: false,
          backgroundColor: "rgba(88, 214, 141,1)",
          borderColor: "rgba(88, 214, 141,1)",
          data: data1,
          order: 2,
          percentdiff: data3,
        },
        // {
        //   label: "Benchmark",
        //   fill: false,
        //   backgroundColor: "rgba(244, 208, 63,1)",
        //   borderColor: "rgba(244, 208, 63,1)",
        //   data: data2,
        //   type: "line",
        //   order: 1,
        // },
        {
          label: "Budget",
          fill: false,
          backgroundColor: "rgba(93, 173, 226,1)",
          borderColor: "rgba(93, 173, 226,1)",
          data: data3,
          type: "line",
          order: 1,
        },
      ],
    };
  };

  setProfileTemplate = (data1, data2, data3, data4) => {
    return {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Traditional",
          fill: false,
          backgroundColor: "rgba(228, 182, 95,1)",
          borderColor: "rgba(228, 182, 95,1)",
          data: data1,
        },
        {
          label: "PayLo",
          fill: true,
          backgroundColor: "rgba(79, 92, 180,1)",
          borderColor: "rgba(79, 92, 180,1)",
          data: data2,
        },
        {
          label: "High Risk",
          fill: false,
          backgroundColor: "rgba(97, 204, 186,1)",
          borderColor: "rgba(97, 204, 186,1)",
          data: data3,
        },
        {
          label: "Unassigned",
          fill: false,
          backgroundColor: "rgba(166, 172, 175,1)",
          borderColor: "rgba(166, 172, 175,1)",
          data: data4,
        },
      ],
    };
  };

  setVolByBankTemplate = (data) => {
    return {
      labels: ["CBCAL", "Esquire", "PPS", "Chesapeake"],
      datasets: [
        {
          fill: false,
          backgroundColor: [
            "rgba(88, 214, 141,1)",
            "rgba(244, 208, 63,1)",
            "rgba(93, 173, 226,1)",
            "rgba(191, 141, 242,1)",
          ],
          borderColor: [
            "rgba(88, 214, 141,1)",
            "rgba(244, 208, 63,1)",
            "rgba(93, 173, 226,1)",
            "rgba(191, 141, 242,1)",
          ],
          data: [data[0], data[1], data[2], data[3]],
        },
      ],
    };
  };

  setActivation = (data) => {
    this.setState({
      options_activation: {
        aspectRatio: this.state.aspectratio,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                precision: 0,
                scaleIntegersOnly: true,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                maxTicksLimit: 6,
                beginAtZero: true,
                precision: 0,
                scaleIntegersOnly: true,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            anchor: "end",
            align: "top",
            labels: {
              title: {
                color: "gray",
              },
            },
          },
        },
      },
    });

    return {
      labels: data.map((d) => d.mth),
      datasets: [
        {
          label: "Activation",
          fill: false,
          backgroundColor: "rgba(93, 173, 226,1)",
          borderColor: "rgba(93, 173, 226,1)",
          data: data.map((d) => d.cnt),
        },
      ],
    };
  };

  toggleDownloadModal = () => {
    this.setState(
      {
        showDownloadModal: this.state.showDownloadModal ? false : true,
      },
      () => {
        this.getActivationData();
      }
    );
  };

  getActivationData = () => {
    ApiClient
      .post({
        url: "/api/getActivation",
        body: { 
          dt: this.state.momentVal.subtract(1, 'days').format("YYYY-MM-DD") 
        }
      })
      .then((json) => {
        if (Object.keys(json.data).length === 0) {
          this.setState({
            activationData: [],
            loading: false,
          });
        } else {
          this.setState({
            activationData: json.data,
            loading: false,
          });
        }
      }).catch(err => {
        console.log(err);
      });
  };


  prepareData = (bankName) => {
    return ([...Array(this.state.historicData ? 12 : moment().month() + 1).fill(0)])
    .map((v, i) => this.state.data.length && this.state.data
    .filter(({ bank, mth }) => bank.toLowerCase() === bankName && parseInt(mth.split('-')[1]) == (i + 1))
    .map((d) => d.txamt) || 0).map(d => d[0] || 0)
  };

  

  getData = () => {
    ApiClient
      .post({
        url: "/api/processinginfo",
        body: { 
          dt: this.state.historicData ? this.state.momentVal.format("YYYY-MM-DD") : this.state.momentVal.subtract(1, 'days').format("YYYY-MM-DD") ,
          historic: this.state.historicData
        }
      })
      .then((json) => {
        if (Object.keys(json.data).length === 0) {
          this.setState({
            loading: true,
          });
        } else {
          // data is by bank; data2 is by profile; data3 is daily
            this.setState(
            {
              data: json.data[0],
              data2: json.data[1],
              data3: json.data[2],
              data4: json.data[3],
              data5: json.data[4],
              data6: json.data[5],
              data7: json.data[6],
            },
            function () {
              // console.log(json.data[1])
              this.setState(
                {
                  vol: this.state.data.length && this.state.data
                    .map((d) => d.txamt)
                    .reduce((total, amt) => total + amt)
                    .toFixed(2),
                  vol_ct: this.state.data.length && this.state.data
                    .map((d) => d.txct)
                    .reduce((total, amt) => total + amt),

                  cbcal_vol: this.prepareData('cbcal'),
                  esq_vol: this.prepareData('esquire'),
                  pps_vol: this.prepareData('pps'),
                  chspk_vol: this.prepareData('chesapeake'),
                  cbcal_ct: this.state.data.length && this.state.data
                    .filter(({ bank }) => bank.toLowerCase() === "cbcal")
                    .map((d) => d.txct)
                    .reduce((total, amt) => total + amt, 0),
                  esq_ct: this.state.data.length && this.state.data
                    .filter(({ bank }) => bank.toLowerCase() === "esquire")
                    .map((d) => d.txct)
                    .reduce((total, amt) => total + amt, 0),
                  pps_ct: this.state.data.length && this.state.data
                    .filter(({ bank }) => bank.toLowerCase() === "pps")
                    .map((d) => d.txct)
                    .reduce((total, amt) => total + amt, 0),
                  chspk_ct: this.state.data.length && this.state.data
                    .filter(({ bank }) => bank.toLowerCase() === "chesapeake")
                    .map((d) => d.txct)
                    .reduce((total, amt) => total + amt, 0),
                  daily_vol: this.state.data3.map((d) => {
                    return {
                      x: moment(d.day.slice(0, 10)),
                      y: d.txamt,
                    };
                  }).slice(0, this.state.historicData ? this.state.data3.length : -1),
                  daily_vol_bench: this.state.data3.map((d) => {
                    return {
                      x: moment(d.day.slice(0, 10)),
                      y: d.benchamt,
                    };
                  }),
                  daily_vol_budget: this.state.data3.map((d) => {
                    return {
                      x: moment(d.day.slice(0, 10)),
                      y: d.budgetamt,
                    };
                  }),
                  daily_vol_benchbymerch: this.state.data3.map((d) => {
                    return {
                      x: moment(d.day.slice(0, 10)),
                      y: d.benchamtbymerch,
                    };
                  }),
                  hr_vol: this.state.data2
                    .filter(
                      ({ processor }) => processor && processor.toLowerCase() === "high risk"
                    )
                    .map((d) => d.txamt),
                  trad_vol: this.state.data2
                    .filter(
                      ({ processor }) =>
                      processor && processor.toLowerCase() === "traditional"
                    )
                    .map((d) => d.txamt),
                  paylo_vol: this.state.data2
                    .filter(
                      ({ processor }) => processor && processor.toLowerCase() === "paylo"
                    )
                    .map((d) => d.txamt),
                  none_vol: this.state.data2
                    .filter(
                      ({ processor }) => processor === null || processor === "None" || processor === "NULL" || processor === undefined //  && processor.toLowerCase() === "null"
                    )
                    .map((d) => d.txamt),
                  // activationByMonth: this.setActivation(this.state.data4),
                  vol_mtd: this.state.data3.length && this.state.data3
                    .filter(({ day }) => moment(day).isSameOrAfter(this.state.data5[0].start)
                      && moment(day).isSameOrBefore(this.state.data5[0].end))
                    .map((d) => d.txamt)
                    .reduce((total, amt) => total + amt)
                    .toFixed(2),
                  budget_mtd: this.state.data5[0].budget,
                  budget_yr: this.state.data7.map(d => d.budget),
                  bank_dates: this.state.data6.map((d) => {
                    var yester = this.state.historicData ? this.state.momentVal.endOf('year') : moment().startOf('day').subtract(1, 'days');
                    var batchDays = moment(d.end).startOf('day').diff(moment(d.start).startOf('day'), 'days')+1;
                    var batchDaysLeft = moment(d.end).startOf('day').diff(yester, 'days')+1; 
                    var batchDaysOver = batchDays - batchDaysLeft;
                    batchDaysOver = batchDaysOver === 0 ? 1 : batchDaysOver;
                    return !this.state.historicData && moment().startOf('day').year() === moment(d.end).startOf('day').year() && moment().startOf('day').month() === moment(d.end).startOf('day').month() ? batchDaysOver : batchDays;
                  }),
                },

                function () {
                  function abbreviateNumber(value) {
                    if (value <= 1000) {
                      return value;
                    } else if (value > 1000 && value < 1000000) {
                      return value / 1000 + "k";
                    } else if (value >= 1000000) {
                      return Math.round(value / 1e6) + "M";
                    }
                  }
                  
                  var trendPercent = 0;
                  var trendVal = 0;

                  if(this.state.historicData || moment().startOf('day').date() > 2 || moment().startOf('day').subtract(1, 'days').year() !== moment().startOf('day').year()){

                    var yester = this.state.historicData ? this.state.momentVal.endOf('year') : moment().startOf('day').subtract(1, 'days');
                    var batchDays = moment(this.state.data5[0].end).startOf('day').diff(moment(this.state.data5[0].start).startOf('day'), 'days')+1;
                    var batchDaysLeft = moment(this.state.data5[0].end).startOf('day').diff(yester, 'days')+1; 
                    
                    var batchDone = batchDays - batchDaysLeft;
                    if(!this.state.historicData && batchDone > 1){
                      batchDone--;
                    }
                    var thisMonthVolume = this.state.cbcal_vol[yester.month()]

                    thisMonthVolume += this.state.esq_vol[yester.month()]
                    thisMonthVolume += this.state.pps_vol[yester.month()]
                    thisMonthVolume += this.state.chspk_vol[yester.month()]
                    if(!this.state.historicData)
                      thisMonthVolume -= this.state.data3[this.state.data3.length - 1].txamt;

                      trendVal = (thisMonthVolume / batchDone) * batchDays;
                    trendPercent = (trendVal * 100) / this.state.budget_mtd;
                    trendVal = abbreviateNumber(trendVal)
                  }
                  var budget = abbreviateNumber(this.state.budget_mtd);
                  this.setState(
                    {
                      budget_mtd: budget,
                      trendP: Math.round(trendPercent),
                      trendV: trendVal,
                      volByBank: [
                        this.state.cbcal_vol.length && this.state.cbcal_vol
                          .reduce((total, amt) => total + amt)
                          .toFixed(2),
                          this.state.esq_vol.length && this.state.esq_vol
                          .reduce((total, amt) => total + amt)
                          .toFixed(2),
                          this.state.pps_vol.length && this.state.pps_vol
                          .reduce((total, amt) => total + amt)
                          .toFixed(2),
                          this.state.chspk_vol.length && this.state.chspk_vol
                          .reduce((total, amt) => total + amt)
                          .toFixed(2),
                      ],
                      volByProfile: [
                        this.state.hr_vol.length && this.state.hr_vol
                          .reduce((total, amt) => total + amt)
                          .toFixed(2),
                          this.state.trad_vol.length && this.state.trad_vol
                          .reduce((total, amt) => total + amt)
                          .toFixed(2),
                        this.state.paylo_vol.length && this.state.paylo_vol
                          .reduce((total, amt) => total + amt)
                          .toFixed(2),
                          this.state.none_vol.length && this.state.none_vol
                          .reduce((total, amt) => total + amt)
                          .toFixed(2),
                      ],
                      volamt_data: this.setBankTemplate(
                        this.state.cbcal_vol,
                        this.state.esq_vol,
                        this.state.pps_vol,
                        this.state.chspk_vol,
                        this.state.budget_yr
                      ),
                      avgvolamt_data: this.setAVgVolTemplate(
                        this.state.cbcal_vol,
                        this.state.esq_vol,
                        this.state.pps_vol,
                        this.state.chspk_vol,
                        this.state.bank_dates
                      ),
                      volamt_day_data: this.setVolByDay(
                        this.state.daily_vol,
                        this.state.daily_vol_bench,
                        this.state.daily_vol_budget
                      ),
                      volamt_profile_data: this.setProfileTemplate(
                        this.state.trad_vol,
                        this.state.paylo_vol,
                        this.state.hr_vol,
                        this.state.none_vol
                      ),
                    },
                    function () {
                      this.setState({
                        volamt_ytd: this.setVolByBankTemplate(
                          this.state.volByBank
                        ),
                        loading: false,
                      });
                    }
                  );
                }
              );
            }
          );
        }
      }).catch(err => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.getWindowDimensions();
    this.getData();
  }

  render() {

    return (
      <div>
        <div className="contentHeader">
          <ButtonGroup aria-label="prev-next">
            <Button className="navButton" onClick={this.updatePrevCounter}>
              <i className="material-icons">arrow_back_ios</i>
            </Button>
            <h3 className="navMonth">{this.state.momentVal.format("YYYY")}</h3>
            <Button className="navButton" onClick={this.updateNextCounter}>
              <i className="material-icons">arrow_forward_ios</i>
            </Button>
          </ButtonGroup>
        </div>
        <div className="content" style={{ padding: "20px 4% 0" }}>
          <CardDeck style={{ display: "flex" }}>
            <BarCard
              title={`Daily Processing Volume (\tBudget:${this.state.budget_mtd}\tTrending:${this.state.trendV}\tTrending/Budget:${this.state.trendP}%\t)`}
              ytd={this.state.vol}
              loading={this.state.loading}
              data={this.state.volamt_day_data}
              options={this.state.options_daily}
              plugins={ChartDataLabels}
            />
          </CardDeck>
          <CardDeck>
            <BarCard
              title={"Monthly Processing Volume by Bank"}
              loading={this.state.loading}
              data={this.state.volamt_data}
              options={this.state.options}
              plugins={ChartDataLabels}
            />
          {/* <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <Button variant="success">Hover me to see</Button>
            </OverlayTrigger> */}
            <BarCard
              title={"Monthly Avg Daily Processing Volume"}
              loading={this.state.loading}
              data={this.state.avgvolamt_data}
              options={this.state.options_avg}
              plugins={ChartDataLabels}
            />

            {/* <PieCard
              title={"YTD Processing Volume by Bank"}
              loading={this.state.loading}
              data={this.state.volamt_ytd}
              options={this.state.options_pie}
            /> */}
            <BarCard
              title={"Monthly Processing Volume by Profile"}
              loading={this.state.loading}
              data={this.state.volamt_profile_data}
              options={this.state.options}
              plugins={ChartDataLabels}
            />
            {/* <TextCard
              title={"YTD Summary"}
              loading={this.state.loading}
              volByBank={this.state.volByBank}
              avgByBank={[
                (this.state.volByBank[0] / this.state.cbcal_ct || 0).toFixed(2),
                (this.state.volByBank[1] / this.state.esq_ct || 0).toFixed(2),
                (this.state.volByBank[2] / this.state.pps_ct || 0).toFixed(2),
                (this.state.volByBank[3] / this.state.chspk_ct || 0).toFixed(2),
              ]}
              txByBank={[
                this.state.cbcal_ct,
                this.state.esq_ct,
                this.state.pps_ct,
                this.state.chspk_ct,
              ]}
              vol={this.state.vol}
            /> */}
          </CardDeck>
          {/*<CardDeck style={{ display: "flex" }}>
            <BarCard
              title={"Monthly Activation"}
              loading={this.state.loading}
              data={this.state.activationByMonth}
              options={this.state.options_activation}
              plugins={ChartDataLabels}
              isDownloadable={true}
              showDownloadModal={this.state.showDownloadModal}
              modalData={this.state.activationData}
              toggleDownloadModal={this.toggleDownloadModal}
              filename={"MonthlyActivation"}
            />
            </CardDeck> */}
        </div>
      </div>
    );
  }
}
export default withCookies(Processing);
