import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  FormText,
  Image
} from "react-bootstrap";
import "./Style/login.css";
import logo from './Style/PayLoSignaPay.png'
import { Auth } from "../Auth";

const ResetPassword = () => {

  const [message, setMessage] = useState('')
  const [resetCode, setResetCode] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    e.persist()

    const form = e.target
    const formData = new FormData(form)

    if(formData.get('password').length < 8){
      setMessage('Password length must be 8 characters or more')
      return
    }

    Auth.resetPassword(resetCode, formData.get('password')).then(() => {
        window.location.replace('/login')
    }).catch(e => {
      setMessage('Something went wrong! please try again')
    })
  }

  useEffect(() => {
    Auth.checkResetPassword(window.location.search.split('=')[1]).then(() => {
      setResetCode(window.location.search.split('=')[1])
    }).catch(() => {
      window.location.replace('/login')
    })
  }, [])


  return (
    <div className="Login">
        <Image src={logo} width="300px" style={{ marginBottom: "20px"}} />
        <form onSubmit={handleSubmit}>
          <FormGroup controlId="password">
              <FormLabel>New Password</FormLabel>
              <FormControl
                name="password"
                type="password"
              placeholder="New Password..."
              required={true}
              />
            </FormGroup>
          <Button
            block
            type="submit"
          >
            Reset Password
          </Button>
          <FormText className="text-muted">{message}</FormText>
        </form>
      </div>
  )

}

export default ResetPassword