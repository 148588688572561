import React, { useState } from 'react'

import Alert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox'
import MaUTable from '@material-ui/core/Table'
import PropTypes from 'prop-types'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TablePaginationActions from './Pagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TemporaryDrawer from "./Drawer";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DownloadModal from "../../DownloadModal/DownloadModal";
import Button from "@material-ui/core/Button";
import { Input, CustomInput } from "reactstrap";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import { ApiClient } from "../../ApiClient"
import TextField from "@material-ui/core/TextField";
import SaveIcon from "@material-ui/icons/Save";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Snackbar from "@material-ui/core/Snackbar";
import LinearProgress from "@material-ui/core/LinearProgress";
import "../Style/Style.scss";
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useFilters,
  useAsyncDebounce
} from 'react-table'

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <Checkbox ref={resolvedRef} {...rest} />
      </>
    )
  }
)

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter, filter },
}) {

  return (
    <Input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`...`}
      type={filter === 'date' ? 'date' : 'text'}
    />
  )
}

const inputStyle = {
  background: 'transparent',
  border: 1
}

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id, editable },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  const onChange = e => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    if(value !== initialValue)
      updateMyData(index, id, value)
  }

  const onKeyDown = (event) => {
    if(event.key === 'Enter' && value !== initialValue)
      updateMyData(index, id, value)
  }

  // If the initialValue is changed externall, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    editable ?
    <input
      // style={inputStyle}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
    /> :
    value
  )
}

function dateBetweenFilterFn(rows, id, filterValues) {
  const sd = filterValues[0] ? new Date(filterValues[0]) : undefined
  const ed = filterValues[1] ? new Date(filterValues[1]) : undefined

  if (ed || sd) {
    return rows.filter(r => {
      const cellDate = new Date(r.values[id])

      if (ed && sd) {
        return cellDate >= sd && cellDate <= ed
      } else if (sd){
        return cellDate >= sd
      } else if (ed){
        return cellDate <= ed
      }
    })
  } else {
    return rows
  }
}

// EditableCell.propTypes = {
//   cell: PropTypes.shape({
//     value: PropTypes.any.isRequired,
//   }),
//   row: PropTypes.shape({
//     index: PropTypes.number.isRequired,
//   }),
//   column: PropTypes.shape({
//     id: PropTypes.number.isRequired,
//   }),
//   updateMyData: PropTypes.func.isRequired,
// }

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
  Filter: DefaultColumnFilter,
}

const Table = ({
  columns,
  data,
  setData,
  updateMyData,
  skipPageReset,
  hasError,
  email,
  loading,
  setLoading,
}) => {

  const filterTypes = React.useMemo(
    () => ({
        dateBetween: dateBetweenFilterFn,
        text: (rows, id, filterValue) => {
            return rows.filter(row => {
                const rowValue = row.values[id];
                return rowValue !== undefined
                    ? String(rowValue)
                        .toLowerCase()
                        .startsWith(String(filterValue).toLowerCase())
                    : true;
            });
        }
    }),
    []
);

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    state,
    gotoPage,
    setPageSize,
    allColumns,
    setAllFilters,
    visibleColumns,
    getToggleHideAllColumnsProps,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetPage: !skipPageReset,
      autoResetPage: !skipPageReset,
      autoResetExpanded: !skipPageReset,
      autoResetGroupBy: !skipPageReset,
      autoResetSelectedRows: !skipPageReset,
      autoResetSortBy: !skipPageReset,
      autoResetFilters: !skipPageReset,
      autoResetRowState: !skipPageReset,
      updateMyData,
      initialState: {
        hiddenColumns: columns
          .filter((col) => col.visible === false)
          .map((col) => col.accessor),
        pageSize: 25
      },
      filterTypes
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
    // hooks => {
    //   hooks.allColumns.push(columns => [
    //     // Let's make a column for selection
    //     {
    //       id: 'selection',
    //       // The header can use the table's getToggleAllRowsSelectedProps method
    //       // to render a checkbox.  Pagination is a problem since this will select all
    //       // rows even though not all rows are on the current page.  The solution should
    //       // be server side pagination.  For one, the clients should not download all
    //       // rows in most cases.  The client should only download data for the current page.
    //       // In that case, getToggleAllRowsSelectedProps works fine.
    //       Header: ({ getToggleAllRowsSelectedProps }) => (
    //         <div>
    //           <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
    //         </div>
    //       ),
    //       // The cell can use the individual row's getToggleRowSelectedProps method
    //       // to the render a checkbox
    //       Cell: ({ row }) => (
    //         <div>
    //           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //         </div>
    //       ),
    //     },
    //     ...columns,
    //   ])
    // }
  )


  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showSaveTextbox, setShowSaveTextbox] = useState(false);
  const [saveTextboxVal, setSaveTextboxVal] = useState("");
  const [snackbarText, setSnackbarText] = useState("");
  const [open, setOpen] = useState(false);
  const [reports, setReports] = useState();
  const [reportCurVal, setReportCurVal] = useState("");
  const [showReports, setShowReports] = useState(false);


  const handleChangePage = (event, newPage) => {
    gotoPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setPageSize(Number(event.target.value))
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const toggleDownloadModal = () => {
    setShowDownloadModal(showDownloadModal ? false : true);
  };

  const toggleSaveTextbox = () => {
    if (
      showSaveTextbox === true &&
      saveTextboxVal !== "" &&
      saveTextboxVal !== undefined
    ) {
      // console.log(saveTextboxVal);
      // console.log(allColumns);

      //MAKE SURE IF ALL FILTERS ARE JUST EQUAL TYPE OR ELSE IT WILL NOT WORK
      var filtr = JSON.stringify(
        allColumns
          .filter((col) => col.filterValue !== undefined)
          .map((col) => ({
            [col.id]: col.filterValue,
          }))
      );
      // .join();

      var visCol = JSON.stringify(
        allColumns.filter((col) => col.isVisible === true).map((col) => col.id)
      );
      // .join();

      // console.log(filtr);
      // console.log(visCol);

      ApiClient
        .post({
          url: "/api/saveACHreport",
          body: {
            email,
            saveTextboxVal,
            filtr,
            visCol,
          }
        })
        .then((json) => {
          if (Object.keys(json.data).length === 0) {
            //ERROR
            setSnackbarText("Fail!");
            setOpen(true);
          } else {
            setSnackbarText("Success");
            setOpen(true);
            getAllReports();
          }
        }).catch(err => {
          console.log(err);
        });
    }
    setShowSaveTextbox(showSaveTextbox ? false : true);
  };

  const getAllReports = () => {
    ApiClient
      .post({
        url: "/api/getallACHreports",
        body: {
          email,
        }
      })
      .then((json) => {
        if (Object.keys(json.data).length === 0) {
          setShowReports(false);
        } else {
          setReports(json.data);
          setShowReports(true);
        }
      }).catch(err => {
        console.log(err);
      });
  };

  const exportData = () => {
    // REMOVE hidden columns from the rows
    var visCol = allColumns
      .filter((col) => col.isVisible === false)
      .map((col) => col.id);

    return rows
      .map((e) => e.values)
      .map((obj) => {
        return Object.assign(
          {},
          ...Object.entries(obj).map(([key, value]) => {
            if (!visCol.includes(key)) {
              return { [key]: value };
            }
            return {};
          })
        );
      });
  };

  const getReport = (event) => {
    var name = event.target.value;
    setReportCurVal(name);
    resetFilters(true);
    if (name !== "None" || name !== undefined) {
      setLoading(true);
      ApiClient
        .post({
          url: "/api/getachreport",
          body: {
            email,
            name,
          }
        })
        .then((json) => {
          if (Object.keys(json.data).length === 0) {
              setLoading(false)
          } else {
              setCols(
              JSON.parse(json.data[0].filters),
              JSON.parse(json.data[0].columns)
            );
          }
        }).catch(err => {
          console.log(err);
        });
    }
  };

  const deleteReport = (name) => {
    ApiClient
      .post({
        url: "/api/deleteachreport",
        body: {
          email,
          name,
        }
      })
      .then((json) => {
        if (Object.keys(json.data).length === 0) {
          getAllReports();
        } else {
          getAllReports();
          resetFilters();
        }
      }).catch(err => {
        console.log(err);
      });
  };

  const setCols = (flt, cl) => {
    if (flt !== undefined && cl !== undefined) {
      for (var i = 0; i < allColumns.length; i++) {
        if (cl.includes(allColumns[i].id)) {
          allColumns[i].toggleHidden(false);
        } else {
          allColumns[i].toggleHidden(true);
        }
      }
      for (var i = 0; i < allColumns.length; i++) {
        for (var j = 0; j < flt.length; j++) {
          if (allColumns[i].id === Object.keys(flt[j])[0]) {
            allColumns[i].filterValue = Object.values(flt[j])[0];
            allColumns[i].setFilter(Object.values(flt[j])[0]);
            continue;
          }
        }
      }
    }
    setLoading(false);
  };

  const resetFilters = (...args) => {
    let isReport = false;
    if (args.length > 0 && args[0] === true)
      [isReport] = args;
    setAllFilters([]);
    // change reports dropdown to None
    if (!isReport)
      setReportCurVal("None");
  };


  // Render the UI for your table
  return (
    <>
    <div className="PCIToolbar">
      <TemporaryDrawer
            allCols={allColumns}
            hideAllColumns={getToggleHideAllColumnsProps()}
          />
        <Button
            onClick={toggleDownloadModal}
            startIcon={<CloudDownloadIcon />}
            style={{ color: "#00a0dd" }}
      >
        Export
      </Button>
      {showDownloadModal ? (
            <DownloadModal
              data={exportData()}
              toggle={toggleDownloadModal}
              show={showDownloadModal}
              filename={"PCI-EXPORT-"}
            />
          ) : null}
        {/* <Button
            onClick={resetFilters}
            startIcon={<SettingsBackupRestoreIcon />}
            style={{ color: "#00a0dd" }}
            disabled={true}
          >
            Reset Filters
        </Button> */}
        {showSaveTextbox ? (
            <TextField
              required
              type="search"
              helperText="Enter name of report"
              value={saveTextboxVal}
              onChange={(e) => {
                setSaveTextboxVal(e.target.value.trim() || undefined);
              }}
            />
          ) : null}
          {/* <Button
            onClick={toggleSaveTextbox}
            startIcon={<SaveIcon />}
            style={{ color: "#00a0dd" }}
            disabled={true}
          >
            Save Report
          </Button> */}
          <div style={{position: "absolute", right: 11, top: 100, zIndex: "4"}}>
            {hasError && <Alert severity="error">Cannot update the database! try again</Alert>}
          </div>
          {showReports ? (
            <>
              <Button
                onClick={null}
                startIcon={<BookmarkBorderIcon />}
                style={{ color: "#00a0dd" }}
                disabled={true}
            >
                Reports
              </Button>

              <FormControl style={{ display: "inline-block" }}>
                <Select
                  value={reportCurVal}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={getReport}
                  style={{ minWidth: 100 }}
                >
                  <MenuItem value="None">None</MenuItem>
                  {reports.map((column, index) => (
                    <MenuItem value={column.reportName} key={index}>
                      {column.reportName}
                    </MenuItem>
                  ))}
                </Select>
                {reportCurVal !== "None" && reportCurVal !== "" ? (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => deleteReport(reportCurVal)}
                  >
                    <DeleteIcon style={{ color: "#d32f2f" }} />
                  </IconButton>
                ) : null}
              </FormControl>
            </>
          ) : null}
          {showDownloadModal ? (
            <DownloadModal
              data={exportData()}
              toggle={toggleDownloadModal}
              show={showDownloadModal}
              filename={"PCI-EXPORT-"}
            />
          ) : null}
          {open ? (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={open}
              autoHideDuration={3000}
              onClose={handleClose}
              message={snackbarText}
            />
          ) : null}
      </div>
    <TableContainer className="PCITable">
      {/* <TableToolbar
        numSelected={Object.keys(selectedRowIds).length}
        deleteUserHandler={deleteUserHandler}
        addUserHandler={addUserHandler}
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
      /> */}
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
              <TableCell
                {...column.getHeaderProps()}
                  style={{minWidth: 120, paddingRight: 2, fontSize: 12, position: 'sticky', top: 0, background: '#fff', zIndex: '1' }}
                >
                <div {...column.getSortByToggleProps()}>
                {column.render('Header')}
                  <TableSortLabel
                    active={column.isSorted}
                    // react-table has a unsorted state which is not treated here
                    direction={column.isSortedDesc ? 'desc' : 'asc'}
                  />
                </div>
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <TableCell {...cell.getCellProps()}
                    style={{ minWidth: 150, paddingRight: 2, fontSize: 12, zIndex: '0' }}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>

        <TableFooter>
          <TableRow>
          {loading && <div className="spinner">
              <LinearProgress  />
            </div>}
            <TablePagination
              rowsPerPageOptions={[
                25,
                50,
                100,
                200,
                500,
                // { label: 'All', value: data.length },
              ]}
              style={{bottom: '0', position: 'fixed', width: "100%", right: '10px'}}
              count={rows.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </MaUTable>
    </TableContainer>
    </>
  )
}

// ACHTable.propTypes = {
//   columns: PropTypes.array.isRequired,
//   data: PropTypes.array.isRequired,
//   updateMyData: PropTypes.func.isRequired,
//   setData: PropTypes.func.isRequired,
//   skipPageReset: PropTypes.bool.isRequired,
// }

export default Table

