import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import Row from "./Row";

class FieldTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0
    };
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      height: this.tableRef.current.offsetWidth / 2
    });
  }

  render() {
    var rows = [];
    var totalCount = 0;
    var totalSubCount = 0;

    for (var i = 0; i < this.props.data[0].ISOName.length; i++) {
      if (this.props.isMonth < 2) {
        rows.push(
          <Row
            name={this.props.data[0].ISOName[i]}
            count={this.props.data[0].count[i]}
            data={this.props.data[0].data}
            func={this.props.func}
          />
        );
        totalCount = totalCount + this.props.data[0].count[i];
        totalSubCount++;
      } else {
        rows.push(
          <Row
            name={this.props.data[0].ISOName[i]}
            count2={this.props.data[0].count2[i]}
            count={this.props.data[0].count[i]}
            data={this.props.data[0].data}
            func={this.props.func}
          />
        );
        totalCount = totalCount + this.props.data[0].count[i];
        totalSubCount = totalSubCount + this.props.data[0].count2[i];
      }
      
    }

    return (
      <div
        className="customTable"
        ref={this.tableRef}
        style={{ height: this.state.height }}
      >
        <Table responsive style={{ marginBottom: 0 }}>
          <thead />

          <tbody>
            <tr>
              <td style={{ fontWeight: "bold" }}>Deals</td>
              {this.props.isMonth === 2 ? (<td style={{ fontWeight: "bold" }}>{totalSubCount}</td>) : (<td style={{ fontWeight: "bold" }}>{}</td>) }
              {/*<td style={{ fontWeight: "bold" }}>{totalSubCount}</td>*/}
              <td style={{ fontWeight: "bold" }}>{totalCount}</td>
            </tr>
            {this.props.isMonth < 2 &&
            <tr>
              <td style={{ fontWeight: "bold" }}>Partners</td>
              <td style={{ fontWeight: "bold" }}>{}</td>
              <td style={{ fontWeight: "bold" }}>{totalSubCount}</td>
            </tr>}
            {rows}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default FieldTable;
