import React, { Component } from "react";
import "./Style/selfserve.scss";
import { withCookies } from "react-cookie";
import moment from "moment";
import UploadModal from "../UploadModal/UploadModal";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import CusTable from "./CusTable";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  SelectColumnFilter,
  MinMaxAmtColumnFilter,
  MinMaxNumColumnFilter,
  AutoMultiSelectColumnFilter,
} from "./Filters";
import { ApiClient } from "../ApiClient";

class ResidualSelfServe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      momentVal: moment(),
      data: [],
      loading: true,
      showImportModal: false,
      searchValue: "",
      startIndex: 0,
      saveVis: [],
    };
  }

  updatePartnerView = (...args) => {
    var dt = null;
    if (args.length > 0) {
      dt = args[0];
    }
    this.setState({
      loading: true,
      saveVis: args[1],
    });
    ApiClient
      .post({
        url: "/api/ResidualSelfServe",
        body: {
          dt,
        }
      })
      .then((json) => {
        if (Object.keys(json.data).length === 0) {
          this.setState({
            data: [],
            loading: false,
          });
        } else {
          this.setState({
            data: json.data,
            loading: false,
          });
        }
      }).catch(err => {
        console.log(err);
      });
  };

  toggleImportModal = () => {
    this.setState({
      showImportModal: this.state.showImportModal ? false : true,
    });
  };

  getData = (val) => {
    ApiClient
      .post({
        url: "/api/searchResidualSelfServe",
        body: {
          val,
        }
      })
      .then((json) => {
        if (Object.keys(json.data).length === 0) {
          this.setState({
            data: [],
            loading: false,
          });
        } else {
          this.setState({
            data: json.data,
            loading: false,
          });
        }
      }).catch(err => {
        console.log(err);
      });
  };

  updatePrevCounter = () => {
    let { startIndex } = this.state;
    if (startIndex > 0) {
      this.setState({ startIndex: startIndex - 1 }, () => {
        this.updateView();
      });
    }
  };

  updateNextCounter = () => {
    let { data, startIndex } = this.state;
    if (data.length > startIndex * 60 + 60) {
      this.setState({ startIndex: startIndex + 1 }, () => {
        this.updateView();
      });
    }
  };

  handleChange = (event) => {
    clearTimeout(this.timer);
    this.setState({
      searchValue: event.target.value,
      startIndex: 0,
      loading: true,
    });
    if (event.target.value !== "") {
      this.timer = setTimeout(() => {
        this.getData(this.state.searchValue);
      }, 1000);
    } else {
      this.updatePartnerView();
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  UNSAFE_componentWillMount() {
    this.timer = null;
  }

  componentDidMount() {
    this.updatePartnerView();
  }

  render() {
    const { cookies } = this.props;
    const dept = cookies.get("dept");
    const email = cookies.get("email");

    const columns = [
      {
        Header: "Date",
        id: "YearMonth",
        accessor: "YearMonth",
        Filter: AutoMultiSelectColumnFilter,
        filter: "includesSome",
      },
      {
        Header: "MID",
        id: "MID",
        accessor: "MID",
      },
      {
        Header: "DBA",
        id: "DBA",
        accessor: "DBA",
      },
      {
        Header: "Processor",
        id: "Processor",
        accessor: "Processor",
        Filter: AutoMultiSelectColumnFilter,
        filter: "includesSome",
      },
      {
        Header: "Agent",
        id: "Agent",
        accessor: "Agent",
        Filter: AutoMultiSelectColumnFilter,
        filter: "includesSome",
      },
      {
        Header: "Office",
        id: "Office",
        accessor: "Office",
        Filter: AutoMultiSelectColumnFilter,
        filter: "includesSome",
      },
      {
        Header: "PayoutSchedule",
        id: "PayoutSchedule",
        accessor: "PayoutSchedule",
        Filter: AutoMultiSelectColumnFilter,
        filter: "includesSome",
      },
      {
        Header: "PayoutTo",
        id: "PayoutTo",
        accessor: "PayoutTo",
        Filter: AutoMultiSelectColumnFilter,
        filter: "includesSome",
      },
      {
        Header: "Transactions",
        id: "Transactions",
        accessor: "Transactions",
        Filter: MinMaxAmtColumnFilter,
        filter: "between",
      },
      {
        Header: "Volume",
        id: "Volume",
        accessor: "Volume",
        Filter: MinMaxAmtColumnFilter,
        filter: "between",
      },
      {
        Header: "Income",
        id: "Income",
        accessor: "Income",
        Filter: MinMaxAmtColumnFilter,
        filter: "between",
      },
      {
        Header: "Gross",
        id: "Gross",
        accessor: "Gross",
        Filter: MinMaxAmtColumnFilter,
        filter: "between",
      },
      {
        Header: "Net",
        id: "Net",
        accessor: "Net",
        Filter: MinMaxAmtColumnFilter,
        filter: "between",
      },
      {
        Header: "ACHCollectedGross",
        id: "ACHCollectedGross",
        accessor: "ACHCollectedGross",
        Filter: MinMaxAmtColumnFilter,
        filter: "between",
      },
      {
        Header: "ACHCollectedPayout",
        id: "ACHCollectedPayout",
        accessor: "ACHCollectedPayout",
        Filter: MinMaxAmtColumnFilter,
        filter: "between",
      },
      {
        Header: "ACHCollectedNet",
        id: "ACHCollectedNet",
        accessor: "ACHCollectedNet",
        Filter: MinMaxAmtColumnFilter,
        filter: "between",
      },
      {
        Header: "ACHRejectedGross",
        id: "ACHRejectedGross",
        accessor: "ACHRejectedGross",
        Filter: MinMaxAmtColumnFilter,
        filter: "between",
      },
      {
        Header: "ACHRejectedNet",
        id: "ACHRejectedNet",
        accessor: "ACHRejectedNet",
        Filter: MinMaxNumColumnFilter,
        filter: "between",
      },
      {
        Header: "RefundsGross",
        id: "RefundsGross",
        accessor: "RefundsGross",
        Filter: MinMaxNumColumnFilter,
        filter: "between",
      },
      {
        Header: "RefundsPayout",
        id: "RefundsPayout",
        accessor: "RefundsPayout",
        Filter: MinMaxNumColumnFilter,
        filter: "between",
      },
      {
        Header: "RefundsNet",
        id: "RefundsNet",
        accessor: "RefundsNet",
        Filter: MinMaxNumColumnFilter,
        filter: "between",
      },
      {
        Header: "TotalGross",
        id: "TotalGross",
        accessor: "TotalGross",
        Filter: MinMaxNumColumnFilter,
        filter: "between",
      },
      {
        Header: "TotalPayout",
        id: "TotalPayout",
        accessor: "TotalPayout",
        Filter: MinMaxNumColumnFilter,
        filter: "between",
      },
      {
        Header: "TotalNet",
        id: "TotalNet",
        accessor: "TotalNet",
        Filter: MinMaxNumColumnFilter,
        filter: "between",
      },
      {
        Header: "Income1",
        id: "Income1",
        accessor: "Income1",
        Filter: MinMaxNumColumnFilter,
        filter: "between",
      },
      {
        Header: "Expense",
        id: "Expense",
        accessor: "Expense",
        Filter: MinMaxNumColumnFilter,
        filter: "between",
      },
      {
        Header: "ProfitLoss",
        id: "ProfitLoss",
        accessor: "ProfitLoss",
        Filter: MinMaxNumColumnFilter,
        filter: "between",
      },

    ];

    if (this.state.saveVis !== undefined && this.state.saveVis.length !== 0) {
      let cl = this.state.saveVis;
      for (var i = 0; i < columns.length; i++) {
        if (cl.includes(columns[i].id)) {
          columns[i].visible = true;
        } else {
          columns[i].visible = false;
        }
      }
    }

    //
    // const columns = [
    //   { Header: "Account ID", accessor: "accountid", show: false },
    //   { Header: "Account Name", accessor: "accountname" },
    //   { Header: "RM", accessor: "rm" },
    //   { Header: "Status", accessor: "status" },
    //   { Header: "ISC", accessor: "isc" },
    //   { Header: "Liability", accessor: "liability" },
    //   {
    //     id: "datesigned",
    //     Header: "Date Signed",
    //     accessor: d => {
    //       return moment(d.datesigned)
    //         .local()
    //         .format("YYYY-MM-DD");
    //     }
    //   },
    //   { Header: "Full Name", accessor: "fullname" },
    //   { Header: "Email", accessor: "processingemail" },
    //   { Header: "Phone", accessor: "phone" },
    //   { Header: "Billing City", accessor: "billingcity" },
    //   { Header: "Billing State", accessor: "billingstate" },
    //   { Header: "Billing Zip", accessor: "billingzip" },
    //   { Header: "Billing Country", accessor: "billingcountry" },
    //   { Header: "Group Name", accessor: "groupname" },
    //   { Header: "Group ID", accessor: "groupid" },
    //   { Header: "Bank", accessor: "bank" },
    //   { Header: "Profile", accessor: "profile" },
    //   { Header: "Processor", accessor: "processor" }
    // ];
    //
    // var content = (
    //   <ReactTable
    //     className={"-striped -highlight"}
    //     data={this.state.data}
    //     columns={columns}
    //     filterable={true}
    //     loading={this.state.loading}
    //   />
    // );

    // let updateCards = this.updateView();

    return (
      <div className="baseHeader">
        <div className="contentHeader">
          <h3 className="leaderboardHeader">
            {"Residual Self Serve BETA"}
            {/*<div className="importExport"> */}

            {this.state.showImportModal &&
              (dept === "admin" || dept === "sales_admin") ? (
              <UploadModal
                toggle={this.toggleImportModal}
                show={this.state.showImportModal}
                refresh={this.updatePartnerView}
                filename={"partner"}
              />
            ) : null}

            {/*</div> */}
          </h3>
        </div>

        <CardDeck className="partnerDeck">
          <Card style={{ border: "none", borderRadius: "0" }}>
            <Card.Body>
              <Card.Title></Card.Title>

              <CssBaseline />
              <CusTable
                columns={columns}
                data={this.state.data}
                email={email}
                getData={this.updatePartnerView}
                loading={this.state.loading}
              />
            </Card.Body>
          </Card>
        </CardDeck>
      </div>
    );
  }
}

export default withCookies(ResidualSelfServe);
