import React, { Component } from "react";
import XLSX from "xlsx";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Form, Row, Col } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import Alert from "react-bootstrap/Alert";
import { ApiClient } from "../ApiClient"

class UploadModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      momentVal: moment(),
      disabled: true,
      selectedFile: null,
      spin: false,
      finalStatus: false
    };
  }

  //set the spinner, parse the xlsx file and send the json to backend
  xlsxUploader = event => {
    event.preventDefault();
    this.setState({
      spin: "border"
    });

    var reader = new FileReader();
    var records = null;
    reader.onload = event => {
      var data = new Uint8Array(event.target.result);
      var workbook = XLSX.read(data, { type: "array" });
      // console.log(workbook);
      var sheetname = workbook.SheetNames[0];
      var sheet = workbook.Sheets[sheetname];

      records = XLSX.utils
        .sheet_to_json(sheet, { header: 1, raw: false })
        .slice(1);
      var filename = this.state.selectedFile.name;
      // console.log(records);
      ApiClient
        .post({
          url: `/api/upload${this.props.filename}`,
          body: {
            records,
            filename
          }
        })
        .then(json => {
          if (json.status === 200) {
            this.setState(
              {
                spin: false,
                finalStatus: "Success"
              },
              () => {
                this.props.refresh();
              }
            );
          } else {
            this.setState({
              spin: false,
              finalStatus: "Fail"
            });
          }
        })
        .catch(error => this.setState({
          spin: false,
          finalStatus: "Fail...Please check the file"
        })
        );
    };
    reader.readAsArrayBuffer(this.state.selectedFile);
  };

  //check if the file is xlsx and then enable the import button
  onChangeHandler = event => {
    // console.log(event.target.files);
    if (event.target.files.length > 0) {
      let filename = event.target.files[0].name;
      let filesize = event.target.files[0].size;
      if (
        filename.substring(filename.length - 5) === ".xlsx" &&
        filesize !== 0
      ) {
        this.setState({
          disabled: false,
          selectedFile: event.target.files[0],
          finalStatus: false
        });
      } else {
        this.setState({
          disabled: true,
          selectedFile: null,
          finalStatus: false
        });
      }
    }
  };

  render() {
    return (
      <div>
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard
          onHide={this.props.toggle}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {"Import XLSX"}
              {"  "}
              <Spinner
                animation={this.state.spin}
                role="status"
                variant="primary"
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Form>
                <Row>
                  <Col style={{ padding: "1%", textAlign: "center" }}>
                    <Form.Control
                      className="btn btn-outline-primary"
                      type="file"
                      onChange={this.onChangeHandler}
                    />
                  </Col>
                  <Col style={{ padding: "1%", textAlign: "center" }}>
                    <Button
                      variant="outline-primary"
                      type="submit"
                      style={{ marginTop: "1%" }}
                      disabled={this.state.disabled}
                      onClick={this.xlsxUploader}
                    >
                      Import
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ padding: "1%", textAlign: "center" }}>
                    {this.state.finalStatus ? (
                      <Alert variant={this.state.finalStatus} style={{}}>
                        {this.state.finalStatus}
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default UploadModal;
