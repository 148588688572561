import React, { useEffect, useState } from "react"
import { Form, FormControl, Dropdown, ListGroup, OverlayTrigger, Tooltip } from "react-bootstrap"
import "../Style/Style.scss"
import { ApiClient } from "../../ApiClient"
import { useException } from "./FeesContext"

export default function FeesCustomSelect({ id, filter, operator, values }) {

    const [sourceValues, setSourceValues] = useState()
    const [keyValue, setKeyValue] = useState()
    const [keyDisplay, setKeyDisplay] = useState()
    const [selectedValues, setSelectedValues] = useState()
    const [filterValues, setFilterValues] = useState()
    const [toggleDropDown, setToggleDropdown] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const { setExceptionValue } = useException()

    const getFilterSourceValues = () => {

           ApiClient.post({
            url: "/api/pci/GetFilterSourceValues",
            body: {
                table: filter.source_value.split(".")[0] + "." + filter.source_value.split(".")[1],
                field: filter.source_value.split(".")[2],
            }
        })
        .then((json) => {
           setSourceValues(json.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const getFilterSourceDisplays = () => {
        ApiClient.post({
            url: "/api/pci/GetFilterSourceDisplays",
            body: {
                table: filter.source_value.split(".")[0] + "." + filter.source_value.split(".")[1],
                field_value: filter.source_value.split(".")[2],
                field_display: filter.source_display.split(".")[2],
            }
        })
        .then((json) => {
           setSourceValues(json.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const onSelectHandle = (value, event) => {
        if(operator.operator_type === "list")
            if(selectedValues.includes(value)){
                const arr = [...selectedValues]
                arr.splice(arr.indexOf(value), 1)
                setSelectedValues([...arr])
            }
            else
                setSelectedValues([value, ...selectedValues])
        else
        setSelectedValues([value])
    }

    const onToggleHandle = (v, e, s) => {
        if(!v && s.source === "select") return
        setToggleDropdown(v)
    }

    useEffect(() => {
        setExceptionValue(id, selectedValues && selectedValues.join("|") || null)
    }, [selectedValues])

    useEffect(() => {
        if(!sourceValues || !keyValue || !keyDisplay) {
            setFilterValues(selectedValues)
            return
        }

        if(filter.source_display){
            const temp =  selectedValues && selectedValues.map(v => {
                return sourceValues.find(s => s[keyValue] === v)[keyDisplay]
            })
            setFilterValues(temp)
        }
    }, [selectedValues, sourceValues, keyValue, keyDisplay])

    useEffect(() => {
        setKeyDisplay()
        setKeyValue()
        setSourceValues()
        setSelectedValues((values && values.split('|')) || [])
        if(!filter || !operator) return
        if (filter.source_display){
            getFilterSourceDisplays()
            setKeyDisplay(filter.source_display.split(".")[2])
            setKeyValue(filter.source_value.split(".")[2])
        }
        else if (filter.source_value){ 
            getFilterSourceValues()
            setKeyValue(filter.source_value.split(".")[2])
        }
    }, [filter, operator])

    return (
        <div className="filterValueSelect">
        {keyValue && (operator && operator.operator_type === "list" ?
        <>
            <Dropdown className="filterValueDropdown" onSelect={(v, e) => onSelectHandle(v, e)} onToggle={onToggleHandle} show={toggleDropDown}>
            <Dropdown.Toggle as={CustomToggle}>
                Select
            </Dropdown.Toggle>
            <Dropdown.Menu className="filterValueDropdownMenu">
                <FormControl
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Search..."
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                />
                <ListGroup className="listGroupMultiple">
                {sourceValues && sourceValues.filter(f => {
                    return !searchValue || f[keyDisplay || keyValue].toLowerCase().startsWith(searchValue)
                }).map((d, i) => 
                    <ListGroup.Item key={i} eventKey={d[keyValue]}>
                    <Form.Group>
                    <input className="mr-1" type="checkbox" onChange={() => {}} checked={selectedValues.includes(d[keyValue])} />
                        {d[keyDisplay || keyValue]}
                    </Form.Group>
                    </ListGroup.Item>
                )}
                </ListGroup>
            </Dropdown.Menu>
        </Dropdown> 
        </>
        :
        <Dropdown className="filterValueDropdown" onSelect={onSelectHandle}>
            <Dropdown.Toggle as={CustomToggle} >
                Select
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomSingleMenu}>
            {sourceValues && sourceValues.map((d, i) => 
                <Dropdown.Item key={i} eventKey={d[keyValue]}>{d[keyDisplay || keyValue]}</Dropdown.Item>
            )}
            </Dropdown.Menu>
        </Dropdown>
        )}
        <OverlayTrigger
            placement="right"
            overlay={
                <Tooltip>
                    {filterValues && filterValues.map(value => <ul style={{margin: 0, paddingLeft: "15px", textAlign: "left"}}><li>{value}</li></ul>)}
                </Tooltip>
            }
        >
            <Form.Control 
                style={{paddingRight: keyValue ? "80px" : "10px", width: keyValue ? "500px" : "150px"}} 
                disabled={!operator || keyValue} 
                type={filter && filter.field_type}
                value={filterValues || ''}
                onChange={e => setSelectedValues([e.target.value]) }
            /> 
        </OverlayTrigger>
        </div>
    )
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href="!#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </a>
  ))

const CustomSingleMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('')
    
        return (
        <div
            ref={ref}
            style={style}
            className={className}
            aria-labelledby={labeledBy}
        >
            <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Search..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
            />
            <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
                (child) => 
                    !value || child.props.children.toLowerCase().startsWith(value)
            )}
            </ul>
        </div>
        )
    })
