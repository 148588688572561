import React, { Component } from "react";
import "react-sweet-progress/lib/style.css";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import CurrentMth from "./CurrentMth";
import PreviousMth from "./PreviousMth";
import "./Style/sales.scss";
import { withCookies } from "react-cookie";
import moment from "moment";

class SalesDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      counter: 0,
      momentVal: moment()
    };

    this.updatePrevCounter = this.updatePrevCounter.bind();
    this.updateNextCounter = this.updateNextCounter.bind();
    this.onYearMonthClick = this.onYearMonthClick.bind();
  }

  onYearMonthClick = (e) => {
    this.setState({
      counter: moment().diff(moment(`${e.target.value || moment().startOf("month").format("YYYY-MM")}-01`), "months") * -1,
      momentVal: moment(`${e.target.value || moment().startOf("month").format("YYYY-MM")}-01`)
    });
  };


  updatePrevCounter = () => {

    if (this.state.momentVal.format("YYYY-MM") !== "2019-01") {
      this.setState({
        counter: this.state.counter - 1,
        momentVal: this.state.momentVal.subtract(1, "months")
      });
    }
  };

  updateNextCounter = () => {
    if (this.state.counter !== 0) {
      this.setState({
        counter: this.state.counter + 1
      });
    }

    if (this.state.momentVal.format("YYYY-MM") !== moment().format("YYYY-MM")) {
      this.setState({
        momentVal: this.state.momentVal.add(1, "months")
      });
    }
  };

  render() {
    
    // const { cookies } = this.props;
    // var login = cookies.get("login") || null;
    // if (login === null) {
    //   this.props.history.push("/login");
    // }


    if (this.state.counter === 0) {
      return (
        <div>
          <div className="contentHeader">
            <ButtonGroup aria-label="prev-next">
              <Button className="navButton" onClick={this.updatePrevCounter}>
                <i className="material-icons">arrow_back_ios</i>
              </Button>
              <h3 className="navMonth">
                {/* {this.state.momentVal.format("MMM")}{" "} */}
                {/* {this.state.momentVal.format("YYYY")} */}
                <input 
                  type="month" 
                  className="month-select" 
                  value={this.state.momentVal.format("YYYY-MM")} 
                  min={"2019-01"} 
                  max={moment().format("YYYY-MM")}
                  onChange={this.onYearMonthClick}
                />
              </h3>
              <Button className="navButton" onClick={this.updateNextCounter}>
                <i className="material-icons">arrow_forward_ios</i>
              </Button>
            </ButtonGroup>
          </div>
          <div className="content" style={{ padding: "20px 4% 0" }}>
            <CurrentMth
              visibility={this.state.counter}
              day={this.state.momentVal}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="contentHeader">
            <ButtonGroup aria-label="prev-next">
              <Button className="navButton" onClick={this.updatePrevCounter}>
                <i className="material-icons">arrow_back_ios</i>
              </Button>
              <h3 className="navMonth">
                {/* {this.state.momentVal.format("MMM")}{" "}
                {this.state.momentVal.format("YYYY")} */}
                <input 
                  type="month" 
                  className="month-select" 
                  value={this.state.momentVal.format("YYYY-MM")} 
                  min={"2019-01"} 
                  max={moment().format("YYYY-MM")}
                  onChange={this.onYearMonthClick}
                />
              </h3>
              <Button className="navButton" onClick={this.updateNextCounter}>
                <i className="material-icons">arrow_forward_ios</i>
              </Button>
            </ButtonGroup>
          </div>
          <div className="content" style={{ padding: "20px 4% 0" }}>
            <PreviousMth
              visibility={this.state.counter}
              day={this.state.momentVal}
            />
          </div>
        </div>
      );
    }
  }
}

export default withCookies(SalesDashboard);
