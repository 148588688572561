import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import { ApiClient } from "../ApiClient"
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { green, red } from '@material-ui/core/colors';
import Alert from 'react-bootstrap/Alert'

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
        color: "#fff"
    },
    parent: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    child: {
        width: "700px",
        height: "235px",
        padding: "20px"
    }
    
}))
  
const theme = createMuiTheme({
    palette: {
        primary: green,
        secondary: red
    },
})

export default function Approval(props) {
    const classes = useStyles();
    const location = useLocation()
    const [data, setData] = useState()
    const [step, setStep] = useState()
    const [comment, setComment] = useState("")
    const [loading, setLoading] = useState(false)

    const approve = (approved) => {
        setLoading(true)
        ApiClient.post({
            url: "/api/leads/Approve",
            body: { 
                app_id: data.app_id,
                lead_id: data.lead_id,
                approved: approved,
                comment: comment || null
            }
        })
        .then((json) => {
            setStep(2)
        }).catch(err => {
            setStep(4)
        });

    }

    useEffect(() => {
        const app_id = location.search.replace("?id=", "")
        ApiClient.post({
            url: "/api/leads/GetApproval",
            body: { app_id: app_id }
        })
        .then((json) => {
            if(json.data.length == 0) {
                setStep(3)
                return
            }
            setStep(1)
            setData(...json.data)
        }).catch(err => {
            setStep(4)
        });

    }, [location])

    return <>
        <div className={classes.parent}>
            {step == 1 &&
            <Paper className={classes.child}>
                <p>{data?.first_name} {data?.last_name} - {data?.dba}</p>
                <TextField
                    fullWidth 
                    label="Optional comment..."
                    multiline
                    rows={4}
                    variant="outlined"
                    onChange={(e) => setComment(e.target.value)}
                    value={comment}
                />
                <ThemeProvider theme={theme}>
                    <Button variant="contained" color="primary" disableElevation className={classes.margin} onClick={() => approve(true)} disabled={loading}>
                        Approve
                    </Button>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                    <Button variant="contained" color="secondary" disableElevation className={classes.margin} onClick={() => approve(false)} disabled={loading}>
                        Reject
                    </Button>
                </ThemeProvider>
            </Paper>}
            {step == 2 &&
            <Alert variant="success">
                The decision has been sent. Thank you!
            </Alert>}
            {step == 3 &&
            <Alert variant="danger">
                The link is not valid or has expired!
            </Alert>}
            {step == 4 &&
            <Alert variant="warning">
                Something went wrong...
            </Alert>}
        </div>
    </>
}