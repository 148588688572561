import React, { useEffect, useState } from "react"
import { Modal, Button, Table } from "react-bootstrap"
import { ApiClient } from "../../ApiClient"
import moment from "moment";

export default function MatchModal(props) {

    const [data, setData] = useState([])

    useEffect(() => {
        if(props.mids.length == 0) return

        ApiClient.post({
            url: "/api/bonus/GetMatchesByMid",
            body: {
                mids: props.mids
            }
        }).then(json => {
            setData(json.data)
        }).catch(err => {
        });
    }, [props.mids])

    const onClose = () => {
        setData([])
    }

    return (
      <Modal
        {...props}
        onExited={onClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <small>Matches found</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {data.length == 0 ? <div style={{ textAlign: "center"}}>Loading...</div> :
        <Table size="sm" className="matchTable">
                <thead>
                    <tr>
                        <th>MID</th>
                        <th>DBA</th>
                        <th>ISO</th>
                        <th>Address</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Zipcode</th>
                        <th>Bonus Date</th>
                        <th>Clawed Back</th>
                    </tr>
                </thead>
                <tbody>
            {data.map((d, i) => 
                    <tr key={i}>
                        <td>
                            {d.mid}
                        </td>
                        <td>
                            {d.dba_name}
                        </td>
                        <td>
                            {d.iso_name}
                        </td>
                        <td>
                            {d.address}
                        </td>
                        <td>
                            {d.city}
                        </td>
                        <td>
                            {d.state}
                        </td>
                        <td>
                            {d.zipcode}
                        </td>
                        <td>
                            { moment.utc(d.bonus_date).format("YYYY-MM-DD")}
                        </td>
                        <td>
                            { d.clawed_back === 1 ? <>&#x2714;</> : <></>}
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
}

