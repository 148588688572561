import React, { useEffect, useState } from "react"
import { Button, Table } from "react-bootstrap"
import "../Style/Style.scss"
import { ApiClient } from "../../ApiClient"
import AddActivationModal from "./AddActivationModal"

export default function ActivationSpecs(props) {

    const [selectedActivation, setSelectedActivation] = useState({})
    const [addModalShow, setAddModalShow] = useState(false)
    const [addModalEditMode, setAddModalEditMode] = useState(false)
    const [activationList, setActivationList] = useState([])

    const onEditActivation = (index) => {
        setAddModalEditMode(true)
        setSelectedActivation(activationList[index])
        setAddModalShow(true)
    }

    const onDeleteActivation = (index) => {

        if(!window.confirm(`Are you sure you want to delete ${activationList[index].iso_name} ?`)) return

        const group_id = activationList[index].group_id.replaceAll(' ', '').split(',').filter(gid => gid !== null || gid !== "")

        ApiClient.post({
            url: "/api/bonus/DeleteActivation",
            body: {
                group_id: group_id,
            }
        }).then(json => {
            if(!json.data.success){
                window.alert(json.data.message)
                return
            }
            getActivationList()
    }).catch(err => {
        });
    }

    const getActivationList = () => {
        ApiClient.get({
            url: "/api/bonus/GetActivationList"
        }).then(json => {
            setActivationList(json.data);
        }).catch(err => {
        });
    }

    useEffect(() => {
        getActivationList()
    }, [])

    return (
        <>
        <div className="activationSpecs">
            <div className="specsTitle">
                Activation Requirements
                <Button variant="secondary" size="sm" className="float-right py-0" onClick={() => setAddModalShow(true)}>&#x002B;</Button>
            </div>
            <div className="bonusIncludeISOList">
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>ISO</th>
                            <th>Group ID</th>
                            <th>Days for Volume</th>
                            <th>Volume Threshold</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {activationList.map((clawback, i) => (
                            <tr key={i}>
                                <td>
                                    {clawback.iso_name}
                                </td>
                                <td>
                                    {clawback.group_id}
                                </td>
                                <td>
                                    {clawback.volume_days}
                                </td>
                                <td>
                                    {clawback.volume_threshold}
                                </td>
                                <td className="text-right">
                                    <Button variant="outline-info" size="sm" className="py-0 mr-2 border-0" onClick={() => onEditActivation(i)}>&#x270E;</Button>
                                    <Button variant="outline-danger" size="sm" className="py-0 border-0" onClick={() => onDeleteActivation(i)}>&#x2716;</Button>
                                </td>
                            </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        </div>
        <AddActivationModal
            data={selectedActivation}
            show={addModalShow}
            onHide={() => { setAddModalShow(false); setAddModalEditMode(false); setSelectedActivation() }}
            onEdit={() => { getActivationList(); setSelectedActivation() }}
            editMode={addModalEditMode}
        />
        </>
    )
}

