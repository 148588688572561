import React, { useEffect, useState } from "react"
import { ApiClient } from "../ApiClient"
import Table from './components/Table'
import moment from "moment";
import { Button, Form } from "react-bootstrap"
import "./Style/Style.scss";

export default function Commissions(props) {

    const [data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [hasError, sethasError] = React.useState(false)
    const [skipPageReset, setSkipPageReset] = React.useState(false)
    const [selectedYear, setSelectedYear] = useState(moment().year())
    const [selectedMonth, setSelectedMonth] = useState(moment().month())

    const columns =  [
            {
                Header: 'MID',
                accessor: 'mid',
            },
            {
                Header: 'DBA',
                accessor: 'dba_name',
            },
            {
                Header: 'ISO',
                accessor: 'iso_name',
            },
            {
                Header: 'Profile',
                accessor: 'merchant_profile',
            },
            {
                Header: 'Billing Type',
                accessor: 'billing_flag',
            },
            {
                Header: 'Date Billed',
                accessor: (d) => {
                    if (d.date_billed) {
                      return moment.utc(d.date_billed).format("YYYY-MM-DD");
                    }
                    return ""
                },
                filter: 'date'
            },
            {
                Header: 'Rev Share',
                accessor: 'iso_rev_share',
            },
            {
                Header: 'ISO Cost',
                accessor: 'iso_cost',
            },
            {
                Header: 'Billed Amount',
                accessor: 'amount_billed',
            },
            {
                Header: 'Amount Due',
                accessor: 'amount_due',
            },
        ]

    const GetCommissions = () => {
        setLoading(true)
        ApiClient.post({
            url: "/api/pci/GetCommissions",
            body: {
                year: selectedYear,
                month: selectedMonth + 1
            }
        })
        .then((json) => {
            setData(json.data)
            setLoading(false)
        }).catch(err => {
            console.log(err);
            setLoading(false)
        });
    }

    const onSelectYear = (value) => {
        setSelectedYear(Number(value))
        setSelectedMonth(-1)
    }

    const onSelectMonth = (value) => {
        setSelectedMonth(Number(value))
    }

    useEffect(() => {
        if(!selectedYear || selectedMonth === -1) return
        GetCommissions()
    }, [selectedYear, selectedMonth])

    return (
        <div className="PCI">
            <div className="billingToolbar">
                <Form inline className="mb-0">
                    <Form.Control className="mr-2" as="select" custom disabled={loading} value={selectedYear} onChange={e => onSelectYear(e.target.value)}>
                        {[...Array(moment().year() - 2021).keys()].reverse().map(y => (
                            <option key={y} value={y + 2022}>{y + 2022}</option>
                        ))}
                    </Form.Control>
                    <Form.Control className="mr-2" as="select" custom disabled={loading} value={selectedMonth} onChange={e => onSelectMonth(e.target.value)}>
                        <option value={-1}></option>
                        {[...Array(12).keys()].map(m => (
                            <option key={m} value={m}>{moment().month(m).format("MMMM")}</option>
                        ))}
                    </Form.Control>
                </Form>
            </div>
            <Table className="PCITable"
                columns={columns}
                data={data}
                setData={setData}
                // updateMyData={updateMyData}
                skipPageReset={skipPageReset}
                hasError={hasError}
                loading={loading}
                setLoading={setLoading}
            />
        </div>
    )
}

