import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import "./Style/processing.scss";
import DownloadModal from "../DownloadModal/DownloadModal";

class BarCard extends Component {
  

  
  render() {

    function abbreviateNumber(value) {
      if (value <= 1000) {
        return value;
      } else if (value > 1000 && value < 1000000) {
        return value / 1000 + "k";
      } else if (value >= 1000000 && value < 1000000000) {
        return Math.round(value / 1e6) + "M";
      } else if (value >= 1000000000) {
        return (value / 1e9).toFixed(2) + "B";
      }
      
    }


    return (
      <Card className="processingCard">
        <Card.Body
          style={{
            position: "relative",
            textAlign: "center",
            margin: "auto",
          }}
        >
          <Card.Title style={{ textAlign: "left" }}>
            {this.props.title}
            {this.props.ytd && <div style={{ float: "right" }}>
              YTD Volume:  {abbreviateNumber(this.props.ytd)}
            </div>}
          </Card.Title>
          {this.props.loading === true ? (
            <Spinner animation="border" variant="success" />
          ) : (
            <Bar
              data={this.props.data}
              options={this.props.options}
              height={null}
              width={null}
              plugins={this.props.plugins}
            />
          )}
          {this.props.isDownloadable ? (
            <i
              className="material-icons gray-icon"
              onClick={this.props.toggleDownloadModal}
            >
              cloud_download
            </i>
          ) : null}
          {this.props.showDownloadModal ? (
            <DownloadModal
              data={this.props.modalData}
              toggle={this.props.toggleDownloadModal}
              show={this.props.showDownloadModal}
              filename={this.props.filename}
            />
          ) : null}
        </Card.Body>
      </Card>
    );
  }
}
export default BarCard;
