import React, { Component } from "react";
import "./Style/partner.scss";
import { withCookies } from "react-cookie";
import moment from "moment";
import DownloadModal from "../DownloadModal/DownloadModal";
import UploadModal from "../UploadModal/UploadModal";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import Badge from "react-bootstrap/Badge";
import Spinner from "react-bootstrap/Spinner";
import { ApiClient } from "../ApiClient"

class Partner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      momentVal: moment(),
      data: [],
      loading: true,
      showDownloadModal: false,
      showImportModal: false,
      searchValue: "",
      startIndex: 0,
      lambdaStatus: null
    };
  }

  updatePartnerView = () => {
    ApiClient
      .post({
        url: "/api/partner"
      })
      .then((json) => {
        if (Object.keys(json.data).length === 0) {
          this.setState({
            data: [],
            loading: false,
          });
        } else {
          this.setState({
            data: json.data,
            loading: false,
          });
        }
      }).catch(err => {
        console.log(err);
      });
  };

  toggleDownloadModal = () => {
    this.setState({
      showDownloadModal: this.state.showDownloadModal ? false : true,
    });
  };

  toggleImportModal = () => {
    this.setState({
      showImportModal: this.state.showImportModal ? false : true,
    });
  };

  importSFPartners = () => {
      this.setState({ data: [], lambdaStatus: "started" })
      ApiClient
      .post({
        url: "/api/importSFPartners"
      })
      .then((json) => {
        if(json.data.uuid)
          this.interval = setInterval(() => {
            this.getLambdaRuntimeStatus(json.data.uuid);
      }, 1000);
      }).catch(err => {
        console.log(err);
        this.setState({ lambdaStatus: "stopped" })
    });
  };

  getLambdaRuntimeStatus = (uuid) => {
    ApiClient
      .post({
        url: "/api/checkLambdaRuntime",
        body: {
          uuid: uuid
        }
      }).then((json) => {
            if(json.data.length === 0) return
            this.setState({ lambdaStatus: json.data[0].runtime_status })
            if(json.data[0].runtime_status === 'finished' || json.data[0].runtime_status === 'stopped'){
              if(json.data[0].runtime_status === 'finished') this.updatePartnerView()
              clearInterval(this.interval)
            }
      }).catch(err => {
        clearInterval(this.interval)
        this.setState({ lambdaStatus: "stopped"})
        console.log(err);
      });
  }

  getData = (val) => {
    ApiClient
      .post({
        url: "/api/searchpartner",
        body: {
          val,
        }
      })
      .then((json) => {
        if (Object.keys(json.data).length === 0) {
          this.setState({
            data: [],
            loading: false,
          });
        } else {
          this.setState({
            data: json.data,
            loading: false,
          });
        }
      }).catch(err => {
        console.log(err);
      });
  };

  updatePrevCounter = () => {
    let { startIndex } = this.state;
    if (startIndex > 0) {
      this.setState({ startIndex: startIndex - 1 }, () => {
        this.updateView();
      });
    }
  };

  updateNextCounter = () => {
    let { data, startIndex } = this.state;
    if (data.length > startIndex * 60 + 60) {
      this.setState({ startIndex: startIndex + 1 }, () => {
        this.updateView();
      });
    }
  };

  updateView = () => {
    let { data, startIndex } = this.state;
    // FIND THE START INDEX FOR THE PAGE
    let inc = 0;
    while (
      data[startIndex * 60 - 1] !== undefined &&
      data[startIndex * 60 + inc] !== undefined &&
      data[startIndex * 60 - 1].accountname ===
        data[startIndex * 60 + inc].accountname
    ) {
      inc = inc + 1;
    }
    let start = startIndex * 60 + inc;
    // FIND THE END INDEX FOR THE PAGE
    inc = 1;
    while (
      data[startIndex * 60 + 60] !== undefined &&
      data[startIndex * 60 + (60 + inc)] !== undefined &&
      data[startIndex * 60 + 60].accountname ===
        data[startIndex * 60 + (60 + inc)].accountname
    ) {
      inc = inc + 1;
    }
    let end = startIndex * 60 + (60 + inc);

    // SHOW AROUND 60 PER PAGE
    let newData = data.slice(start, end);
    let len = newData.length;
    let cards = [];
    let status = "";
    let table = "";
    const columns = [
      { Header: "Group Name", accessor: "groupname" },
      { Header: "Group ID", accessor: "groupid" },
      { Header: "Bank", accessor: "bank" },
      { Header: "Profile", accessor: "profile" },
      { Header: "Processor", accessor: "processor" },
      { Header: "Effective Date", accessor: "effectivedate" },
    ];

    //extract group info
    for (let i = 0; i < len; i++) {
      let j = i;
      let groupData = [];
      let effectivedate = "NA";
      try {
        effectivedate = newData[j].effectivedate.slice(0, 10);
      } catch (err) {}
      groupData.push({
        groupname: newData[j].groupname,
        groupid: newData[j].groupid,
        bank: newData[j].bank,
        profile: newData[j].profile,
        processor: newData[j].processor,
        effectivedate: effectivedate,
      });

      while (
        j + 1 < len &&
        newData[j].accountname === newData[j + 1].accountname
      ) {
        effectivedate = "NA";
        try {
          effectivedate = newData[j + 1].effectivedate.slice(0, 10);
        } catch (err) {}
        groupData.push({
          groupname: newData[j + 1].groupname,
          groupid: newData[j + 1].groupid,
          bank: newData[j + 1].bank,
          profile: newData[j + 1].profile,
          processor: newData[j + 1].processor,
          effectivedate: effectivedate,
        });

        j = j + 1;
      }
      i = j;

      let datesigned = "NA";
      try {
        datesigned = newData[i].datesigned.slice(0, 10);
      } catch (err) {}

      table = (
        <ReactTable
          className={"groupTable -striped -highlight"}
          data={groupData}
          columns={columns}
          loading={this.state.loading}
          showPagination={false}
          minRows={4}
        />
      );
      if (newData[i]?.status?.toLowerCase() === "active") {
        status = (
          <Badge pill variant="success">
            Active
          </Badge>
        );
      } else if (newData[i]?.status?.toLowerCase() === "inactive") {
        status = (
          <Badge pill variant="warning">
            Inactive
          </Badge>
        );
      } else {
        status = (
          <Badge pill variant="danger">
            Dead
          </Badge>
        );
      }

      cards.push(
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey={i.toString()}
            style={{
              backgroundColor: "rgba(245, 245, 245)",
            }}
          >
            {newData[i].accountname} {status}{" "}
            <i className="material-icons" style={{ float: "right" }}>
              expand_more
            </i>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey={i.toString()}>
            <Card.Body>
              <div>
                RM: {newData[i].rm}
                <br />
                Recruiter: {newData[i].recruiter}
                <br />
                ISC: {newData[i].isc}
                <br />
                Liability: {newData[i].liability}
                <br />
                Date Signed: {datesigned}
                <br />
                Full Name: {newData[i].fullname}
                <br />
                Email: {newData[i].processingemail}
                <br />
                Phone: {newData[i].phone}
                <br />
                City: {newData[i].billingcity}
                <br />
                State: {newData[i].billingstate}
                <br />
                Zip: {newData[i].billingzip}
                <br />
                Operations Email: {newData[i].operationemail}
                <br />
                {table}
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      );
    }

    return cards;
  };

  handleChange = (event) => {
    clearTimeout(this.timer);
    this.setState({
      searchValue: event.target.value,
      startIndex: 0,
      loading: true,
    });
    if (event.target.value !== "") {
      this.timer = setTimeout(() => {
        this.getData(this.state.searchValue);
      }, 1000);
    } else {
      this.updatePartnerView();
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  componentWillMount() {
    this.timer = null;
  }

  componentDidMount() {
    this.updatePartnerView();
  }

  render() {
    const { cookies } = this.props;

    const dept = cookies.get("dept");

    //
    // const columns = [
    //   { Header: "Account ID", accessor: "accountid", show: false },
    //   { Header: "Account Name", accessor: "accountname" },
    //   { Header: "RM", accessor: "rm" },
    //   { Header: "Status", accessor: "status" },
    //   { Header: "ISC", accessor: "isc" },
    //   { Header: "Liability", accessor: "liability" },
    //   {
    //     id: "datesigned",
    //     Header: "Date Signed",
    //     accessor: d => {
    //       return moment(d.datesigned)
    //         .local()
    //         .format("YYYY-MM-DD");
    //     }
    //   },
    //   { Header: "Full Name", accessor: "fullname" },
    //   { Header: "Email", accessor: "processingemail" },
    //   { Header: "Phone", accessor: "phone" },
    //   { Header: "Billing City", accessor: "billingcity" },
    //   { Header: "Billing State", accessor: "billingstate" },
    //   { Header: "Billing Zip", accessor: "billingzip" },
    //   { Header: "Billing Country", accessor: "billingcountry" },
    //   { Header: "Group Name", accessor: "groupname" },
    //   { Header: "Group ID", accessor: "groupid" },
    //   { Header: "Bank", accessor: "bank" },
    //   { Header: "Profile", accessor: "profile" },
    //   { Header: "Processor", accessor: "processor" }
    // ];
    //
    // var content = (
    //   <ReactTable
    //     className={"-striped -highlight"}
    //     data={this.state.data}
    //     columns={columns}
    //     filterable={true}
    //     loading={this.state.loading}
    //   />
    // );

    let updateCards = this.updateView();

    return (
      <div>
        <div className="contentHeader">
          <h3 className="leaderboardHeader">{"Partners"}</h3>
        </div>

        <CardDeck className="partnerDeck">
          <Card style={{ border: "none", borderRadius: "0" }}>
            <Card.Body>
              <Card.Title>
                <div className="importExport">
                  <Button onClick={this.importSFPartners} className="mr-1" disabled={this.state.lambdaStatus === "started"}>Import from Salesforce</Button>
                  {dept === "admin" || dept === "sales_admin" ? (
                    <Button
                      variant="outline-secondary"
                      onClick={this.toggleImportModal}
                      disabled={this.state.lambdaStatus === "started"}
                    >
                      Import XLSX
                    </Button>
                  ) : null}{" "}
                  <Button
                    className="buttonXlsx"
                    variant="outline-success"
                    onClick={this.toggleDownloadModal}
                    disabled={this.state.lambdaStatus === "started"}
                  >
                    Export XLSX
                  </Button>
                  {this.state.showImportModal &&
                  (dept === "admin" || dept === "sales_admin") ? (
                    <UploadModal
                      toggle={this.toggleImportModal}
                      show={this.state.showImportModal}
                      refresh={this.updatePartnerView}
                      filename={"partner"}
                    />
                  ) : null}
                  {this.state.showDownloadModal ? (
                    <DownloadModal
                      data={this.state.data}
                      toggle={this.toggleDownloadModal}
                      show={this.state.showDownloadModal}
                      filename={"PartnersView"}
                    />
                  ) : null}
                </div>
              </Card.Title>
              <div>
                {this.state.lambdaStatus === "started" && <div className="text-center">Importing...</div>}
                {this.state.lambdaStatus === "finished" && <div className="text-center text-success">Partners successfully imported from the Salesforce</div>}
                {this.state.lambdaStatus === "stopped" && <div className="text-center text-danger">Something went wrong! Try again</div>}
              </div>
              <br />
              <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="searchPartner">
                  <InputGroup>
                    <Form.Control
                      placeholder="Search..."
                      value={this.state.searchValue}
                      onChange={this.handleChange}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text id="inputGroupPrepend">
                        {this.state.loading ? (
                          <Spinner
                            animation="border"
                            variant="success"
                            size="sm"
                            style={{
                              position: "relative",
                              textAlign: "center",
                              margin: "auto",
                            }}
                          />
                        ) : (
                          <i
                            className="material-icons"
                            style={{
                              width: "18px",
                              position: "relative",
                              textAlign: "center",
                              margin: "auto",
                            }}
                          >
                            check
                          </i>
                        )}
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
              </Form>

              <div>
                <div className="paginationHeader">
                  <ButtonGroup aria-label="prev-next">
                    <Button
                      className="navButton"
                      onClick={this.updatePrevCounter}
                    >
                      <i className="material-icons">arrow_back_ios</i>
                    </Button>
                    <h3 className="navMonth">{this.state.startIndex + 1}</h3>
                    <Button
                      className="navButton"
                      onClick={this.updateNextCounter}
                    >
                      <i className="material-icons">arrow_forward_ios</i>
                    </Button>
                  </ButtonGroup>
                </div>
              </div>

              <Accordion defaultActiveKey="0">{updateCards}</Accordion>
            </Card.Body>
          </Card>
        </CardDeck>
      </div>
    );
  }
}

export default withCookies(Partner);
