import React, { useEffect, useState } from "react"
import { Form, Modal, Button, Col, InputGroup, FormControl, Dropdown, Alert } from "react-bootstrap"
import { ApiClient } from "../../ApiClient"
import CustomSelect from "./CustomSelect"

export default function AddActivationModal(props) {

    const DEFAULT_VOLUME_DAYS = 30
    const DEFAULT_VOLUME_THRESHOLD = 250

    const [isoList, setIsoList] = useState([])
    const [selectedIndex, setSelectedIndex] = useState()
    const [volumeDays, setVolumeDays] = useState(DEFAULT_VOLUME_DAYS)
    const [volumeThreshold, setVolumeThreshold] = useState(DEFAULT_VOLUME_THRESHOLD)
    const [warningMessage, setWarningMessage] = useState()
    const [selectedValues, setSelectedValues] = useState([])

    const onClose = () => {
        setSelectedIndex()
        setWarningMessage()
        setSelectedValues([])
        setVolumeDays(DEFAULT_VOLUME_DAYS)
        setVolumeThreshold(DEFAULT_VOLUME_THRESHOLD)
    }

    const onSave = () => {
        if(!props.editMode && (!selectedIndex && selectedValues.length == 0)) return

        const group_id = selectedValues.filter(gid => gid !== null || gid !== "")
        ApiClient.post({
            url: "/api/bonus/AddActivation",
            body: {
                group_id: group_id,
                volume_days: Number(volumeDays),
                volume_threshold: Number(volumeThreshold),
                edit: props.editMode,
                iso_name: props.editMode ? props.data.iso_name : isoList[selectedIndex].iso_name
            }
        }).then(json => {
            if(json.data.success){
                props.onHide()
                props.onEdit()
                return
            }
            setWarningMessage(json.data.message)
        }).catch(err => {
            setWarningMessage(err)
        });
    }

    useEffect(() => {
        setSelectedValues([])
    }, [selectedIndex])

    useEffect(() => {
        if(!props.editMode) return
        setSelectedValues(props.data?.group_id?.replaceAll(' ', '').split(','))
        setVolumeDays(props.data?.volume_days)
        setVolumeThreshold(props.data?.volume_threshold)
    }, [props.data])

    useEffect(() => {
        ApiClient.get({
            url: "/api/bonus/GetIsoList",
        }).then(json => {
            setIsoList(json.data);
        }).catch(err => {
        });
    }, [])

    return (
      <Modal
      show={props.show}
      onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onExited={onClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <small>{props.editMode ? "Edit" : "Add"} Activation</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Group>
            <Form.Row>
                <Col column="md" md={3} className="text-right pt-2">
                    {!props.editMode && <Dropdown onSelect={setSelectedIndex}>
                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                            Select ISO
                        </Dropdown.Toggle>
                        <Dropdown.Menu as={CustomMenu}>
                            {isoList.map((iso, i) => (
                                <Dropdown.Item key={i} eventKey={i}>{iso.iso_name}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>}
                </Col>
                <Col>
                    <FormControl 
                        value={props.editMode ? props.data.iso_name : isoList[selectedIndex]?.iso_name || ""} 
                        disabled={true} 
                    />
                </Col>
            </Form.Row>
            <br />
            <Form.Row>
                <Col column="md" md={3} className="text-right pt-2">
                    <CustomSelect 
                        data={props.editMode ? isoList.find(iso => iso.iso_name === props.data.iso_name)?.group_id.replaceAll(' ', '').split(',') : isoList[selectedIndex]?.group_id.replaceAll(' ', '').split(',')}
                        selectedValues={selectedValues}
                        onSelect={setSelectedValues}
                    />
                </Col>
                <Col>
                    <FormControl value={selectedValues?.join(', ')} disabled={true} />
                </Col>
            </Form.Row>
            <br />
            <Form.Row>
                <Form.Label column="md" md={3} className="text-right">
                    Days for Volume
                </Form.Label>
                <Col>
                    <FormControl placeholder="0" type="number" value={volumeDays} onChange={e => setVolumeDays(e.target.value)} />
                </Col>
            </Form.Row>
            <br />
            <Form.Row>
                <Form.Label column="md" md={3} className="text-right">
                    Volume Threshold
                </Form.Label>
                <Col>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl placeholder="0.00" type="number" value={volumeThreshold} onChange={e => setVolumeThreshold(e.target.value)} />
                    </InputGroup>
                </Col>
            </Form.Row>
        </Form.Group>
        {warningMessage && <Alert variant="danger" className="text-center">
            <small>{warningMessage}</small>
        </Alert>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>Close</Button>
          <Button onClick={onSave}>Save</Button>
        </Modal.Footer>
      </Modal>
    )
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </a>
  ))

const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');
  
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
        </div>
      );
    },
  );

