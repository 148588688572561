import React, { useEffect, useState, useRef } from "react";
import "./Style/Style.scss";
import { withCookies } from "react-cookie";
import CssBaseline from '@material-ui/core/CssBaseline'
import EnhancedTable from './Components/EnhancedTable'
import { ApiClient } from "../ApiClient"
import Badge from "react-bootstrap/Badge";
import GlobalFilter, { DefaultColumnFilter, NumberRangeColumnFilter, SelectColumnFilter, SliderColumnFilter } from './Components/GlobalFilter'
import LinearProgress from "@material-ui/core/LinearProgress";
import { Button } from "react-bootstrap"

function Index(props) {

    const editors = ['rashai@signapay.com', 'luism@signapay.com', 'pault@signapay.com', 'daniellec@signapay.com', 'bahramb@signapay.com']

    const [xlsxData, setXlsxData] = React.useState([])
    const [partners, setPartners] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [skipPageReset, setSkipPageReset] = React.useState(false)
    const [lambdaStatus, setLambdaStatus] = useState()
    const myInterval = useRef();
    const [canEdit, setCanEdit] = useState(false)

    const columns = React.useMemo(
        () => [
            {
                id: "account_name",
                Header: 'Account Name',
                accessor: 'accountname',
                style: {
                    fontWeight: '500'
                }
            },
            {
                id: "status",
                Header: 'Status',
                Filter: SelectColumnFilter,
                accessor: 'status',
                Cell: ({ cell }) => <Badge pill variant={cell.value === 'Active' ? 'success' : cell.value === 'Inactive' ? 'warning' : 'danger'}>{cell.value}</Badge>,
            },
            {
                id: "fullname",
                Header: 'Name',
                accessor: 'fullname',
            },
            {
                id: "rm",
                Header: 'RM',
                Filter: SelectColumnFilter,
                accessor: 'rm',
            }
        ],
        []
    )

    const prepareData = (data) => {
        const accountIds = new Set(data.map(d => d.accountid))
        return Array.from(accountIds.values()).map(accountId => {
            const groups = data.filter(d => d.accountid === accountId)
            return {
                accountid: groups[0].accountid,
                accountname: groups[0].accountname,
                rm: groups[0].rm,
                recruiter: groups[0].recruiter,
                isc: groups[0].isc,
                liability: groups[0].liability,
                datesigned: groups[0].datesigned,
                fullname: groups[0].fullname,
                processingemail: groups[0].processingemail,
                phone: groups[0].phone,
                billingstreet: groups[0].billingstreet,
                billingcity: groups[0].billingcity,
                billingstate: groups[0].billingstate,
                billingzip: groups[0].billingzip,
                operationemail: groups[0].operationemail,
                status: groups[0].status,
                groups: groups.map(group => ({
                    group_sf_id: group.group_sf_id,
                    groupname: group.groupname,
                    groupid: group.groupid,
                    association: group.association_number,
                    rev_share: group.rev_share,
                    bank: group.bank,
                    profile: group.profile,
                    processor: group.processor,
                    effectivedate: group.effectivedate,
                }))
            }
        })
    }

    const prepareXlsxData = (data) => {

        const accountIds = new Set(data.map(d => d.accountid))

        return Array.from(accountIds.values()).map(id => {
            const account = data.filter(d => d.accountid === id)

            return {
                accountname: account[0].accountname,
                rm: account[0].rm,
                recruiter: account[0].recruiter,
                isc: account[0].isc,
                liability: account[0].liability,
                datesigned: account[0].datesigned,
                fullname: account[0].fullname,
                processingemail: account[0].processingemail,
                phone: account[0].phone,
                billingstreet: account[0].billingstreet,
                billingcity: account[0].billingcity,
                billingstate: account[0].billingstate,
                billingzip: account[0].billingzip,
                operationemail: account[0].operationemail,
                status: account[0].status,
                groupid: account.map(acc => acc.groupid).join(', '),
                effectivedate: account[0].effectivedate,
            }
        }
        )
    }

    const importSFPartners = () => {
        setLambdaStatus("started")
        setLoading(true)
        ApiClient
            .post({
                url: "/api/importSFPartners"
            })
            .then((json) => {
                if (json.data.uuid) {
                    myInterval.current = setInterval(() => {
                        getLambdaRuntimeStatus(json.data.uuid);
                    }, 1000);
                }
            }).catch(err => {
                console.log(err);
                setLambdaStatus("stopped")
            });
    };

    const getLambdaRuntimeStatus = (uuid) => {
        ApiClient
            .post({
                url: "/api/checkLambdaRuntime",
                body: {
                    uuid: uuid
                }
            }).then((json) => {
                if (!json.data[0]?.runtime_status || json?.data[0]?.runtime_status === "started") return
                clearInterval(myInterval.current);
                myInterval.current = null;
                setLambdaStatus(json.data[0].runtime_status)
            }).catch(err => {
                clearInterval(myInterval.current);
                myInterval.current = null;
                setLambdaStatus("stopped")
                setLoading(false)
                console.log(err);
            });
    }

    useEffect(() => {
        if (!!lambdaStatus && lambdaStatus !== 'finished') return
        setLoading(true)
        ApiClient.get({
            url: "/api/partners/GetPartners",
        }).then(results => {
            setPartners(prepareData(results.data))
            setXlsxData(prepareXlsxData(results.data))
        }).catch(e => {
            console.log(e)
        }).finally(() => setLoading(false))

    }, [lambdaStatus])

    useEffect(() => {

        const { cookies } = props;
        const email = cookies.get("email");
        setCanEdit(editors.includes(String(email).toLowerCase()));

        return () => clearInterval(myInterval.current);
    }, []);


    return (
        <div className="mph-container">
            <div className="mph-import">
                <Button onClick={() => importSFPartners()} className="mr-1" disabled={lambdaStatus === "started"}>Import from Salesforce</Button>
                <div>
                    {lambdaStatus === "started" && <div className="text-center">Importing...</div>}
                    {lambdaStatus === "finished" && <div className="text-center text-success">Partners successfully imported from the Salesforce</div>}
                    {lambdaStatus === "stopped" && <div className="text-center text-danger">Something went wrong! Try again</div>}
                </div>
            </div>
            {loading && <div className="spinner-overlay">
                <div className="spinner-progress">
                    <LinearProgress />
                </div>
            </div>}
            <div className="mph-main-table-container">
                <div className="mph-main-table">
                    <CssBaseline />
                    <EnhancedTable
                        className='mph-enhanced-table'
                        columns={columns}
                        canEdit={canEdit}
                        data={partners}
                        xlsxData={xlsxData}
                        skipPageReset={skipPageReset}
                        defaultSorting={[{ id: 'account_name' }]}
                        updateRevShare={({ accountid, groupid, revshare }) => {
                            if(!canEdit) return
                            setSkipPageReset(true)
                            setPartners(data => data.map(d => {
                                if (d.accountid !== accountid) return d
                                const { groups, ...rest } = d
                                return {
                                    ...rest,
                                    groups: groups.map(group => {
                                        if (group.groupid !== groupid) return group
                                        return {
                                            ...group,
                                            rev_share: Number(revshare) / 100,
                                        }
                                    })
                                }
                            }))

                        }}
                        addGroup={(values) => {
                            if(!canEdit) return
                            setSkipPageReset(true)
                            setPartners(data => data.map(d => {
                                if (d.accountid !== values[0].accountid) return d
                                const { groups, ...rest } = d
                                return {
                                    ...rest,
                                    groups: [
                                        ...groups,
                                        ...values.map(v => ({
                                            group_sf_id: v.group_sf_id,
                                            groupname: v.groupname,
                                            groupid: v.groupid,
                                            association: v.association,
                                            rev_share: Number(v.revshare) / 100,
                                            bank: v.bank,
                                            profile: v.profile,
                                            processor: v.processor,
                                            effectivedate: v.effectivedate,
                                        }))
                                    ]
                                }
                            }))

                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default withCookies(Index)
