import React, { useState } from "react"
import Modal from "react-bootstrap/Modal";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from "moment";
import { xlsxDownloader } from "./xlsx";
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

export default function TopTable(props) {

    const [modalOpen, setModalOpen] = useState(false)
    const [selectedStatus, setSelectedStatus] = useState('')

    const onModalOpenHandle = (status) => {
        setSelectedStatus(status)
        setModalOpen(true)
    }

    return (
        <>
            {modalOpen ?
                <Modal
                    show={modalOpen}
                    onShow={() => { }}
                    size="lg"
                    centered
                    onHide={() => setModalOpen(false)}
                    className="iso-modal-container"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <span style={{ marginRight: 20 }}>
                                {selectedStatus} Partners
                            </span>
                            <Button variant="outlined" color="primary" onClick={() => xlsxDownloader(props?.statuses.filter(status => status.iso_status === selectedStatus), selectedStatus, `${selectedStatus}_Partners_${props.selectedDate}.xlsx`)}>
                                Export
                            </Button>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Partner</TableCell>
                                        <TableCell>Latest App Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props?.statuses.filter(status => status.iso_status === selectedStatus).map(status => (

                                        <TableRow>
                                            <TableCell>{status.iso_name}</TableCell>
                                            <TableCell>{String(status.latest_app_date ?? '').slice(0, 10)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Modal.Body>
                </Modal>
                :
                <></>
            }
            <table>
                <thead>
                    <tr>
                        <td>Active</td>
                        <td>Inactive</td>
                        <td>Dead</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <Tooltip title="At least 1 deal in this month">
                                <a href="#?" onClick={() => onModalOpenHandle('Active')}>
                                    {props?.statuses.filter(status => status.iso_status === 'Active')?.length ?? 0}
                                </a>
                            </Tooltip>
                        </td>
                        <td>
                            <Tooltip title="At least 1 deal in the last 90 days">
                                <a href="#?" onClick={() => onModalOpenHandle('InActive')}>
                                    {props?.statuses.filter(status => status.iso_status === 'InActive')?.length ?? 0}
                                </a>
                            </Tooltip>
                        </td>
                        <td>
                            <Tooltip title="0 deal in the last 90 days">
                                <a href="#?" onClick={() => onModalOpenHandle('Dead')}>
                                    {props?.statuses.filter(status => status.iso_status === 'Dead')?.length ?? 0}
                                </a>
                            </Tooltip>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}