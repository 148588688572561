import React, { useEffect, useState } from "react"
import { Alert } from "react-bootstrap"
import moment from "moment";

export default function CurrentWindow(props) {

    const [from, setFrom] = useState()
    const [to, setTo] = useState()
    const today = moment()

    const getWeeklyWindow = () => {
        const weekday = today.isoWeekday()
        if(weekday >= 4) {
            setFrom(today.clone().subtract(weekday - 4, 'days'))
            setTo(today.clone().subtract(weekday - 4, 'days').add(6, 'days'))
        }
        if(weekday < 4) {
            setFrom(today.clone().subtract(weekday + 3, 'days'))
            setTo(today.clone().add(3 - weekday, 'days'))
        }
    }

    const getMonthlyWindow = () => {
        const monthday = today.date()
        if(monthday >= 21) {
            setFrom(today.clone().date(21))
            setTo(today.clone().add(1, 'months').date(20))
        }
        if(monthday < 21) {
            setFrom(today.clone().subtract(1, 'months').date(21))
            setTo(today.clone().date(20))
        }
    }

    const getClawbackWindow = () => {
        const monthday = today.date()
        if(monthday >= 20) {
            setTo(today.clone().add(1, 'months').date(20))
        }
        if(monthday < 20) {
            setTo(today.clone().date(20))
        }
    }

    const getMonthlyExtraWindow = () => {
        setFrom(today.clone().startOf('month'))
        setTo(today.clone().endOf('month').add(1, 'day'))
    }

    useEffect(() => {

        switch (props.windowType) {
            case "W":
                getWeeklyWindow()
                break;
            case "M":
                getMonthlyWindow()
                break;
            case "C":
                getClawbackWindow()
                break;
            case "E":
                getMonthlyExtraWindow()
                break;
            default:
                break;
        }

    }, [props.windowType])

    return (
        <>
            {props.windowType === "C" || props.windowType === 'E' ? <Alert variant="info" className="text-center"><small>Next run {to?.fromNow()} ({to?.format("YYYY-MM-DD")})</small></Alert>
            : <Alert variant="info" className="text-center"><small>Current window: {from?.format("YYYY-MM-DD")} 	&#x2192; {to?.format("YYYY-MM-DD")}</small></Alert>}
        </>
    )
}