import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import "./Style/processing.scss";

class TextCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,
      loading: props.loading,
    };
    this.tableRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    // KEEP THE RATIO OF ALL THE CARDS SAME (ALSO SUMMARY DOESN'T HAVE CARD TITLE)
    // console.log("cdu", this.tableRef);
    if (this.props.loading !== prevProps.loading) {
      this.setState({
        loading: this.props.loading,
        height: this.tableRef.current.offsetWidth / 2,
      });
    }
  }

  currencyFormat = () => {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    return formatter;
  };

  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  render() {
    return (
      <Card className="processingCard">
        <Card.Body
          style={{
            position: "relative",
            margin: "auto",
          }}
        >
          <Card.Title style={{ textAlign: "left" }}>
            {this.props.title}
          </Card.Title>
          {this.props.loading ? (
            <Spinner animation="border" variant="success" />
          ) : (
            <div
              className="customTable"
              ref={this.tableRef}
              style={{ height: this.state.height }}
            >
              <div style={{ fontSize: "0.95rem" }}>
                <div>
                  Volume
                  <div
                    style={{
                      fontWeight: "500",
                      marginRight: "55px",
                      float: "right",
                    }}
                  >
                    {this.currencyFormat().format(this.props.vol)}
                  </div>
                </div>
              </div>
              <br />
              <div style={{ fontWeight: "500", fontSize: "0.95rem" }}>
                {" "}
                CBCAL
              </div>
              <div style={{ fontSize: "0.95rem" }}>
                <div>
                  {"Processed Volume"}{" "}
                  <div
                    style={{
                      fontWeight: "500",
                      marginRight: "55px",
                      float: "right",
                    }}
                  >
                    {this.currencyFormat().format(this.props.volByBank[0])}
                  </div>
                </div>

                <div>
                  {"Transactions Count"}{" "}
                  <div
                    style={{
                      fontWeight: "500",
                      marginRight: "55px",
                      float: "right",
                    }}
                  >
                    {this.numberWithCommas(this.props.txByBank[0])}
                  </div>
                </div>
                <div>
                  {"Average Ticket"}{" "}
                  <div
                    style={{
                      fontWeight: "500",
                      marginRight: "55px",
                      float: "right",
                    }}
                  >
                    {this.currencyFormat().format(this.props.avgByBank[0])}
                  </div>
                </div>
              </div>
              <br />
              <div style={{ fontWeight: "500", fontSize: "0.95rem" }}>
                {" "}
                Esquire
              </div>
              <div style={{ fontSize: "0.95rem" }}>
                <div>
                  {"Processed Volume"}{" "}
                  <div
                    style={{
                      fontWeight: "500",
                      marginRight: "55px",
                      float: "right",
                    }}
                  >
                    {this.currencyFormat().format(this.props.volByBank[1])}
                  </div>
                </div>

                <div>
                  {"Transactions Count"}{" "}
                  <div
                    style={{
                      fontWeight: "500",
                      marginRight: "55px",
                      float: "right",
                    }}
                  >
                    {this.numberWithCommas(this.props.txByBank[1])}
                  </div>
                </div>
                <div>
                  {"Average Ticket"}{" "}
                  <div
                    style={{
                      fontWeight: "500",
                      marginRight: "55px",
                      float: "right",
                    }}
                  >
                    {this.currencyFormat().format(this.props.avgByBank[1])}
                  </div>
                </div>
              </div>
              <br />
              <div style={{ fontWeight: "500", fontSize: "0.95rem" }}>
                {" "}
                Chesapeake
              </div>
              <div style={{ fontSize: "0.95rem" }}>
                <div>
                  {"Processed Volume"}{" "}
                  <div
                    style={{
                      fontWeight: "500",
                      marginRight: "55px",
                      float: "right",
                    }}
                  >
                    {this.currencyFormat().format(this.props.volByBank[3])}
                  </div>
                </div>

                <div>
                  {"Transactions Count"}{" "}
                  <div
                    style={{
                      fontWeight: "500",
                      marginRight: "55px",
                      float: "right",
                    }}
                  >
                    {this.numberWithCommas(this.props.txByBank[3])}
                  </div>
                </div>
                <div>
                  {"Average Ticket"}{" "}
                  <div
                    style={{
                      fontWeight: "500",
                      marginRight: "55px",
                      float: "right",
                    }}
                  >
                    {this.currencyFormat().format(this.props.avgByBank[3])}
                  </div>
                </div>
              </div>
              <br />
              <div style={{ fontWeight: "500", fontSize: "0.95rem" }}> PPS</div>
              <div style={{ fontSize: "0.95rem" }}>
                <div>
                  {"Processed Volume"}{" "}
                  <div
                    style={{
                      fontWeight: "500",
                      marginRight: "55px",
                      float: "right",
                    }}
                  >
                    {this.currencyFormat().format(this.props.volByBank[2])}
                  </div>
                </div>

                <div>
                  {"Transactions Count"}{" "}
                  <div
                    style={{
                      fontWeight: "500",
                      marginRight: "55px",
                      float: "right",
                    }}
                  >
                    {this.numberWithCommas(this.props.txByBank[2])}
                  </div>
                </div>
                <div>
                  {"Average Ticket"}{" "}
                  <div
                    style={{
                      fontWeight: "500",
                      marginRight: "55px",
                      float: "right",
                    }}
                  >
                    {this.currencyFormat().format(this.props.avgByBank[2])}
                  </div>
                </div>
              </div>

              <br />
            </div>
          )}
        </Card.Body>
      </Card>
    );
  }
}

export default TextCard;
