//Created by Katherine Hartman
//Handles the rendering and calculation for the Sales Calculator.

import React, { Component } from "react";
import { Card, Tabs, Tab, Accordion, Table, ButtonToolbar, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import CurrencyInput from 'react-currency-input';
import { withCookies } from "react-cookie";
import Loader from "react-loader-spinner";
import "./Style/salescalculator.scss";

class SalesCalculator extends Component {
  constructor() {
    super();
    this.state = {
      owner: 'iso',
      interchangeIncome: '68.83',
      interchangeExpense: '68.83',
      expense: '243.50',
      income: '552.05',
      liabilityIncome: '0',
      authorizations: '342',
      authorizationTrxnsIncome: '0',
      grossVolume: '12683.64',
      numberPinDebit: '2',
      numberBatches: '15',
      ebt: '5',
      voicec: '0',
      avsCount: '45',
      monthlymin: '0',
      cancellationmme: '0',
      mic: '0',
      amex: '1007.78',
      pprogram: '14.44',
      settlement: '0',
      pbin:'0',
      ndf: '1.77',
      cardBrandIncome: '14.02',
      cardBrandExpense: '0',
      chargebackCount: '0',
      chargebackIncome: '36.50',
      achRejectCount: '0',
      achRejectIncome: '2.90',
      retrievalCount: '0',
      retrievalIncome: '1.29',
      reversalCount: '0',
      reversalIncome: '3.95',

      isc: '80',
      interchange: 'Pass-Thru',
      acs: '0.04',
      bin: '2.0',
      liability: '2.0',
      cbliability: '25',
      arliability: '25',
      rtliability: '25',
      rvliability: '25',
      account: '2.00',
      statement: '2.00',
      pin: '0.06',
      batch: '0.03',
      ebtA: '0.06',
      voice: '0.98',
      avs: '0.02',
      annual: '5.00',
      monthly: '0.00',
      cancellation: '495.00',
      govt: '2.95',
      welcome: '2.95',
      micros: '0.065',
      liable: false,

      tab: "tsys",

      tbank: "cbcal",
      tprofile: "paylo",
      tprogramm: '0.00',
      tprogrami: '2.50',
      tnoncompliancem: '29.95',
      tnoncompliancei: '14.95',
      tpprogram: '0.00',
      tsettlement: '0.00',
      tpbin: '0.0',
      tndf: '5.00',
      tndfb: false,

      fprogramm: '99.00',
      fprogrami: '30.00',
      fnoncompliancem: '19.95',
      fnoncompliancei: '9.95',
      fbuystatement: '.25',
      fbuyauth: '.01',
      fbuydownfull: '1.00',
      fbuydownpartial: '.50',
      fbuydowntable: '.50',
      famex: '15.0',

      pprogramm: '99.00',
      pprogrami: '30.00',
      pnoncompliancem: '24.95',
      pnoncompliancei: '14.95',
      pmissingoriginals: '1.00',
      phelpdesk: '3.00',
      phelpafter: '5.75',
      pamex: '20.0',
      ppprogram: '9.95',
      ppsettlement: '.05',
      ppbin: '5.0',
      loaded: true
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.handleBankChange = this.handleBankChange.bind(this);
  }



  handleInputChange(event){
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleCurrencyChange(maskedvalue, floatvalue, event){
    const name = event.target.name;
    this.setState({[name]: floatvalue});
  }

  handleTotalChange = (owner) =>{
    this.setState({
      owner: owner
    });
  }

  handleTabChange = (tab) =>{
    this.setState({
      tab: tab
    });
  }

  handleBankChange = (bank) =>{
    this.setState({
      tbank: bank
    });
    if(bank === "cbcal"){
      this.setState({
        tndf: '5.00'
      });
    }
    else{
      this.setState({
        tndf: '2.0'
      });
    }
  }

  handleProfileChange = (profile) =>{
    this.setState({
      tprofile: profile
    });
    if(profile === "paylo"){
      this.setState({
        tprogrami: '2.50',
        tprogramm: '0'
      });
    }
    else{
      this.setState({
        tprogrami: '6.00',
        tprogramm: '6.00'
      });
    }
  }

  handleLiabilityChange = (liable) =>{
    console.log(liable);
    if (liable){
      this.setState({liable: false});
    }
    else{
      this.setState({liable: true});
    }
  }

  handleNDFChange = (ndf) =>{
    if (ndf){
      this.setState({tndfb: false});
    }
    else{
      this.setState({tndfb: true});
    }
  }

  calcTotal = (annual) =>{
    var base = 0;
    var lia = 0;
    var spec = 0;
    var ndft = 0;
    if (this.state.liable){
      lia = (parseFloat(this.state.chargebackIncome) - (parseFloat(this.state.chargebackCount)*parseFloat(this.state.cbliability)))
          + (parseFloat(this.state.achRejectIncome) - (parseFloat(this.state.achRejectCount)*parseFloat(this.state.arliability)))
          + (parseFloat(this.state.retrievalIncome) - (parseFloat(this.state.retrievalCount)*parseFloat(this.state.rtliability)))
          + (parseFloat(this.state.reversalIncome) - (parseFloat(this.state.reversalCount)*parseFloat(this.state.rvliability)))
    }
    if (this.state.tab === "tsys"){
      spec = ((parseFloat(this.state.pprogram) - parseFloat(this.state.tpprogram))
          + (parseFloat(this.state.settlement) - parseFloat(this.state.tsettlement))
          + parseFloat(this.state.pbin) - ((parseFloat(this.state.tpbin)/10000) * parseFloat(this.state.grossVolume)))
      if (this.state.tndfb){
        if (this.state.tbank === "cbcal"){
          ndft = (parseFloat(this.state.ndf) - (parseFloat(this.state.tndf)));
        }
        else{
          ndft = (parseFloat(this.state.ndf) - (parseFloat(this.state.tndf)/10000));
        }
      }
    }
    else if (this.state.tab === "first"){

    }
    else{

    }
    if (annual){
      base = ((parseFloat(this.state.annual)
          + (parseFloat(this.state.interchangeIncome) - parseFloat(this.state.interchangeExpense))
          + (parseFloat(this.state.cardBrandIncome) - parseFloat(this.state.cardBrandExpense))
          /*+ parseFloat(this.state.authorizationTrxnsIncome)*/ - (parseFloat(this.state.acs) * parseFloat(this.state.authorizations))
          - ((parseFloat(this.state.bin)/10000) * parseFloat(this.state.grossVolume))
          - ((parseFloat(this.state.liability)/10000) * parseFloat(this.state.grossVolume))
          - parseFloat(this.state.account)
          - parseFloat(this.state.statement)
          - (parseFloat(this.state.pin) * parseFloat(this.state.numberPinDebit))
          - (parseFloat(this.state.batch) * parseFloat(this.state.numberBatches))
          - (parseFloat(this.state.ebtA) * parseFloat(this.state.ebt))
          - (parseFloat(this.state.voice) * parseFloat(this.state.voicec))
          - (parseFloat(this.state.avs) * parseFloat(this.state.avsCount))
          + (parseFloat(this.state.monthlymin) - parseFloat(this.state.monthly))
          - parseFloat(this.state.govt)
          - (parseFloat(this.state.micros) * parseFloat(this.state.mic))
          + lia + spec)*12);
    }
    else{
      base = ((parseFloat(this.state.interchangeIncome) - parseFloat(this.state.interchangeExpense))
          + (parseFloat(this.state.cardBrandIncome) - parseFloat(this.state.cardBrandExpense))
          /*+ parseFloat(this.state.authorizationTrxnsIncome)*/ - (parseFloat(this.state.acs) * parseFloat(this.state.authorizations))
          - ((parseFloat(this.state.bin)/10000) * parseFloat(this.state.grossVolume))
          - ((parseFloat(this.state.liability)/10000) * parseFloat(this.state.grossVolume))
          - parseFloat(this.state.account)
          - parseFloat(this.state.statement)
          - (parseFloat(this.state.pin) * parseFloat(this.state.numberPinDebit))
          - (parseFloat(this.state.batch) * parseFloat(this.state.numberBatches))
          - (parseFloat(this.state.ebtA) * parseFloat(this.state.ebt))
          - (parseFloat(this.state.voice) * parseFloat(this.state.voicec))
          - (parseFloat(this.state.avs) * parseFloat(this.state.avsCount))
          + (parseFloat(this.state.monthlymin) - parseFloat(this.state.monthly))
          - parseFloat(this.state.govt)
          - (parseFloat(this.state.micros) * parseFloat(this.state.mic))
          + lia + spec);
    }
    if (!annual){
      console.log("\nsplit: " + (parseFloat(this.state.isc)/100)
                + "\nIncome: " + parseFloat(this.state.income)
                + "\nExpense: " + parseFloat(this.state.expense)
                + "\nnet Income: " + (parseFloat(this.state.income) - parseFloat(this.state.expense))
                + "\nbase: " + (base - lia - spec)
                + "\nlia: " + lia
                + "\nspec: " + spec
                + "\ntotal base: " + base
                + "\ntotal pre split: " + (parseFloat(this.state.income) - parseFloat(this.state.expense) + base)
                + "\ntotal post split + ndf: " + (((parseFloat(this.state.income) - parseFloat(this.state.expense) + base)*((parseFloat(this.state.isc)/100)))+ndft)
                + "\nbin base: " + (parseFloat(this.state.bin)/10000)
                + "\ngrossVolume: " + (parseFloat(this.state.grossVolume))
                + "\nbin: " + ((parseFloat(this.state.bin)/10000) * parseFloat(this.state.grossVolume)))
    }
    if (this.state.owner === "iso"){
      if (annual){
        return (((((parseFloat(this.state.income) - parseFloat(this.state.expense))*12) + base)*((parseFloat(this.state.isc)/100)))+(ndft*12)).toFixed(2);
      }
      else{
        return (((parseFloat(this.state.income) - parseFloat(this.state.expense) + base)*((parseFloat(this.state.isc)/100)))+ndft).toFixed(2);
      }
    }
    else{
      if (annual){
        return ((parseFloat(this.state.income)*12) - (parseFloat(this.state.expense)*12) - (((((parseFloat(this.state.income) - parseFloat(this.state.expense))*12) + base)*((parseFloat(this.state.isc)/100)))+(ndft*12))).toFixed(2); //
      }
      else{
        return (parseFloat(this.state.income) - (parseFloat(this.state.expense)) - (((parseFloat(this.state.income) - parseFloat(this.state.expense) + base)*((parseFloat(this.state.isc)/100)))+ndft)).toFixed(2); //
      }
    }
  }

  render() {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    })

    return (
      <div className="scDashboard">
        {this.state.loaded ? (
          <Card className="calculator">
            <Card.Header className="Merchant">
              <div>
                <div className="scflex">
                  <div className="monthly">
                    <div className="total">
                      Monthly total:
                    </div>
                    <div className="totalnum">
                      ${parseFloat(this.calcTotal(false)).toFixed(2)}
                    </div>
                  </div>
                  <div className="yearly">
                    <div className="total">
                      Yearly total:
                    </div>
                    <div className="totalnum">
                      ${parseFloat(this.calcTotal(true)).toFixed(2)}
                    </div>
                  </div>
                </div>
                <ButtonToolbar>
                  <ToggleButtonGroup className="radioSelect" type="radio" name="total" defaultValue={"iso"}>
                    <ToggleButton variant={"outline-success"} id="iso" value="iso" onClick={() => this.handleTotalChange("iso")}> ISO </ToggleButton>
                    <ToggleButton variant={"outline-success"} id="sgpy" value="sgpy" onClick={() => this.handleTotalChange("sgpy")}> SignaPay </ToggleButton>
                  </ToggleButtonGroup>
                </ButtonToolbar>
                <Accordion className="merchantDetails">
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="merchantDetailsToggle">
                      Merchant Details
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <div className="flexDetails">
                          <div className="detailInput">
                            <label>
                              Gross Volume:
                            </label>
                            <CurrencyInput name="grossVolume" inputType="text" value={this.state.grossVolume} onChange={this.handleCurrencyChange} prefix="$"/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Expense:
                            </label>
                            <CurrencyInput name="expense" inputType="text" value={this.state.expense} onChange={this.handleCurrencyChange} prefix="$"/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Income:
                            </label>
                            <CurrencyInput name="income" inputType="text" value={this.state.income} onChange={this.handleCurrencyChange} prefix="$"/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Interchange Income:
                            </label>
                            <CurrencyInput name="interchangeIncome" inputType="text" value={this.state.interchangeIncome} onChange={this.handleCurrencyChange} prefix="$"/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Interchange Expense:
                            </label>
                            <CurrencyInput name="interchangeExpense" inputType="text" value={this.state.interchangeExpense} onChange={this.handleCurrencyChange} prefix="$"/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Card Brand Fees Income:
                            </label>
                            <CurrencyInput name="cardBrandIncome" inputType="text" value={this.state.cardBrandIncome} onChange={this.handleCurrencyChange} prefix="$"/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Card Brand Fees Expense:
                            </label>
                            <CurrencyInput name="cardBrandExpense" inputType="text" value={this.state.cardBrandExpense} onChange={this.handleCurrencyChange} prefix="$"/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Liability Income:
                            </label>
                            <CurrencyInput name="liabilityIncome" inputType="text" value={this.state.liabilityIncome} onChange={this.handleCurrencyChange} prefix="$"/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Chargeback Count:
                            </label>
                            <input name="chargebackCount" inputType="text" value={this.state.chargebackCount} onChange={this.handleInputChange}/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Chargeback Income:
                            </label>
                            <CurrencyInput name="chargebackIncome" inputType="text" value={this.state.chargebackIncome} onChange={this.handleCurrencyChange} prefix="$"/>
                          </div>
                          <div className="detailInput">
                            <label>
                              ACH Reject Count:
                            </label>
                            <input name="achRejectCount" inputType="text" value={this.state.achRejectCount} onChange={this.handleInputChange}/>
                          </div>
                          <div className="detailInput">
                            <label>
                              ACH Reject Income:
                            </label>
                            <CurrencyInput name="achRejectIncome" inputType="text" value={this.state.achRejectIncome} onChange={this.handleCurrencyChange} prefix="$"/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Retrieval Count:
                            </label>
                            <input name="retrievalCount" inputType="text" value={this.state.retrievalCount} onChange={this.handleInputChange}/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Retrieval Income:
                            </label>
                            <CurrencyInput name="retrievalIncome" inputType="text" value={this.state.retrievalIncome} onChange={this.handleCurrencyChange} prefix="$"/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Reversal Count:
                            </label>
                            <input name="reversalCount" inputType="text" value={this.state.reversalCount} onChange={this.handleInputChange}/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Reversal Income:
                            </label>
                            <CurrencyInput name="reversalIncome" inputType="text" value={this.state.reversalIncome} onChange={this.handleCurrencyChange} prefix="$"/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Number of Authorizations:
                            </label>
                            <input name="authorizations" inputType="text" value={this.state.authorizations} onChange={this.handleInputChange}/>
                          </div>
                          {/*<div className="detailInput">
                            <label>
                              Authorization Trxns Income:
                            </label>
                            <CurrencyInput name="authorizationTrxnsIncome" inputType="text" value={this.state.authorizationTrxnsIncome} onChange={this.handleCurrencyChange} prefix="$"/>
                          </div>*/}
                          <div className="detailInput">
                            <label>
                              Number of Pin Debit:
                            </label>
                            <input name="numberPinDebit" inputType="text" value={this.state.numberPinDebit} onChange={this.handleInputChange}/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Number of Batches:
                            </label>
                            <input name="numberBatches" inputType="text" value={this.state.numberBatches} onChange={this.handleInputChange}/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Number of EBT Authorizations:
                            </label>
                            <input name="ebt" inputType="text" value={this.state.ebt} onChange={this.handleInputChange}/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Number of Voice Authorizations:
                            </label>
                            <input name="voicec" inputType="text" value={this.state.voicec} onChange={this.handleInputChange}/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Number of AVS:
                            </label>
                            <input name="avsCount" inputType="text" value={this.state.avsCount} onChange={this.handleInputChange}/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Monthly Minimum:
                            </label>
                            <CurrencyInput name="monthlymin" inputType="text" value={this.state.monthlymin} onChange={this.handleCurrencyChange} prefix="$"/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Cancellation:
                            </label>
                            <CurrencyInput name="cancellationmme" inputType="text" value={this.state.cancellationmme} onChange={this.handleCurrencyChange} prefix="$"/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Number of Micros Authorizations:
                            </label>
                            <input name="mic" inputType="text" value={this.state.mic} onChange={this.handleInputChange}/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Amex Volume:
                            </label>
                            <CurrencyInput name="amex" inputType="text" value={this.state.amex} onChange={this.handleCurrencyChange} prefix="$"/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Paylo Program Income:
                            </label>
                            <CurrencyInput name="pprogram" inputType="text" value={this.state.pprogram} onChange={this.handleCurrencyChange} prefix="$"/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Paylo Settlement Income:
                            </label>
                            <CurrencyInput name="settlement" inputType="text" value={this.state.settlement} onChange={this.handleCurrencyChange} prefix="$"/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Paylo Additional Bin Sponsorship Income:
                            </label>
                            <CurrencyInput name="pbin" inputType="text" value={this.state.pbin} onChange={this.handleCurrencyChange} prefix="$"/>
                          </div>
                          <div className="detailInput">
                            <label>
                              Next Day Funding Income:
                            </label>
                            <CurrencyInput name="ndf" inputType="text" value={this.state.ndf} onChange={this.handleCurrencyChange} prefix="$"/>
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </Card.Header>
            <Card.Body className="SalesCalculator">
              <div className="scflex">
                <div className="general">
                  <Card.Header>
                    General pricing
                  </Card.Header>
                  <div className="genBodyCard">
                    <label>
                      ISC split:
                    </label>
                    <CurrencyInput className="text" name="isc" inputType="text" value={this.state.isc} onChange={this.handleCurrencyChange} suffix="%" precision="0"/>
                    <label>
                      V/MC/Discover Interchange:
                    </label>
                    <input className="text" name="interchange" type="text" value={this.state.interchange} onChange={this.handleInputChange} disabled="True"/>
                    <label>
                      Authorization/Capture/Settle:
                    </label>
                    <CurrencyInput className="text" name="acs" inputType="text" value={this.state.acs} onChange={this.handleCurrencyChange} prefix="$"/>
                    <label>
                      BIN Sponsorship Fee: [Basis Points]
                    </label>
                    <input className="text" name="bin" type="text" value={this.state.bin} onChange={this.handleInputChange}/>
                    <label>
                      Liability/Risk Fee: [Basis Points]
                    </label>
                    <input className="text" name="liability" type="text" value={this.state.liability} onChange={this.handleInputChange}/>
                    <ButtonToolbar className="radio">
                      <ToggleButtonGroup className="radioSelect" type="radio" name="liable" defaultValue={"nliable"}>
                        <ToggleButton variant={"outline-success"} id="liable" value="liable" onClick={() => this.handleLiabilityChange(false)}> Liable </ToggleButton>
                        <ToggleButton variant={"outline-success"} id="nliable" value="nliable" onClick={() => this.handleLiabilityChange(true)}> Not Liable </ToggleButton>
                      </ToggleButtonGroup>
                    </ButtonToolbar>
                    {this.state.liable ? (
                      <div>
                        <label>
                          Chargeback Liability:
                        </label>
                        <CurrencyInput className="text" name="cbliability" inputType="text" value={this.state.cbliability} onChange={this.handleCurrencyChange} prefix="$"/>
                        <label>
                          ACH Reject Liability:
                        </label>
                        <CurrencyInput className="text" name="arliability" inputType="text" value={this.state.arliability} onChange={this.handleCurrencyChange} prefix="$"/>
                        <label>
                          Retrieval Liability:
                        </label>
                        <CurrencyInput className="text" name="rtliability" inputType="text" value={this.state.rtliability} onChange={this.handleCurrencyChange} prefix="$"/>
                        <label>
                          Reversal Liability:
                        </label>
                        <CurrencyInput className="text" name="rvliability" inputType="text" value={this.state.rvliability} onChange={this.handleCurrencyChange} prefix="$"/>
                      </div>
                    ):(
                      <div>
                      </div>
                    )}
                    <label>
                      Merchant Account on File: [$/Per acount per month]
                    </label>
                    <CurrencyInput className="text" name="account" type="text" value={this.state.account} onChange={this.handleCurrencyChange} prefix="$"/>
                    <label>
                      Merchant Statement: [$/statement per month]
                    </label>
                    <CurrencyInput className="text" name="statement" type="text" value={this.state.statement} onChange={this.handleCurrencyChange} prefix="$"/>
                    <label>
                      Pin Debit Transaction Fee:
                    </label>
                    <CurrencyInput className="text" name="pin" type="text" value={this.state.pin} onChange={this.handleCurrencyChange} prefix="$"/>
                    <label>
                      Batch Header:
                    </label>
                    <CurrencyInput className="text" name="batch" type="text" value={this.state.batch} onChange={this.handleCurrencyChange} prefix="$"/>
                    <label>
                      EBT Authorization Fee:
                    </label>
                    <CurrencyInput className="text" name="ebtA" type="text" value={this.state.ebtA} onChange={this.handleCurrencyChange} prefix="$"/>
                    <label>
                      Voice Authorization Inquiry: [$/attempt]
                    </label>
                    <CurrencyInput className="text" name="voice" type="text" value={this.state.voice} onChange={this.handleCurrencyChange} prefix="$"/>
                    <label>
                      AVS:
                    </label>
                    <CurrencyInput className="text" name="avs" type="text" value={this.state.avs} onChange={this.handleCurrencyChange} prefix="$"/>
                    <label>
                      Annual Fee:
                    </label>
                    <CurrencyInput className="text" name="annual" type="text" value={this.state.annual} onChange={this.handleCurrencyChange} prefix="$"/>
                    <label>
                      Monthly Minimum:
                    </label>
                    <CurrencyInput className="text" name="monthly" type="text" value={this.state.monthly} onChange={this.handleCurrencyChange} prefix="$"/>
                    <label>
                      Cancellation Fee:
                    </label>
                    <CurrencyInput className="text" name="cancellation" type="text" value={this.state.cancellation} onChange={this.handleCurrencyChange} prefix="$"/>
                    <label>
                      Gov't Regulatory Program:
                    </label>
                    <CurrencyInput className="text" name="govt" type="text" value={this.state.govt} onChange={this.handleCurrencyChange} prefix="$"/>
                    <label>
                      Merchant Welcome Kit:
                    </label>
                    <CurrencyInput className="text" name="welcome" type="text" value={this.state.welcome} onChange={this.handleCurrencyChange} prefix="$"/>
                    <label>
                      Micros Authorization/Capture:
                    </label>
                    <CurrencyInput className="text" name="micros" type="text" value={this.state.micros} onChange={this.handleCurrencyChange} prefix="$" precision="3" disabled={"True"}/>
                  </div>
                </div>
                <div className="Specific">
                  <Tabs defaultActiveKey="TSYS" className="sctabs" id="tabs" variant="pills">
                    <Tab eventKey="TSYS" title="TSYS" className="specBody" onClick={() => this.handleTabChange("tsys")}>
                      <div>
                        <ButtonToolbar className="radio">
                          <ToggleButtonGroup className="radioSelect" type="radio" name="bank" defaultValue={"cbcal"}>
                            <ToggleButton variant="outline-success" value={"cbcal"} onClick={() => this.handleBankChange("cbcal")}>CBCAL</ToggleButton>
                            <ToggleButton variant="outline-success" value={"esquire"} onClick={() => this.handleBankChange("esquire")}>ESQUIRE</ToggleButton>
                          </ToggleButtonGroup>
                        </ButtonToolbar>
                        <ButtonToolbar className="radio">
                          <ToggleButtonGroup className="radioSelect" type="radio" name="profile" defaultValue={"paylo"}>
                            <ToggleButton variant="outline-success" value={"paylo"} onClick={() => this.handleProfileChange("paylo")}>PAYLO</ToggleButton>
                            <ToggleButton variant="outline-success" value={"traditional"} onClick={() => this.handleProfileChange("traditional")}>TRADITIONAL</ToggleButton>
                            <ToggleButton variant="outline-success" value={"highrisk"} disabled={"True"} onClick={() => this.handleProfileChange("highrisk")}>HIGHRISK</ToggleButton>
                          </ToggleButtonGroup>
                        </ButtonToolbar>
                        <div className="specBodyCard">
                          <div className="fields">
                            <label>
                              PCI Program Fee (merchant level):
                            </label>
                            <CurrencyInput name="tprogramm" type="text" value={this.state.tprogramm} onChange={this.handleCurrencyChange} prefix="$" disabled={"True"}/>
                            <label>
                              PCI Program Fee (iso level):
                            </label>
                            <CurrencyInput name="tprogrami" type="text" value={this.state.tprogrami} onChange={this.handleCurrencyChange} prefix="$" disabled={"True"}/>
                            <label>
                              PCI Non-Compliance Fee (merchant level):
                            </label>
                            <CurrencyInput name="tnoncompliancem" type="text" value={this.state.tnoncompliancem} onChange={this.handleCurrencyChange} prefix="$" disabled={"True"}/>
                            <label>
                              PCI Non-Compliance Fee (iso level):
                            </label>
                            <CurrencyInput name="tnoncompliancei" type="text" value={this.state.tnoncompliancei} onChange={this.handleCurrencyChange} prefix="$" disabled={"True"}/>
                            <label>
                              Paylo Program Fee:
                            </label>
                            <CurrencyInput name="tpprogram" type="text" value={this.state.tpprogram} onChange={this.handleCurrencyChange} prefix="$"/>
                            <label>
                              Paylo Settlement Item Fee:
                            </label>
                            <CurrencyInput name="tsettlement" type="text" value={this.state.tsettlement} onChange={this.handleCurrencyChange} prefix="$"/>
                            <label>
                              Paylo Additional BIN Sponsorship Fee: [Basis Points]
                            </label>
                            <input name="tpbin" type="text" value={this.state.tpbin} onChange={this.handleInputChange}/>
                            <ButtonToolbar>
                              <ToggleButtonGroup className="radioSelect" type="radio" name="ndf" defaultValue={"nndfund"}>
                                <ToggleButton variant={"outline-success"} id="ndfund" value="ndfund" onClick={() => this.handleNDFChange(false)}> Next Day Funding </ToggleButton>
                                <ToggleButton variant={"outline-success"} id="nndfund" value="nndfund" onClick={() => this.handleNDFChange(true)}> No Next Day Funding </ToggleButton>
                              </ToggleButtonGroup>
                            </ButtonToolbar>
                            {this.state.tndfb ? (
                              <div>
                              {this.state.tbank === "cbcal" ? (
                                <div>
                                  <label>
                                    Next Day Funding:
                                  </label>
                                  <CurrencyInput name="tndf" type="text" value={this.state.tndf} onChange={this.handleCurrencyChange} prefix="$" disabled="True"/>
                                </div>
                              ):(
                                <div>
                                  <label>
                                    Next Day Funding: [Basis Points]
                                  </label>
                                  <input name="tndf" type="text" value={this.state.tndf} onChange={this.handleInputChange} disabled="True"/>
                                </div>
                              )}
                              </div>
                            ):(
                              <div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="FirstData" title="First Data" className="specBody" onClick={() => this.handleTabChange("first")} disabled={"True"}>
                      <div className="specBodyCard">
                        <ButtonToolbar className="radio">
                          <ToggleButtonGroup className="radioSelect" type="radio" name="bank" defaultValue={"cbcal"}>
                            <ToggleButton variant="outline-success" value={"cbcal"} onClick={() => this.handleBankChange("cbcal")}>CBCAL</ToggleButton>
                            <ToggleButton variant="outline-success" value={"esquire"} onClick={() => this.handleBankChange("esquire")}>ESQUIRE</ToggleButton>
                          </ToggleButtonGroup>
                        </ButtonToolbar>
                        <div className="fields">
                          <label>
                            PCI Program Fee (merchant level):
                          </label>
                          <CurrencyInput name="fprogramm" type="text" value={this.state.fprogramm} onChange={this.handleCurrencyChange} prefix="$" disabled={"True"}/>
                          <label>
                            PCI Program Fee (iso level):
                          </label>
                          <CurrencyInput name="fprogrami" type="text" value={this.state.fprogrami} onChange={this.handleCurrencyChange} prefix="$" disabled={"True"}/>
                          <label>
                            PCI Non-Compliance Fee (merchant level):
                          </label>
                          <CurrencyInput name="fnoncompliancem" type="text" value={this.state.fnoncompliancem} onChange={this.handleCurrencyChange} prefix="$" disabled={"True"}/>
                          <label>
                            PCI Non-Compliance Fee (iso level):
                          </label>
                          <CurrencyInput name="fnoncompliancei" type="text" value={this.state.fnoncompliancei} onChange={this.handleCurrencyChange} prefix="$" disabled={"True"}/>
                          <label>
                            Buypass Statement Fee:
                          </label>
                          <CurrencyInput name="fbuystatement" type="text" value={this.state.fbuystatement} onChange={this.handleCurrencyChange} prefix="$"/>
                          <label>
                            Buypass Pre Auth Fee (Pay-at-the-Pump): [$/Per occurence]
                          </label>
                          <CurrencyInput name="fbuyauth" type="text" value={this.state.fbuyauth} onChange={this.handleCurrencyChange} prefix="$"/>
                          <label>
                            Buypass Down Services Full Download: [$/Per occurence]
                          </label>
                          <CurrencyInput name="fbuydownfull" type="text" value={this.state.fbuydownfull} onChange={this.handleCurrencyChange} prefix="$"/>
                          <label>
                            Buypass Down Services Partial Download: [$/Per occurence]
                          </label>
                          <CurrencyInput name="fbuydownpartial" type="text" value={this.state.fbuydownpartial} onChange={this.handleCurrencyChange} prefix="$"/>
                          <label>
                            Buypass Down Services Table Download: [$/Per occurence]
                          </label>
                          <CurrencyInput name="fbuydowntable" type="text" value={this.state.fbuydowntable} onChange={this.handleCurrencyChange} prefix="$"/>
                          <label>
                            Amex OptBlue Sponsor Cost: [Basis Points]
                          </label>
                          <input name="famex" type="text" value={this.state.famex} onChange={this.handleInputChange}/>
                          <ButtonToolbar>
                            <ToggleButtonGroup className="radioSelect" type="radio" name="ndf" defaultValue={"nndfund"}>
                              <ToggleButton variant={"outline-success"} id="ndfund" value="ndfund" onClick={() => this.handleNDFChange(false)}> Next Day Funding </ToggleButton>
                              <ToggleButton variant={"outline-success"} id="nndfund" value="nndfund" onClick={() => this.handleNDFChange(true)}> No Next Day Funding </ToggleButton>
                            </ToggleButtonGroup>
                          </ButtonToolbar>
                          {this.state.tndfb ? (
                            <div>
                            {this.state.tbank === "cbcal" ? (
                              <div>
                                <label>
                                  Next Day Funding:
                                </label>
                                <CurrencyInput name="tndf" type="text" value={this.state.tndf} onChange={this.handleCurrencyChange} prefix="$" disabled="True"/>
                              </div>
                            ):(
                              <div>
                                <label>
                                  Next Day Funding: [Basis Points]
                                </label>
                                <input name="tndf" type="text" value={this.state.tndf} onChange={this.handleInputChange} disabled="True"/>
                              </div>
                            )}
                            </div>
                          ):(
                            <div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="PPS" title="PPS" className="specBody" onClick={() => this.handleTabChange("pps")} disabled={"True"}>
                      <div className="specBodyCard">
                        <ButtonToolbar className="radio">
                          <ToggleButtonGroup className="radioSelect" type="radio" name="bank" defaultValue={"cbcal"}>
                            <ToggleButton variant="outline-success" value={"cbcal"} onClick={() => this.handleBankChange("cbcal")}>CBCAL</ToggleButton>
                            <ToggleButton variant="outline-success" value={"esquire"} onClick={() => this.handleBankChange("esquire")}>ESQUIRE</ToggleButton>
                          </ToggleButtonGroup>
                        </ButtonToolbar>
                        <ButtonToolbar className="radio">
                          <ToggleButtonGroup className="radioSelect" type="radio" name="ndf" defaultValue={"nndfund"}>
                            <ToggleButton variant={"outline-success"} id="ndfund" value="ndfund" onClick={() => this.handleNDFChange(false)}> Next Day Funding </ToggleButton>
                            <ToggleButton variant={"outline-success"} id="nndfund" value="nndfund" onClick={() => this.handleNDFChange(true)}> No Next Day Funding </ToggleButton>
                          </ToggleButtonGroup>
                        </ButtonToolbar>
                        {this.state.tndfb ? (
                          <div>
                          {this.state.tbank === "cbcal" ? (
                            <div>
                              <label>
                                Next Day Funding:
                              </label>
                              <CurrencyInput name="tndf" type="text" value={this.state.tndf} onChange={this.handleCurrencyChange} prefix="$" disabled="True"/>
                            </div>
                          ):(
                            <div>
                              <label>
                                Next Day Funding: [Basis Points]
                              </label>
                              <input name="tndf" type="text" value={this.state.tndf} onChange={this.handleInputChange} disabled="True"/>
                            </div>
                          )}
                          </div>
                        ):(
                          <div>
                          </div>
                        )}
                        <label>
                          PCI Program Fee (merchant level):
                        </label>
                        <CurrencyInput name="pprogramm" type="text" value={this.state.pprogramm} onChange={this.handleCurrencyChange} prefix="$" disabled={"True"}/>
                        <label>
                          PCI Program Fee (iso level):
                        </label>
                        <CurrencyInput name="pprogrami" type="text" value={this.state.pprogrami} onChange={this.handleCurrencyChange} prefix="$" disabled={"True"}/>
                        <label>
                          PCI Non-Compliance Fee (merchant level):
                        </label>
                        <CurrencyInput name="pnoncompliancem" type="text" value={this.state.pnoncompliancem} onChange={this.handleCurrencyChange} prefix="$" disabled={"True"}/>
                        <label>
                          PCI Non-Compliance Fee (iso level):
                        </label>
                        <CurrencyInput name="pnoncompliancei" type="text" value={this.state.pnoncompliancei} onChange={this.handleCurrencyChange} prefix="$" disabled={"True"}/>
                        <label>
                          Missing Originals Fee: [$/Per day]
                        </label>
                        <CurrencyInput name="pmissingoriginals" type="text" value={this.state.pmissingoriginals} onChange={this.handleCurrencyChange} prefix="$"/>
                        <label>
                          Help Desk/Service Calls:
                        </label>
                        <CurrencyInput name="phelpdesk" type="text" value={this.state.phelpdesk} onChange={this.handleCurrencyChange} prefix="$"/>
                        <label>
                          After-Hours Help Desk Calls:
                        </label>
                        <CurrencyInput name="phelpafter" type="text" value={this.state.phelpafter} onChange={this.handleCurrencyChange} prefix="$"/>
                        <label>
                          Amex OptBlue Sponsor Cost: [Basis Points]
                        </label>
                        <input name="pamex" type="text" value={this.state.pamex} onChange={this.handleInputChange}/>
                        <label>
                          Paylo Program Fee:
                        </label>
                        <CurrencyInput name="ppprogram" type="text" value={this.state.ppprogram} onChange={this.handleCurrencyChange} prefix="$"/>
                        <label>
                          Paylo Settlement Item Fee:
                        </label>
                        <CurrencyInput name="ppsettlement" type="text" value={this.state.ppsettlement} onChange={this.handleCurrencyChange} prefix="$"/>
                        <label>
                          Paylo Additional BIN Sponsorship Fee: [Basis Points]
                        </label>
                        <input name="ppbin" type="text" value={this.state.ppbin} onChange={this.handleInputChange}/>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </Card.Body>
          </Card>
        ):(
          <div style={{height: '100%', width: '100%', justifyContent: "center", display: "flex" }}>
            <Loader color="green" width={125} heigth={125} type="Oval" margin="auto"/>
          </div>
        )}
      </div>
    );
  }
}
export default withCookies(SalesCalculator);
