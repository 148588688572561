import React, { useEffect, useState } from "react"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { Form, Modal, Button, Col, InputGroup, FormControl, Dropdown, Alert } from "react-bootstrap"
import { ApiClient } from "../../ApiClient"
import CustomSelect from "./CustomSelect"

export default function AddISOModal(props) {

    const DEFAULT_VOLUME_DAYS = 30
    const DEFAULT_VOLUME_THRESHOLD = 250

    const [isoList, setIsoList] = useState([])
    const [selectedIndex, setSelectedIndex] = useState()
    const [bonus, setBonus] = useState(0)
    const [isFailed, setIsFailed] = useState(false)
    const [forceAdd, setForceAdd] = useState(false)
    const [warningMessage, setWarningMessage] = useState()
    const [selectedValues, setSelectedValues] = useState([])
    const [placementOnly, setPlacementOnly] = useState(false)
    const [payOnActivation, setPayOnActivation] = useState(false)
    const [volumeDays, setVolumeDays] = useState(DEFAULT_VOLUME_DAYS)
    const [volumeThreshold, setVolumeThreshold] = useState(DEFAULT_VOLUME_THRESHOLD)
    const FLAG_NAMES = {
        W: "Weekly",
        M: "Monthly",
        PL: "PayLo",
        TR: "Traditional",
    }

    const onClose = () => {
        setSelectedIndex()
        setWarningMessage()
        setIsFailed(false)
        setForceAdd(false)
        setSelectedValues([])
        setBonus(0)
        setPayOnActivation(false)
        setVolumeDays(DEFAULT_VOLUME_DAYS)
        setVolumeThreshold(DEFAULT_VOLUME_THRESHOLD)
    }

    const onSave = () => {
        if (!props.editMode && (!selectedIndex && selectedValues.length == 0)) return

        const group_id = selectedIndex == -1 || (props.editMode && props.data.iso_name == null) ? null : selectedValues.filter(gid => gid !== null || gid !== "")
        ApiClient.post({
            url: "/api/bonus/AddBonusISO",
            body: {
                profile: props.profile,
                period: props.period,
                group_id: group_id,
                amount: Number(bonus),
                force: forceAdd,
                edit: props.editMode,
                iso_name: props.editMode ? props.data.iso_name || null : selectedIndex > -1 ? isoList[selectedIndex].iso_name : null,
                on_activation: payOnActivation,
                volume_days: volumeDays,
                volume_threshold: volumeThreshold,
                placement_only: placementOnly ? 1 : 0
            }
        }).then(json => {
            if (json.data.success) {
                props.onHide()
                props.onEdit()
                return
            }
            setIsFailed(!json.data.addable)
            setForceAdd(json.data.addable)
            setWarningMessage(json.data.message)
        }).catch(err => {
            setWarningMessage(err)
        });
    }

    const onClickNo = () => {
        setWarningMessage()
        setIsFailed(false)
        setForceAdd(false)
    }

    useEffect(() => {
        setSelectedValues([])
    }, [selectedIndex])

    useEffect(() => {
        if (!props.editMode) return
        setSelectedValues(props.data?.group_id?.replaceAll(' ', '').split(','))
        setBonus(props.data?.amount)
        setPayOnActivation(props.data?.on_activation == 1)
        setPlacementOnly(props.data?.placement_only == 1)
        if (props.data?.on_activation != 1) return
        setVolumeDays(props.data?.volume_days)
        setVolumeThreshold(props.data?.volume_threshold)
    }, [props.data])

    useEffect(() => {
        ApiClient.get({
            url: "/api/bonus/GetIsoList",
        }).then(json => {
            setIsoList(json.data);
        }).catch(err => {
        });
    }, [])

    return (
        <Modal
        show={props.show}
        onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onExited={onClose}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <small>{props.editMode ? "Edit" : "Add"} {FLAG_NAMES[props.period]} {FLAG_NAMES[props.profile]} Bonus</small>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Row>
                        <Col column="md" md={3} className="text-right pt-2">
                            {!props.editMode && <Dropdown onSelect={setSelectedIndex}>
                                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                    Select ISO
                                </Dropdown.Toggle>
                                <Dropdown.Menu as={CustomMenu}>
                                    <Dropdown.Item eventKey={-1}>Default</Dropdown.Item>
                                    {isoList.map((iso, i) => (
                                        <Dropdown.Item key={i} eventKey={i}>{iso.iso_name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>}
                        </Col>
                        <Col>
                            <FormControl
                                value={props.editMode ? props.data.iso_name || "Default" : selectedIndex > -1 ? isoList[selectedIndex].iso_name : (selectedIndex == -1 ? "Default" : "")}
                                disabled={true}
                            />
                        </Col>
                    </Form.Row>
                    <br />
                    {(!props.editMode || props.data.iso_name != null) && <>
                        <Form.Row>
                            <Col column="md" md={3} className="text-right pt-2">
                                <CustomSelect
                                    data={props.editMode ? isoList.find(iso => iso.iso_name === props.data.iso_name)?.group_id.replaceAll(' ', '').split(',') : selectedIndex > -1 ? isoList[selectedIndex]?.group_id.replaceAll(' ', '').split(',') : []}
                                    selectedValues={selectedValues}
                                    onSelect={setSelectedValues}
                                />
                            </Col>
                            <Col>
                                <FormControl value={selectedValues?.join(', ')} disabled={true} />
                            </Col>
                        </Form.Row>
                        <br /></>}
                    {(selectedIndex > -1 || props.data?.iso_name != null) &&
                        <>
                            <Form.Row>
                                <Form.Label column="md" md={3} className="text-right">
                                    Placement Only
                                </Form.Label>
                                <Col>
                                    <RadioGroup
                                        value={placementOnly ? "yes" : "no"}
                                        onChange={(e) => {
                                            if (e.target.value === "yes") {
                                                setBonus(0)
                                                setPayOnActivation(false)
                                            }
                                            setPlacementOnly(e.target.value === "yes")
                                        }}>
                                        <InputGroup>
                                            <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                                            <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                                        </InputGroup>
                                    </RadioGroup>
                                </Col>
                            </Form.Row>
                            <br /></>}
                    <Form.Row>
                        <Form.Label column="md" md={3} className="text-right">
                            Bonus
                        </Form.Label>
                        <Col>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl placeholder="0.00" type="number" value={placementOnly ? 0 : bonus} onChange={e => setBonus(e.target.value)} disabled={placementOnly} />
                            </InputGroup>
                        </Col>
                    </Form.Row>
                    <br />
                    {(selectedIndex > -1 || props.data?.iso_name != null) && !placementOnly &&
                        <Form.Row>
                            <Form.Label column="md" md={3} className="text-right">
                                Pay On
                            </Form.Label>
                            <Col>
                                <InputGroup>
                                    <Form.Control as="select" custom value={payOnActivation ? 1 : 0} onChange={e => setPayOnActivation(Number(e.target.value) == 1)} disabled={props.editMode && props.data?.on_activation == 1}>
                                        <option value={0} key={0}>Approved</option>
                                        <option value={1} key={1}>Activated</option>
                                    </Form.Control>
                                </InputGroup>
                            </Col>
                        </Form.Row>}
                    {payOnActivation && !placementOnly && <>
                        <br />
                        <Form.Row>
                            <Form.Label column="md" md={3} className="text-right">
                                Days for Volume
                            </Form.Label>
                            <Col>
                                <FormControl placeholder="0" type="number" value={volumeDays} onChange={e => setVolumeDays(e.target.value)} disabled={props.editMode && props.data?.on_activation == 1} />
                            </Col>
                        </Form.Row>
                        <br />
                        <Form.Row>
                            <Form.Label column="md" md={3} className="text-right">
                                Volume Threshold
                            </Form.Label>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>$</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl placeholder="0.00" type="number" value={volumeThreshold} onChange={e => setVolumeThreshold(e.target.value)} disabled={props.editMode && props.data?.on_activation == 1} />
                                </InputGroup>
                            </Col>
                        </Form.Row></>}
                </Form.Group>
                {warningMessage && <Alert variant={isFailed ? "danger" : "warning"} className="text-center">
                    <small>{warningMessage}</small>
                    {forceAdd &&
                        <>
                            <p className="mt-3">Are you sure you want to continue?</p>
                            <Button variant="secondary" size="sm" className="mr-2" onClick={onClickNo}>No</Button>
                            <Button size="sm" onClick={onSave}>Yes</Button>
                        </>
                    }
                </Alert>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Close</Button>
                <Button onClick={onSave} disabled={forceAdd}>Save</Button>
            </Modal.Footer>
        </Modal>
    )
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        &#x25bc;
    </a>
))

const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <FormControl
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Type to filter..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value),
                    )}
                </ul>
            </div>
        );
    },
);

