import React from "react";
import { Card, Col, Row, Container, OverlayTrigger, Button, Popover } from "react-bootstrap";
import "./Style/iso.scss";
import { shortIt, commaIt } from "./Utils"

export const IncomeCard = ({ color, title, gross, net, expenses, payouts, count, loading }) => {
    return <Card className="m-2 w-100 ">
                <Card.Header className={`py-2 pl-3 ${color}`}>
                    {title} 
                </Card.Header>
                <Card.Body className="pb-1">
                    <Container>
                        <Row>
                            <Col className="text-right text-muted" xs={5}><h5>Gross: </h5></Col>
                            <Col>
                            {!loading ? <><h5>$ {shortIt(gross || 0, 'M')}</h5>
                            </> : <span className="float-left text-muted">Loading...</span>}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right text-muted" xs={5}><h5>Net: </h5></Col>
                            <Col>
                            {!loading ? <> <h5>$ {shortIt(net || 0, 'M')}</h5>
                            </> : <span className="float-left text-muted">Loading...</span>}
                            </Col>
                        </Row>
                    </Container>
                    <div className="float-right">
                        <OverlayTrigger 
                            placement="bottom" 
                            containerPadding={38}
                            overlay={
                                <Popover style={{width: "200px"}}>
                                <Popover.Content>
                                <Container>
                                    <Row>
                                        <Col className="text-right text-muted pr-1">Gross: </Col>
                                        <Col className="px-0">${shortIt(gross || 0, 'M')}</Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-right text-muted pr-1">Expenses: </Col>
                                        <Col className="px-0">${shortIt(expenses || 0, 'M')}</Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-right text-muted pr-1">Payouts: </Col>
                                        <Col className="px-0">${shortIt(payouts || 0, 'M')}</Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-right text-muted pr-1">Net: </Col>
                                        <Col className="px-0">${shortIt(net || 0, 'M')}</Col>
                                    </Row>
                                </Container>
                                </Popover.Content>
                                </Popover>
                            }
                        >
                            <Button variant="link" size="sm" onClick={e => e.preventDefault()}>Details</Button>
                        </OverlayTrigger>
                    </div>
                </Card.Body>
            </Card>
}