import React, { useEffect, useState } from "react"
import { Button, Table } from "react-bootstrap"
import "../Style/Style.scss"
import { ApiClient } from "../../ApiClient"
import AddExtraBonusModal from "./AddExtraBonusModal"
import CurrentWindow from "./CurrentWindow"
import moment from "moment"

export default function ExtraSpecs(props) {

    const [selectedExtraBonus, setSelectedExtraBonus] = useState({})
    const [addModalShow, setAddModalShow] = useState(false)
    const [addModalEditMode, setAddModalEditMode] = useState(false)
    const [extraBonusIsoList, setExtraBonusIsoList] = useState([])

    const onEditExtraBonus = (index) => {
        setAddModalEditMode(true)
        setSelectedExtraBonus(extraBonusIsoList[index])
        setAddModalShow(true)
    }

    const onDeleteExtraBonus = (index) => {

        if(!window.confirm(`Are you sure you want to delete ${extraBonusIsoList[index].iso_name} ?`)) return

        const group_id = extraBonusIsoList[index].group_id.replaceAll(' ', '').split(',').filter(gid => gid !== null || gid !== "")

        ApiClient.post({
            url: "/api/bonus/DeleteExtraBonusISO",
            body: {
                group_id: group_id,
            }
        }).then(json => {
            if(!json.data.success){
                window.alert(json.data.message)
                return
            }
            getExtraBonusIsoList()
    }).catch(err => {
        });
    }

    const getExtraBonusIsoList = () => {
        ApiClient.get({
            url: "/api/bonus/GetExtraBonusIsoList"
        }).then(json => {
            setExtraBonusIsoList(json.data);
        }).catch(err => {
        });
    }

    useEffect(() => {
        getExtraBonusIsoList()
    }, [])

    return (
        <>
        <div className="bonusCurrentWindow">
            <CurrentWindow windowType="E" />
        </div>
        <div className="extraBonusSpecs">
            <div className="specsTitle">
                Extra Bonus Requirements
                <Button variant="secondary" size="sm" className="float-right py-0" onClick={() => setAddModalShow(true)}>&#x002B;</Button>
            </div>
            <div className="bonusIncludeISOList">
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>ISO</th>
                            <th>Group ID</th>
                            <th>Number of Deals</th>
                            <th>Extra Bonus</th>
                            <th>Bonus Type</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Enabled</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {extraBonusIsoList.map((extra, i) => (
                            <tr key={i}>
                                <td>
                                    {extra.iso_name}
                                </td>
                                <td>
                                    {extra.group_id}
                                </td>
                                <td>
                                    {extra.extra_deals}
                                </td>
                                <td>
                                    {extra.extra_amount}
                                </td>
                                <td>
                                    {extra.bonus_type === 'EPD' ? 'Per Deal' : extra.bonus_type === 'EFR' ? 'Flat Rate' : ''}
                                </td>
                                <td>
                                    {extra.start_date && moment.utc(extra.start_date).format("YYYY-MM-DD")}
                                </td>
                                <td>
                                    {extra.end_date && moment.utc(extra.end_date).format("YYYY-MM-DD")}
                                </td>
                                <td>
                                    {extra.enabled == 1 ? <>&#x2714;</> : <></>}
                                </td>
                                <td className="text-right">
                                    <Button variant="outline-info" size="sm" className="py-0 mr-2 border-0" onClick={() => onEditExtraBonus(i)}>&#x270E;</Button>
                                    <Button variant="outline-danger" size="sm" className="py-0 border-0" onClick={() => onDeleteExtraBonus(i)}>&#x2716;</Button>
                                </td>
                            </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        </div>
        <AddExtraBonusModal
            data={selectedExtraBonus}
            show={addModalShow}
            onHide={() => { setAddModalShow(false); setAddModalEditMode(false); setSelectedExtraBonus() }}
            onEdit={() => { getExtraBonusIsoList(); setSelectedExtraBonus() }}
            editMode={addModalEditMode}
        />
        </>
    )
}

