const rejList = [
    "Used Reserve",
    "Used Reserve - PARTIAL",
    "Re-Ran Successfully",
    "Billed to Esquire Merch Svcs (Craig Davis)",
    "Billed to ISO",
    "Cancels Out with DR/CR",
    "Wire Received",
    "Received a Check"
]

const calc = (data) => {

    let merchRejBal = {}
    let legalRejBal = {}
    let legalResBal = {}
    let legalMids = {}

    data.map(d => {

        const rejBal = getLossAmount(d) * -1
        if(d['IndividualIdentificationNumber'] in merchRejBal) {
            merchRejBal[d['IndividualIdentificationNumber']] += rejBal
        }
        else {
            merchRejBal[d['IndividualIdentificationNumber']] = rejBal
        }

        if(d['LegalName'] in legalRejBal) {
            legalRejBal[d['LegalName']] += rejBal
        }
        else {
            legalRejBal[d['LegalName']] = rejBal
        }

        if(d['LegalName'] in legalResBal) {
            legalResBal[d['LegalName']] += Number(d['rsvBalance'])
        }
        else {
            legalResBal[d['LegalName']] = Number(d['rsvBalance'])
        }

        legalMids[d['LegalName']] = new Set(legalMids[d['LegalName']]).add(d["IndividualIdentificationNumber"])
    })

    return [merchRejBal, legalRejBal, legalResBal, legalMids]
}

const getRejStatus = (d) => {
    if(
        d.AddendaRelated && 
        d.AddendaRelated.slice(0, 1) === 'C' && 
        d.TransactionAmount == 0
    ) return 'NOC'

    if(
        d.MerchantStatus === 'Active' &&
        (
            d.rejRes === 'Write Off' ||
            d.rejRes === 'Sent to Collections' ||
            (
                parseFloat(d.sentToColl || 0) + parseFloat(d.writeOff || 0) > 0 &&
                d.rejRes === 'Used Reserve - PARTIAL'
            )
        )
    ) return 'resurrect'
    
    if(
        d.rejRes === '' || d.rejRes == null ||
        d.rejRes === 'Will Be Sent to Coll.'
    ) return 'unresolved'
    
    return 'reject closed'
}

const getCollected = (d) => {
    let collected = 0

    if(getRejStatus(d) === 'unresolved') {
        collected = parseFloat(d.usedRsv || 0) + parseFloat(d.reRan || 0)
        return collected
    }

    if(rejList.includes(d.rejRes)) {
        collected = -1 * d.TransactionAmount - parseFloat(d.sentToColl || 0) - parseFloat(d.writeOff || 0)
        return collected
    }

    if(d.rejRes === 'Will Be Sent to Coll.') {
        collected = parseFloat(d.usedRsv || 0) + parseFloat(d.reRan || 0)
        return collected
    }

    return collected
}

const getLossAmount = (d) => {

    if
    (
        d.rejRes === 'Write Off - Waived' ||
        d.rejRes === 'TSYS ERROR'
    ) return 0

    return -1 * d.TransactionAmount - getCollected(d)
}

export const ACHCalculator = {
    calc
};


