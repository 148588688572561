//material-ui.com/components/drawers/
// https://react-table.tanstack.com/docs/examples/column-hiding
import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import SettingsIcon from "@material-ui/icons/Settings";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  buttonText: {
    color: "#00a0dd",
  },
}));

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <Switch inputRef={resolvedRef} {...rest} />;
  }
);

export default function TemporaryDrawer({ allCols, hideAllColumns, email }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  // onClick={toggleDrawer(anchor, false)}

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className={classes.root}>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Toggle Columns</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <ListItem>
                <IndeterminateCheckbox {...hideAllColumns} /> Toggle All
              </ListItem>
              {allCols.map((column) => (
                <ListItem>
                  <Switch
                    color={"primary"}
                    {...column.getToggleHiddenProps()}
                  />{" "}
                  {column.Header}
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );

  return (
    <div style={{ display: "inline-block" }}>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            className={classes.buttonText}
            onClick={toggleDrawer(anchor, true)}
            startIcon={<SettingsIcon className={"selfserveIcon"} />}
          >
            {"view columns"}
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
