import React, { useContext, useState, useEffect } from 'react';
import { ApiClient } from "../../ApiClient"

const BillingTypesContext = React.createContext();

export function useException(){
    return useContext(BillingTypesContext);
}

export function BillingTypesProvider({ children }) {

    const [exceptions, setExceptions] = useState()
    
    const setExceptionValue = (id, value) => {
        setExceptions(exceptions =>
            exceptions.map((exception) => 
                [
                    ...exception.map((exc) =>
                        exc.id === id ? {...exc, filter_value: value} : {...exc}    
                    )
                    
                ]
            )
        )
    }

    const setExceptionFilter = (id, value) => {
        setExceptions(exceptions =>
            exceptions.map((exception) => 
                [
                    ...exception.map((exc) =>
                        exc.id === id ? {...exc, filter_id: value, filter_operator_id: null} : {...exc}    
                    )
                    
                ]
            )
        )
    }

    const setExceptionOperator = (id, value) => {
        setExceptions(exceptions =>
            exceptions.map((exception) => 
                [
                    ...exception.map((exc) =>
                        exc.id === id ? {...exc, filter_operator_id: value} : {...exc}    
                    )
                    
                ]
            )
        )
    }

    const setExceptionFrequency = (index, frequency_group, value) => {
        setExceptions(exceptions =>
            exceptions.map((exception, i) => 
                i === index ?
                [
                    ...exception.map((exc) =>
                        exc.frequency_group === frequency_group ? {...exc, frequency: value} : {...exc}    
                    )
                    
                ] : [...exception]
            )
        )
    }

    const addException = (id, frequency_group) => {
        setExceptions(exceptions => 
            [...exceptions, 
            [{
                id: id,
                frequency: null,
                filter_id: null,
                filter_value: null,
                filter_operator_id: null,
                frequency_group: frequency_group,
            }]]
        )
    }

    const removeFilter = (index, id) => {
        setExceptions(exceptions =>
            exceptions.map((exception, i) => 
                index === i    
                ?   [ ...exception.filter(exc => exc.id !== id) ]
                :   [ ...exception ]
            ).filter(exception => exception.length !== 0)
        )
    }

    const addFilter = (index, frequency_group, frequency, id) => {
        setExceptions(exceptions =>
            exceptions.map((exception, i) => 
                index === i
                ?   [
                        ...exception,
                        {
                            id: id,
                            frequency: frequency,
                            filter_id: null,
                            filter_value: null,
                            filter_operator_id: null,
                            frequency_group: frequency_group,
                        }
                    ]
                :   [ ...exception ]
            )
        )
    }


    const saveExceptions = async () => {
        let found_null = false
        exceptions.forEach(exception => {
            exception.forEach(el => {
                Object.keys(el).forEach(key => {
                     if(!found_null && !el[key]){
                        found_null = true
                    }
                })
            })
        })
        if(found_null) return false

        await ApiClient.post({
            url: "/api/pci/InsertFrequency",
            body: exceptions
        })

        return true
    }

    const value = {
        setExceptionValue: setExceptionValue,
        setExceptionFilter: setExceptionFilter,
        setExceptionOperator: setExceptionOperator,
        setExceptionFrequency: setExceptionFrequency,
        addNewException: addException,
        removeExceptionFilter: removeFilter,
        addNewExceptionFilter: addFilter,
        setExceptionList: setExceptions,
        saveExceptions: saveExceptions
    }

    return (
        <BillingTypesContext.Provider value={value}>
            { children }
        </BillingTypesContext.Provider>
    )
}