import React from "react";
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { MapContainer, TileLayer, CircleMarker, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  }));

export default function ISOMap({data}) {
    const classes = useStyles();

    // console.log(data);
    // US center coordinates
    const position = [37.0902, -95.7129];
    var corner1 = L.latLng(18.91619, -171.791110603),
    corner2 = L.latLng(71.3577635769, -66.96466);
    const bounds = L.latLngBounds(corner1, corner2);

    // have single color gradient for showing contrast in number of mids
    // < 10, 25, 50, 100, 500, 1000, +1000
    const colorByNumMids = ['#F1200F', '#F1760F', '#D4F10F','#2BF10F', '#0FC4F1', '#310FF1', '#D112CB']

    const colorVal = (cnt) => {
        if (cnt < 10){
            return colorByNumMids[0];
        } else if (cnt < 25) {
            return colorByNumMids[1];
        } else if (cnt < 50) {
            return colorByNumMids[2];
        } else if (cnt < 100) {
            return colorByNumMids[3];
        } else if (cnt < 500) {
            return colorByNumMids[4];
        } else if (cnt < 1000) {
            return colorByNumMids[5];
        } else {
            return colorByNumMids[6];
        }
    }

    return (
        <>
            <div className={classes.root}>
                <Chip label="< 10" style={{backgroundColor:colorByNumMids[0], color:"white"}} />
                <Chip label="< 25" style={{backgroundColor:colorByNumMids[1], color:"white"}} />
                <Chip label="< 50" style={{backgroundColor:colorByNumMids[2], color:"white"}} />
                <Chip label="< 100" style={{backgroundColor:colorByNumMids[3], color:"white"}} />
                <Chip label="< 500" style={{backgroundColor:colorByNumMids[4], color:"white"}} />
                <Chip label="< 1000" style={{backgroundColor:colorByNumMids[5], color:"white"}} />
                <Chip label="1000+" style={{backgroundColor:colorByNumMids[6], color:"white"}} />
            </div>
            <div style={{position: 'sticky'}}>
                <MapContainer center={position} maxBounds={bounds} maxBoundsViscosity={1.0} zoom={5} minZoom={4} scrollWheelZoom={true} style={{height: '75vh', width: '150vh', margin: 'auto'}}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {data.map((e) => (
                        <CircleMarker center={[e.state_lat, e.state_lng ]} pathOptions={{color:colorVal(e.cnt)}} radius={10}>
                            <Tooltip direction="center" opacity={0.8} >{e.cnt}</Tooltip>
                        </CircleMarker>
                    ))}
                </MapContainer>
            </div>
        </>
    );
}