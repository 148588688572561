import React, { useEffect, useState } from "react"
import { withCookies } from "react-cookie"
import { ApiClient } from "../ApiClient"
import Table from './components/Table'
import moment from "moment";
import { Button, Form } from "react-bootstrap"
import "./Style/Style.scss";

function Billing(props) {

    const [data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [hasError, sethasError] = React.useState(false)
    const [skipPageReset, setSkipPageReset] = React.useState(false)
    const [selectedYear, setSelectedYear] = useState(moment().year())
    const [selectedMonth, setSelectedMonth] = useState(moment().month())
    const [canEdit, setCanEdit] = useState(false)
    const editors = ['daniellec@signapay.com', 'bahramb@signapay.com', 'howardm@signapay.com', 'katherineh@signapay.com']

    const columns =  [
            {
                Header: 'MID',
                accessor: 'mid',
            },
            {
                Header: 'DBA',
                accessor: 'dba_name',
            },
            {
                Header: 'ISO',
                accessor: 'iso_name',
            },
            {
                Header: 'Profile',
                accessor: 'merchant_profile',
            },
            {
                Header: 'Date Opened',
                accessor: (d) => {
                    if (d.open_date) {
                      return moment.utc(d.open_date).format("YYYY-MM-DD");
                    }
                    return ""
                },
                filter: 'date'
            },
            {
                Header: 'Billing Type',
                accessor: 'billing_flag',
            },
            {
                Header: 'Date Created',
                accessor: (d) => {
                    if (d.created_date) {
                      return moment.utc(d.created_date).format("YYYY-MM-DD");
                    }
                    return ""
                },
                filter: 'date'
            },
            {
                Header: 'Date Processed',
                accessor: (d) => {
                    if (d.processed_date) {
                      return moment.utc(d.processed_date).format("YYYY-MM-DD");
                    }
                    return ""
                },
                filter: 'date'
            },

            {
                Header: 'Amount',
                accessor: 'amount',
            },
        ]
    
    const GetACHBilling = () => {
        setLoading(true)
        ApiClient.post({
            url: "/api/pci/GetACHBilling",
            body: {
                year: selectedYear,
                month: selectedMonth + 1
            }
        })
        .then((json) => {
            const element = document.createElement("a");
            let data = json.data
            data.push("") 
            const file = new Blob([data.map(d => d.ACH?.replace("#", " ")
                                                      .replace("$", " ")
                                                      .replace("&", " ")
                                                      .replace("(", " ")
                                                      .replace(")", " ")
                                                      .replace("-", " ")
                                                      .replace(",", " ")
                                                      .replace(".", " ")
                                                      .replace("'", " ")).join("\r\n")], {
                type: "text/plain"
            });
            element.href = URL.createObjectURL(file);
            element.download = "ACHBilling.txt";
            document.body.appendChild(element);
            element.click();
            GetBilling()
        }).catch(err => {
            console.log(err);
        });
    }

    const GetBilling = () => {
        setLoading(true)
        ApiClient.post({
            url: "/api/pci/GetBilling",
            body: {
                year: selectedYear,
                month: selectedMonth + 1
            }
        })
        .then((json) => {
            setData(json.data)
            setLoading(false)
        }).catch(err => {
            console.log(err);
            setLoading(false)
        });
    }

    const onSelectYear = (value) => {
        setSelectedYear(Number(value))
        setSelectedMonth(-1)
    }

    const onSelectMonth = (value) => {
        setSelectedMonth(Number(value))
    }

    useEffect(() => {
        if(!selectedYear || selectedMonth === -1) return
        console.log(selectedYear, selectedMonth)
        GetBilling()
    }, [selectedYear, selectedMonth])

    useEffect(() => {
        const { cookies } = props;
        const email = cookies.get("email");
        setCanEdit(editors.includes(email));
    }, [])

    return (
        <div className="PCI">
            <div className="billingToolbar">
                <Form inline className="mb-0">
                    <Form.Control className="mr-2" as="select" custom disabled={loading} value={selectedYear} onChange={e => onSelectYear(e.target.value)}>
                        {[...Array(moment().year() - 2021).keys()].reverse().map(y => (
                            <option key={y} value={y + 2022}>{y + 2022}</option>
                        ))}
                    </Form.Control>
                    <Form.Control className="mr-2" as="select" custom disabled={loading} value={selectedMonth} onChange={e => onSelectMonth(e.target.value)}>
                        <option value={-1}></option>
                        {[...Array(12).keys()].map(m => (
                            <option key={m} value={m}>{moment().month(m).format("MMMM")}</option>
                        ))}
                    </Form.Control>
                    <Button style={{width: "180px"}} onClick={() => GetACHBilling()} disabled={loading || !canEdit}>{loading ? "Loading..." : "Run ACH Billing File"}</Button>
                </Form>
            </div>
            <Table className="PCITable"
                columns={columns}
                data={data}
                setData={setData}
                // updateMyData={updateMyData}
                skipPageReset={skipPageReset}
                hasError={hasError}
                loading={loading}
                setLoading={setLoading}
            />
        </div>
    )
}

export default withCookies(Billing);
