import React, { Component } from "react";
import "./Style/uploadfiles.scss";
import { withCookies } from "react-cookie";
import moment from "moment";
import DownloadModal from "../DownloadModal/DownloadModal";
import UploadModal from "../UploadModal/UploadModal";
import "react-table-6/react-table.css";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import Button from "react-bootstrap/Button";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { ApiClient } from "../ApiClient"

class UploadFiles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      momentVal: moment(),
      data: [],
      loading: true,
      showDownloadModal: false,
      showImportModal: false,
      filename: "select filetype",
    };
  }

  toggleDownloadModal = () => {
    this.setState(
      {
        showDownloadModal: this.state.showDownloadModal ? false : true,
      },
      () => {
        this.getData();
      }
    );
  };

  toggleImportModal = () => {
    this.setState({
      showImportModal: this.state.showImportModal ? false : true,
    });
  };

  getFilename = (event) => {
    this.setState({
      filename: event,
    });
  };

  getData = () => {
    ApiClient
      .post({
        url: `/api/${this.state.filename}`
      })
      .then((json) => {
        if (Object.keys(json.data).length === 0) {
          this.setState({
            data: [],
            loading: false,
          });
        } else {
          this.setState({
            data: json.data,
            loading: false,
          });
        }
      }).catch(err => {
        console.log(err);
      });
  };

  render() {
    const { cookies } = this.props;
    const dept = cookies.get("dept");
    // let updateCards = this.updateView();

    return (
      (dept === "admin" || dept === "finance_admin" || dept === "sales_admin") &&
      <div>
        <div className="contentHeader">
          <h3 className="leaderboardHeader">{"Upload Data"}</h3>
        </div>

        <CardDeck className="partnerDeck">
          <Card style={{ border: "none", borderRadius: "0" }}>
            <Card.Body>
              <Card.Title>
                <div className="center-fixed">
                  <DropdownButton
                    id="dropdown-item-button"
                    title={this.state.filename}
                    variant="outline-secondary"
                    onSelect={this.getFilename}
                  >
                    <Dropdown.Item eventKey="Adjustments">
                      Adjustments
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="ScheduleA">
                      ScheduleA
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="Residual_TSYS">
                      Residual_TSYS
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="Residual_PPS">
                      Residual_PPS
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
                <br />
                <div className="center-fixed">
                  <Button
                    variant="outline-secondary"
                    onClick={this.toggleImportModal}
                  >
                    Import XLSX
                  </Button>{" "}
                  <Button
                    className="buttonXlsx"
                    variant="outline-success"
                    onClick={this.toggleDownloadModal}
                  >
                    Export XLSX
                  </Button>
                  {this.state.showImportModal &&
                  this.state.filename !== "select filetype" ? (
                    <UploadModal
                      toggle={this.toggleImportModal}
                      show={this.state.showImportModal}
                      refresh={() => void 0}
                      filename={this.state.filename.toLowerCase()}
                    />
                  ) : null}
                  {this.state.showDownloadModal &&
                  this.state.filename !== "select filetype" ? (
                    <DownloadModal
                      data={this.state.data}
                      toggle={this.toggleDownloadModal}
                      show={this.state.showDownloadModal}
                      filename={this.state.filename}
                    />
                  ) : null}
                </div>
              </Card.Title>
            </Card.Body>
          </Card>
        </CardDeck>
      </div>
    );
  }
}

export default withCookies(UploadFiles);
