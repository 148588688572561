import React, { useEffect, useState } from "react"
import { Form, Modal, Button, Col, InputGroup, FormControl, Dropdown, Alert } from "react-bootstrap"
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { ApiClient } from "../../ApiClient"
import CustomSelect from "./CustomSelect"
import moment from "moment"

export default function AddExtraBonusModal(props) {

    const DEFAULT_NUMBER_OF_DEALS = 10
    const DEFAULT_EXTRA_BONUS = 200
    const DEFAULT_BONUS_TYPE = 'EPD'

    const [isoList, setIsoList] = useState([])
    const [selectedIndex, setSelectedIndex] = useState()
    const [numberOfDeals, setNumberOfDeals] = useState(DEFAULT_NUMBER_OF_DEALS)
    const [extraBonus, setExtraBonus] = useState(DEFAULT_EXTRA_BONUS)
    const [bonusType, setBonusType] = useState(DEFAULT_BONUS_TYPE)
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [enabled, setEnabled] = useState(true)
    const [warningMessage, setWarningMessage] = useState()
    const [selectedValues, setSelectedValues] = useState([])

    const onClose = () => {
        setSelectedIndex()
        setWarningMessage()
        setSelectedValues([])
        setNumberOfDeals(DEFAULT_NUMBER_OF_DEALS)
        setExtraBonus(DEFAULT_EXTRA_BONUS)
        setBonusType(DEFAULT_BONUS_TYPE)
        setStartDate()
        setEndDate()
        setEnabled(true)
    }

    const onSave = () => {
        if(!props?.editMode && (!selectedIndex && selectedValues.length == 0)) return

        const group_id = selectedValues.filter(gid => gid !== null || gid !== "")
        ApiClient.post({
            url: "/api/bonus/AddExtraBonusISO",
            body: {
                group_id: group_id,
                extra_deals: Number(numberOfDeals),
                extra_amount: Number(extraBonus),
                extra_type: bonusType,
                start_date: startDate,
                end_date: endDate,
                enabled: enabled,
                edit: props?.editMode,
                iso_name: props?.editMode ? props.data.iso_name : isoList[selectedIndex].iso_name
            }
        }).then(json => {
            if(json.data.success){
                props.onHide()
                props.onEdit()
                return
            }
            setWarningMessage(json.data.message)
        }).catch(err => {
            setWarningMessage(err)
        });
    }

    useEffect(() => {
        setSelectedValues([])
    }, [selectedIndex])

    useEffect(() => {
        if(!props?.editMode) return
        setSelectedValues(props.data?.group_id?.replaceAll(' ', '').split(','))
        setNumberOfDeals(props.data?.extra_deals)
        setExtraBonus(props.data?.extra_amount)
        setBonusType(props.data?.bonus_type)
        setStartDate(props.data?.start_date ? moment.utc(props.data?.start_date).format("YYYY-MM-DD") : undefined)
        setEndDate(props.data?.end_date ? moment.utc(props.data?.end_date).format("YYYY-MM-DD") : undefined)
        setEnabled(props.data?.enabled === 1)
    }, [props.data])

    useEffect(() => {
        ApiClient.get({
            url: "/api/bonus/GetIsoList",
        }).then(json => {
            setIsoList(json.data);
        }).catch(err => {
        });
    }, [])

    return (
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onExited={onClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <small>{props?.editMode ? "Edit" : "Add"} Extra Bonus</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Group>
            <Form.Row>
                <Col column="md" md={3} className="text-right pt-2">
                    {!props?.editMode && <Dropdown onSelect={setSelectedIndex}>
                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                            Select ISO
                        </Dropdown.Toggle>
                        <Dropdown.Menu as={CustomMenu}>
                            {isoList.map((iso, i) => (
                                <Dropdown.Item key={i} eventKey={i}>{iso.iso_name}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>}
                </Col>
                <Col>
                    <FormControl 
                        value={props?.editMode ? props.data.iso_name : isoList[selectedIndex]?.iso_name || ""} 
                        onChange={() => {}}
                        disabled={true} 
                    />
                </Col>
            </Form.Row>
            <br />
            <Form.Row>
                <Col column="md" md={3} className="text-right pt-2">
                    <CustomSelect 
                        data={props?.editMode ? isoList.find(iso => iso.iso_name === props.data.iso_name)?.group_id.replaceAll(' ', '').split(',') : isoList[selectedIndex]?.group_id.replaceAll(' ', '').split(',')}
                        selectedValues={selectedValues}
                        onSelect={setSelectedValues}
                    />
                </Col>
                <Col>
                    <FormControl value={selectedValues?.join(', ')} disabled={true} />
                </Col>
            </Form.Row>
            <br />
            <Form.Row>
                <Form.Label column="md" md={3} className="text-right">
                    Number of Deals
                </Form.Label>
                <Col>
                    <FormControl placeholder="0" type="number" value={numberOfDeals} onChange={e => setNumberOfDeals(e.target.value)} required={true} />
                </Col>
            </Form.Row>
            <br />
            <Form.Row>
                <Form.Label column="md" md={3} className="text-right">
                    Extra Bonus
                </Form.Label>
                <Col>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl placeholder="0.00" type="number" value={extraBonus} onChange={e => setExtraBonus(e.target.value)} required={true} />
                    </InputGroup>
                </Col>
            </Form.Row>
            <br />
            <Form.Row>
                <Form.Label column="md" md={3} className="text-right">
                    Bonus Type
                </Form.Label>
                <Col>
                    <RadioGroup
                        value={bonusType}
                        onChange={(e) => setBonusType(e.target.value)}
                        >
                        <InputGroup>
                            <FormControlLabel value="EPD" control={<Radio color="primary" />} label="Per Deal" />
                            <FormControlLabel value="EFR" control={<Radio color="primary" />} label="Flat Rate" />
                        </InputGroup>
                    </RadioGroup>
                </Col>
            </Form.Row>
            <br />
            <Form.Row>
                <Form.Label column="md" md={3} className="text-right">
                    Start Date
                </Form.Label>
                <Col>
                    <FormControl placeholder="0" type="date" value={startDate || ""} onChange={e => setStartDate(e.target.value || undefined)} />
                </Col>
            </Form.Row>
            <br />
            <Form.Row>
                <Form.Label column="md" md={3} className="text-right">
                    End Date
                </Form.Label>
                <Col>
                    <FormControl placeholder="0" type="date" value={endDate || ""} onChange={e => setEndDate(e.target.value || undefined)} />
                </Col>
            </Form.Row>
            <br />
            <Form.Row>
                <Form.Label column="md" md={3} className="text-right">
                    Active
                </Form.Label>
                <Col>
                <Switch
                    checked={enabled}
                    onChange={(e) => setEnabled(e.target.checked)}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                </Col>
            </Form.Row>
        </Form.Group>
        {warningMessage && <Alert variant="danger" className="text-center">
            <small>{warningMessage}</small>
        </Alert>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>Close</Button>
          <Button onClick={onSave}>Save</Button>
        </Modal.Footer>
      </Modal>
    )
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </a>
  ))

const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');
  
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
        </div>
      );
    },
  );

