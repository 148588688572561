import React, { useEffect, useState } from "react";
import "./Style/iso.scss";
import { ApiClient } from "../../ApiClient"
import { withCookies } from "react-cookie";
import { ListGroup, Form, Nav } from "react-bootstrap";
import { ISO } from "./ISO"
import { Merchant } from "./Merchant"

function Exec(){

    const [isoData, setIsoData] = useState([]);
    const [isoNames, setIsoNames] = useState([]);
    const [selectedIso, setSelectedIso] = useState();
    const [selectedTab, setSelectedTab] = useState('tab-1');

    const onIsoSearch = (value) => {
        if(value.length > 0)
            setIsoNames(isoData.filter(el =>  el.iso_name.toLowerCase().includes(value.toLowerCase())).map(el =>  el.iso_name));
        else
            setIsoNames(isoData.map(el =>  el.iso_name));
    }

    const onIsoSelect = (value) => {
        setSelectedIso(isoData.find(el => el.iso_name === value));
    }

    useEffect(() => {
        if(isoData.length === 0) return;
        setIsoNames(isoData.map(el => el.iso_name));
        setSelectedIso(isoData[0]);
    }, [isoData]);

    useEffect(() => {
        ApiClient.get({
            url: "/api/exec/iso/GetAllInformation"
        }).then(json => {
            setIsoData(json.data.filter(d => d.iso_name !== ""));
        }).catch(err => {
        });
    }, []);

    return (
        <div className="execContainer">
            <div className="leftBox">
                <div className="isoMenuSearch">
                    <Form.Control size="sm" type="text" placeholder="Search..." onChange={(e) => onIsoSearch(e.target.value)} />
                </div>
            {isoNames.length !== 0 && 
            <ListGroup variant="flush" defaultActiveKey={'#' + isoNames[0].replace(" ", "-")}>
                {isoNames.map((iso, i) => 
                    <ListGroup.Item action key={i} onClick={() => onIsoSelect(iso)} href={'#' + iso.replace(" ", "-")}>
                        <div className="isoMenuItem text-truncate">
                            {iso}
                        </div>
                    </ListGroup.Item>
                )}
            </ListGroup>
            }   
            </div>
            <div className="rightBox">
                <div className="isoTabs">
                    <Nav  variant="tabs" className="text-center justify-content-end" defaultActiveKey="tab-1" onSelect={(selectedKey) => setSelectedTab(selectedKey)}>
                        <Nav.Item style={{width: "150px"}}>
                            <Nav.Link eventKey="tab-1">ISO</Nav.Link>
                        </Nav.Item>
                        <Nav.Item style={{width: "150px"}}>
                            <Nav.Link eventKey="tab-2">Merchant</Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                            <Nav.Link eventKey="tab-3">Deals</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="tab-4">MCC</Nav.Link>
                        </Nav.Item> */}
                    </Nav>
                </div>
                <div className="isoTitle d-flex">
                    <div>
                        <h4>{selectedIso?.iso_name}</h4>
                    </div>
                    <div className="d-flex ml-4">
                        {selectedIso?.cbcal > 0 && <div className="alert alert-cbcal mx-1 py-1 px-2" role="alert">
                            <small style={{ fontSize: ".7rem"}}>CBCAL {Math.round((selectedIso?.cbcal * 100) / selectedIso?.approved_deals)}%</small>
                        </div>}
                        {selectedIso?.esquire > 0 && <div className="alert alert-esquire mx-1 py-1 px-2" role="alert">
                            <small style={{ fontSize: ".7rem"}}> ESQUIRE {Math.round((selectedIso?.esquire * 100) / selectedIso?.approved_deals)}%</small>
                        </div>}
                        {selectedIso?.chesapeake > 0 && <div className="alert alert-chesapeake mx-1 py-1 px-2" role="alert">
                            <small style={{ fontSize: ".7rem"}}> CHSPK {Math.round((selectedIso?.chesapeake * 100) / selectedIso?.approved_deals)}%</small>
                        </div>}
                        {selectedIso?.pl_count > 0 && <div className="alert alert-primary mx-1 py-1 px-2" role="alert">
                            <small style={{ fontSize: ".7rem"}}>PayLo {Math.round((selectedIso?.pl_count * 100) / selectedIso?.approved_deals)}%</small>
                        </div>}
                        {selectedIso?.tr_count > 0 && <div className="alert alert-warning mx-1 py-1 px-2" role="alert">
                            <small style={{ fontSize: ".7rem"}}>Traditional {Math.round((selectedIso?.tr_count * 100) / selectedIso?.approved_deals)}%</small>
                        </div>}
                        {selectedIso?.hr_count > 0 && <div className="alert alert-info mx-1 py-1 px-2" role="alert">
                            <small style={{ fontSize: ".7rem"}}>High Risk {Math.round((selectedIso?.hr_count * 100) / selectedIso?.approved_deals)}%</small>
                        </div>}
                        {selectedIso?.ns_count > 0 && <div className="alert alert-dark mx-1 py-1 px-2" role="alert">
                            <small style={{ fontSize: ".7rem"}}>Not Specified {Math.round((selectedIso?.ns_count * 100) / selectedIso?.approved_deals)}%</small>
                        </div>}
                    </div>
                </div>
                {selectedTab === 'tab-1' && <>
                    {selectedIso && <ISO name={selectedIso} />}
                </>}
                {selectedTab === 'tab-2' && <>
                    {selectedIso && <Merchant iso={selectedIso} />}
                </>}
            </div>
        </div>
    )
}

export default withCookies(Exec);