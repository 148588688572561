import React, { useEffect, useState } from "react";
import { Card, ProgressBar, Button } from "react-bootstrap";
import moment from "moment";

export const DealsCard = ({ data }) => {
    return (
        <Card className="m-2 p-0">
            <Card.Body>
                <div className="d-flex m-0 justify-content-evenly">
                    <div className="m-1 w-100 text-center">
                        <div className="mb-2"><strong>Total Deals</strong></div>
                        <div className="mb-2"><ProgressBar style={{height: "25px", fontSize: "14px"}} now={data.approved_deals} min={0} max={data.approved_deals} label={data.approved_deals} /></div>
                        <div className="mb-1 text-left"><small>First Deal: {moment(data.first_deal).fromNow()} <span className="text-muted">({moment(data.first_deal).format("YYYY-MM-DD")})</span></small></div>
                        <div className="mb-1 text-left"><small>Last Deal: {moment(data.last_deal).fromNow()} <span className="text-muted">({moment(data.last_deal).format("YYYY-MM-DD")})</span></small></div>
                        <div className="mb-1 text-left"><small>Avg days b/w deals: {data.average_days_bw_deals} day(s)</small></div>
                    </div>
                    <div className="m-1 w-100 text-center">
                        <div className="mb-2"><strong>Open Deals</strong></div>
                        <div className="mb-2"><ProgressBar style={{height: "25px", fontSize: "14px"}} variant="success" label={data.open_deals} now={data.open_deals || 0}  min={0} max={data.approved_deals} /></div>
                        <div className="mb-1 text-left"><small>Longest Deal: {data.open_longest ? moment().subtract(data.open_longest, 'days').fromNow().replace('ago', 'old') : '-'}</small></div>
                        <div className="mb-1 text-left"><small>Shortest Deal: {data.open_shortest ? moment().subtract(data.open_shortest, 'days').fromNow().replace('ago', 'old') : '-'}</small></div>
                    </div>
                    <div className="m-1 w-100 text-center">
                        <div className="mb-2"><strong>Closed Deals</strong></div>
                        <div className="mb-2"><ProgressBar style={{height: "25px", fontSize: "14px"}} variant="danger" label={data.closed_deals} now={data.closed_deals || 0} min={0} max={data.approved_deals} /></div>
                        <div className="mb-1 text-left"><small>Longest Deal: {data.closed_longest ? moment().subtract(data.closed_longest, 'days').fromNow().replace('ago', 'old') : '-'}</small></div>
                        <div className="mb-1 text-left"><small>Shortest Deal: {data.closed_shortest ? moment().subtract(data.closed_shortest, 'days').fromNow().replace('ago', 'old') : '-'}</small></div>
                    </div>
                </div>
                <div className="d-flex m-0 justify-content-evenly">
                    <DealsAge color="success" title="Open Deals Age" total={data.open_deals} ageSet={data.open_age_set} test={"open"} />
                    <DealsAge color="danger" title="Closed Deals Age" total={data.closed_deals} ageSet={data.closed_age_set}  test={"closed"} />
                </div>
            </Card.Body>
        </Card>
    )
}

const DealsAge = ({ color, title, total, ageSet, test}) => {
    
    const agesTitle = ['0-6 months', '6-12 months', '1-2 years', '2-3 years', 'More than 3 years'];
    const [ages, setAges] = useState([0, 0, 0, 0, 0]);
    const [maxValue, setMaxValue] = useState()

    useEffect(() => {
        setMaxValue(total)
    }, [ages])

    useEffect(() => {
        if(!ageSet) return
        setAges(ageSet.split(',').map(age => Number(age)))
    }, [ageSet])
    
    return (<div className="m-1 w-100">
            <div className="mb-2"><strong>{title}</strong></div>
            {[...Array(5).keys()].map(i => 
                <div key={i} className="mb-1 text-left">
                    <small>{agesTitle[i]}: 
                        <ProgressBar min={0} max={maxValue} variant={color} now={ages[i]} label={ages[i]}  />
                    </small>
                </div>
            )}
        </div>
    )
}