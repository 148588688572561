import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import CustomTable from "./CustomTable";
import Loader from "react-loader-spinner";

class Row extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  getFilteredData = () => {
    var filteredData = [];
    if (this.props.data) {
      var name = this.props.name;

      this.props.data.forEach(function(row, index) {
        if (row.ISO === name) {
          filteredData.push({
            id: row.id,
            MID: row.MID,
            DBA: row.DBA,
            SponsorBank: row.SponsorBank,
            profile: row.profile
          });
        }
      });
    }

    return [filteredData];
  };

  toggle = () => {
    this.setState({ open: this.state.open ? false : true });
  };

  render() {
    var tableData = null;
    if (this.props.data !== "") {
      tableData = this.props.data;
    }
    return (
      <>
        {this.state.open ? (
          <Modal
            show={this.state.open}
            onShow={this.props.func}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={this.toggle}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.name}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {tableData ? (
                <CustomTable data={this.getFilteredData()} />
              ) : (
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <Loader color="green" width={25} heigth={25} type="Oval" />
                </div>
              )}
            </Modal.Body>
          </Modal>
        ) : null}
        <tr
          onClick={this.toggle}
          style={{
            backgroundColor: "#fafafa",
            cursor: "pointer",
            border: "4px solid white"
          }}
        >
          <td>{this.props.name}</td>
          <td>{this.props.count2}</td>
          <td> {this.props.count}</td>
        </tr>
      </>
    );
  }
}

export default Row;
