import React, { useEffect, useState } from "react"
import { withCookies } from "react-cookie"
import { Nav } from "react-bootstrap";
import "./Style/Style.scss";
import Bonus from "./Bonus"
import Clawback from "./Clawback"
import OnActivation from "./OnActivation"
import Specs from "./Specs"
import Logs from "./Logs"


function Index(props) {
    const [selectedTab, setSelectedTab] = useState('tab-1');

    return (
        <div className="BonusContainer">
            <Nav className="justify-content-end" variant="tabs" defaultActiveKey="tab-1" onSelect={(selectedKey) => setSelectedTab(selectedKey)}>
                <Nav.Item>
                    <Nav.Link eventKey="tab-1">Bonuses</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="tab-2">Clawbacks</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="tab-3">Activation</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="tab-4">Specifications</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="tab-5">Activity Logs</Nav.Link>
                </Nav.Item>
            </Nav>
            <div className="tabContainer">
                {selectedTab === 'tab-1' && <>
                    <Bonus />
                </>}
                {selectedTab === 'tab-2' && <>
                    <Clawback />
                </>}
                {selectedTab === 'tab-3' && <>
                    <OnActivation />
                </>}
                {selectedTab === 'tab-4' && <>
                    <Specs />
                </>}
                {selectedTab === 'tab-5' && <>
                    <Logs />
                </>}
            </div>
        </div>
    )
}

export default withCookies(Index)
