import React, { useEffect, useState } from "react"
import { Form, InputGroup, FormControl, Button, Dropdown } from "react-bootstrap"
import { withCookies } from "react-cookie"
import "../Style/Style.scss"
import { ApiClient } from "../../ApiClient"
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{ width: '100%' }}
            {...other}
        >
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function CustomTab(props) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="pci-revshares-tabs">
            <Tabs
                orientation="vertical"
                indicatorColor="primary"
                textColor="primary"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
            >
                {Object.entries(props[1]).map((eff, j) => (
                    <Tab label={eff[0]} {...a11yProps(j)} />
                ))}
            </Tabs>
            {Object.entries(props[1]).map((eff_dates, j) => (
                <TabPanel value={value} index={j}>
                    <div className="pci-revshares-groups">
                        {Object.entries(eff_dates[1]).map(eff_date => (
                            <>
                                {Array(eff_date).map(eff => (
                                    <div className="pci-revshares-groupid">
                                        <span>{eff[1][0]}</span>
                                        <span>{Number(eff[1][1]) * 100}%</span>
                                    </div>))}
                            </>
                        ))}

                        {eff_dates[0] !== 'Default' && <div className="pci-revshare-delete">
                            <IconButton
                                size="small"
                                onClick={() => { if (window.confirm('Are you sure?')) props.onDelete(props[0]) }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>}

                    </div>
                </TabPanel>
            ))
            }
        </div>
    )
}

function RevShares(props) {

    const [revShares, setRevShares] = useState([])
    const [partners, setPartners] = useState([])
    const [selectedValue, setSelectedValue] = useState()


    const onSubmit = (e) => {
        e.persist()
        e.preventDefault()

        const formData = new FormData(e.target)

        if (!selectedValue) return

        ApiClient.post({
            url: "/api/pci/AddRevShare",
            body: {
                iso_name: selectedValue,
                rev_share: formData.get("revShare"),
                effective_date: formData.get("effectiveDate"),
            }
        }).then(json => {
            e.target.reset()
            setSelectedValue()
            getRevShares()
        }).catch(err => {
            console.log(err)
        })

    }

    const onDelete = (iso_name) => {
        ApiClient.post({
            url: "/api/pci/DeleteRevShare",
            body: {
                iso_name: iso_name,
            }
        }).then(json => {
            getRevShares();
        }).catch(err => {
            console.log(err)
        })
    }

    const getRevShares = () => {
        ApiClient.get({
            url: "/api/pci/GetPCIRevShares",
        }).then((json) => {
            setSelectedValue()
            setRevShares(json.data)
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {

        getRevShares();
        ApiClient.get({
            url: "/api/pci/GetPartners",
        }).then((json) => {
            setPartners(json.data)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    return (
        <>
            <Form inline onSubmit={onSubmit}>
                <InputGroup className="mb-3 mr-sm-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>ISO</InputGroup.Text>
                    </InputGroup.Prepend>
                    <InputGroup.Append>
                        <div className="filterValueSelect">
                            <Dropdown className="filterValueDropdown" onSelect={setSelectedValue}>
                                <Dropdown.Toggle as={CustomToggle} >
                                    Select
                                </Dropdown.Toggle>
                                <Dropdown.Menu as={CustomSingleMenu}>
                                    {partners?.map((d, i) =>
                                        <Dropdown.Item key={i} eventKey={d.iso_name}>{d.iso_name}</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Form.Control
                                type="text"
                                style={{ paddingRight: "80px", width: "500px" }}
                                required={true}
                                value={selectedValue}
                                readOnly={true}
                            />
                        </div>
                    </InputGroup.Append>
                </InputGroup>
                <InputGroup className="mb-3 mr-sm-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>Rev Share</InputGroup.Text>
                        <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup.Prepend>
                    <InputGroup.Append>
                        <Form.Control
                            type="number"
                            style={{ width: "50px" }}
                            required={true}
                            name="revShare"
                        />
                    </InputGroup.Append>
                </InputGroup>
                <InputGroup className="mb-3 mr-sm-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>Effective Date</InputGroup.Text>
                    </InputGroup.Prepend>
                    <InputGroup.Append>
                        <Form.Control
                            type="date"
                            required={true}
                            name="effectiveDate"
                        />
                    </InputGroup.Append>
                </InputGroup>
                <Button className="mb-3" type="submit">
                    Add
                </Button>
            </Form>
            <div className="pci-revshares">
                {Object.entries(revShares).map((rev, i) => (
                    <>
                        <div className="pci-revshares-tabs-title">{rev[0]}</div>
                        <CustomTab {...rev} onDelete={(value) => onDelete(value)} />
                    </>
                ))}
            </div>
        </>
    )
}

export default withCookies(RevShares);

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href="!#"
        ref={ref}
        onClick={(e) => {
            e.preventDefault()
            onClick(e)
        }}
    >
        {children}
    </a>
))

const CustomSingleMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('')

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <FormControl
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Search..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value)
                    )}
                </ul>
            </div>
        )
    })