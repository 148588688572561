import React, { Component } from "react";
import {
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  FormText
} from "react-bootstrap";
import "./Style/login.css";
import Cookies from "universal-cookie";
import { Auth } from "../Auth";

const cookies = new Cookies();

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: cookies.get("email") ? cookies.get("email") : "",
      oldpassword: "",
      password: "",
      confirmpassword: "",
      msg:
        "min 8 char; atleast one uppercase, lowercase, numeric and special char",
      isLoading: false
    };
    this.onChangeUp = this.onChangeUp.bind(this);
  }

  validateForm = () => {
    return (
      this.state.email.length > 0 &&
      this.state.oldpassword.length > 0 &&
      this.state.password.length > 0 &&
      this.state.confirmpassword.length > 0 &&
      this.state.password === this.state.confirmpassword
    );
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
  };

  onChangeUp(event) {
    const { email, oldpassword, password, confirmpassword } = this.state;
    this.setState({
      isLoading: true
    });
    Auth.changePassword(email, oldpassword, password, confirmpassword)
    .then(json => {
      if (json.data.success) {
        this.setState({
          isLoading: false,
          msg: json.data.message,
          oldpassword: "",
          password: "",
          confirmpassword: ""
        });
        event.persist();
      } else {
      console.log(json)
      this.setState({
          isLoading: false,
          msg: json.data.message
        });
      }
    }).catch(error => {
      this.setState({
        isLoading: false,
        msg: error.response.data.message
      });
    });
  }

  render() {
    return (
      <div>
        <div className="Login">
          <form onSubmit={this.handleSubmit}>
            <FormGroup controlId="formPlaintextEmail">
              <FormLabel>Email</FormLabel>
              <FormControl plaintext readOnly value={this.state.email} />
            </FormGroup>
            <FormGroup controlId="oldpassword">
              <FormLabel>Current Password</FormLabel>
              <FormControl
                value={this.state.oldpassword}
                onChange={this.handleChange}
                type="password"
              />
            </FormGroup>
            <FormGroup controlId="password">
              <FormLabel>New Password</FormLabel>
              <FormControl
                value={this.state.password}
                onChange={this.handleChange}
                type="password"
              />
            </FormGroup>
            <FormGroup controlId="confirmpassword">
              <FormLabel>Confirm Password</FormLabel>
              <FormControl
                value={this.state.confirmpassword}
                onChange={this.handleChange}
                type="password"
              />
            </FormGroup>
            <Button
              block
              disabled={!this.validateForm()}
              variant="outline-warning"
              type="submit"
              onClick={this.onChangeUp}
            >
              Change
            </Button>
            <FormText className="text-muted">{this.state.msg}</FormText>
          </form>
        </div>
      </div>
    );
  }
}

export default ChangePassword;
