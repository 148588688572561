// https://thewidlarzgroup.com/react-table-7/
// https://github.com/TheWidlarzGroup/RT7-example/tree/eab1df9fcf75471c509b887d72d2ff21512b5af0/src
import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const Filter = ({ column, pagezero }) => {
  // kinda hack solution for now
  column.pagereset = pagezero;
  return (
    <div style={{ marginTop: 5 }}>
      {column.canFilter && column.render("Filter")}
    </div>
  );
};

export const DefaultColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
    pagereset,
  },
}) => {
  return (
    <TextField
      id="outlined-basic"
      variant="outlined"
      size="small"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
        pagereset();
      }}
      style={{ minWidth: 150 }}
      placeholder={`...`}
    />
  );
};

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id, pagereset },
}) => {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <FormControl variant="outlined" size="small">
      <Select
        native
        id="custom-select"
        type="select"
        value={filterValue || ""}
        style={{ minWidth: 100 }}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
          pagereset();
        }}
      >
        <option value="">All</option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export const MultiSelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id, pagereset },
}) => {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <Select
      multiple
      labelId="demo-mutiple-name-label"
      id="demo-mutiple-name"
      value={filterValue || []}
      renderValue={(selected) => selected.join(', ')}
      onChange={(e) => {
        const allValues = e.target.value
        setFilter(allValues && allValues.length ? allValues : undefined)
        pagereset();
      }}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );

};

export const AutoMultiSelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id, pagereset },
}) => {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <Autocomplete
      multiple
      freeSolo
      limitTags={1}
      size="small"
      id="size-small-outlined-multi"
      disableClearable={true}
      value={filterValue || []}
      onChange={(e, val) => {
        const allValues = val;
        setFilter(allValues && allValues.length ? allValues : undefined);
        pagereset();
      }}
      options={options}
      style={{ minWidth: 200, fontSize: 'small' }}
      renderInput={(params) =>
        <TextField {...params} variant="outlined" size="small" />}
    />
  );

};

export const MinMaxAmtColumnFilter = ({
  column: { filterValue = [], setFilter, preFilteredRows, id, pagereset },
}) => {
  React.useMemo(() => {
    // Number(currency.replace(/[^0-9.-]+/g,""))
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div style={{ display: "flex" }}>
      <TextField
        id="outlined-basic"
        variant="outlined"
        size="small"
        value={filterValue[0] || ""}
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
          pagereset();
        }}
        placeholder={`min...`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      <TextField
        id="outlined-basic"
        variant="outlined"
        size="small"
        value={filterValue[1] || ""}
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
          pagereset();
        }}
        placeholder={`max...`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
    </div>
  );
};

export const MinMaxNumColumnFilter = ({
  column: { filterValue = [], setFilter, preFilteredRows, id, pagereset },
}) => {
  React.useMemo(() => {
    //unnecessary as we are not using min max variable
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div style={{ display: "flex", justifyContent: 'center'}}>
      <TextField
        id="outlined-basic"
        variant="outlined"
        size="small"
        value={filterValue[0] || ""}
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
          pagereset();
        }}
        placeholder={`min..`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      <TextField
        id="outlined-basic"
        variant="outlined"
        size="small"
        value={filterValue[1] || ""}
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
          pagereset();
        }}
        placeholder={`max..`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
    </div>
  );
};
