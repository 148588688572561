import React, { useEffect, useState } from "react"
import { Form, Modal, Button, Col, InputGroup, FormControl, Dropdown, Alert } from "react-bootstrap"
import { ApiClient } from "../../ApiClient"

export default function AddEquipmentModal(props) {

    const [equipmentList, setEquipmentList] = useState([])
    const [selectedEquipment, setSelectedEquipment] = useState()
    const [equipmentAmount, setEquipmentAmount] = useState(0)
    const [warningMessage, setWarningMessage] = useState()

    const onClose = () => {
        setSelectedEquipment()
        setWarningMessage()
        setEquipmentAmount(0)
    }

    const onSelect = (index) => {
      setSelectedEquipment({
        title: equipmentList[index]?.name || "",
        model: equipmentList[index]?.model || "",
      })
    }

    const onSave = () => {
        if(!props.editMode && !selectedEquipment) return

        ApiClient.post({
            url: "/api/bonus/AddBonusEquipment",
            body: {
                title: selectedEquipment.title,
                model: selectedEquipment.model || null,
                amount: Number(equipmentAmount),
                edit: props.editMode,
            }
        }).then(json => {
            if(json.data.success){
                props.onHide()
                props.onEdit()
                return
            }
            setWarningMessage(json.data.message)
        }).catch(err => {
            setWarningMessage(err)
        });
    }

    useEffect(() => {
        if(!props.editMode) return
        setSelectedEquipment({
          title: props.data?.title || "",
          model: props.data?.model || "",
        })
        setEquipmentAmount(props.data?.amount)
    }, [props.data])

    useEffect(() => {
        ApiClient.get({
            url: "/api/bonus/GetEquipmentList",
        }).then(json => {
            setEquipmentList(json.data)
        }).catch(err => {
        });
    }, [])

    return (
      <Modal
      show={props.show}
      onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onExited={onClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <small>{props.editMode ? "Edit" : "Add"} Equipment</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Group>
            <Form.Row>
                <Col column="md" md={3} className="text-right pt-2">
                    {!props.editMode && <Dropdown onSelect={onSelect}>
                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                            Select Equipment
                        </Dropdown.Toggle>
                        <Dropdown.Menu as={CustomMenu}>
                            {equipmentList.map((equipment, i) => (
                                <Dropdown.Item key={i} eventKey={i}>{equipment.name}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>}
                </Col>
                <Col>
                    <FormControl 
                        value={selectedEquipment?.title} 
                        disabled={true} 
                    />
                </Col>
            </Form.Row>
            <br />
            <Form.Row>
                <Col column="md" md={3} className="text-right pt-2">
                    Model
                </Col>
                <Col>
                    <FormControl 
                        value={selectedEquipment?.model} 
                        disabled={true} 
                    />
                </Col>
            </Form.Row>
            <br />
            <Form.Row>
                <Form.Label column="md" md={3} className="text-right">
                    Amount
                </Form.Label>
                <Col>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl placeholder="0.00" type="number" value={equipmentAmount} onChange={e => setEquipmentAmount(e.target.value)} />
                    </InputGroup>
                </Col>
            </Form.Row>
        </Form.Group>
        {warningMessage && <Alert variant="danger" className="text-center">
            <small>{warningMessage}</small>
        </Alert>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>Close</Button>
          <Button onClick={onSave}>Save</Button>
        </Modal.Footer>
      </Modal>
    )
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </a>
  ))

const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');
  
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
        </div>
      );
    },
  );

