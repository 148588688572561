import React, { useState } from "react";
import {
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  FormText,
  Image
} from "react-bootstrap";
import "./Style/login.css";
import logo from './Style/PayLoSignaPay.png'
import { Auth } from "../Auth";

const ForgotPassword = () => {

  const [message, setMessage] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    e.persist()

    const form = e.target
    const formData = new FormData(form)
    Auth.forgotPassword(formData.get('email')).then(() => {
      setMessage('We sent you an email with instructions to reset your password')
      form.reset()
      setTimeout(() => {
        window.location.replace('/login')
      }, 8000);
    }).catch(e => {
      setMessage('Something went wrong! please try again')
    })
  }

  return (
    <div className="Login">
        <Image src={logo} width="300px" style={{ marginBottom: "20px"}} />
        <form onSubmit={handleSubmit}>
          <FormGroup controlId="email">
            <FormControl
              autoFocus
              name="email"
              type="email"
              placeholder="Email..."
              required={true}
            />
          </FormGroup>
          <Button
            block
            type="submit"
          >
            Submit
          </Button>
          <FormText className="text-muted">{message}</FormText>
        </form>
      </div>
  )

}

export default ForgotPassword