import React, { useEffect, useState } from "react"
import { withCookies } from "react-cookie"
import { Nav } from "react-bootstrap";
import Merchants from './Merchants'
import Billing from './Billing'
import Commissions from './Commissions'
import Historic from './Historic'
import Config from './Config'
import "./Style/Style.scss";

function Index(props) {
    const [selectedTab, setSelectedTab] = useState('tab-1');
    const [canEdit, setCanEdit] = useState(false)

    const editors = ['daniellec@signapay.com', 'bahramb@signapay.com']

    useEffect(() => {

        const { cookies } = props;
        const email = cookies.get("email");
        setCanEdit(editors.includes(email));
    }, [])

    return (
        <div className="PCIContainer">
            <Nav className="justify-content-end" variant="tabs" defaultActiveKey="tab-1" onSelect={(selectedKey) => setSelectedTab(selectedKey)}>
                <Nav.Item>
                    <Nav.Link eventKey="tab-1">Merchants</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="tab-2">Billing</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="tab-3">Commissions</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="tab-4" disabled={!canEdit}>Old Merchants</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="tab-5" disabled={!canEdit}>Config</Nav.Link>
                </Nav.Item>
            </Nav>
            <div className="tabContainer">
                {selectedTab === 'tab-1' && <>
                    <Merchants />
                </>}
                {selectedTab === 'tab-2' && <>
                    <Billing />
                </>}
                {selectedTab === 'tab-3' && <>
                    <Commissions />
                </>}
                {selectedTab === 'tab-4' && canEdit && <>
                    <Historic />
                </>}
                {selectedTab === 'tab-5' && canEdit && <>
                    <Config />
                </>}
            </div>
        </div>
    )
}

export default withCookies(Index);
