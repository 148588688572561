import { ApiClient } from "./ApiClient"

const validateUser = async () => {
  return ApiClient.post({
      url: "/api/app/account/validateUser"
  });
}

const signIn = async (email, password) => {
  return ApiClient.post({
    url: "/api/app/account/signin",
    body: {
      email: email,
      password: password
    }
  });
}

const signOut = async () => {
  return ApiClient.post({
    url: "/api/app/account/signout"
  });
}

const changePassword = async (email, oldpassword, password, confirmpassword) => {
  return ApiClient.post({
    url: "/api/app/account/changepassword",
    body: {
      email: email,
      oldpassword: oldpassword,
      password: password,
      confirmpassword: confirmpassword
    }
  });
}

const forgotPassword = async (email) => {
  return ApiClient.post({
    url: "/api/app/account/forgotpassword",
    body: {
      email: email,
    }
  })
}

const resetPassword = async (code, password) => {
  return ApiClient.post({
    url: "/api/app/account/resetpassword",
    body: {
      code: code,
      password: password,
    }
  })
}

const checkResetPassword = async (code) => {
  return ApiClient.post({
    url: "/api/app/account/checkresetpassword",
    body: {
      code: code,
    }
  })
}

const register = async (email, department, password, confirmpassword) => {
  return ApiClient.post({
    url: "/api/app/account/register",
    body: {
      email: email,
      department: department,
      password: password,
      confirmpassword: confirmpassword
    }
  });
}

export const Auth = {
  validateUser: validateUser,
  signIn: signIn,
  signOut: signOut,
  changePassword: changePassword,
  resetPassword: resetPassword,
  forgotPassword: forgotPassword,
  checkResetPassword: checkResetPassword,
  register: register,
};