import React, { Component } from "react";
import Table from "react-bootstrap/Table";
// import ReactTable from "react-table";
import "react-table-6/react-table.css";

class FieldTable extends Component {
  render() {
    var rows = [];
    var totalCount = 0;

    for (var i = 0; i < this.props.data.name.length; i++) {
      rows.push(
        <tr>
          <td>{this.props.data.name[i]}</td>
          <td style={{ textAlign: "center" }}>{this.props.data.count[i]}</td>
        </tr>
      );
      totalCount = totalCount + this.props.data.count[i];
    }

    return (
      <div className="FieldTable">
        <Table responsive style={{ marginBottom: 0 }}>
          <thead />

          <tbody>
            <tr>
              <td style={{ fontWeight: "bold" }}>Total</td>
              <td style={{ fontWeight: "bold", textAlign: "center" }}>
                {totalCount}
              </td>
            </tr>
            {rows}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default FieldTable;
