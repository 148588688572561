import React, { useEffect, useState } from "react"
import { Form, Button, Table, Row, Col } from "react-bootstrap"
import "../Style/Style.scss"
import { ApiClient } from "../../ApiClient"
import AddBonusModal from "./AddBonusModal"
import AddExcludeBonusModal from "./AddExcludeBonusModal"
import CurrentWindow from "./CurrentWindow"

export default function BonusSpecs(props) {

    const [selectedIso, setSelectedIso] = useState({})
    const [selectedPeriod, setSelectedPeriod] = useState('W')
    const [selectedProfile, setSelectedProfile] = useState('PL')
    const [addModalShow, setAddModalShow] = useState(false)
    const [addModalEditMode, setAddModalEditMode] = useState(false)
    const [addExcludeModalShow, setAddExcludeModalShow] = useState(false)
    const [includeISO, setIncludeISO] = useState([])
    const [excludeISO, setExcludeISO] = useState([])

    const onEditBonusIso = (index) => {
        setAddModalEditMode(true)
        setSelectedIso(includeISO[index])
        setAddModalShow(true)
    }

    const onDeleteBonusIso = (index) => {

        if(!window.confirm(`Are you sure you want to delete ${includeISO[index].iso_name || "Default bonus"} ?`)) return

        const group_id = includeISO[index].group_id == null ? null : includeISO[index].group_id.replaceAll(' ', '').split(',').filter(gid => gid !== null || gid !== "")

        ApiClient.post({
            url: "/api/bonus/DeleteBonusISO",
            body: {
                group_id: group_id,
                profile: selectedProfile,
                period: selectedPeriod,
            }
        }).then(json => {
            if(!json.data.success){
                window.alert(json.data.message)
                return
            }
            getBonusIsoList(selectedProfile, selectedPeriod)
        }).catch(err => {
        });
    }

    const onDeleteExcludeBonusIso = (index) => {

        if(!window.confirm(`Are you sure you want to delete ${excludeISO[index].iso_name} ?`)) return

        const group_id = excludeISO[index].group_id.replaceAll(' ', '').split(',').filter(gid => gid !== null || gid !== "")

        ApiClient.post({
            url: "/api/bonus/DeleteExcludeBonusISO",
            body: {
                group_id: group_id,
                profile: selectedProfile,
                period: selectedPeriod,
            }
        }).then(json => {
            if(!json.data.success){
                window.alert(json.data.message)
                return
            }
            getBonusExcludeIsoList(selectedProfile, selectedPeriod)
        }).catch(err => {
        });
    }

    const getBonusIsoList = (profile, period) => {
        ApiClient.post({
            url: "/api/bonus/GetBonusIsoList",
            body: {
                profile: profile,
                period: period,
            }
        }).then(json => {
            setIncludeISO(json.data);
        }).catch(err => {
        });
    }

    const getBonusExcludeIsoList = (profile, period) => {
        ApiClient.post({
            url: "/api/bonus/GetBonusExcludeIsoList",
            body: {
                profile: profile,
                period: period,
            }
        }).then(json => {
            setExcludeISO(json.data);
        }).catch(err => {
        });
    }

    useEffect(() => {
        if(!selectedPeriod || !selectedProfile) return
        
        getBonusIsoList(selectedProfile, selectedPeriod)
        getBonusExcludeIsoList(selectedProfile, selectedPeriod)
        
    }, [selectedPeriod, selectedProfile])

    return (
        <>
        <Row>
            <Col>
                <Form inline>
                    <Form.Control as="select" custom value={selectedPeriod} onChange={e => setSelectedPeriod(e.target.value)}>
                        <option value="W" key="W">Weekly</option>
                        <option value="M" key="M">Monthly</option>
                    </Form.Control>
                    <Form.Control className="ml-2" as="select" custom value={selectedProfile} onChange={e => setSelectedProfile(e.target.value)}>
                        <option value="PL" key="PL">PayLo</option>
                        <option value="TR" key="TR">Traditional</option>
                    </Form.Control>
                </Form>
            </Col>
            <Col>
                <div className="bonusCurrentWindow">
                    <CurrentWindow windowType={selectedPeriod} />
                </div>
            </Col>
        </Row>
        <div className="bonusSpecs">
            <div className="bonusIncludeISO">
                <div className="specsTitle">
                    Include
                    <Button variant="secondary" size="sm" className="float-right py-0" onClick={() => setAddModalShow(true)}>&#x002B;</Button>
                </div>
                <div className="bonusIncludeISOList">
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>ISO</th>
                                <th>Group ID</th>
                                <th>Bonus</th>
                                <th>On Activated</th>
                                <th>Placement Only</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {includeISO.map((iso, i) => (
                                <tr key={i}>
                                    <td>
                                        {iso.iso_name || "Default"}
                                    </td>
                                    <td>
                                        {iso.group_id}
                                    </td>
                                    <td>
                                        {iso.amount}
                                    </td>
                                    <td>
                                        {iso.on_activation == 1 && <>&#x2714;</>}
                                    </td>
                                    <td>
                                        {iso.placement_only == 1 && <>&#x2714;</>}
                                    </td>
                                    <td className="text-right">
                                        <Button variant="outline-info" size="sm" className="py-0 mr-2 border-0" onClick={() => onEditBonusIso(i)}>&#x270E;</Button>
                                        <Button variant="outline-danger" size="sm" className="py-0 border-0" onClick={() => onDeleteBonusIso(i)}>&#x2716;</Button>
                                    </td>
                                </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>
            </div>
            <div className="bonusExcludeISO">
                <div className="specsTitle">
                    Exclude
                    <Button variant="secondary" size="sm" className="float-right py-0" onClick={() => setAddExcludeModalShow(true)} disabled={includeISO && includeISO[0]?.iso_name}>&#x002B;</Button>
                </div>
                <div className="bonusIncludeISOList">
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>ISO</th>
                                <th>Group ID</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {excludeISO.map((iso, i) => (
                                <tr key={i}>
                                    <td>
                                        {iso.iso_name}
                                    </td>
                                    <td>
                                        {iso.group_id}
                                    </td>
                                    <td className="text-right">
                                        <Button variant="outline-danger" size="sm" className="py-0 border-0"  onClick={() => onDeleteExcludeBonusIso(i)}>&#x2716;</Button>
                                    </td>
                                </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
        <AddBonusModal
            data={selectedIso}
            show={addModalShow}
            onHide={() => { setAddModalShow(false); setAddModalEditMode(false); setSelectedIso()}}
            onEdit={() => { getBonusIsoList(selectedProfile, selectedPeriod); setSelectedIso()}}
            profile={selectedProfile}
            period={selectedPeriod}
            editMode={addModalEditMode}
        />
        <AddExcludeBonusModal
            show={addExcludeModalShow}
            onHide={() => setAddExcludeModalShow(false)}
            onAdd={() => getBonusExcludeIsoList(selectedProfile, selectedPeriod)}
            profile={selectedProfile}
            period={selectedPeriod}
        />
        </>
    )
}

