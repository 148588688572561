import React, { Component } from "react";

class NoMatch extends Component {
  render() {
    const { cookies } = this.props;
    var login = cookies.get("login") || null;
    if (login === null) {
      this.props.history.push("/login");
    }
    return (
      <div className="Dashboard">
        <h1> 404 Couldn't Route </h1>
      </div>
    );
  }
}

export default NoMatch;
