import React, { Component } from "react";
import "./Style/selfserve.scss";
import { withCookies } from "react-cookie";
import moment from "moment";
import UploadModal from "../UploadModal/UploadModal";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import CusTable from "./CusTable";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  SelectColumnFilter,
  MinMaxAmtColumnFilter,
  MinMaxNumColumnFilter,
  AutoMultiSelectColumnFilter,
} from "./Filters";
import { ApiClient } from "../ApiClient";

class SelfServe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      momentVal: moment(),
      data: [],
      loading: true,
      showImportModal: false,
      searchValue: "",
      startIndex: 0,
      saveVis: [],
    };
  }

  updatePartnerView = (...args) => {
    var dt = null;
    if (args.length > 0) {
      dt = args[0];
    }
    this.setState({
      loading: true,
      saveVis: args[1],
    });
    ApiClient
      .post({
        url: "/api/selfserve",
        body: {
          dt,
        }
      })
      .then((json) => {
        if (Object.keys(json.data).length === 0) {
          this.setState({
            data: [],
            loading: false,
          });
        } else {
          this.setState({
            data: json.data,
            loading: false,
          });
        }
      }).catch(err => {
        console.log(err);
      });
  };

  toggleImportModal = () => {
    this.setState({
      showImportModal: this.state.showImportModal ? false : true,
    });
  };

  getData = (val) => {
    ApiClient
      .post({
        url: "/api/searchselfserve",
        body: {
          val,
        }
      })
      .then((json) => {
        if (Object.keys(json.data).length === 0) {
          this.setState({
            data: [],
            loading: false,
          });
        } else {
          this.setState({
            data: json.data,
            loading: false,
          });
        }
      }).catch(err => {
        console.log(err);
      });
  };

  updatePrevCounter = () => {
    let { startIndex } = this.state;
    if (startIndex > 0) {
      this.setState({ startIndex: startIndex - 1 }, () => {
        this.updateView();
      });
    }
  };

  updateNextCounter = () => {
    let { data, startIndex } = this.state;
    if (data.length > startIndex * 60 + 60) {
      this.setState({ startIndex: startIndex + 1 }, () => {
        this.updateView();
      });
    }
  };

  handleChange = (event) => {
    clearTimeout(this.timer);
    this.setState({
      searchValue: event.target.value,
      startIndex: 0,
      loading: true,
    });
    if (event.target.value !== "") {
      this.timer = setTimeout(() => {
        this.getData(this.state.searchValue);
      }, 1000);
    } else {
      this.updatePartnerView();
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  UNSAFE_componentWillMount() {
    this.timer = null;
  }

  componentDidMount() {
    this.updatePartnerView();
  }

  render() {
    const { cookies } = this.props;
    const dept = cookies.get("dept");
    const email = cookies.get("email");

    const columns = [
      {
        Header: "Bank",
        id: "banknum",
        accessor: "banknum",
        Filter: AutoMultiSelectColumnFilter,
        filter: "includesSome",
      },
      {
        Header: "ISO",
        id: "groupname",
        accessor: "groupname",
        Filter: AutoMultiSelectColumnFilter,
        filter: "includesSome",
      },
      {
        Header: "Group",
        id: "groupid",
        accessor: "groupid",
        visible: false,
      },
      {
        Header: "MID",
        id: "mid",
        accessor: "mid",
      },
      {
        Header: "DBA",
        id: "dba",
        accessor: "dba",
      },
      {
        Header: "Legal",
        id: "legalname",
        accessor: "legalname",
      },
      {
        Header: "Profile",
        id: "merchprofile",
        accessor: "merchprofile",
        Filter: AutoMultiSelectColumnFilter,
        filter: "includesSome",
      },
      {
        Header: "HighRisk",
        id: "highrisk",
        accessor: "highrisk",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Association",
        id: "assocnum",
        accessor: "assocnum",
        visible: false,
      },
      {
        Header: "SIC",
        id: "sic",
        accessor: "sic",
        Filter: AutoMultiSelectColumnFilter,
        filter: "includesSome",
      },
      {
        Header: "Status",
        id: "merchstatus",
        accessor: "merchstatus",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      { Header: "Email", id: "email", accessor: "email" },
      { Header: "Phone", id: "phone", accessor: "phone" },
      {
        Header: "CB Email",
        id: "cbemail",
        accessor: "cbemail",
        visible: false,
      },
      {
        Header: "Address",
        id: "Address",
        accessor: "Address",
        visible: false,
      },
      {
        Header: "City",
        id: "City",
        accessor: "City",
        visible: false,
      },
      {
        Header: "State",
        id: "State",
        accessor: "State",
        visible: false,
      },
      {
        Header: "Zip",
        id: "Zip",
        accessor: "Zip",
        visible: false,
      },
      {
        Header: "Fund",
        id: "fund",
        accessor: "fund",
        Filter: SelectColumnFilter,
        filter: "equals",
        visible: false,
      },
      {
        Header: "Activation",
        id: "activationd",
        accessor: (d) => {
          if (d.activationd) {
            return moment.utc(d.activationd).format("YYYY-MM-DD");
          }
        },
      },
      {
        Header: "Open",
        id: "opend",
        accessor: (d) => {
          if (d.opend) {
            return moment.utc(d.opend).format("YYYY-MM-DD");
          }
        },
      },
      {
        Header: "Close",
        id: "closed",
        accessor: (d) => {
          if (d.closed) {
            return moment.utc(d.closed).format("YYYY-MM-DD");
          }
        },
      },
      {
        Header: "Tx Amount($)",
        id: "tx_amt",
        accessor: "tx_amt",
        Filter: MinMaxAmtColumnFilter,
        filter: "between",
      },
      {
        Header: "Tx Count",
        id: "tx_cnt",
        accessor: "tx_cnt",
        Filter: MinMaxNumColumnFilter,
        filter: "between",
      },
      {
        Header: "CB Amount($)",
        id: "cb_amt",
        accessor: "cb_amt",
        Filter: MinMaxNumColumnFilter,
        filter: "between",
      },
      {
        Header: "CB Count",
        id: "cb_cnt",
        accessor: "cb_cnt",
        Filter: MinMaxNumColumnFilter,
        filter: "between",
      },
      {
        Header: "Refund Amount($)",
        id: "ref_amt",
        accessor: "ref_amt",
        Filter: MinMaxNumColumnFilter,
        filter: "between",
      },
      {
        Header: "Refund Count",
        id: "ref_cnt",
        accessor: "ref_cnt",
        Filter: MinMaxNumColumnFilter,
        filter: "between",
      },
      {
        Header: "Income($)",
        id: "income",
        accessor: "income",
        Filter: MinMaxNumColumnFilter,
        filter: "between",
      },

    ];

    if (this.state.saveVis !== undefined && this.state.saveVis.length !== 0) {
      let cl = this.state.saveVis;
      for (var i = 0; i < columns.length; i++) {
        if (cl.includes(columns[i].id)) {
          columns[i].visible = true;
        } else {
          columns[i].visible = false;
        }
      }
    }

    //
    // const columns = [
    //   { Header: "Account ID", accessor: "accountid", show: false },
    //   { Header: "Account Name", accessor: "accountname" },
    //   { Header: "RM", accessor: "rm" },
    //   { Header: "Status", accessor: "status" },
    //   { Header: "ISC", accessor: "isc" },
    //   { Header: "Liability", accessor: "liability" },
    //   {
    //     id: "datesigned",
    //     Header: "Date Signed",
    //     accessor: d => {
    //       return moment(d.datesigned)
    //         .local()
    //         .format("YYYY-MM-DD");
    //     }
    //   },
    //   { Header: "Full Name", accessor: "fullname" },
    //   { Header: "Email", accessor: "processingemail" },
    //   { Header: "Phone", accessor: "phone" },
    //   { Header: "Billing City", accessor: "billingcity" },
    //   { Header: "Billing State", accessor: "billingstate" },
    //   { Header: "Billing Zip", accessor: "billingzip" },
    //   { Header: "Billing Country", accessor: "billingcountry" },
    //   { Header: "Group Name", accessor: "groupname" },
    //   { Header: "Group ID", accessor: "groupid" },
    //   { Header: "Bank", accessor: "bank" },
    //   { Header: "Profile", accessor: "profile" },
    //   { Header: "Processor", accessor: "processor" }
    // ];
    //
    // var content = (
    //   <ReactTable
    //     className={"-striped -highlight"}
    //     data={this.state.data}
    //     columns={columns}
    //     filterable={true}
    //     loading={this.state.loading}
    //   />
    // );

    // let updateCards = this.updateView();

    return (
      <div className="baseHeader">
        <div className="contentHeader">
          <h3 className="leaderboardHeader">
            {"Self Serve BETA"}
            {/*<div className="importExport"> */}

            {this.state.showImportModal &&
              (dept === "admin" || dept === "sales_admin") ? (
              <UploadModal
                toggle={this.toggleImportModal}
                show={this.state.showImportModal}
                refresh={this.updatePartnerView}
                filename={"partner"}
              />
            ) : null}

            {/*</div> */}
          </h3>
        </div>

        <CardDeck className="partnerDeck">
          <Card style={{ border: "none", borderRadius: "0" }}>
            <Card.Body>
              <Card.Title></Card.Title>

              <CssBaseline />
              <CusTable
                columns={columns}
                data={this.state.data}
                email={email}
                getData={this.updatePartnerView}
                loading={this.state.loading}
              />
            </Card.Body>
          </Card>
        </CardDeck>
      </div>
    );
  }
}

export default withCookies(SelfServe);
