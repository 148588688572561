//Created by Katherine Hartman
//Handles the rendering the volume graph

import React, { Component } from "react";
import moment from "moment";
import { Chart, Bar } from "react-chartjs-2";
import "../Style/finance.scss";
import "hammerjs";
import "chartjs-plugin-zoom";

class VGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prev_min: props.time.min,
      prev_max: props.time.max
    }
  }

  setxy = () => {
    var xy = [];
    for (var i = 0; i < this.props.data.length; i++){
      var obj = this.props.data[i];
      if (moment(obj.Year_Months).startOf('month') === moment(obj.Year_Months)){
        xy.push({
          x: moment(obj.Year_Months).subtract(1, 'months').startOf('month'),
          y: obj.Volume
        });
      }
      else{
        xy.push({
          x: moment(obj.Year_Months).startOf('month'),
          y: obj.Volume
        });
      }
    }
    return xy;
  }

  render() {
    console.log(this.props);
    let dataPices = this.setxy();
    let coloring = Array (dataPices.length).fill("rgba(88, 214, 141, 1)");
    if(this.props.iselected){
      coloring[this.props.iselected] = "rgba(0, 174, 101, 1)";
    }

    var data = {
      datasets: [
        {
          label: "Total",
          type: "bar",
          backgroundColor: coloring,
          borderColor: "rgba(300,300,300,1)",
          borderWidth: 1,
          hoverBackgroundColor: coloring,
          hoverBorderColor: "rgba(300,300,300,1)",
          data: dataPices,
          stack: "Volume",
          yAxisID: "volume"
        }
      ]
    };

    Chart.Tooltip.positioners.cursor = function(chartElements, coordinates) {
      return coordinates;
    };

    return (
      <div className="payloTraditional">
        <Bar
          data={data}
          onElementsClick = {element => {this.props.handleBarClick(element)}}
          options={{
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            scales: {
              yAxes: [
                {
                  fontFamily: 'Roboto',
                  scaleLabel: {
                    display: true,
                    labelString: "$ of Volume"
                  },
                  ticks: {
                    beginAtZero: true,
                    callback: function(value, index, values) {
                      return "$" + (value*(.000001)).toFixed(2) + "M";
                    }
                  },
                  stacked: true,
                  distribution: "linear",
                  id: "volume",
                  position: "left"
                 }
              ],
              xAxes: [
                {
                  fontFamily: 'Roboto',
                  stacked: true,
                  type: "time",
                  offset: true,
                  time: {
                    unit: "month",
                    displayFormats: {
                      Month: "MMMM YYYY"
                    },
                    min: this.props.time.min,
                    max: this.props.time.max
                  },
                  distribution: "linear",
                  bounds: "ticks"
                }
              ]
            },
            tooltips: {
              fontFamily: 'Roboto',
              mode: "index",
              intersect: true,
              position: "cursor",
              callbacks: {
                label: function(tooltipItem, data){
                  return "$" + tooltipItem.yLabel.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                title: function(tooltipItem, data){
                  return moment(tooltipItem[0].xLabel).format('MMMM YYYY');
                }
              }
            },
            plugins: {
              zoom: {
                pan: {
                  enabled: true,
                  mode: "x",
                  onPan: ({ chart }) => {
                    var {min, max} = chart.config.options.scales.xAxes[0].time
                    var xScale = chart.scales['x-axis-0']
                    min = moment(min)
                    max = moment(max)
                    if (min && max) {
                      if (min._i && Math.abs(min._i - this.props.time.min) > (15*86400*xScale.getPixelForTick(0))) {
                        console.log(Math.abs(min._i - this.props.time.min) + " > " + (15*86400*xScale.getPixelForTick(0)))
                        this.props.handleMove(min, max)
                      }
                    }
                  }
                },
                zoom: {
                  enabled: true,
                  drag: false,
                  mode: "x",
                  rangeMin: {
                    x: moment().subtract(4, 'month').format("MMMM YYYY"),
                    y: 0
                  },
                  rangeMax: {
                    x: moment().format("MMMM YYYY")
                  },
                  speed: 0.1,
                  onZoom: ({ chart }) => {
                    var {min, max} = chart.config.options.scales.xAxes[0].time
                    var xScale = chart.scales['x-axis-0']
                    min = moment(min)
                    max = moment(max)
                    if (min && max) {
                      if (min._i && Math.abs((max._i - min._i) - (this.props.time.max - this.props.time.min)) > (30*86400*xScale.getPixelForTick(0))) {
                        console.log(Math.abs((max._i - min._i) - (this.props.time.max - this.props.time.min)) + " > " + (30*86400*xScale.getPixelForTick(0)))
                        this.props.handleMove(min, max)
                      }
                    }
                  }
                }
              }
            }
          }}
        />
      </div>
    );
  }
}

export default VGraph;
