import React, { useEffect, useState } from "react"
import { withCookies } from "react-cookie"
import { Nav } from "react-bootstrap"
import "./Style/Style.scss"
import { ApiClient } from "../ApiClient"
import Fees from "./components/Fees"
import BillingTypes from "./components/BillingTypes"
import RevShares from "./components/RevShares"
import { BillingTypesProvider } from "./components/BillingTypesContext"
import { FeesProvider } from "./components/FeesContext"

function Config(props) {
    const [flags, setFlags] = React.useState([])
    const [selectedTab, setSelectedTab] = useState('tab-1')

    useEffect(() => {
        ApiClient.get({
            url: "/api/pci/GetFlags",
        })
        .then((json) => {
            setFlags(json.data)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    return (
        <div className="ConfigContainer">
            <Nav className="config-tabs justify-content-start" variant="pills" defaultActiveKey="tab-1" onSelect={(selectedKey) => setSelectedTab(selectedKey)}>
                <Nav.Item>
                    <Nav.Link eventKey="tab-1">Billing Types</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="tab-2">Fees</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="tab-3">Rev Shares</Nav.Link>
                </Nav.Item>
            </Nav>
            <div className="configs">
                {selectedTab === 'tab-1' && <>
                <BillingTypesProvider>
                    <BillingTypes flags={flags} />
                </BillingTypesProvider>
                </>}
                {selectedTab === 'tab-2' && <>
                <FeesProvider>
                    <Fees flags={flags} />
                </FeesProvider>
                </>}
                {selectedTab === 'tab-3' && <>
                    <RevShares />
                </>}
            </div>
        </div>
    )
}

export default withCookies(Config);


