import React, { Component } from "react";
import Loader from "react-loader-spinner";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import "./Style/leaderboard.scss";
import { withCookies } from "react-cookie";
import moment from "moment";
import FieldTable from "./FieldTable";
import { ApiClient } from "../ApiClient";

class Leaderboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      momentVal: moment(),
      nameUW: "",
      apprUW: 0,
      loadingLeadUW: false,
      nameRM: "",
      apprRM: 0,
      loadingLeadRM: false
    };
  }

  updateLeaderboardUW() {
    ApiClient
      .post({
        url: "/api/leaderboardUW"
      })
      .then(json => {
        if (Object.keys(json.data).length === 0) {
          this.setState({
            nameUW: "",
            apprUW: "0",
            loadingLeadUW: true
          });
        } else {
          this.setState({
            nameUW: json.data.map(d => d.Name),
            apprUW: json.data.map(d => d.Approved),
            loadingLeadUW: true
          });
        }
      }).catch(err => {
        console.log(err);
      });
  }

  updateLeaderboardRM() {
    ApiClient
      .post({
        url: "/api/leaderboardRM"
      })
      .then(json => {
        if (Object.keys(json.data).length === 0) {
          this.setState({
            nameRM: "",
            apprRM: "0",
            loadingLeadRM: true
          });
        } else {
          this.setState({
            nameRM: json.data.map(d => d.Name),
            apprRM: json.data.map(d => d.Approved),
            loadingLeadRM: true
          });
        }
      }).catch(err => {
        console.log(err);
      });
  }

  updateLeaderboard() {
    this.updateLeaderboardUW();
    this.updateLeaderboardRM();
  }

  componentDidMount() {
    this.updateLeaderboard();
    this.interval = setInterval(() => {
      this.updateLeaderboard();
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {

    var dataUW = {
      name: this.state.nameUW,
      count: this.state.apprUW,
      title:
        this.state.momentVal.format("MMMM YYYY") + " Approvals by Underwriters"
    };

    var dataRM = {
      name: this.state.nameRM,
      count: this.state.apprRM,
      title:
        this.state.momentVal.format("MMMM YYYY") +
        " Approvals by Relationship Manager"
    };

    var options = {
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false
            },
            ticks: {
              beginAtZero: true,
              precision: 0,
              scaleIntegersOnly: true
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              display: false
            },
            ticks: {
              beginAtZero: true,
              precision: 0,
              scaleIntegersOnly: true
            }
          }
        ]
      }
    };

    var content = <FieldTable data={dataUW} options={options} />;
    var contentRM = <FieldTable data={dataRM} options={options} />;

    return (
      <div>
        <div className="contentHeader">
          <h3 className="leaderboardHeader">{"Leaderboard"}</h3>
        </div>
        <CardDeck className="leaderboardDeck">
          <Card style={{ border: "none", borderRadius: "0" }}>
            <Card.Body>
              <Card.Title>{dataUW.title}</Card.Title>
              {this.state.loadingLeadUW ? (
                content
              ) : (
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <Loader color="green" width={25} heigth={25} type="Oval" />
                </div>
              )}
            </Card.Body>
          </Card>
          <Card style={{ border: "none", borderRadius: "0" }}>
            <Card.Body>
              <Card.Title>{dataRM.title}</Card.Title>
              {this.state.loadingLeadRM ? (
                contentRM
              ) : (
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <Loader color="green" width={25} heigth={25} type="Oval" />
                </div>
              )}
            </Card.Body>
          </Card>
        </CardDeck>
      </div>
    );
  }
}

export default withCookies(Leaderboard);
