//Created by Katherine Hatman
//Handles the rendering and data retrieval of the export page.

import React, { Component } from "react";
import {Card, Button} from "react-bootstrap";
import { withCookies } from "react-cookie";
import moment from "moment";
import "./Style/export.scss";
import { ApiClient } from "../ApiClient"

class Exports extends Component {
  constructor() {
    super();
    this.state = {
      yr0: false,
      v0: "success",
      yr1: false,
      v1: "success",
      yr2: false,
      v2: "success",
      yr3: false,
      v3: "success",
      yr4: false,
      v4: "success",
      yr5: false,
      v5: "success",
      yr6: false,
      v6: "success"
    }
  }

  sendEmail = (btn, v, cookies, year) => {
    this.setState({[btn]: true});
    this.setState({[v]: "light"})
    ApiClient
      .post({
        url: "/api/email", 
        body: {
          rec: cookies.get("email"),
          yr: year.format('YYYY'),
          qr: year.format('Q')
        }
      }).then(json => {
        console.log(json.data);
      }).catch(err => {
        console.log(err);
      });
  };

  render() {
    const { cookies } = this.props;

    return (
      <div className="dashboard">
        <Card className="Exports" >
          <Card.Header className="header1">
            <Card.Title>
              {" "}
              How to Export
            </Card.Title>
            <Card.Body>
              Find the report you would like to export from the list below.
              The report will be sent to the email you are signed in with.
              Please allow a few minutes before resending a report.
            </Card.Body>
          </Card.Header>
          <Card.Body>
            <Card.Title>
            Email to {cookies.get("email")}
            </Card.Title>
            <div>
              <Button className="exportbtn" disabled={this.state.yr0} variant={this.state.v0} onClick={() => this.sendEmail("yr0", "v0", cookies, moment().subtract(1, 'months'))} block>
                {moment().subtract(1, 'months').format('YYYY')} Q{moment().subtract(1, 'months').format('Q')} to month financial dashboard <i className="material-icons">email</i>
              </Button>
            </div>
            <div>
              <Button className="exportbtn" disabled={this.state.yr1} variant={this.state.v1} onClick={() => this.sendEmail("yr1", "v1", cookies, moment().subtract(1, 'months').subtract(1, 'Q'))} block>
                {moment().subtract(1, 'months').subtract(1, 'Q').format('YYYY')} Q{moment().subtract(1, 'months').subtract(1, 'Q').format('Q')} financial dashboard <i className="material-icons">email</i>
              </Button>
            </div>
            <div>
              <Button className="exportbtn" disabled={this.state.yr2} variant={this.state.v2} onClick={() => this.sendEmail("yr2", "v2", cookies, moment().subtract(1, 'months').subtract(2, 'Q'))} block>
                {moment().subtract(1, 'months').subtract(2, 'Q').format('YYYY')} Q{moment().subtract(1, 'months').subtract(2, 'Q').format('Q')} financial dashboard <i className="material-icons">email</i>
              </Button>
            </div>
            <div>
              <Button className="exportbtn" disabled={this.state.yr3} variant={this.state.v3} onClick={() => this.sendEmail("yr3", "v3", cookies, moment().subtract(1, 'months').subtract(3, 'Q'))} block>
                {moment().subtract(1, 'months').subtract(3, 'Q').format('YYYY')} Q{moment().subtract(1, 'months').subtract(3, 'Q').format('Q')} financial dashboard <i className="material-icons">email</i>
              </Button>
            </div>
            <div>
              <Button className="exportbtn" disabled={this.state.yr4} variant={this.state.v4} onClick={() => this.sendEmail("yr4", "v4", cookies, moment().subtract(1, 'months').subtract(4, 'Q'))} block>
                {moment().subtract(1, 'months').subtract(4, 'Q').format('YYYY')} Q{moment().subtract(1, 'months').subtract(4, 'Q').format('Q')} financial dashboard <i className="material-icons">email</i>
              </Button>
            </div>
            <div>
              <Button className="exportbtn" disabled={this.state.yr5} variant={this.state.v5} onClick={() => this.sendEmail("yr5", "v5", cookies, moment().subtract(1, 'months').subtract(5, 'Q'))} block>
                {moment().subtract(1, 'months').subtract(5, 'Q').format('YYYY')} Q{moment().subtract(1, 'months').subtract(5, 'Q').format('Q')} financial dashboard <i className="material-icons">email</i>
              </Button>
            </div>
            <div>
              <Button className="exportbtn" disabled={this.state.yr6} variant={this.state.v6} onClick={() => this.sendEmail("yr6", "v6", cookies, moment().subtract(1, 'months').subtract(6, 'Q'))} block>
                {moment().subtract(1, 'months').subtract(6, 'Q').format('YYYY')} Q{moment().subtract(1, 'months').subtract(6, 'Q').format('Q')} financial dashboard <i className="material-icons">email</i>
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default withCookies(Exports);
