import React, { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import "../Style/Style.scss"
import CustomSelect from "./CustomSelect"
import { useException } from "./BillingTypesContext"

export default function Exception(props) {
    const [selectedFilter, setSelectedFilter] = useState()
    const [selectedFilterOperator, setSelectedFilterOperator] = useState()
    const [filterValues, setFilterValues] = useState()
    const [filters, setFilters] = useState()
    const [filterOperators, setFilterOperators] = useState()
    const [data, setData] = useState()
    const { setExceptionFilter, setExceptionOperator } = useException()

    const handleFilterChange = (e) => {
        setSelectedFilter(filters.find(f => f.id === Number(e.target.value)))
        setExceptionFilter(data.id, Number(e.target.value))
        setFilterValues()
    }

    const handleFilterOperatorChange = (e) => {
        setSelectedFilterOperator(filterOperators.find(f => f.id === Number(e.target.value)))
        setExceptionOperator(data.id, Number(e.target.value))
        setFilterValues()
    }

    useEffect(() => {
        if (!props.filters|| !props.filterOperators ) return
        setFilters([...props.filters])
        setFilterOperators([...props.filterOperators])
    }, [props.filters, props.filterOperators])

    useEffect(() => {
        if(!props.data || !filters || !filterOperators) return
        setData({...props.data})
    }, [props.data, filters, filterOperators])


    useEffect(() => {
        if(!data) return
        setSelectedFilter(filters.find(f => f.id === data.filter_id))
        setSelectedFilterOperator(filterOperators.find(f => f.id === data.filter_operator_id))
        setFilterValues(data.filter_value)
    }, [data])

    useEffect(() => {

    },)

    return(
        <>
        <Form inline className="mb-0">
            <Form.Control className="mr-2" style={{width: "170px"}} as="select" custom value={(selectedFilter && selectedFilter.id) || 0} onChange={handleFilterChange}>
                <option value={0}></option>
                {filters && filters.map(filter => {
                   return <option value={filter.id} key={filter.id}>{filter.alias}</option>
                })}
            </Form.Control>
            <Form.Control className="mr-2" style={{width: "150px"}} as="select" custom onChange={handleFilterOperatorChange} disabled={!selectedFilter} value={(selectedFilterOperator && selectedFilterOperator.id) || 0}>
                <option value={0}></option>
                {selectedFilter && filterOperators.filter(operator => (operator.operator_type === "list" && selectedFilter.field_type !== "date") || operator.operator_type === selectedFilter.field_type)
                .map(operator => 
                    <option value={operator.id} key={operator.id}>{operator.alias}</option>
                )}
            </Form.Control>
            <CustomSelect id={data && data.id} filter={selectedFilter} operator={selectedFilterOperator} values={filterValues} />
        </Form>
        </>
    )

}