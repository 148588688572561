import React, { useEffect, useState } from "react"
import { withCookies } from "react-cookie"
import { ApiClient } from "../ApiClient"
import CssBaseline from '@material-ui/core/CssBaseline'
import ACHTable from './components/ACHTable'
import DateRangeColumnFilter from './components/DateRangeColumnFilter'
import { ACHCalculator } from "./ACHCalculator"
import moment from "moment";
import "./Style/Style.scss";

function ACHReturns(props) {

    const editors = ['daniellec@signapay.com', 'marianac@signapay.com', 'kimm@signapay.com', 'angelap@signapay.com']

    const [data, setData] = React.useState([])
    const [calculated, setCalculated] = React.useState({})
    const [loading, setLoading] = React.useState(true)
    const [hasError, sethasError] = React.useState(false)
    const [skipPageReset, setSkipPageReset] = React.useState(false)
    const [canEdit, setCanEdit] = useState(false)

    const datePicker = ({
        value: initialValue,
        row: { index },
        column: { id },
      }) => {
        const onChange = (event) => {
            updateMyData(index, id, event.currentTarget.value)
        }

        return (
            <input type="date" value={initialValue ? moment.utc(initialValue).format("YYYY-MM-DD") : ""} onChange={(e) => onChange(e)} />
        )
    }

    const rejResDropDown = ({
        value: initialValue,
        row: { index },
        column: { id },
      }) => {

        const rejList = [
            "Used Reserve",
            "Used Reserve - PARTIAL",
            "Re-Ran Successfully",
            "Write Off",
            "Will Be Sent to Coll.",
            "Sent to Collections",
            "Billed to Esquire Merch Svcs (Craig Davis)",
            "Billed to ISO",
            "Cancels Out with DR/CR",
            "ETFshouldnothavebeencharged",
            "Fraud account, hold all batches",
            "Notice of Correction",
            "Received a Check",
            "TR Investigation",
            "TSYS ERROR",
            "Under litigation",
            "Wire Received",
            "Write Off - Other",
            "Write Off - Waived"
        ]

        const onChange = (event) => {
            updateMyData(index, id, event.currentTarget.value)
        }

        return (
            <select onChange={(e) => onChange(e)} value={initialValue || ""}>
                <option value="" key={rejList.length}></option>
                {rejList.map((rej, i) => (
                    <option value={rej} key={i}>{rej}</option>
                ))}
            </select>
        )
    }

    const columns =  [
            {
                Header: "Unique Key",
                accessor: "main_unique_key",
                visible: false
            },
            {
                Header: "From Excel",
                visible: false,
                accessor: (d) => {
                  return d.historic === 1 ? "Yes" : "No"
                },
            },
            {

                Header: "rejDate",
                accessor: (d) => {
                  if (d.FileCreationDATE) {
                    return moment.utc(d.FileCreationDATE).format("YYYY-MM-DD");
                  }
                  return ""
                },
                Filter: DateRangeColumnFilter,
                filter: "dateBetween"
            },
            {
                Header: 'MID',
                accessor: 'IndividualIdentificationNumber',
            },
            {
                Header: 'DBA',
                accessor: 'DBAName',
            },
            {
                Header: 'Legal Name',
                accessor: 'LegalName',
            },
            {
                Header: 'ISO',
                accessor: 'GroupLevel1Name',
            },
            {
                Header: 'rejDesc',
                accessor: (d) => {

                    if(d.historic == 1)
                        return d.rejDesc

                    const ced = d.CompanyEntryDescription.trim()

                    if (ced === "" || ced == undefined || ced == null)
                        return ""
                    if (ced === "bl07" || (ced === "MERCH DEP" && d.TransactionAmount > 0))
                        return "Deposit"
                    if (ced ===  "MERCH DEP" && d.TransactionAmount < 0)
                        return "Negative Deposit"
                    if (ced ===  "MERCH DEP")
                        return "Deposit"
                    if (ced === "MERCH CHBK" && d.TransactionAmount > 0)
                        return "CHBK Reversal"
                    if (ced === "MERCH CHBK")
                        return "Chargeback"
                    if (d.AddendaRelated && d.AddendaRelated.slice(0, 1) === 'C' && d.TransactionAmount == 0)
                        return "NOC"
                    if (ced === "MERCH FEES")
                        return moment(d.EffectiveEntryDATE).subtract(15, "day").format('MMMM') + " Fees"
                    if (ced === "DLY DISC S")
                        return "Daily Disc"
                    if (ced === "DLY INT S")
                        return "Daily Int"
                    if (ced === "RSRVE FNDG")
                        return "Reserve Funding"
                    if (ced === "RETRY PYMT")
                        return "Look Up"
                    if (ced ===  "RSRVE RLSD")
                        return "Rsv Released"
                    if (ced ===  "MERCH ADJ")
                        return "2nd attempt"
                    if (ced ===  "PPM FEES S")
                        return "Chargeback Fee"
                    return ""
                },
            },
            {
                Header: 'rejAmt',
                accessor: (d) => {
                    return d.TransactionAmount < 0 ? `(${d.TransactionAmount * -1})` : d.TransactionAmount 
                }
            },
            {
                Header: "RejCode",
                accessor: (d) => {
        
                    if(d.historic == 1)
                        return d.AddendaRelated
        
                    if (d.AddendaRelated == null) 
                        return d.AddendaRelated
        
                    var code = d.AddendaRelated.substring(0, 3);
        
                    if (code === "R01") {
        
                        return code.concat(" - NSF");
                    }
                    if(code === "C01") {
        
                        return code.concat(" - Incorrect bank acct#");
                    }
                    if(code === "R03") {
        
                        return code.concat(" - No Acct");
                    }
                    if(code === "R29") {
        
                        return code.concat(" - Advices not authorized");
                    }
                    if(code === "C02") {
        
                        return code.concat(" - Incorrect R/T#");
                    }
                    if(code === "C03") {
        
                        return code.concat(" - Incorrect R/T# & A/C#");
                    }
                    if(code === "C05") {
        
                        return code.concat(" -  Incorrect payment code");
                    }
                    if(code === "R02") {
        
                        return code.concat(" - Acct Closed");
                    }
                    if(code === "R04") {
        
                        return code.concat(" - Invalid Acct #");
                    }
                    if(code === "R05") {
        
                        return code.concat(" - Unauthorized Debit");
                    }
                    if(code === "R06") {
        
                        return code.concat(" - Returned per ODFI Request");
                    }
                    if(code === "R07") {
        
                        return code.concat(" - Authorization Revoked");
                    }
                    if(code === "R08") {
        
                        return code.concat(" - Payment Stopped");
                    }
                    if(code === "R09") {
        
                        return code.concat(" - Uncollected Funds");
                    }
                    if(code === "R10") {
        
                        return code.concat(" - Unauthorized");
                    }
                    if(code === "R11") {
        
                        return code.concat(" - Acct charged before set-up completed");
                    }
                    if(code === "R13") {
        
                        return code.concat(" - Invalid Routing Number (PCI)");
                    }
                    if(code === "R15") {
        
                        return code.concat(" - Beneficiary Deceased");
                    }
                    if(code === "R16") {
        
                        return code.concat(" - Acct Frozen");
                    }
                    if(code === "R20") {
        
                        return code.concat(" - Non-Transaction Acct");
                    }
                    if(code === "R23") {
        
                        return code.concat(" - Payment Refused");
                    }
                    if(code === "R28") {
        
                        return code.concat(" - Transit routing number error");
                    }
                    if(code === "R31") {
        
                        return code.concat(" -  Permissible Return Entry");
                    }
                    if(code === "R51") {
        
                        return code.concat(" - Item is ineligble");
                    }
        
                    else {
                        return code;
                    }
        
                }
            }, 
            {
                Header: 'rejRes',
                accessor: 'rejRes',
                Cell: rejResDropDown
            },
            {
                Header: 'resDate',
                accessor: 'resDate',
                Cell: datePicker,
                filter: 'date'
            },
            {
                Header: 'lastProcDate',
                accessor: (d) => {
                    if (d.lastProcDate) {
                      return moment.utc(d.lastProcDate).format("YYYY-MM-DD");
                    }
                    return ""
                }
            },
            {
                Header: 'rejDDA',
                accessor: (d) => {
                    if(d.historic == 1)
                        return d.rejDDA
                    if(d.AddendaRelated && d.AddendaRelated.slice(0, 3) !== "R13" && d.AddendaRelated.slice(0, 3) !== "C02")
                        return d.DFIAccountNumber
                    if(d.AddendaRelated && d.AddendaRelated.slice(0, 3) === "R13" && d.AddendaRelated.slice(0, 3) === "C02")
                        return "TR#: " + d.OriginatingDFIIdentification
                    return ""
                }
            },
            {
                Header: 'comment',
                accessor: (d) => {

                    if(d.historic == 1 || d.AddendaRelated == null || (d.comment && d.comment.trim() !== ""))
                        return d.comment

                    var code = d.AddendaRelated.substring(0, 3);

                    if(code === "C01")
                        return `New DDA: ${d.AddendaRelated.slice(32, 50).trim()}`

                    if(code === "C02")
                        return `New T/R: ${d.AddendaRelated.slice(32, 50).trim()}`

                    if(code === "C03")
                        return `New T/R: ${d.AddendaRelated.slice(32, 44).trim()}, New DDA: ${d.AddendaRelated.slice(44, 62).trim()}`
                    
                    return d.comment

                },
                editable: true
            },
            {
                Header: 'usedRsv',
                accessor: 'usedRsv',
                editable: true
            },
            {
                Header: 'usedRsvDate',
                accessor: 'usedRsvDate',
                Cell: datePicker,
                filter: 'date'
            },
            {
                Header: 'reRan',
                accessor: 'reRan',
                editable: true
            },
            {
                Header: 'reRanDate',
                accessor: 'reRanDate',
                Cell: datePicker,
                filter: 'date'
            },
            {
                Header: 'sentToColl',
                accessor: 'sentToColl',
                editable: true
            },
            {
                Header: 'writeOff',
                accessor: 'writeOff',
                editable: true
            },
            {
                Header: 'merchStatus',
                accessor: 'MerchantStatus',
            },
            {
                Header: 'rsvHold',
                accessor: 'rsvHold',
            },
            {
                Header: 'rsvCeiling',
                accessor: 'rsvCeiling',
            },
            {
                Header: 'rsvBalance',
                accessor: 'rsvBalance',
            },
            {
                Header: 'merchRejBal',
                accessor: (d) => {
                    return calculated[0] && calculated[0].hasOwnProperty(d.IndividualIdentificationNumber) ? 
                        (calculated[0][d.IndividualIdentificationNumber] < 0 ? 
                            `(${calculated[0][d.IndividualIdentificationNumber].toFixed(2) * -1})` : 
                            calculated[0][d.IndividualIdentificationNumber].toFixed(2)
                        ) : ""
                }
            },
            {
                Header: 'Alert',
                filter: 'no-filter',
                width: 5,
                accessor: (d) => {
                    if(calculated[1] && calculated[2] && calculated[3] && calculated[1].hasOwnProperty(d.LegalName)) {
                        if(calculated[1][d.LegalName] < 0 && calculated[2][d.LegalName] > 0 && calculated[3][d.LegalName].size > 1)
                            return <span style={{
                                fontSize: "20px",
                                color: "red"
                            }}>&#x26A0;</span>
                    } 
                    return ""
                }
            },
            {
                Header: 'ACH Occurrence',
                accessor: 'occurrence',
            },             
            {
                Header: "Last Edit",
                accessor: (d) => {
                    return <i size="sm">
                            {d.datetimeWorked && moment.utc(d.datetimeWorked).local().format("YYYY-MM-DD")}<br />
                            {d.workedBy}
                        </i>
                    
                },
                visible: false
            },
        ]

    const updateMyData = (rowIndex, columnId, value) => {

        if(!canEdit) return

        setSkipPageReset(true)
        sethasError(false)

        setData(old => {
            const newData = old.map((row, index) => {
                        if (index === rowIndex) {
                        return {
                            ...old[rowIndex],
                            [columnId]: value,
                            }
                        }
                        return row
                    })
            setCalculated(ACHCalculator.calc(newData))
            return newData
        })
        ApiClient.post({
            url: "/api/updateACH",
            body: {
                unique_key: data[rowIndex].main_unique_key,
                column: columnId,
                value: value,
                historic: data[rowIndex].historic
            }
        })
        .then((json) => {
            setSkipPageReset(false)
        }).catch(err => {
            console.log(err);
            sethasError(true);
            setSkipPageReset(false);
        });
    }

    const joinData = (data1, data2, data3) => {
        const data = data1.map(d => {
            let index = data2.findIndex(el => el["IndividualIdentificationNumber"] === d["IndividualIdentificationNumber"]);
            if(index > -1){
                const rsvBalance =  data2[index]["rsvBalance"]
                const rsvHold =  data2[index]["rsvHold"]
                const rsvCeiling =  data2[index]["rsvCeiling"]

                d["rsvBalance"] = isNaN(rsvBalance) ? null : Number(rsvBalance);
                d["rsvHold"] = isNaN(rsvHold) ? null : Number(rsvHold);
                d["rsvCeiling"] = isNaN(rsvCeiling) ? null : Number(rsvCeiling);
            }

            index = data3.findIndex(el => el["mid"] === d["IndividualIdentificationNumber"]);
            if(index > -1){
                d["occurrence"] = data3[index]["occurrence"];
            }
            return d;
        })

        return data;
    }

    useEffect(() => {

        const { cookies } = props;
        const email = cookies.get("email");
        setCanEdit(editors.includes(String(email).toLowerCase()));

        ApiClient.post({
            url: "/api/ach",
        })
        .then((json) => {
            const joinedData = joinData(json.data[0], json.data[1], json.data[2])
            setData(joinedData)
            setCalculated(ACHCalculator.calc(joinedData))
            setLoading(false)
        }).catch(err => {
            console.log(err);
            setLoading(false)
        });
    }, [])

    return (
        <div className="ACHContainer">
            <div className="ACH">
                <CssBaseline />
                <ACHTable className="ACHTable"
                    columns={columns}
                    data={data}
                    setData={setData}
                    updateMyData={updateMyData}
                    skipPageReset={skipPageReset}
                    hasError={hasError}
                    email={props.allCookies.email}
                    loading={loading}
                    setLoading={setLoading}
                />
            </div>
        </div>
    )
}

export default withCookies(ACHReturns);
