import XLSX from "xlsx";

export const xlsxDownloader = (data, sheetName, fileName) => {
    const xlsxData = data.map(d => {
        console.log({
            ...d.groups,
            ...d
        })
});
    const new_workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(
        new_workbook,
        worksheet,
        sheetName
    );
    XLSX.writeFile(
        new_workbook,
        fileName
    );
};