import React, { Component } from "react";
import { Container, Row, Col} from "react-bootstrap";

class Summary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0
    };
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    // KEEP THE RATIO OF ALL THE CARDS SAME (ALSO SUMMARY DOESN'T HAVE CARD TITLE)
    this.setState({
      height: this.tableRef.current.offsetWidth / 1.76
    });
  }

  render() {
    return (
      <div
        className="customTable"
        ref={this.tableRef}
        style={{ height: this.state.height }}
      >
        <div style={{ fontSize: "0.95rem" }}>
          <Container fluid>
            <Row>
              <Col xs={3}>Goal: <strong>{this.props.data[1]}</strong></Col>
              <Col xs={4}>Trending: <strong>{this.props.data[0]}</strong></Col>
              <Col xs={5}>Trending/Goal: <strong>{this.props.data[2]}%</strong></Col>
            </Row>
          </Container>
          {/* <div style={{ display: "inline-block", width: "200px" }}>
            Trending{" "}
            <div
              style={{
                display: "inline-block",
                fontWeight: "500",
                marginRight: "55px",
                float: "right"
              }}
            >
              {this.props.data[0]}
            </div>
          </div>
          <div style={{ display: "inline-block", width: "200px" }}>
            Goal{" "}
            <div
              style={{
                display: "inline-block",
                fontWeight: "500",
                marginRight: "55px",
                float: "right"
              }}
            >
              {this.props.data[1]}
            </div>
          </div>
          <div style={{ display: "inline-block", width: "200px" }}>
            Trending/Goal{" "}
            <div
              style={{
                display: "inline-block",
                fontWeight: "500",
                marginRight: "55px",
                float: "right"
              }}
            >
              {this.props.data[2]}%
            </div>
          </div> */}
        </div>
        <br />
        <Container fluid className="bankByProfile">
          <Row>
            <Col xs={3}></Col>
            <Col></Col>
            <Col><small>Traditional</small></Col>
            <Col><small>PayLo</small></Col>
            <Col><small>High Risk</small></Col>
          </Row>
          <Row>
            <Col xs={3}>{this.props.data[3][0]}</Col>
            <Col><strong>{this.props.data[4][0]}</strong></Col>
            <Col>{this.props.data[9][2]}</Col>
            <Col>{this.props.data[9][1]}</Col>
            <Col>{this.props.data[9][0]}</Col>
          </Row>
          <Row>
            <Col xs={3}>{this.props.data[3][2]}</Col>
            <Col><strong>{this.props.data[4][2]}</strong></Col>
            <Col>{this.props.data[9][8]}</Col>
            <Col>{this.props.data[9][7]}</Col>
            <Col>{this.props.data[9][6]}</Col>
          </Row>
          <Row>
            <Col xs={3}>{this.props.data[3][3]}</Col>
            <Col><strong>{this.props.data[4][3]}</strong></Col>
            <Col>{this.props.data[9][11]}</Col>
            <Col>{this.props.data[9][10]}</Col>
            <Col>{this.props.data[9][9]}</Col>
          </Row>
          <Row>
            <Col xs={3}>{this.props.data[3][1]}</Col>
            <Col><strong>{this.props.data[4][1]}</strong></Col>
            <Col>{this.props.data[9][5]}</Col>
            <Col>{this.props.data[9][4]}</Col>
            <Col>{this.props.data[9][3]}</Col>
          </Row>
          <Row>
            <Col xs={3}>{this.props.data[3][4]}</Col>
            <Col><strong>{this.props.data[4][4]}</strong></Col>
            <Col>{this.props.data[9][14]}</Col>
            <Col>{this.props.data[9][13]}</Col>
            <Col>{this.props.data[9][12]}</Col>
          </Row>
          <Row>
            <Col xs={3}>Total</Col>
            <Col><strong>{this.props.data[4][0] + this.props.data[4][1] + this.props.data[4][2] + this.props.data[4][3] + this.props.data[4][4]}</strong></Col>
            <Col><strong>{this.props.data[9][2] + this.props.data[9][8] + this.props.data[9][11] + this.props.data[9][5] + this.props.data[9][14]}</strong></Col>
            <Col><strong>{this.props.data[9][1] + this.props.data[9][7] + this.props.data[9][10] + this.props.data[9][4] + this.props.data[9][13]}</strong></Col>
            <Col><strong>{this.props.data[9][0] + this.props.data[9][6] + this.props.data[9][9] + this.props.data[9][3] + this.props.data[9][12]}</strong></Col>
          </Row>
        </Container>
        {/* <div style={{ fontSize: "0.95rem" }}>
          <div
            style={{
              display: "inline-block",
              width: "200px"
            }}
          >
            {this.props.data[3][0]}{" "}
            <div
              style={{
                display: "inline-block",
                fontWeight: "500",
                marginRight: "55px",
                float: "right"
              }}
            >
              {this.props.data[4][0]}
            </div>
          </div>
          <div style={{ display: "inline-block", width: "200px" }}>
            {this.props.data[3][2]}{" "}
            <div
              style={{
                display: "inline-block",
                fontWeight: "500",
                marginRight: "55px",
                float: "right"
              }}
            >
              {this.props.data[4][2]}
            </div>
          </div>
        </div>
        <div style={{ fontSize: "0.95rem" }}>
          <div
            style={{
              display: "inline-block",
              width: "200px"
            }}
          >
            {this.props.data[3][3]}{" "}
            <div
              style={{
                display: "inline-block",
                fontWeight: "500",
                marginRight: "55px",
                float: "right"
              }}
            >
              {this.props.data[4][3]}
            </div>
          </div>
          <div style={{ display: "inline-block", width: "200px" }}>
            {this.props.data[3][1]}{" "}
            <div
              style={{
                display: "inline-block",
                fontWeight: "500",
                marginRight: "55px",
                float: "right"
              }}
            >
              {this.props.data[4][1]}
            </div>
          </div>
        </div> */}
        {/* <br /> */}
        {/* <div style={{ fontWeight: "500", fontSize: "0.95rem" }}>
          {" "}
          Approvals by Profile
        </div>
        <div style={{ fontSize: "0.95rem" }}>
          <div style={{ display: "inline-block", width: "200px" }}>
            {this.props.data[5][2]}{" "}
            <div
              style={{
                display: "inline-block",
                fontWeight: "500",
                marginRight: "55px",
                float: "right"
              }}
            >
              {this.props.data[6][2]}
            </div>
          </div>
          <div style={{ display: "inline-block", width: "200px" }}>
            {this.props.data[5][1]}{" "}
            <div
              style={{
                display: "inline-block",
                fontWeight: "500",
                marginRight: "55px",
                float: "right"
              }}
            >
              {this.props.data[6][1]}
            </div>
          </div>
        </div>
        <div style={{ fontSize: "0.95rem" }}>
          <div style={{ display: "inline-block", width: "200px" }}>
            {this.props.data[5][0]}{" "}
            <div
              style={{
                display: "inline-block",
                fontWeight: "500",
                marginRight: "55px",
                float: "right"
              }}
            >
              {this.props.data[6][0]}
            </div>
          </div>
          <div style={{ display: "inline-block", width: "200px" }}>
            {this.props.data[5][3]}{" "}
            <div
              style={{
                display: "inline-block",
                fontWeight: "500",
                marginRight: "55px",
                float: "right"
              }}
            >
              {this.props.data[6][3]}
            </div>
          </div>
        </div> */}
        {/* <br />*/}
        {this.props.data[7] !== "" ? (
          <>
          <div style={{ fontWeight: "500", fontSize: "0.85rem", marginTop: "15px" }}>
            Pending by Level
          </div>
          <Container fluid style={{ fontSize: 14 }}>
            <Row>
              <Col xs={3}>{this.props.data[7][0]}: <span style={{fontWeight: 500}}>{this.props.data[8][0]}</span></Col>
              <Col xs={4}>{this.props.data[7][1]}: <span style={{fontWeight: 500}}>{this.props.data[8][1]}</span></Col>
              <Col xs={5}>{this.props.data[7][2]}: <span style={{fontWeight: 500}}>{this.props.data[8][2]}</span></Col>
            </Row>
          </Container>
          </>
        ) : null}
      </div>
    );
  }
}

export default Summary;
