import React, { useEffect, useState } from "react"
import { Form, Button } from "react-bootstrap"
import { ApiClient } from "../ApiClient"
import Table from './components/Table'
import moment from "moment";
import "./Style/Style.scss";

export default function Clawback(props) {

    const CLAWBACK_FIRST_RUN_DATE = moment([2022, 6, 20])

    const editors = ['daniellec@signapay.com', 'marianac@signapay.com']

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [hasError, sethasError] = useState(false)
    const [skipPageReset, setSkipPageReset] = useState(false)
    const [dates, setDates] = useState([])
    const [selectedYear, setSelectedYear] = useState(-1)
    const [selectedMonth, setSelectedMonth] = useState(-1)
    const [selectedDay, setSelectedDay] = useState(-1)

    const getDates = () => {
        let days = []
        let runDate = CLAWBACK_FIRST_RUN_DATE.clone()
        do {
            days.push(runDate)
            runDate = runDate.clone().endOf('month').add(20, 'days').startOf('day')
        } while (runDate <= moment([]))

        setDates(days)
    }

    const donotclawbackCheckbox =  ({
        value: initialValue,
        row: { index },
        column: { id },
      }) => {

        const onChange = (event) => {
            setSkipPageReset(true)
            updateMyData(index, id, event.currentTarget.checked)
            updateDoNotClawback(data[index].mid, data[index].clawback_date, event.currentTarget.checked)
        }

        return (
            <>
            {data[index]["clawback_flag"] === 'CBN' ? 
                <Form.Check
                    custom
                    inline
                    type="checkbox"
                    defaultChecked={initialValue}
                    onChange={(e) => onChange(e)}
                    id={`donotclawback-${index}`}
                />
                :
                <></>
                }
            </>
        )
    }

    const columns =  [
            {
                Header: 'MID',
                accessor: 'mid',
            },
            {
                Header: 'DBA',
                accessor: 'dba_name',
            },
            {
                Header: 'ISO',
                accessor: 'iso_name',
            },
            {
                Header: 'Group ID',
                accessor: 'group_id',
            },
            {
                Header: 'RM',
                accessor: 'rm',
            },
            {
                Header: 'Sales Rep Name',
                accessor: 'sales_rep',
            },
            {
                Header: 'Profile',
                accessor: 'merchant_profile',
            },
            {
                Header: 'Date Opened',
                accessor: (d) => {
                    if (d.open_date) {
                      return moment.utc(d.open_date).format("YYYY-MM-DD");
                    }
                    return ""
                },
                filter: 'date'
            },
            {
                Header: 'Date Closed',
                accessor: (d) => {
                    if (d.close_date) {
                      return moment.utc(d.close_date).format("YYYY-MM-DD");
                    }
                    return ""
                },
                filter: 'date'
            },
            {
                Header: 'Total Volume',
                accessor: 'volume',
            },
            {
                Header: 'Last Deposit Date',
                accessor: (d) => {
                    if (d.last_deposit_date) {
                      return moment.utc(d.last_deposit_date).format("YYYY-MM-DD");
                    }
                    return ""
                }
            },
            {
                Header: 'Volume Threshold Date',
                accessor: (d) => {
                    if (d.volume_threshold_date) {
                      return moment.utc(d.volume_threshold_date).format("YYYY-MM-DD");
                    }
                    return ""
                },
                filter: 'date'
            },
            {
                Header: 'Ownership Type',
                accessor: 'ownership_type',
            },
            {
                Header: 'Equipment Model',
                accessor: 'equipment_model',
            },
            {
                Header: 'Bonus Type',
                accessor: 'bonus_type',
            },
            {
                Header: 'Bonus Date',
                accessor: (d) => {
                    if (d.bonus_date) {
                      return moment.utc(d.bonus_date).format("YYYY-MM-DD");
                    }
                    return ""
                },
                filter: 'date'
            },
            {
                Header: 'Clawback Date',
                accessor: (d) => {
                    if (d.clawback_date) {
                      return moment.utc(d.clawback_date).format("YYYY-MM-DD");
                    }
                    return ""
                },
                filter: 'date'
            },
            {
                Header: 'Clawback Reason',
                accessor: 'clawback_reason',
            },
            {
                Header: 'Bonus',
                accessor: 'bonus_amount',
            },
            {
                Header: 'Paid',
                accessor: (d) => {
                    if (d.do_not_pay) return "No"
                    return "Yes"
                },
            },
            {
                Header: 'Note',
                accessor: 'note',
            },
            {
                Header: 'Do Not Clawback',
                accessor: 'do_not_clawback',
                Cell: donotclawbackCheckbox
            }
        ]

        const updateMyData = (rowIndex, columnId, value) => {

            setSkipPageReset(true)
            sethasError(false)

            const mid = data[rowIndex].mid
    
            setData(old => {
                const newData = old.map((row, index) => {
                            if (row.mid === mid) {
                            return {
                                ...row,
                                [columnId]: value,
                                }
                            }
                            return row
                        })
                return newData
            })
           
        }

    const updateDoNotClawback = (mid, clawback_date, do_not_clawback) => {
        ApiClient.post({
            url: "/api/bonus/UpdateDoNotClawback",
            body: {
                mid: mid,
                clawback_date: clawback_date,
                do_not_clawback: do_not_clawback,
            }
        })
        .then((json) => {
            setSkipPageReset(false)
        }).catch(err => {
            console.log(err);
            setSkipPageReset(false)
            sethasError(true)
        });
    }

    const onSearch = () => {
        setLoading(true)
        ApiClient.post({
            url: "/api/bonus/GetClawback",
            body: {
                date: `${selectedYear}-${String(selectedMonth + 1).padStart(2, 0)}-${selectedDay}`
            }
        })
        .then((json) => {
            setData(json.data)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        });
    }

    const onYearSelect = (value) => {
        setSelectedMonth(-1)
        setSelectedDay(-1)
        setSelectedYear(value)
    }

    const onMonthSelect = (value) => {
        setSelectedDay(dates?.filter(date => date.year() == dates[dates.length - 1].year() && date.month() == dates[dates.length - 1].month()).map(date => date.date())[0])
        setSelectedMonth(value)
    }

    useEffect(() => {
        getDates()
    }, [])

    useEffect(() => {
        if(dates.length == 0) return
        setSelectedYear(dates[dates.length - 1].year())
        setSelectedMonth([...new Set(dates?.filter(date => date.year() == dates[dates.length - 1].year()).map(date => date.month()).reverse())][0])
        setSelectedDay([...new Set(dates?.filter(date => date.year() == dates[dates.length - 1].year() && date.month() == dates[dates.length - 1].month()).map(date => date.date()).reverse())][0])
    }, [dates])

    return (
        <>
        <div className="bonusSearchToolbar">
            <Form inline>
                <Form.Control className="mr-2" as="select" custom disabled={loading} value={selectedYear} onChange={e => onYearSelect(Number(e.target.value))}>
                        <option key={-1} value={-1}></option>
                    {[...new Set(dates?.map(date => date.year()).reverse())].map(y => (
                        <option key={y} value={y}>{y}</option>
                    ))}
                </Form.Control>
                <Form.Control className="mr-2" as="select" custom disabled={loading} value={selectedMonth} onChange={e => onMonthSelect(Number(e.target.value))}>
                        <option key={-1} value={-1}></option>
                    {[...new Set(dates?.filter(date => date.year() == selectedYear).map(date => date.month()).reverse())].map(m => (
                        <option key={m} value={m}>{moment().month(m).format("MMMM")}</option>
                    ))}
                </Form.Control>
                <Button onClick={onSearch} disabled={loading || selectedYear == -1 || selectedMonth == -1 || selectedDay == -1}>Search</Button>
            </Form>
        </div>
        <Table className="BonusTable"
            columns={columns}
            data={data}
            setData={setData}
            // updateMyData={updateMyData}
            skipPageReset={skipPageReset}
            hasError={hasError}
            loading={loading}
            setLoading={setLoading}
        />
        </>
    )
}

