import React, { useEffect, useState } from "react"
import { Button, Table } from "react-bootstrap"
import "../Style/Style.scss"
import { ApiClient } from "../../ApiClient"
import AddEquipmentModal from "./AddEquipmentModal"
import AddExclusiveEquipmentModal from "./AddExclusiveEquipmentModal"

export default function Equipment(props) {

    const [selectedEquipment, setSelectedEquipment] = useState({})
    const [selectedExcludeEquipment, setSelectedExcludeEquipment] = useState({})
    const [addModalShow, setAddModalShow] = useState(false)
    const [addModalEditMode, setAddModalEditMode] = useState(false)
    const [addExcludeModalShow, setAddExcludeModalShow] = useState(false)
    const [addExcludeModalEditMode, setAddExcludeModalEditMode] = useState(false)
    const [BonusEquipmentList, setBonusEquipmentList] = useState([])
    const [exclusiveEquipmentList, setExcludeEquipmentList] = useState([])

    const onEditBonusEquipment = (index) => {
        setAddModalEditMode(true)
        setSelectedEquipment(BonusEquipmentList[index])
        setAddModalShow(true)
    }

    const onEditExclusiveEquipment = (index) => {
        setAddExcludeModalEditMode(true)
        setSelectedExcludeEquipment(exclusiveEquipmentList[index])
        setAddExcludeModalShow(true)
    }

    const onDeleteBonusEquipment = (index) => {

        if(!window.confirm(`Are you sure you want to delete ${BonusEquipmentList[index].title} ?`)) return

        ApiClient.post({
            url: "/api/bonus/DeleteBonusEquipment",
            body: {
                title: BonusEquipmentList[index].title,
            }
        }).then(json => {
            if(!json.data.success){
                window.alert(json.data.message)
                return
            }
            getEquipmentBonusList()
    }).catch(err => {
        });
    }

    const onDeleteExclusiveEquipment = (index) => {

        if(!window.confirm(`Are you sure you want to delete ${exclusiveEquipmentList[index].iso_name} ?`)) return

        const group_id = exclusiveEquipmentList[index].group_id.replaceAll(' ', '').split(',').filter(gid => gid !== null || gid !== "")

        ApiClient.post({
            url: "/api/bonus/DeleteExclusiveEquipmentt",
            body: {
                group_id: group_id,
            }
        }).then(json => {
            if(!json.data.success){
                window.alert(json.data.message)
                return
            }
            getExclusiveEquipmentList()
    }).catch(err => {
        });
    }

    const getEquipmentBonusList = () => {
        ApiClient.get({
            url: "/api/bonus/GetBonusEquipmentList",
        }).then(json => {
            setBonusEquipmentList(json.data);
        }).catch(err => {
        });
    }

    const getExclusiveEquipmentList = () => {
        ApiClient.get({
            url: "/api/bonus/GetExclusiveBonusEquipmentList",
        }).then(json => {
            setExcludeEquipmentList(json.data);
        }).catch(err => {
        });
    }

    useEffect(() => {
        
        getEquipmentBonusList()
        getExclusiveEquipmentList()
        
    }, [])

    return (
        <>
        <div className="equipmentSpecs">
            <div className="bonusEquipment">
                <div className="specsTitle">
                    Equipments
                    <Button variant="secondary" size="sm" className="float-right py-0" onClick={() => setAddModalShow(true)}>&#x002B;</Button>
                </div>
                <div className="bonusEquipmentList">
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Model</th>
                                <th>Amount</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {BonusEquipmentList.map((iso, i) => (
                                <tr key={i}>
                                    <td>
                                        {iso.title}
                                    </td>
                                    <td>
                                        {iso.model}
                                    </td>
                                    <td>
                                        {iso.amount}
                                    </td>
                                    <td className="text-right">
                                        <Button variant="outline-info" size="sm" className="py-0 mr-2 border-0" onClick={() => onEditBonusEquipment(i)}>&#x270E;</Button>
                                        <Button variant="outline-danger" size="sm" className="py-0 border-0" onClick={() => onDeleteBonusEquipment(i)}>&#x2716;</Button>
                                    </td>
                                </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>
            </div>
            <div className="bonusExclusiveEquipment">
                <div className="specsTitle">
                    Exclusive Equipments
                    <Button variant="secondary" size="sm" className="float-right py-0" onClick={() => setAddExcludeModalShow(true)}>&#x002B;</Button>
                </div>
                <div className="bonusEquipmentList">
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>ISO</th>
                                <th>Group ID</th>
                                <th>Name</th>
                                <th>Model</th>
                                <th>Amount</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {exclusiveEquipmentList.map((iso, i) => (
                                <tr key={i}>
                                    <td>
                                        {iso.iso_name}
                                    </td>
                                    <td>
                                        {iso.group_id}
                                    </td>
                                    <td>
                                        {iso.title}
                                    </td>
                                    <td>
                                        {iso.model}
                                    </td>
                                    <td>
                                        {iso.amount}
                                    </td>
                                    <td className="text-right">
                                        <Button variant="outline-info" size="sm" className="py-0 mr-2 border-0" onClick={() => onEditExclusiveEquipment(i)}>&#x270E;</Button>
                                        <Button variant="outline-danger" size="sm" className="py-0 border-0"  onClick={() => onDeleteExclusiveEquipment(i)}>&#x2716;</Button>
                                    </td>
                                </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
        <AddEquipmentModal
            data={selectedEquipment}
            show={addModalShow}
            onHide={() => { setAddModalShow(false); setAddModalEditMode(false); setSelectedEquipment() }}
            onEdit={() => { getEquipmentBonusList(); setSelectedEquipment() }}
            editMode={addModalEditMode}
        />
        <AddExclusiveEquipmentModal
            data={selectedExcludeEquipment}
            show={addExcludeModalShow}
            onHide={() => { setAddExcludeModalShow(false); setAddExcludeModalEditMode(false); setSelectedExcludeEquipment() }}
            onEdit={() => { getExclusiveEquipmentList(); setSelectedExcludeEquipment() }}
            editMode={addExcludeModalEditMode}
        />
        </>
    )
}

