import React, { useState } from "react"
import { Nav } from "react-bootstrap"
import "./Style/Style.scss";
import BonusSpecs from "./components/BonusSpecs";
import ClawbackSpecs from "./components/ClawbackSpecs";
import Equipment from "./components/Equipment";
import ActivationSpecs from "./components/ActivationSpecs";
import ExtraSpecs from "./components/ExtraSpecs";

export default function Specs(props) {
    const [selectedTab, setSelectedTab] = useState('tab-1')

    return (
        <div className="ConfigContainer">
            <Nav className="config-tabs justify-content-start" variant="pills" defaultActiveKey="tab-1" onSelect={(selectedKey) => setSelectedTab(selectedKey)}>
                <Nav.Item>
                    <Nav.Link eventKey="tab-1">Bonus</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="tab-5">Extra</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="tab-2">Clawback</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="tab-3">Activation</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="tab-4">Equipment</Nav.Link>
                </Nav.Item>
            </Nav>
            <div className="configs">
                {selectedTab === 'tab-1' && <>
                    <BonusSpecs />
                </>}
                {selectedTab === 'tab-2' && <>
                    <ClawbackSpecs />
                </>}
                {selectedTab === 'tab-3' && <>
                    <ActivationSpecs />
                </>}
                {selectedTab === 'tab-4' && <>
                    <Equipment />
                </>}
                {selectedTab === 'tab-5' && <>
                    <ExtraSpecs />
                </>}
            </div>
        </div>
    )
}

