import React from 'react'
import { xlsxDownloader } from "../xlsx";
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox'
import PropTypes from 'prop-types'
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TablePaginationActions from './TablePaginationActions'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import InputBase from '@material-ui/core/InputBase'
import TableToolbar from './TableToolbar'
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useFilters,
  state,
} from 'react-table'
import GlobalFilter, { DefaultColumnFilter, fuzzyTextFilterFn } from './GlobalFilter'
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const EnhancedTable = ({
  columns,
  data,
  defaultSorting
}) => {

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const sortees = React.useMemo(
    () => defaultSorting,
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    nextPage,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter },
    state,
    rows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        sortBy: sortees,
        pageSize: 15
      }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,

  )

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setPageSize(Number(event.target.value))
  }

  // Render the UI for your table
  return (
    <TableContainer className="enhanced-table-container">
      {/* <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      /> */}
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()} className="enhanced-table-header-row">
              {headerGroup.headers.map(column => (
                <TableCell className="enhanced-table-header">
                  <div {...(column.getHeaderProps({ ...column.getSortByToggleProps() }))} className={`enhanced-table-header-cell ${column.className}`}>
                    {/* <TableSortLabel
                      active={column.isSorted}
                      direction={column.isSortedDesc ? 'desc' : 'asc'}
                      hideSortIcon={false}
                      IconComponent={UnfoldMoreIcon}
                    >
                      {column.render('Header')}
                    </TableSortLabel> */}
                    {column.render('Header')}
                    <span style={{ color: '#999999' }}>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <KeyboardArrowDownIcon style={{ width: 20, height: 20 }} />
                          : <KeyboardArrowUpIcon style={{ width: 20, height: 20 }} />
                        : <UnfoldMoreIcon style={{ width: 20, height: 20 }} />}
                    </span>
                  </div>
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <TableRow {...row.getRowProps()} className="enhanced-table-body-row">
                {row.cells.map(cell => {
                  return (
                    <TableCell {...cell.getCellProps([
                      {
                        className: cell.column.className,
                        style: cell.column.style,
                      },
                    ])}>
                      {cell.render('Cell')}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
        <div className="enhanced-table-footer">
          <TableRow>
            <div className="enhanced-table-footer-container">
              <div className='enhanced-table-export'>
                <Button variant="outlined" color="primary" onClick={() => xlsxDownloader(data, 'Partners', `Partners.xlsx`)}>
                  Export
                </Button>
              </div>
              <div className="enhanced-table-pagination">
                <TablePagination
                  rowsPerPageOptions={[
                    15,
                    25,
                    50,
                    { label: 'All', value: rows.length },
                  ]}
                  colSpan={columns.length}
                  count={rows.length}
                  rowsPerPage={pageSize}
                  page={pageIndex}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </div>
            </div>
          </TableRow>
        </div>
      </MaUTable>
    </TableContainer>
  )
}

EnhancedTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
}

export default EnhancedTable
