import React, { useEffect, useState, useRef, useMemo } from "react"
import { Form, Button, Modal } from "react-bootstrap"
import { ApiClient } from "../ApiClient"
import Table from './components/Table'
import moment from "moment";
import "./Style/Style.scss";
import MatchModal from "./components/MatchModal"


export default function Bonus(props) {

    const WEEKLY_FIRST_RUN_DATE = moment([2022, 4, 26])
    const MONTHLY_FIRST_RUN_DATE = moment([2022, 5, 21])
    const MONTHLY_EXTRA_FIRST_RUN_DATE = moment([2023, 5, 1])

    const editors = ['daniellec@signapay.com', 'marianac@signapay.com']

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [hasError, sethasError] = useState(false)
    const [skipPageReset, setSkipPageReset] = useState(false)
    const [selectedPeriod, setSelectedPeriod] = useState('W')
    const [dates, setDates] = useState([])
    const [selectedYear, setSelectedYear] = useState()
    const [selectedMonth, setSelectedMonth] = useState()
    const [selectedDay, setSelectedDay] = useState()
    const [showNoteModal, setShowNoteModal] = useState(false)
    const [showMatchModal, setShowMatchModal] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState()
    const [matches, setMatches] = useState([])
    const [selectedMatch, setSelectedMatch] = useState([])
    const note = useRef()
    

    const getDates = (period) => {
        let days = []
        if(period === 'W') {
            let runDate = WEEKLY_FIRST_RUN_DATE.clone()
            do {
                days.push(runDate)
                runDate = runDate.clone().add(1, 'weeks')
            } while (runDate <= moment([]))
        }
        if(period === 'M') {
            let runDate = MONTHLY_FIRST_RUN_DATE.clone()
            do {
                days.push(runDate)
                runDate = runDate.clone().endOf('month').add(21, 'days').startOf('day')
            } while (runDate <= moment([]))
        }
        if(period === 'ME') {
            let runDate = MONTHLY_EXTRA_FIRST_RUN_DATE.clone()
            do {
                days.push(runDate)
                runDate = runDate.clone().endOf('month').add(1, 'days').startOf('day')
            } while (runDate <= moment([]))
        }
        setDates(days)
    }

    const donotpayCheckbox =  ({
        value: initialValue,
        row: { index },
        column: { id },
      }) => {

        const onChange = (event) => {
        setSkipPageReset(true)
        updateMyData(index, id, event.currentTarget.checked)
            updateDoNotPayBonus(data[index].id, event.currentTarget.checked)
        }

        return (
            <Form.Check
                custom
                inline
                type="checkbox"
                defaultChecked={initialValue}
                onChange={(e) => onChange(e)}
                id={`donotpay-${index}`}
            />
        )
    }

    const noteField =  ({
        value: initialValue,
        row: { index },
        column: { id },
      }) => {

        const onClickHandle = () => {
        setSkipPageReset(true)
        setSelectedIndex(index)
            setShowNoteModal(true)
        }

        return (
            <>
                <div className="d-flex  align-items-center">
                    <input type="button" className="bonusNoteButton" onClick={() => onClickHandle()} value="&#128456;" />
                    <pre className="bonusNote">{initialValue}</pre>
                </div>
            </>
        )
    }

    const matchWarning = ({
        value: initialValue,
        row: { index },
        column: { id },
      }) => {

        const mids = matches.find(match => match.includes(data[index]?.mid))

        const onClickHandle = () => {
            setSelectedIndex(index)
            setSelectedMatch(mids)
            setShowMatchModal(true)
        }

        return (
            mids?.length > 0 ? <input type="button" className="bonusWarningButton" onClick={() => onClickHandle()} value="&#x26A0;" /> : ''
        )
    }

    const columns = [
            {
                Header: ' ',
                filter: 'no-filter',
                width: 5,
                Cell: matchWarning,
            },
            {
                Header: 'MID',
                accessor: 'mid',
            },
            {
                Header: 'DBA',
                accessor: 'dba_name',
            },
            {
                Header: 'ISO',
                accessor: 'iso_name',
            },
            {
                Header: 'Group ID',
                accessor: 'group_id',
            },
            {
                Header: 'RM',
                accessor: 'rm',
            },
            {
                Header: 'Sales Rep Name',
                accessor: 'sales_rep',
            },
            {
                Header: 'Profile',
                accessor: 'merchant_profile',
            },
            {
                Header: 'Date Opened',
                accessor: (d) => {
                    if (d.open_date) {
                      return moment.utc(d.open_date).format("YYYY-MM-DD");
                    }
                    return ""
                },
                filter: 'date'
            },
            {
                Header: 'Ownership Type',
                accessor: 'ownership_type',
            },
            {
                Header: 'Deployment Status',
                accessor: 'placement_status',
            },
            {
                Header: 'Equipment Model',
                accessor: 'equipment_model',
            },
            {
                Header: 'Bonus Date',
                accessor: (d) => {
                    if (d.bonus_date) {
                      return moment.utc(d.bonus_date).format("YYYY-MM-DD");
                    }
                    return ""
                },
                filter: 'date'
            },
            {
                Header: 'Bonus',
                accessor: 'bonus_amount',
            },
            {
                Header: 'Do Not Pay',
                accessor: 'do_not_pay',
                Cell: donotpayCheckbox
            },
            {
                Header: 'Note',
                accessor: 'note',
                Cell: noteField
            },             
            {
                Header: "Last Edit",
                accessor: (d) => {
                    return <i size="sm">
                            {d.worked_datetime && moment.utc(d.worked_datetime).local().format("YYYY-MM-DD")}<br />
                            {d.worked_by}
                        </i>
                    
                },
                visible: false
            }
        ]
    
    const extra_columns = [
            {
                Header: 'MID',
                accessor: 'mid',
            },
            {
                Header: 'DBA',
                accessor: 'dba_name',
            },
            {
                Header: 'ISO',
                accessor: 'iso_name',
            },
            {
                Header: 'Group ID',
                accessor: 'group_id',
            },
            {
                Header: 'RM',
                accessor: 'rm',
            },
            {
                Header: 'Sales Rep Name',
                accessor: 'sales_rep',
            },
            {
                Header: 'Profile',
                accessor: 'merchant_profile',
            },
            {
                Header: 'Date Opened',
                accessor: (d) => {
                    if (d.open_date) {
                      return moment.utc(d.open_date).format("YYYY-MM-DD");
                    }
                    return ""
                },
                filter: 'date'
            },
            {
                Header: 'Bonus Date',
                accessor: (d) => {
                    if (d.bonus_date) {
                      return moment.utc(d.bonus_date).format("YYYY-MM-DD");
                    }
                    return ""
                },
                filter: 'date'
            },
            {
                Header: 'Bonus Type',
                accessor: 'bonus_type',
            },
            {
                Header: 'Extra Bonus',
                accessor: 'bonus_amount',
            },
            {
                Header: 'Reg. Bonus Paid',
                accessor: (d) => {
                    if (d.do_not_pay) return "No"
                    return d.bonus_type === "Per Deal" ? "Yes" : ""
                },
            },
            {
                Header: 'Note',
                accessor: 'note',
            },
        ]

    const updateMyData = (rowIndex, columnId, value) => {

        setSkipPageReset(true)
        sethasError(false)

        setData(old => {
            const newData = old.map((row, index) => {
                        if (index === rowIndex) {
                        return {
                            ...old[rowIndex],
                            [columnId]: value,
                            }
                        }
                        return row
                    })
            return newData
        })
       
    }

    const updateDoNotPayBonus = (id, value) => {
        ApiClient.post({
            url: "/api/bonus/UpdateDoNotPayBonus",
            body: {
                id:id,
                do_not_pay: value,
            }
        })
        .then((json) => {
            setSkipPageReset(false)
        }).catch(err => {
            console.log(err);
            setSkipPageReset(false)
            sethasError(true)
        });
    }

    const updateBonusNote = (id, value) => {
        ApiClient.post({
            url: "/api/bonus/UpdateBonusNote",
            body: {
                id:id,
                note: value,
            }
        })
        .then((json) => {
            setSkipPageReset(false)
        }).catch(err => {
            console.log(err);
            setSkipPageReset(false)
            sethasError(true)
        });
    }

    const onNoteSave = () => {
        updateMyData(selectedIndex, 'note', note.current.value)
        updateBonusNote(data[selectedIndex].id, note.current.value)
        setShowNoteModal(false)
    }

    const onSearch = () => {
        setLoading(true)
        if(selectedPeriod === 'ME') {
            ApiClient.post({
                url: "/api/bonus/GetExtraBonus",
                body: {
                    date: `${selectedYear}-${String(selectedMonth + 1).padStart(2, 0)}-${selectedDay}`
                }
            })
            .then((json) => {
                setData(json.data)
                setLoading(false)
            }).catch(err => {
                setLoading(false)
            });
            return
        }

        ApiClient.post({
            url: "/api/bonus/GetBonus",
            body: {
                bonusType: selectedPeriod,
                date: `${selectedYear}-${String(selectedMonth + 1).padStart(2, 0)}-${selectedDay}`
            }
        })
        .then((json) => {
            setData(json.data)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        });
    }

    const getMatches = () => {
        ApiClient.get({
            url: "/api/bonus/GetMatches"
        })
        .then((json) => {
            setMatches(json.data.map(data => data.matches.split(',')))
        }).catch(err => {
        });
    }

    const onPeriodSelect = (value) => {
        setData([])
        setSelectedYear(-1)
        setSelectedMonth(-1)
        setSelectedDay(-1)
        getDates(value)
        setSelectedPeriod(value)
    }

    const onYearSelect = (value) => {
        setSelectedMonth(-1)
        if(selectedPeriod === 'W') setSelectedDay(-1)
        setSelectedYear(value)
    }

    const onMonthSelect = (value) => {
        if(selectedPeriod === 'W') setSelectedDay(-1)
        setSelectedMonth(value)
    }

    const onDaySelect = (value) => {
        setSelectedDay(value)
    }

    useEffect(() => {
        setSelectedYear(-1)
        setSelectedMonth(-1)
        setSelectedDay(-1)
        getDates('W')
    }, [])

    useEffect(() => {
        if(data.length == 0) return
        getMatches()
    }, [data])

    useEffect(() => {
        if(dates.length == 0) return
        setSelectedYear(dates[dates.length - 1].year())
        setSelectedMonth([...new Set(dates?.filter(date => date.year() == dates[dates.length - 1].year()).map(date => date.month()).reverse())][0])
        setSelectedDay([...new Set(dates?.filter(date => date.year() == dates[dates.length - 1].year() && date.month() == dates[dates.length - 1].month()).map(date => date.date()).reverse())][0])
    }, [dates])


    return (
        <>
        <div className="bonusSearchToolbar">
            <Form inline>
                <Form.Control className="mr-2" as="select" custom disabled={loading} value={selectedPeriod} onChange={e => onPeriodSelect(e.target.value)}>
                    <option value="W" key="W">Weekly</option>
                    <option value="M" key="M">Monthly</option>
                    <option value="ME" key="ME">Monthly Extra</option>
                </Form.Control>
                <Form.Control className="mr-2" as="select" custom disabled={loading} value={selectedYear} onChange={e => onYearSelect(Number(e.target.value))}>
                        <option key={-1} value={-1}></option>
                    {[...new Set(dates?.map(date => date.year()).reverse())].map(y => (
                        <option key={y} value={y}>{y}</option>
                    ))}
                </Form.Control>
                <Form.Control className="mr-2" as="select" custom disabled={loading} value={selectedMonth} onChange={e => onMonthSelect(Number(e.target.value))}>
                        <option key={-1} value={-1}></option>
                    {[...new Set(dates?.filter(date => date.year() == selectedYear).map(date => date.month()).reverse())].map(m => (
                        <option key={m} value={m}>{moment().month(m).format("MMMM")}</option>
                    ))}
                </Form.Control>
                {selectedPeriod === 'W' && <Form.Control className="mr-2" as="select" custom disabled={loading} value={selectedDay} onChange={e => onDaySelect(Number(e.target.value))}>
                        <option key={-1} value={-1}></option>
                    {[...new Set(dates?.filter(date => date.year() == selectedYear && date.month() == selectedMonth).map(date => date.date()).reverse())].map(d => (
                        <option key={d} value={d}>{d}</option>
                    ))}
                </Form.Control>}
                <Button onClick={onSearch} disabled={loading || selectedYear == -1 || selectedMonth == -1 || selectedDay == -1}>Search</Button>
            </Form>
        </div>
        <Table className="BonusTable"
            columns={selectedPeriod === 'ME' ? extra_columns : columns}
            data={data}
            setData={setData}
            // updateMyData={updateMyData}
            skipPageReset={skipPageReset}
            hasError={hasError}
            loading={loading}
            setLoading={setLoading}
        />
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showNoteModal}
            onHide={() => setShowNoteModal(false)}
        >
            <Modal.Body>
                <Form.Control as="textarea" rows={3} ref={note} defaultValue={data[selectedIndex]?.note} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => onNoteSave()}>Save</Button>
            </Modal.Footer>
        </Modal>
        <MatchModal
            data={data[selectedIndex]}
            mids={selectedMatch}
            show={showMatchModal}
            onHide={() => {setShowMatchModal(false); setSelectedMatch([]); setSelectedIndex()}}
        />
        </>
    )
}

