import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Auth } from "../Auth";
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuIcon from '@material-ui/icons/Menu';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import TableChartIcon from '@material-ui/icons/TableChart';
import BusinessIcon from '@material-ui/icons/Business';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import BarChartIcon from '@material-ui/icons/BarChart';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PeopleIcon from '@material-ui/icons/People';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import BackupIcon from '@material-ui/icons/Backup';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import SpeedIcon from '@material-ui/icons/Speed';
import "./Style/menu.scss";
import logo from '../LoginAndChangePassword/Style/PayLoSignaPay.png'
import whiteLogo from './Style/SignaPay-White.png'

export default function Menubar(props) {
  const [open, setOpen] = useState(false)

  const list = () => {
    const { cookies } = props;
    const dept = cookies.get("dept");
    const email = cookies.get("email");

    const bi_whitelist = ["johnm@signapay.com", "brianm@signapay.com", "chaitanyav@signapay.com", "bahramb@signapay.com"];
    const leads_whitelist = ["rashai@signapay.com", "johnm@signapay.com", "luism@signapay.com", "rashai@signapay.com", "bahramb@signapay.com", "mattn@signapay.com", "pault@signapay.com", "shaquirahj@signapay.com", "mayrac@signapay.com", "seanm@signapay.com"];

    return <div
      style={{ width: "300px" }}
      role="presentation"
      onClick={() => setOpen(false)}
      onKeyDown={() => setOpen(false)}
    >
      <List className="text-center">
        <img src={logo} className="mt-2" width="200px" style={{ marginBottom: "20px" }} />
        <Divider />
        {bi_whitelist.includes(email) &&
          <ListItem button key={0} component={Link} to="/bi">
            <ListItemIcon><BubbleChartIcon /></ListItemIcon>
            <ListItemText primary={"Business Intelligence"} />
          </ListItem>
        }
        <ListItem button key={1} component={Link} to="/sales">
          <ListItemIcon><MonetizationOnIcon /></ListItemIcon>
          <ListItemText primary={"Sales"} />
        </ListItem>
        {leads_whitelist.includes(email) &&
          <ListItem button key={2} component={Link} to="/leads">
            <ListItemIcon><ContactMailIcon /></ListItemIcon>
            <ListItemText primary={"Leads"} />
          </ListItem>
        }
        {/* <ListItem button key={3} component={Link} to="/financial">
          <ListItemIcon><TableChartIcon /></ListItemIcon>
          <ListItemText primary={"Analytics"} />
        </ListItem> */}
        {/* <ListItem button key={4} component={Link} to="/isoanalytics">
          <ListItemIcon><BusinessIcon /></ListItemIcon>
          <ListItemText primary={"ISO Analytics"} />
        </ListItem> */}
        <ListItem button key={4} component={Link} to="/isoperformance">
          <ListItemIcon><SpeedIcon /></ListItemIcon>
          <ListItemText primary={"ISO Performance"} />
        </ListItem>
        {/* <ListItem button key={5} component={Link} to="/salescalculator">
          <ListItemIcon><KeyboardIcon /></ListItemIcon>
          <ListItemText primary={"Sales Calculator"} />
        </ListItem> */}
        <ListItem button key={6} component={Link} to="/processing">
          <ListItemIcon><BarChartIcon /></ListItemIcon>
          <ListItemText primary={"Processing"} />
        </ListItem>
        <ListItem button key={7} component={Link} to="/leaderboard">
          <ListItemIcon><AssignmentIcon /></ListItemIcon>
          <ListItemText primary={"Leaderboard"} />
        </ListItem>
        <ListItem button key={8} component={Link} to="/partner">
          <ListItemIcon><PeopleIcon /></ListItemIcon>
          <ListItemText primary={"Partners"} />
        </ListItem>
        <ListItem button key={9} component={Link} to="/selfserve">
          <ListItemIcon><TouchAppIcon /></ListItemIcon>
          <ListItemText primary={"Self Serve"} />
        </ListItem>
        <ListItem button key={19} component={Link} to="/residualselfserve">
          <ListItemIcon><TouchAppIcon /></ListItemIcon>
          <ListItemText primary={"Residual Self Serve"} />
        </ListItem>
        <ListItem button key={10} component={Link} to="/ach">
          <ListItemIcon><AccountBalanceIcon /></ListItemIcon>
          <ListItemText primary={"ACH Returns"} />
        </ListItem>
        <ListItem button key={11} component={Link} to="/pci">
          <ListItemIcon><VerifiedUserIcon /></ListItemIcon>
          <ListItemText primary={"PCI Compliance"} />
        </ListItem>
        <ListItem button key={12} component={Link} to="/bonus">
          <ListItemIcon><LocalAtmIcon /></ListItemIcon>
          <ListItemText primary={"Bonus & Clawback"} />
        </ListItem>
        {/* <ListItem button key={13} component={Link} to="/export">
          <ListItemIcon><PresentToAllIcon /></ListItemIcon>
          <ListItemText primary={"Export"} />
        </ListItem> */}
        <ListItem button key={14} component={Link} to="/changePassword">
          <ListItemIcon><LockOpenIcon /></ListItemIcon>
          <ListItemText primary={"Change Password"} />
        </ListItem>
        {dept === "admin" &&
          <ListItem button key={15} component={Link} to="/register">
            <ListItemIcon><PersonAddIcon /></ListItemIcon>
            <ListItemText primary={"Register"} />
          </ListItem>
        }
        {/* {(dept === "admin" || dept === "finance_admin" || dept === "sales_admin") &&
          <ListItem button key={16} component={Link} to="/uploadfiles">
          <ListItemIcon><BackupIcon /></ListItemIcon>
            <ListItemText primary={"Upload Data"} />
          </ListItem>
        } */}
        <ListItem button key={17}
          onClick={() =>
            Auth.signOut().then(() => {
              props.cookies.remove("login");
              props.cookies.remove("email");
              props.cookies.remove("dept");
              props.history.push("/login");
            })
          }
        >
          <ListItemIcon><ExitToAppIcon /></ListItemIcon>
          <ListItemText primary={"Logout"} />
        </ListItem>
      </List>
    </div>
  }

  return (
    <>
      <div className='menu'>
        <Button onClick={() => setOpen(true)} style={{ backgroundColor: 'transparent', marginTop: "7px" }}>
          <MenuIcon style={{ color: "white" }} />
        </Button>
        <img src={whiteLogo} className="mt-2" width="150px" />
      </div>
      <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
        {list()}
      </Drawer>
    </>
  );
}
