import React, { useEffect, useState } from "react"
import { withCookies } from "react-cookie"
import "./Style/Style.scss";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import moment from "moment";
import TopTable from "./TopTable";
import EnhancedTable from './Components/EnhancedTable'
import GlobalFilter, { DefaultColumnFilter, NumberRangeColumnFilter, SelectColumnFilter, SliderColumnFilter } from './Components/GlobalFilter'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ApiClient } from "../ApiClient"
import LinearProgress from "@material-ui/core/LinearProgress";

function Index(props) {

    const [partners, setPartners] = React.useState([])
    const [statuses, setStatuses] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [selectedDate, setSelectedDate] = React.useState(moment().startOf("month"))

    const columns = React.useMemo(
        () => [
            {
                Header: 'Partner',
                accessor: 'partner',
                filter: 'fuzzyText',
                style: {
                    width: 350,
                },
            },
            {
                Header: 'Age/Date Signed',
                accessor: (d) => {
                    if (!!d.date_signed)
                        return Math.round((moment().diff(d.date_signed, 'years', true) + Number.EPSILON) * 10) / 10;
                    else
                        return ''
                },
                Cell: ({ cell }) => {
                    const dateSigned = cell.row.original.date_signed
                    if (!dateSigned) return ''
                    return <div style={{display: 'flex', alignItems: 'center', placeContent: 'center', columnGap: '3px'}}>
                        <span>{Math.round((moment().diff(dateSigned, 'years', true) + Number.EPSILON) * 10) / 10}</span>
                        <span style={{color: "#cccccc"}}>/</span>
                        <span style={{fontSize: 11, color: "#666666"}}>{dateSigned.slice(0, 10)}</span>
                    </div>
                },
                Filter: NumberRangeColumnFilter,
                filter: 'between',
                className: 'iso-table-header-center',
                style: {
                    textAlign: 'center',
                },
            },
            {
                Header: 'RM',
                accessor: 'rm',
                filter: 'equals',
                Filter: SelectColumnFilter,
                className: 'iso-table-header-center',
                style: {
                    textAlign: 'center',
                },
            },
            {
                Header: 'Days Since Last App',
                accessor: 'days_since_last_app',
                Filter: NumberRangeColumnFilter,
                filter: 'between',
                className: 'iso-table-header-center',
                style: {
                    textAlign: 'center',
                },
            },
            {
                Header: `${selectedDate.format('MMM')} Approvals`,
                accessor: 'approvals_this_month',
                Filter: NumberRangeColumnFilter,
                filter: 'between',
                className: 'iso-table-header-center',
                style: {
                    textAlign: 'center',
                    fontWeight: 'bold'
                },
            },
            {
                Header: `${selectedDate.clone().subtract(1, "months").format('MMM')} Approvals`,
                accessor: 'approvals_last_month',
                Filter: NumberRangeColumnFilter,
                filter: 'between',
                className: 'iso-table-header-center',
                style: {
                    textAlign: 'center',
                },
            },
            {
                Header: `${selectedDate.format('YYYY')} Approvals`,
                accessor: 'approvals_this_year',
                Filter: NumberRangeColumnFilter,
                filter: 'between',
                className: 'iso-table-header-center',
                style: {
                    textAlign: 'center',
                },
            },
            {
                Header: `${selectedDate.clone().startOf("year").subtract(1, "days").format('YYYY')} Approvals`,
                accessor: 'approvals_last_year',
                Filter: NumberRangeColumnFilter,
                filter: 'between',
                className: 'iso-table-header-center',
                style: {
                    textAlign: 'center',
                },
            },
        ],
        [selectedDate]
    )

    const onArrowClick = (increment) => {
        if (increment === 1 && moment().startOf("month").diff(selectedDate, "months") <= 0) return
        setSelectedDate(old => old.clone().add(increment, 'months').startOf("month"))
    }

    useEffect(() => {
        setLoading(true)
        Promise.all(
            [ApiClient.post({
                url: "/api/iso/GetPartners",
                body: {
                    today: moment().format('YYYY-MM-DD'),
                    this_month_start: selectedDate.clone().startOf("month").format('YYYY-MM-DD'),
                    this_month_end: selectedDate.clone().endOf("month").format('YYYY-MM-DD'),
                    last_month_end: selectedDate.clone().startOf("month").subtract(1, "days").format('YYYY-MM-DD'),
                    last_month_start: selectedDate.clone().startOf("month").subtract(1, "months").startOf('month').format('YYYY-MM-DD'),
                    this_year_start: selectedDate.clone().startOf("year").format('YYYY-MM-DD'),
                    this_year_end: selectedDate.clone().endOf("year").format('YYYY-MM-DD'),
                    last_year_start: selectedDate.clone().startOf("year").subtract(1, "days").startOf("year").format('YYYY-MM-DD'),
                    last_year_end: selectedDate.clone().startOf("year").subtract(1, "days").format('YYYY-MM-DD'),
                }
            }),
            ApiClient.post({
                url: "/api/iso/GetPartnersStatus",
                body: {
                    this_month_start: selectedDate.clone().startOf("month").format('YYYY-MM-DD'),
                    this_month_end: selectedDate.clone().endOf("month").format('YYYY-MM-DD'),
                    last_90D_end: selectedDate.clone().startOf("month").subtract(1, "days").format('YYYY-MM-DD'),
                    last_90D_start: selectedDate.clone().startOf("month").subtract(3, "months").startOf('month').format('YYYY-MM-DD'),
                }
            })]).then(results => {
                setPartners(results[0].data)
                setStatuses(results[1].data)
            }).catch(e => {
                console.log(e)
            }).finally(() => setLoading(false))

    }, [selectedDate])


    return (
        <div className="iso-container">
            {loading && <div className="iso-spinner-overlay">
                <div className="iso-spinner-progress">
                    <LinearProgress />
                </div>
            </div>}
            <div className="iso-month-select">
                <ButtonGroup aria-label="prev-next">
                    <Button className="navButton iso-nav-button" onClick={() => onArrowClick(-1)}>
                        <i className="material-icons">arrow_back_ios</i>
                    </Button>
                    <h3 className="iso-nav-month">
                        <input
                            type="month"
                            className="month-select"
                            value={selectedDate.format("YYYY-MM")}
                            min={"2019-01"}
                            max={moment().format("YYYY-MM")}
                            onChange={(e) => setSelectedDate(moment(`${e.target.value || moment().startOf("month").format("YYYY-MM")}-01`))}
                        />
                    </h3>
                    <Button className="navButton iso-nav-button" onClick={() => onArrowClick(1)}>
                        <i className="material-icons">arrow_forward_ios</i>
                    </Button>
                </ButtonGroup>
            </div>
            <div className="top-table">
                <TopTable selectedDate={selectedDate.format('YYYY-MM-DD')} statuses={statuses} />
            </div>
            <div className="main-table-container">
                <div className="main-table">
                    <CssBaseline />
                    <EnhancedTable
                        className='enhanced-table'
                        columns={columns}
                        data={partners}
                        defaultSorting={[{ id: 'approvals_this_year', desc: true }]}
                    />

                </div>
            </div>
        </div>
    )
}

export default withCookies(Index)
