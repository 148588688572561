import axios from "axios";

const axiosInstance = axios.create({
  withCredentials: true
});

const HOSTNAME = `${process.env.REACT_APP_HOSTNAME}:${process.env.REACT_APP_SERVERPORT}`;

const post = async ({url, body = {}, headers = {}}) => {
  return axiosInstance.post(HOSTNAME + url, body, headers);
};

const get = async ({url}) => {
  return axiosInstance.get(HOSTNAME + url);
};

export const ApiClient = {
  post: post,
  get: get
};
