//Created by Katherine Hartman
//Handles the rendering and data retrieval of the financial dashboard.

import React, { Component } from "react";
import { Card, Tabs, Tab } from "react-bootstrap";
import moment from "moment";
import { withCookies } from "react-cookie";
import Loader from "react-loader-spinner";
import Select, { components } from 'react-select';
import VGraph from "./graphs/VGraph";
import TGraph from "./graphs/TGraph";
import IGraph from "./graphs/IGraph";
import VDoughnut from "./graphs/VDoughnut";
import "./Style/finance.scss";
import { ApiClient } from "../ApiClient";

class Financial extends Component {
  constructor() {
    super();
    this.state = {
      davg: "12 Month",
      gselected: null,
      groups: [],
      selectedOptions: [],
      bankOptions: [],
      partnerOptions: [],
      mccOptions: [],
      profileOptions: [],
      omonthOptions: [],
      oyearOptions: [],
      graph: [],
      savolume: 0,
      satransactions: 0,
      saincome: 0,
      saage: 0,
      avolume: 0,
      atransactions: 0,
      aincome: 0,
      aage: 0,
      where: "",
      loaded: false,
      time: {
        min: moment().subtract(5, 'months'),
        max: moment().subtract(1, 'months')
      },
    };
    if (typeof(Storage) !== "undefined"){
      if(sessionStorage.getItem("selectedOptions")){
        try{
          this.state.selectedOptions = JSON.parse(sessionStorage.getItem("selectedOptions"));
        }
        catch (error){
          console.log(sessionStorage.getItem("selectedOptions"));
          sessionStorage.removeItem("selectedOptions");
        }
      }
      else{
        console.log("no options found");
      }
    }
    else{
      console.log("storage not supported");
    }
    this.getInfo();
  }

  //gets data from the server to display on the graphs
  getInfo = () => {
    console.log("getting info");
    ApiClient
      .get({
        url: "/api/finoptions"
      })
      .then(res => {
        var profileo = (res.data.profile.map(val => val.Merchant_Profile));
        var banko = (res.data.bank.map(val => val.Bank));
        var mcco = (res.data.mcc.map(val => val.MCC));
        var mccd = (res.data.descript.map(val => val.Code + " - " + val.Description));
        var partnero = (res.data.partner.map(val => val.Partner_Name));
        var mido = (res.data.mid.map(val => val.MID));
        var dbao = (res.data.mid.map(val => val.MID + " - " + val.DBA));
        var omontho = (res.data.omonth.map(val => val.open_month));
        var oyearo = (res.data.oyear.map(val => val.open_year));

        var mcc = [];
        for (var i = 0; i < mcco.length; i++){
          for (var j = 0; j < mccd.length; j++){
            if (res.data.descript[j].Code === mcco[i]){
              mcc.push(mccd[j]);
              break;
            }
          }
        }
        omontho.sort(function(a, b){return a-b});
        oyearo.sort(function(a, b){return a-b});

        this.setState({
          profileOptions: profileo,
          bankOptions: banko,
          mccOptions: mcc,
          partnerOptions: partnero,
          midOptions: mido,
          dbaOptions: dbao,
          omonthOptions: omontho,
          oyearOptions: oyearo

        });
        var bank = [];
        for (i = 0 ; i < this.state.bankOptions.length; i++){
          if (this.state.bankOptions[i] === "NULL"){
            bank.push({label: "Not Specified", value: this.state.bankOptions[i]});
          }
          else{
            bank.push({label: this.state.bankOptions[i], value: this.state.bankOptions[i]});
          }
        }
        var profile = [];
        for (i = 0 ; i < this.state.profileOptions.length; i++){
          if (this.state.profileOptions[i] === "NULL"){
            profile.push({label: "Not Specified", value: this.state.profileOptions[i]});
          }
          else{
            profile.push({label: this.state.profileOptions[i], value: this.state.profileOptions[i]});
          }
        }
        mcc = [];
        for (i = 0 ; i < this.state.mccOptions.length; i++){
          if (this.state.mccOptions[i] === "NULL"){
            mcc.push({label: "Not Specified", value: this.state.mccOptions[i]});
          }
          else{
            mcc.push({label: this.state.mccOptions[i], value: this.state.mccOptions[i]});
          }
        }
        var partner = [];
        for (i = 0 ; i < this.state.partnerOptions.length; i++){
          if (this.state.partnerOptions[i] === "NULL"){
            partner.push({label: "Not Specified", value: this.state.partnerOptions[i]});
          }
          else{
            partner.push({label: this.state.partnerOptions[i], value: this.state.partnerOptions[i]});
          }
        }
        var mid = [];
        for (i = 0 ; i < this.state.midOptions.length; i++){
          if (this.state.midOptions[i] === "NULL"){
            mid.push({label: "Not Specified", value: this.state.midOptions[i]});
          }
          else{
            mid.push({label: this.state.dbaOptions[i], value: this.state.midOptions[i]});
          }
        }
        var monthname = ["January","February","March","April","May","June","July","August","September","October","November","December"]
        var omonth = [];
        for (i = 0 ; i < this.state.omonthOptions.length; i++){
          if (this.state.omonthOptions[i] === null){
            omonth.push({label: "Not Specified", value: this.state.omonthOptions[i]});
          }
          else{
            omonth.push({label: monthname[this.state.omonthOptions[i]-1], value: this.state.omonthOptions[i]});
          }
        }
        var oyear = [];
        for (i = 0 ; i < this.state.oyearOptions.length; i++){
          if (this.state.oyearOptions[i] === null){
            oyear.push({label: "Not Specified", value: this.state.oyearOptions[i]});
          }
          else{
            oyear.push({label: this.state.oyearOptions[i], value: this.state.oyearOptions[i]});
          }
        }
        var group = [
          {
            label: "Profile",
            options: profile
          },
          {
            label: "Bank",
            options: bank
          },
          {
            label: "Open Month",
            options: omonth
          },
          {
            label: "Open Year",
            options: oyear
          },
          {
            label: "MCC",
            options: mcc
          },
          {
            label: "Partner",
            options: partner
          }
          // ,
          // {
          //   label: "Merchant",
          //   options: mid
          // }
        ]
        this.setState({
          loaded: true,
          groups: group
        });
        this.getData(this.state.selectedOptions,moment().startOf('month').subtract(12, 'months').format("YYYY-MM-DD"));
    }).catch(err => {
      console.log(err);
    });
  };

  getData = (selectedOptions, date) => {
    console.log("reached getData");
    var pr = [];
    var ba = [];
    var mc = [];
    var pa = [];
    var mid = [];
    var om = [];
    var oy = [];

    if (selectedOptions){
      for (var i = 0; i < selectedOptions.length; i++){
        if (this.state.profileOptions.includes(selectedOptions[i].value)) {
          pr.push(selectedOptions[i].value);
        }
        else if (this.state.bankOptions.includes(selectedOptions[i].value)) {
          ba.push(selectedOptions[i].value);
        }
        else if (this.state.omonthOptions.includes(selectedOptions[i].value)) {
          om.push(selectedOptions[i].value);
        }
        else if (this.state.oyearOptions.includes(selectedOptions[i].value)) {
          oy.push(selectedOptions[i].value);
        }
        else if (this.state.mccOptions.includes(selectedOptions[i].value)) {
          mc.push(selectedOptions[i].value);
        }
        else if (this.state.partnerOptions.includes(selectedOptions[i].value)) {
          pa.push(selectedOptions[i].value);
        }
        else if (this.state.midOptions.includes(selectedOptions[i].value)) {
          mid.push(selectedOptions[i].value);
        }
        else{
          console.log(
            "ERROR OPTION NOT RECOGNIZED",
            {Merchant_Profile: selectedOptions[i].value}
          );
        }
      }
    }
    var wheres = "";
    var continuing = false;
    if (pr.length > 0 || ba.length > 0 || om.length > 0 || oy.length > 0 || mc.length > 0 || pa.length > 0 || mid.length > 0){
      wheres = "where "
      if (pr.length > 0){
        wheres = wheres + "(";
        for (i = 0; i < pr.length; i++){
          if (i !== 0){
            wheres = wheres + " or";
          }
          wheres = wheres + " Merchant_Profile like '" + pr[i] + "'";
          continuing = true;
        }
        if (continuing){
          wheres = wheres + ")"
        }
      }
      if (ba.length > 0){
        for (i = 0; i < ba.length; i++){
          if (continuing && i === 0){
            wheres = wheres + " and (";
          }
          else if (i === 0){
            wheres = wheres + "("
          }
          if (i !== 0){
            wheres = wheres + " or";
          }
          wheres = wheres + " Bank like '" + ba[i] + "'";
          continuing = true;
        }
        if (continuing){
          wheres = wheres + ")"
        }
      }
      if (om.length > 0){
        for (i = 0; i < om.length; i++){
          if (continuing && i === 0){
            wheres = wheres + " and (";
          }
          else if (i === 0){
            wheres = wheres + "("
          }
          if (i !== 0){
            wheres = wheres + " or";
          }
          wheres = wheres + " extract(month from open_date) = " + om[i];
          continuing = true;
        }
        if (continuing){
          wheres = wheres + ")"
        }
      }
      if (oy.length > 0){
        for (i = 0; i < oy.length; i++){
          if (continuing && i === 0){
            wheres = wheres + " and (";
          }
          else if (i === 0){
            wheres = wheres + "("
          }
          if (i !== 0){
            wheres = wheres + " or";
          }
          wheres = wheres + " extract(year from open_date) = " + oy[i];
          continuing = true;
        }
        if (continuing){
          wheres = wheres + ")"
        }
      }
      if (mc.lenght > 0){
        for (i = 0; i < mc.length; i++){
          if (continuing && i === 0){
            wheres = wheres + " and (";
          }
          else if (i === 0){
            wheres = wheres + "("
          }
          if (i !== 0){
            wheres = wheres + " or";
          }
          wheres = wheres + " MCC like '" + mc[i].substring(0,4) + "'";
          continuing = true;
        }
        if (continuing){
          wheres = wheres + ")"
        }
      }
      if (pa.length > 0){
        for (i = 0; i < pa.length; i++){
          if (continuing && i === 0){
            wheres = wheres + " and (";
          }
          else if (i === 0){
            wheres = wheres + "("
          }
          if (i !== 0){
            wheres = wheres + " or";
          }
          wheres = wheres + " Partner_Name like '" + pa[i] + "'";
          continuing = true;
        }
        if (continuing){
          wheres = wheres + ")"
        }
      }
      if (mid.length > 0){
        for (i = 0; i < mid.length; i++){
          if (continuing && i === 0){
            wheres = wheres + " and (";
          }
          else if (i === 0){
            wheres = wheres + "("
          }
          if (i !== 0){
            wheres = wheres + " or";
          }
          wheres = wheres + " MID like '" + mid[i] + "'";
          continuing = true;
        }
        if (continuing){
          wheres = wheres + ")"
        }
      }
    }
    var whereav = "";
    if (wheres){
      whereav = wheres + "and Year_Months >= '" + date + "'";
    }
    else{
      whereav = " where Year_Months >= '" + date + "'";
    }
    console.log(
    `where = '",${wheres},"'`
    );
  ApiClient
    .post({
      url: "/api/finaverage", 
      body: {
        sql: whereav
      }
    })
    .then(resl => {
      if (this.state.loaded){
        this.setState({
          avolume: resl.data.output[0].Volume,
          atransactions: resl.data.output[0].Transactions,
          aincome: resl.data.output[0].Income,
          aage: resl.data.output[0].Average_Age,
        });
      }
      else{
        this.setState({
          savolume: resl.data.output[0].Volume,
          satransactions: resl.data.output[0].Transactions,
          saincome: resl.data.output[0].Income,
          saage: resl.data.output[0].Average_Age,
          avolume: resl.data.output[0].Volume,
          atransactions: resl.data.output[0].Transactions,
          aincome: resl.data.output[0].Income,
          aage: resl.data.output[0].Average_Age,
          loaded: true
        });
      }
    }).catch(err => {
      console.log(err);
    });
  ApiClient
    .post({
      url: "/api/findata",
      body: {
        sql: wheres
      }
    })
    .then(res => {
      this.setState({
        graph: res.data.output,
        where: wheres
      });
    }).catch(err => {
      console.log(err);
    });
};

  //allows graphs to pan and zoom in unison
  handleMove = (newMin, newMax) => {
    this.setState({time:{min: newMin, max: newMax}});
  };

  handleBarClick = (element) => {
    if (typeof element[0] !== 'undefined'){
      if (this.state.davg === moment(this.state.graph[element[0]._index].Year_Months).format("MMMM YYYY")){
        this.setState({davg: "12 Month", gselected: null});
        this.getData(this.state.selectedOptions,moment().startOf('month').subtract(12, 'months').format("YYYY-MM-DD"));
      }
      else{
        this.setState({davg: moment(this.state.graph[element[0]._index].Year_Months).format("MMMM YYYY"), gselected: element[0]._index});
        this.getData(this.state.selectedOptions,this.state.graph[element[0]._index].Year_Months);
      }
    }
    else{
      this.setState({davg: "12 Month", gselected: null});
      this.getData(this.state.selectedOptions,moment().startOf('month').subtract(12, 'months').format("YYYY-MM-DD"));
    }

  };

  handleChange = (selectedOptions) => {
    this.setState({selectedOptions});
    sessionStorage.setItem("selectedOptions", JSON.stringify(selectedOptions));
    this.setState({davg: "12 Month", gselected: null});
    this.getData(selectedOptions,moment().startOf('month').subtract(12, 'months').format("YYYY-MM-DD"));
  }

  render() {

    const groupStyles = {
      color: 'white',
      background: "rgba(88, 214, 141, .5)",
      padding: '5px 0px',
      display: 'flex'
    };

    const GroupHeading = props => (
      <div style={groupStyles}>
        <components.GroupHeading {...props}/>
      </div>
    );

    return (
      <div className="Dashboard">
        {this.state.loaded ? (
          <Card className="financial">
            <Card.Header className="Filters">
              <h1>Filters: </h1>
              <Select
                value={this.state.selectedOptions}
                isMulti
                onChange={this.handleChange}
                options={this.state.groups}
                components={{ GroupHeading }}
              />
            </Card.Header>
            <Card.Body className="analytics">
              <div className="flex">
                <div className="doughnutgraph">
                  <p className="average">
                    {this.state.davg} Average:
                  </p>
                  <div className="fourdozen">
                    <VDoughnut name="Volume" old_avg={this.state.savolume} avg={this.state.avolume}/>
                    <VDoughnut name="Transactions" old_avg={this.state.satransactions} avg={this.state.atransactions}/>
                    <VDoughnut name="Income" old_avg={this.state.saincome} avg={this.state.aincome}/>
                    <VDoughnut name="Age in months" old_avg={this.state.saage} avg={this.state.aage}/>
                  </div>
                </div>
                <div className="bargraph">
                  <Tabs defaultActiveKey="volume" className="tabs" id="tabs" variant="pills">
                    <Tab eventKey="volume" title="Volume">
                      <div className="graphs">
                        <VGraph
                          classname="bars"
                          id="volbars"
                          data={this.state.graph}
                          states={this.state.SelectedOption}
                          handleBarClick={element => this.handleBarClick(element)}
                          handleMove={(min,max) => this.handleMove(min,max)}
                          time={this.state.time}
                          iselected={this.state.gselected}
                        />
                      </div>
                    </Tab>
                    <Tab eventKey="transactions" title="Transactions">
                      <div className="graphs">
                        <TGraph
                          classname="bars"
                          data={this.state.graph}
                          states={this.SelectedOption}
                          handleBarClick={element => this.handleBarClick(element)}
                          handleMove={(min,max) => this.handleMove(min,max)}
                          time={this.state.time}
                          iselected={this.state.gselected}
                        />
                      </div>
                    </Tab>
                    <Tab eventKey="income" title="Income">
                      <div className="graphs">
                        <IGraph
                          classname="bars"
                          data={this.state.graph}
                          states={this.SelectedOption}
                          handleBarClick={element => this.handleBarClick(element)}
                          handleMove={(min,max) => this.handleMove(min,max)}
                          time={this.state.time}
                          iselected={this.state.gselected}
                        />
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </Card.Body>
          </Card>
        ):(
          <div style={{height: '100%', width: '100%', justifyContent: "center", display: "flex" }}>
            <Loader color="green" width={125} heigth={125} type="Oval" margin="auto"/>
          </div>
        )}
      </div>
    );
  }
}

export default withCookies(Financial);
