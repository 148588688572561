import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import { Bar } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import FieldTable from "./FieldTable";
import Loader from "react-loader-spinner";
import Popup from "reactjs-popup";
import Button from "react-bootstrap/Button";
import CustomTable from "./CustomTable";
import Summary from "./Summary";
import TodayYesterdaySummary from "./TodayYesterdaySummary";

class CustomSalesCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // id: 0,
      type: "",
      id: "",
      title: "",
      data: "",
      graph: "",
      options: "",
      closePopup: false,
      width: 0,
      height: 0,
      chartHeight: 0
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    // console.log("window.innerWidth", window.innerWidth);
    this.setState({
      width: window.innerWidth
      // height: window.innerHeight
    });
  }

  closeUp = () => {
    this.setState({
      closePopup: this.state.closePopup ? false : true
    });
  };

  render() {
    var content;
    if (this.props.type === "bar") {
      content = <Bar data={this.props.graph} options={this.props.options} />;
    } else if (this.props.type === "line") {
      content = <Line data={this.props.graph} options={this.props.options} />;
    } else if (this.props.type === "pie") {
      content = <Pie data={this.props.graph} />;
    } else if (this.props.type === "table") {
      content = (
        <FieldTable
          data={this.props.graph}
          options={this.props.options}
          func={this.props.func}
          isMonth={this.props.isMonth}
        />
      );
    } else if (this.props.type === "bar_stacked") {
      content = <Bar data={this.props.graph} options={this.props.options} />;
    } else if (this.props.type === "progress") {
      // content = (
      //   <DonutWithText data={this.props.graph} options={this.props.options} />
      // );
      content = <Summary data={this.props.graph.data} />;
    } else if (this.props.type === "ty_progress") {
      // content = (
      //   <DonutWithText data={this.props.graph} options={this.props.options} />
      // );
      content = <TodayYesterdaySummary data={this.props.tdata}  />;
    }
    var tableData = null;
    if (
      (this.props.data[0] !== "" && this.props.data !== "") ||
      (this.props.data[1] !== "" && this.props.data !== "")
    ) {
      tableData = this.props.data[0];
    }

    return (
        <Card style={{ border: "none", borderRadius: "0" }}>
          <Card.Body>
            {this.props.type !== "progress" && this.props.title !== null ? (
              <Card.Title>
                <span className="sales-card-title">{this.props.title}</span>
                {this.props.isMonth == null ? null : (
                <Button className="navButton" style={{paddingTop: 'revert'}} onClick={this.props.toggler}>
                  <i className="material-icons">arrow_forward_ios</i>
                </Button>) }
              </Card.Title>
            ) : null}

            {this.props.loading ? (
              content
            ) : (
              <div style={{ justifyContent: "center", display: "flex", height: "100px" }}>
                <Loader color="green" width={25} heigth={25} type="Oval" />
              </div>
            )}
            <Popup
              contentStyle={{
                width: this.state.width < 500 ? "100%" : "50%"
              }}
              trigger={
                <div>
                  <i className="material-icons gray-icon">cloud_download</i>
                </div>
              }
              modal
              onOpen={this.props.func}
              onClose={this.closeUp}
              disabled={this.state.closePopup}
              closeOnDocumentClick={false}
              closeOnEscape={false}
              position="center center"
            >
              <div className="popupContent">
                {" "}
                <div>
                  <Button
                    className="buttonXlsx"
                    variant="outline-success"
                    onClick={this.props.exportfunc}
                  >
                    Export to XLSX
                  </Button>
                  <i
                    className="material-icons gray-icon"
                    onClick={this.closeUp}
                  >
                    close
                  </i>
                </div>
                <div>
                  {tableData ? (
                    <CustomTable data={this.props.data} />
                  ) : (
                    <div style={{ justifyContent: "center", display: "flex" }}>
                      <Loader
                        color="green"
                        width={25}
                        heigth={25}
                        type="Oval"
                      />
                    </div>
                  )}
                </div>
              </div>
            </Popup>
          </Card.Body>
        </Card>
    );
  }
}
export default CustomSalesCard;
