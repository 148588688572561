import React, { useEffect, useState } from "react"
import { Form, ListGroup, InputGroup, FormControl, Button, DropdownButton, Dropdown, Row, Col, Toast } from "react-bootstrap"
import { withCookies } from "react-cookie"
import "../Style/Style.scss"
import { ApiClient } from "../../ApiClient"
import Exception from "./FeesException"
import { useException } from "./FeesContext"

function Fees(props){
    const [defaultFees, setDefaultFees] = useState([])
    const [selectedFee, setSelectedFee] = useState({
        flag: 'MTF', 
        amount: 0, 
        iso_cost: 0
    })
    const [fees, setFees] = useState()
    const [filters, setFilters] = useState()
    const [filterOperators, setFilterOperators] = useState()
    const [exceptions, setExceptions] = useState()
    const [flags, setFlags] = useState([])
    const { saveExceptions, setExceptionList, setExceptionFee, addNewException, removeExceptionFilter, addNewExceptionFilter } = useException()
    const [saved, setSaved] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [message, setMessage] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [canEdit, setCanEdit] = useState(false)
    const editors = ['daniellec@signapay.com', 'bahramb@signapay.com', 'katherineh@signapay.com']
    const DEFAULT_FLAGS = ['MTF', 'ANF', 'NCF']

    const onSaveDefault = () => {
        setSaved(false)
        setIsLoading(true)
        ApiClient.post({
            url: "/api/pci/UpdateDefaultFee",
            body: {
                flag: selectedFee.flag,
                amount: selectedFee.amount,
                iso_cost: selectedFee.iso_cost,
            }
        })
        .then((json) => {
            setSaved(true)
            setHasError(false)
            setMessage("Saved successfully")
            setIsLoading(false)
        }).catch(err => {
            setSaved(true)
            setHasError(true)
            setMessage("ERROR! try again")
            setIsLoading(false)
            console.log(err)
        })
    }

    const onSaveExceptions = async () => {
        setSaved(false)
        setIsLoading(true)
        const result = await saveExceptions()
        setSaved(true)
        setHasError(!result)
        setMessage(result ? "Saved successfully" : "Please either fill up empty fields or remove them!")
        if(result) getFees()
        setIsLoading(false)
    }

    const getDefaultFees = () => {
        ApiClient.get({
            url: "/api/pci/GetDefaultFees",
        })
        .then((json) => {
            setDefaultFees(json.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const getFees = () => {
        ApiClient.get({
            url: "/api/pci/GetFees",
        }).then((json) => {
            setFees(json.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const getFilters = () => {
        ApiClient.get({
            url: "/api/pci/GetFilters",
        }).then((json) => {
            setFilters(json.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const getFilterOperatpors = () => {
        ApiClient.get({
            url: "/api/pci/GetFilterOperators",
        }).then((json) => {
            setFilterOperators(json.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const onChange = (e) => {
        switch (e.target.id) {
            case "txtFee":
                setSelectedFee({...selectedFee, amount: Number(e.target.value || 0)})
                break
            case "txtISOCost":
                setSelectedFee({...selectedFee, iso_cost: Number(e.target.value || 0)})
                break
            default:
                break
        }
    }

    const onSelectDefaultFee = (value) => {
        setSelectedFee({
            flag: value, 
            amount: {...defaultFees.find(f => f.fee_flag === value)}.amount, 
            iso_cost: {...defaultFees.find(f => f.fee_flag === value)}.iso_cost
        })
    }

    const setFee = (index, fee_group, value) => {
        setExceptions(exceptions =>
            exceptions.map((exception, i) => 
                i === index ?
                [
                    ...exception.map((exc) =>
                        exc.fee_group === fee_group ? {
                                                        ...exc, 
                                                        ...value
                                                    } : {...exc}    
                    )
                    
                ] : [...exception]
            )
        )
        setExceptionFee(index, fee_group, value)
    }

    const addException = () => {
        const temp_id = new Date().getTime()
        const temp_fee_group = new Date().getTime()
        setExceptions(exceptions => 
            [...exceptions, 
            [{
                id: temp_id,
                fee_flag: null,
                amount: null,
                iso_cost: null,
                filter_id: null,
                filter_value: null,
                filter_operator_id: null,
                fee_group: temp_fee_group
            }]]
        )
        addNewException(temp_id, temp_fee_group)
    }

    const addFilter = (index, fee_group, fee_flag, amount, iso_cost) => {
        const temp_id = new Date().getTime()
        setExceptions(exceptions =>
            exceptions.map((exception, i) => 
                index === i
                ?   [
                        ...exception,
                        {
                            id: temp_id,
                            fee_flag: fee_flag,
                            amount: amount,
                            iso_cost: iso_cost,
                            filter_id: null,
                            filter_value: null,
                            filter_operator_id: null,
                            fee_group: fee_group
                        }
                    ]
                :   [ ...exception ]
            )
        )
        addNewExceptionFilter(index, fee_group, fee_flag, amount, iso_cost, temp_id)
    }

    const removeFilter = (index, id) => {
        setExceptions(exceptions =>
            exceptions.map((exception, i) => 
                index === i    
                ?   [ ...exception.filter(exc => exc.id !== id) ]
                :   [ ...exception ]
            ).filter(exception => exception.length !== 0)
        )
        removeExceptionFilter(index, id)
    }

    useEffect(() => {
        if(!fees) return
        let fee_group = []
        let groups = []
        for (const obj of fees) {
            if(fee_group.includes(obj.fee_group)) continue
                fee_group.push(obj.fee_group)

            const group = fees.filter(type => type.fee_group == obj.fee_group)
            groups.push(group)
        }
        setExceptions(groups)
        setExceptionList(groups)
        setIsLoading(false)
    }, [fees])

    useEffect(() => {
        if(!defaultFees) return
        onSelectDefaultFee(selectedFee.flag)
    }, [defaultFees])

    useEffect(() => {
        if(!filters || !filterOperators) return
        getFees()
    }, [filters, filterOperators])

    useEffect(() => {
        if(flags.length === 0) return
        getDefaultFees()
        getFilters()
        getFilterOperatpors()
        setSaved(false)
        setHasError(false)
    }, [flags])

    useEffect(() => {
        if(props.flags.length === 0) return
        setFlags([...props.flags])
    }, [props])

    useEffect(() => {
        const { cookies } = props;
        const email = cookies.get("email");
        setCanEdit(editors.includes(email));
    }, [])

    return (
        <>
        <Form inline>
            <InputGroup className="mb-3 mr-sm-2">
            <InputGroup.Prepend>
                    <InputGroup.Text>Default Fee Amounts</InputGroup.Text>
                </InputGroup.Prepend>
                <InputGroup.Append>
            <DropdownButton variant="outline-primary"
                title={selectedFee && {...props.flags.find(f => f.flag === selectedFee.flag)}.description || ''}
                onSelect={onSelectDefaultFee}
            >
            {props.flags.map(f => {
                if(DEFAULT_FLAGS.includes(f.flag)){
                    return <Dropdown.Item key={f.flag} eventKey={f.flag}>{f.description}</Dropdown.Item>
                }
            })}
            </DropdownButton>
                </InputGroup.Append>
            </InputGroup>
            <Form.Label htmlFor="txtFee" srOnly>
                0.00
            </Form.Label>
            <InputGroup className="mb-3 mr-sm-2">
                <InputGroup.Prepend>
                <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl id="txtFee" style={{width: "100px"}} placeholder="0.00" type="number" 
                    value={selectedFee && selectedFee.amount || 0} 
                    onChange={onChange}
                />
            </InputGroup>
            <Form.Label htmlFor="txtISOCost" srOnly>
                0.00
            </Form.Label>
            <InputGroup className="mb-3 mr-sm-2">
                <InputGroup.Prepend>
                <InputGroup.Text>ISO Cost</InputGroup.Text>
                <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl id="txtISOCost" style={{width: "100px"}} placeholder="0.00" type="number" 
                    value={selectedFee && selectedFee.iso_cost || 0} 
                    onChange={onChange}
                />
            </InputGroup>
            <Button className="mb-3" onClick={onSaveDefault} disabled={isLoading || !canEdit}>
                Save
            </Button>
        </Form>
        <div className="feesException">
            {isLoading && <div className="exceptionLoadingLayer"></div>}
            <ListGroup>
            {exceptions && exceptions.map((exception, i) => {
                return <ListGroup.Item key={i}>
                    <Row key={i}>
                        <Col md="auto">
                            <Form.Control className="mb-1" as="select" custom value={exception[0].fee_flag || ''} onChange={e => setFee(i, exception[0].fee_group, {fee_flag: e.target.value})}>
                                {!exception[0].fee_flag && <option></option>}
                                {flags && flags.map(f => {
                                    if(DEFAULT_FLAGS.includes(f.flag)){
                                        return <option value={f.flag} key={f.flag}>{f.description}</option>
                                    }
                                })}
                            </Form.Control>
                            <InputGroup className="mb-1">
                                <InputGroup.Prepend>
                                    <InputGroup.Text  style={{width: "135px"}}>Fee Amount</InputGroup.Text>
                                    <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl style={{width: "100px"}} placeholder="0.00" type="number" defaultValue={exception[0].amount || 0} onChange={e => setFee(i, exception[0].fee_group, {amount: Number(e.target.value)})} />
                            </InputGroup>
                            <InputGroup className="mb-1">
                                <InputGroup.Prepend>
                                    <InputGroup.Text  style={{width: "135px"}}>ISO Cost</InputGroup.Text>
                                    <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl style={{width: "100px"}} placeholder="0.00" type="number" defaultValue={exception[0].iso_cost || 0} onChange={e => setFee(i, exception[0].fee_group, {iso_cost: Number(e.target.value)})} />
                            </InputGroup>
                        </Col>
                        <Col md="auto">
                            {exception.map((exc, j) => {
                                    return <Row className="mb-1" key={exc.id}> 
                                        <Exception index={i} data={exc} filters={filters} filterOperators={filterOperators} />
                                        <Button variant="link" className="text-danger" onClick={() => removeFilter(i, exc.id)}>&#x2716;</Button>
                                    </Row>
                            })}
                            <Row>
                                <Button size="sm" variant="link" onClick={() => addFilter(i, exception[0].fee_group, exception[0].fee_flag, exception[0].amount, exception[0].iso_cost)}>
                                    Add Filter
                                </Button>
                            </Row>
                        </Col>
                        <Col>
                            
                        </Col>
                    </Row>
                </ListGroup.Item>
            })}
            </ListGroup>
            <Button size="sm" className="mt-2" variant="outline-primary" onClick={() => addException()}>Add Exception</Button>
        </div>
        <div className="mt-2">
            <Form inline>
                <Button className="mr-2" onClick={() => getFees()} disabled={isLoading}>Reset</Button>
                <Button onClick={() => onSaveExceptions()} disabled={isLoading || !canEdit}>Save Exceptions</Button>
            </Form>
        </div>
        <div className="configToast">
            <Toast onClose={() => setSaved(false)} show={saved} delay={3000} autohide>
            <Toast.Body>
                <div className={`alert alert-${hasError ? "danger " : "success"} mb-0 py-2`} role="alert">
                    <small style={{ fontSize: ".7rem"}}>{message}</small>
                </div>
            </Toast.Body>
            </Toast>
        </div>
        </>
    )
}

export default withCookies(Fees);
