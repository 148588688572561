import React from "react";
import { ResponsivePie } from '@nivo/pie'
import { shortIt, commaIt } from "./Utils"

export const TxnPie = ({data}) => {
    
    const theme = {
        fontSize: "25px"
    }

    return <ResponsivePie
        data={data}
        margin={{ top: 5, right: 50, bottom: 5, left: 0 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        colors={{ datum: 'data.color' }}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
        enableArcLinkLabels={false}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'brighter', 3 ] ] }}
        arcLabel={(e) => shortIt(e.value)}
        valueFormat={value =>
            `$${commaIt(value)}`
        }
        fill={[
            {
                match: {
                    id: 'Volume'
                },
            },
            {
                match: {
                    id: 'Refunds'
                },
            },
            {
                match: {
                    id: 'Chargebacks'
                },
            }
        ]}
        legends={[
            {
                anchor: 'right',
                direction: 'column',
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 10,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]}
    />
}