import React, { Component } from "react";
import {
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  FormText,
  Image
} from "react-bootstrap";
import "./Style/login.css";
import logo from './Style/PayLoSignaPay.png'
import { Auth } from "../Auth";
// import HttpsRedirect from "react-https-redirect";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      msg: "",
      isLoading: false
    };
    this.onSignIn = this.onSignIn.bind(this);
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleEmailChange = event => {
    this.setState({
      [event.target.id]: String(event.target.value).toLowerCase()
    });
  };

  handlePasswordChange = event => {
    this.setState({
      [event.target.id]: String(event.target.value)
    });
  };

  handleSubmit = event => {
    event.preventDefault();
  };

  onSignIn(event) {
    event.preventDefault();
    // const form = event.target;
    // const data = new FormData(form);

    // console.log(data);
    // return;

    const { email, password } = this.state;
    this.setState({
      isLoading: true
    });
    
    Auth.signIn(email, password).then(json => {
      if (json.data.success) {
        const { cookies } = this.props;
        cookies.set("login", json.data.token, {
          path: "/",
          expires: new Date(Date.now() + 3600000)
        });
        cookies.set("email", this.state.email, {
          path: "/",
          expires: new Date(Date.now() + 3600000)
        });
        cookies.set("dept", json.data.department, {
          path: "/",
          expires: new Date(Date.now() + 3600000)
        });

        this.setState({
          isLoading: false,
          password: "",
          email: ""
        });

        this.props.history.push("/sales");

        // const dept = json.data.department;
        // switch (dept) {
        //   case "sales":
        //     this.props.history.push("/sales");
        //     break;
        //   case "finance":
        //     this.props.history.push("/financial");
        //     break;
        //   default:
        //     if(email.includes("bahramb"))
        //       this.props.history.push("/bi");
        //     else
        //       this.props.history.push("/sales");
        // }
      } else {
        this.setState({
          msg: json.data.message,
          isLoading: false
        });
      }
    }).catch((error) => {
      console.log(error)
      this.setState({
        isLoading: false,
        msg: "Error!"
      });
    });
  }

  render() {

    Auth.validateUser().then(json => {
      if(json.data.success){
        this.props.history.push("/sales");
      }
    }).catch(err => {

    })

    return (
      <div className="Login">
        <Image src={logo} width="300px" style={{ marginBottom: "20px"}} />
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="email">
            <FormControl
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleEmailChange}
              placeholder="Email..."
            />
          </FormGroup>
          <FormGroup controlId="password">
            <FormControl
              value={this.state.password}
              onChange={this.handlePasswordChange}
              type="password"
              placeholder="Password..."
            />
          </FormGroup>
          <Button
            block
            disabled={!this.validateForm()}
            type="submit"
            onClick={this.onSignIn}
          >
            Login
          </Button>
          <FormText className="text-muted">{this.state.msg}</FormText>
        </form>
        <br />
        <a href="/forgot" >Forgot Password</a>
      </div>
    );
  }
}
