import React, { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { withCookies } from "react-cookie"
import { ApiClient } from "../ApiClient"
import Table from './components/Table'
import moment from "moment";
import "./Style/Style.scss";

function Historic(props) {
    const [data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [hasError, sethasError] = React.useState(false)
    const [skipPageReset, setSkipPageReset] = React.useState(false)
    const [canEdit, setCanEdit] = useState(false)

    const editors = ['daniellec@signapay.com']

    const statusDropDown = ({
        value: initialValue,
        row: { index },
        column: { id },
      }) => {

        const onChange = (event) => {
            if(event.currentTarget.value === "")
                if(!window.confirm("Are you sure you want to change the status from Closed to Open?")) return

            updateMyData(index, id, event.currentTarget.value)
        }

        return (
            <Form.Control as="select" custom onChange={(e) => onChange(e)} value={initialValue || ""}>
                <option value="C" key="2">Closed</option>
                <option value="" key="1">Open</option>
            </Form.Control>
        )
    }

    const columns =  [
            {
                Header: 'MID',
                accessor: 'mid',
            },
            {
                Header: 'DBA',
                accessor: 'dba_name',
            },
            {
                Header: 'ISO',
                accessor: 'iso_name',
            },
            {
                Header: 'Status',
                accessor: 'merchant_status',
                Cell: statusDropDown
            },
            {
                Header: 'T/R',
                accessor: 'aba_number',
                editable: true
            },
            {
                Header: 'DDA',
                accessor: 'dda_number',
                editable: true
            },
        ]

    const updateMyData = (rowIndex, columnId, value) => {

        if(!canEdit) return

        setSkipPageReset(true)
        sethasError(false)

        setData(old => {
            const newData = old.map((row, index) => {
                        if (index === rowIndex) {
                        return {
                            ...old[rowIndex],
                            [columnId]: value,
                            }
                        }
                        return row
                    })
            return newData
        })
        ApiClient.post({
            url: "/api/pci/UpdateHistoricMerchantStatus",
            body: {
                mid:  data[rowIndex].mid,
                column: columnId,
                value: value,
            }
        })
        .then((json) => {
            setSkipPageReset(false)
        }).catch(err => {
            console.log(err);
            setSkipPageReset(false)
            sethasError(true)
        });
    }

    useEffect(() => {

        const { cookies } = props;
        const email = cookies.get("email");
        setCanEdit(editors.includes(email));

        ApiClient.get({
            url: "/api/pci/GetHistoricMerchants",
        })
        .then((json) => {
            setData(json.data)
            setLoading(false)
        }).catch(err => {
            console.log(err);
            setLoading(false)
        });
    }, [])

    return (
        <div className="PCI">
            <Table className="PCITable"
                columns={columns}
                data={data}
                setData={setData}
                updateMyData={updateMyData}
                skipPageReset={skipPageReset}
                hasError={hasError}
                loading={loading}
                setLoading={setLoading}
            />
        </div>
    )
}

export default withCookies(Historic);

