import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import Content from "./components/Content";
import { CookiesProvider } from "react-cookie";

class App extends Component {
  render() {
    return (
        <BrowserRouter>
          <CookiesProvider>
            <Content />
          </CookiesProvider>
        </BrowserRouter>
    );
  }
}

export default App;
