import React, { useEffect, useState } from "react"
import { withCookies } from "react-cookie"
import { Form, Nav } from "react-bootstrap"
import { ApiClient } from "../ApiClient"
import "./Style/Style.scss";
import Leads from "./Leads";
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Alert from 'react-bootstrap/Alert'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    boxForm: {
        margin: "20px 0",
        padding: "20px",
        borderColor: "rgba(0, 0, 0, 0.13)",
        borderStyle: "solid",
        borderWidth: "1px",
        borderRadius: "6px",
    }
}));

function Index(props) {
    const classes = useStyles()
    const [selectedTab, setSelectedTab] = useState("leads")
    const [leads, setLeads] = useState([])
    const [approvals, setApprovals] = useState([])
    const [selectedLeads, setSelectedLeads] = useState([])

    useEffect(() => {
        if(selectedTab !== "leads") return

        ApiClient.get({
            url: "/api/leads/GetLeads"
        })
        .then((json) => {
            setLeads(json.data?.records)
        }).catch(err => {
            console.log(err);
        });

        ApiClient.get({
            url: "/api/leads/GetApprovals"
        })
        .then((json) => {
            setApprovals(json.data)
        }).catch(err => {
            console.log(err);
        });

    }, [selectedTab])

    return (
        <div className="LeadsContainer">
            <Nav className="justify-content-start" variant="tabs">
                <Nav.Item onClick={() => setSelectedTab("leads")} key="leads">
                    <Nav.Link eventKey={-1} active={selectedTab === "leads"}>Leads</Nav.Link>
                </Nav.Item>
                {[...selectedLeads].map(selectedLead => (
                    <Nav.Item key={selectedLead}>
                        <div className={`d-flex nav-link ${selectedLead === selectedTab ? "active" : ""}`}>
                            <div style={{cursor: "pointer"}} onClick={() => setSelectedTab(selectedLead)}>
                                {
                                    leads.find(lead => lead.Id === selectedLead)?.Name || 
                                    `${approvals.find(approval => approval.lead_id === selectedLead).first_name} 
                                    ${approvals.find(approval => approval.lead_id === selectedLead).last_name}`
                                }
                            </div>
                            <button 
                                type="button" 
                                className="close ml-2" 
                                aria-label="Close" 
                                onClick={() => {
                                    if(selectedTab === selectedLead) setSelectedTab("leads")
                                    setSelectedLeads(prev => {
                                        const next = new Set(prev)
                                        next.delete(selectedLead)
                                        return next
                                    })
                                }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </Nav.Item>
                ))}
            </Nav>
            <div className="tabContainer">
                {selectedTab === "leads" && <>
                <Form className="searchLeads">
                    <Autocomplete
                        id="custom-autocomplete"
                        options={leads.sort((a, b) => -b.Real_Owner_del__c?.localeCompare(a.Real_Owner_del__c) || 0)}
                        groupBy={(option) => option?.Real_Owner_del__c}
                        filterOptions={(options, { inputValue }) => options.filter(item => item.Name.toLowerCase().includes(inputValue.toLowerCase()) || item.Company.toLowerCase().includes(inputValue.toLowerCase()) ||  item.Real_Owner_del__c?.toLowerCase()?.includes(inputValue.toLowerCase()))}
                        getOptionLabel={(option) => {
                            return `${option.Name} - ${option.Company}`
                        }}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Search Leads..."
                                />
                            )
                        }}
                        renderOption={(option) => {
                            return (
                                <div className="d-flex flex-row w-100" >
                                    <div style={{width: "200px"}}>{option.Name}</div>
                                    <div style={{width: "350px"}}>{option.Company}</div>
                                    <div style={{width: "100px"}}>{option.CreatedDate.slice(0, 10)}</div>
                                    <div>{option.Status}</div>
                                </div>
                            )
                        }}
                        onChange={(event, newValue) => {
                            setSelectedLeads(prev => new Set(prev).add(newValue.Id))
                            setSelectedTab(newValue.Id)
                        }}
                    />
                </Form>
                <div className="approvalList">
                    {approvals.map((approval, index) => 
                    <div className={classes.boxForm} key={index}>
                        <Grid container style={{fontSize: "13px"}}>
                            <Grid item xs={2}>
                                <div className="text-muted">Name</div> 
                                <a href="#" onClick={() => {
                                    setSelectedLeads(prev => new Set(prev).add(approval.lead_id))
                                    setSelectedTab(approval.lead_id)
                                }}>{approval.first_name} {approval.last_name}</a>
                            </Grid>
                            <Grid item xs={3}>
                                <div className="text-muted">DBA</div>{approval.dba}
                            </Grid>
                            <Grid item xs={2}>
                                <div  className="text-muted">RM</div>{approval.owner_name}
                            </Grid>
                            <Grid item xs={2}>
                                <div  className="text-muted">Underwriter</div>{approval.uw_name}
                            </Grid>
                            <Grid item xs={1}>
                                {approval.approved == null && <Alert variant="secondary" style={{width: "fit-content", marginBottom: 0, padding: "5px 15px"}}>Pending</Alert>}
                                {approval?.approved == 1 && <Alert variant="success" style={{width: "fit-content", marginBottom: 0, padding: "5px 15px"}}>Approved</Alert>}
                                {approval?.approved == 0 && <Alert variant="danger" style={{width: "fit-content", marginBottom: 0, padding: "5px 15px"}}>Rejected</Alert>}
                            </Grid>
                            <Grid item xs={2} style={{textAlign: "right"}}>
                                {approval.approved == 1 && 
                                    <a href={`https://signapay111.my.salesforce.com/${approval.account_id}`} target="_blank" className="btn btn-primary btn-sm" role="button" aria-pressed="true">Salesforce Profile</a>
                                }
                            </Grid>
                        </Grid>
                    </div>    
                    )}
                </div> 
                </>}
                {[...selectedLeads].map(selectedLead => {
                    if(selectedTab === selectedLead)
                        return <Leads props={leads.find(lead => lead.Id === selectedLead) || { Id: selectedLead }} />
                })}
            </div>
        </div>
    )
}

export default withCookies(Index)
