import React, { Component } from "react";
import { Chart, Doughnut } from "react-chartjs-2";
import "../Style/finance.scss";

class VDoughnut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name,
      old_avg: props.old_avg,
      avg: props.avg
    }
  }

  formater = (num, digits) => {
    var sn = [
      { value: 1, symbol: "" },
      { value: 1E3, symbol: "k" },
      { value: 1E6, symbol: "M" },
      { value: 1E9, symbol: "G" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = sn.length - 1; i > 0; i--) {
      if (num >= sn[i].value) {
        break;
      }
    }
    return ((num / sn[i].value).toFixed(digits).replace(rx, "$1") + sn[i].symbol);
  };

  render() {
    console.log(this.props);
    var dif = this.props.avg / this.props.old_avg;
    var lef = 2 - this.props.avg / this.props.old_avg;
    console.log(dif, lef);

    var data = {
      datasets: [
        {
          label: "Total",
          type: "doughnut",
          backgroundColor: ["rgba(88, 214, 141, 1)", "rgba(225, 225, 225, 1)"],
          borderColor: "rgba(300,300,300,1)",
          borderWidth: 1,
          hoverBackgroundColor: ["rgba(88, 214, 141, 1)", "rgba(225, 225, 225, 1)"],
          hoverBorderColor: "rgba(300,300,300,1)",
          data: [dif,lef]
        }
      ]
    };

  Chart.pluginService.register({
    beforeDatasetsDraw: function(chart) {
      if (chart.canvas.parentElement.className === "nut"){
        var width = chart.chart.width,
            height = chart.chart.height,
            ctx = chart.chart.ctx;

        ctx.restore();
        var fontSize = (height / 114).toFixed(2);
        ctx.font = fontSize + "em  Roboto";
        ctx.textBaseline = "middle";


        if (chart.titleBlock.options.text === "Volume" || chart.titleBlock.options.text === "Income"){
            var text = "$" + chart.options.tavg,
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2;
        }
        else{
            var text = chart.options.tavg,
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2;
        }

        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    }
  });

    return (
      <div className="nut">
        <Doughnut
          className = "avgNut"
          data={data}
          options={{
            tavg: this.formater(this.props.avg, 1),
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            title: {
              display: true,
              position: 'bottom',
              fontFamily: 'Roboto',
              text: this.state.name,
              fontsize: 14
            },
            tooltips:{
              enabled: false
            },
            animation: {
              animateScale: true,
              animateRotate: true
            },
            circumference: Math.PI * 1.25,
            rotation: -Math.PI * 1.125,
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0
            }
          }}
        />
      </div>
    );
  }
}

export default VDoughnut;
