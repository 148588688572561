import React, { Component } from "react";
import XLSX from "xlsx";
import Loader from "react-loader-spinner";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import CustomTable from "./CustomTable";
import moment from "moment";
import "./Style/downloadmodal.scss";

class DownloadModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      momentVal: moment(),
    };
  }

  xlsxDownloader = () => {
    console.log("xlsxDownloader...");
    let val = [this.props.data];
    var new_workbook = XLSX.utils.book_new();
    for (var i = 0; i < val.length; i++) {
      let data = val[i];
      if (Array.isArray(data)) {
        var worksheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(
          new_workbook,
          worksheet,
          "Sheet " + i.toString()
        );
      }

      if (
        i === val.length - 1 &&
        Object.entries(new_workbook.Sheets).length !== 0 &&
        new_workbook.Sheets.constructor === Object
      ) {
        XLSX.writeFile(
          new_workbook,
          this.props.filename +
            this.state.momentVal.format("MMM D YYYY kk:mm:ss") +
            ".xlsx"
        );
      }
    }
  };

  render() {
    var tableData = null;
    if (
      (this.props.data[0] !== "" && this.props.data !== "") ||
      (this.props.data[1] !== "" && this.props.data !== "")
    ) {
      tableData = this.props.data[0];
    }

    return (
      <div>
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard
          onHide={this.props.toggle}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <div>
                <Button
                  className="buttonXlsx"
                  variant="outline-success"
                  onClick={this.xlsxDownloader}
                >
                  Export to XLSX
                </Button>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {tableData ? (
                <CustomTable data={[this.props.data.slice(0, 10)]} />
              ) : (
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <Loader color="green" width={25} heigth={25} type="Oval" />
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default DownloadModal;
