import React, { Component } from "react";
import CardDeck from "react-bootstrap/CardColumns";
import CardGroup from "react-bootstrap/CardColumns";
import CustomSalesCard from "./CustomSalesCard";
import moment from "moment";
import XLSX from "xlsx";
import { ApiClient } from "../ApiClient"

class CurrentMth extends Component {

  getToday() {
    ApiClient.post({
      url: "/api/day",
      body: {
        dt: this.props.day.format(),
      }
    }).then((json) => {
      if (Object.keys(json.data).length === 0) {
        this.setState({
          submittedTodayCount: "0",
          approvedTodayCount: "0",
        });
      } else {
        this.setState({
          submittedTodayCount: json.data[0].Submitted,
          approvedTodayCount: json.data[0].Approved,
        });
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  getYest() {
    var momentClone = moment(this.props.day);

    ApiClient.post({
      url: "/api/day",
      body: {
        dt: momentClone.subtract(1, "days").format(),
      }
    }).then((json) => {
      if (Object.keys(json.data).length === 0) {
        this.setState({
          // submittedYestData: json.data,
          submittedYestCount: "0",
          approvedYestCount: "0",
        });
      } else {
        this.setState({
          // submittedYestData: json.data,
          submittedYestCount: json.data[0].Submitted,
          approvedYestCount: json.data[0].Approved,
        });
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  getMth() {
    ApiClient.post({
      url: "/api/month",
      body: {
        dt: this.props.day.format(),
      }
    }).then((json) => {
      if (Object.keys(json.data[0]).length === 0) {
        this.setState({
          submittedMTDCount: "0",
          approvedMTDCount: "0",
          closedMTDCount: "0",
          apprHighriskCount: "0",
          apprPayloCount: "0",
          apprTraditionalCount: "0",
          apprUnassignedCount: "0",
          apprCbcalCount: "0",
          apprPpsCount: "0",
          apprEsquireCount: "0",
          apprChesapeakeCount: "0",
          apprFirstDataCount: "0",
          approvedMTDProfileName: [
            "HighRisk",
            "PayLo",
            "Traditional",
            "Unassigned",
          ],
          approvedMTDPlatformName: ["CBCAL", "PPS", "Esquire", "Chesapeake", "FirstData"],
          approvedMTDProfileCount: [0, 0, 0, 0],
          approvedMTDPlatformCount: [0, 0, 0, 0, 0],
          loadingMTD: true,
          apprCBCAL_HR: 0,
          apprCBCAL_PL: 0,
          apprCBCAL_TR: 0,
          apprPPS_HR: 0,
          apprPPS_PL: 0,
          apprPPS_TR: 0,
          apprEsquire_HR: 0,
          apprEsquire_PL: 0,
          apprEsquire_TR: 0,
          apprChesapeake_HR: 0,
          apprChesapeake_PL: 0,
          apprChesapeake_TR: 0,
          apprFirstData_HR: 0,
          apprFirstData_PL: 0,
          apprFirstData_TR: 0,
          approvedMTDBankByProfile: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        });
      } else {
            this.setState(
          {
            submittedMTDCount: json.data[0][0].Submitted,
            approvedMTDCount: json.data[0][0].Approved,
            closedMTDCount: json.data[0][0].Closed,
            apprHighriskCount: json.data[0][0].HighRisk,
            apprPayloCount: json.data[0][0].PayLo,
            apprTraditionalCount: json.data[0][0].Traditional,
            apprUnassignedCount: json.data[0][0].Unassigned,
            apprCbcalCount: json.data[0][0].CBCAL,
            apprPpsCount: json.data[0][0].PPS,
            apprEsquireCount: json.data[0][0].Esquire,
            apprChesapeakeCount: json.data[0][0].Chesapeake,
            apprFirstDataCount: json.data[0][0].FirstData,
            approvedMTDProfileName: [
              "HighRisk",
              "PayLo",
              "Traditional",
              "Unassigned",
            ],
            approvedMTDPlatformName: ["CBCAL", "PPS", "Esquire", "Chesapeake", "FirstData"],
            loadingMTD: true,
            apprCBCAL_HR: json.data[1][0].CBCAL_HR,
            apprCBCAL_PL: json.data[1][0].CBCAL_PL,
            apprCBCAL_TR: json.data[1][0].CBCAL_TR,
            apprPPS_HR: json.data[1][0].PPS_HR,
            apprPPS_PL: json.data[1][0].PPS_PL,
            apprPPS_TR: json.data[1][0].PPS_TR,
            apprEsquire_HR: json.data[1][0].Esquire_HR,
            apprEsquire_PL: json.data[1][0].Esquire_PL,
            apprEsquire_TR: json.data[1][0].Esquire_TR,
            apprChesapeake_HR: json.data[1][0].Chesapeake_HR,
            apprChesapeake_PL: json.data[1][0].Chesapeake_PL,
            apprChesapeake_TR: json.data[1][0].Chesapeake_TR,
            apprFirstData_HR: json.data[1][0].FirstData_HR,
            apprFirstData_PL: json.data[1][0].FirstData_PL,
            apprFirstData_TR: json.data[1][0].FirstData_TR,
          },
          function () {
            this.setState({
              approvedMTDProfileCount: [
                this.state.apprHighriskCount,
                this.state.apprPayloCount,
                this.state.apprTraditionalCount,
                this.state.apprUnassignedCount,
              ],
              approvedMTDPlatformCount: [
                this.state.apprCbcalCount,
                this.state.apprPpsCount,
                this.state.apprEsquireCount,
                this.state.apprChesapeakeCount,
                this.state.apprFirstDataCount,
              ],
              approvedMTDBankByProfile: [
                this.state.apprCBCAL_HR,
                this.state.apprCBCAL_PL,
                this.state.apprCBCAL_TR,
                this.state.apprPPS_HR,
                this.state.apprPPS_PL,
                this.state.apprPPS_TR,
                this.state.apprEsquire_HR,
                this.state.apprEsquire_PL,
                this.state.apprEsquire_TR,
                this.state.apprChesapeake_HR,
                this.state.apprChesapeake_PL,
                this.state.apprChesapeake_TR,
                this.state.apprFirstData_HR,
                this.state.apprFirstData_PL,
                this.state.apprFirstData_TR,
              ],
            });
          }
        );
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  getYr() {
    ApiClient.post({
      url: "/api/year",
      body: {
        dt: this.props.day.format(),
      }
    }).then((json) => {
      if (Object.keys(json.data).length === 0) {
        this.setState({
          approvedYTDCount: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          loadingYTD: true,
        });
      } else {
        this.setState({
          // approvedMTDPlatformName: ["CBCAL", "PPS", "Esquire", "Chesapeake"],
          approvedYTDCount: json.data.map((d) => d.Approved),
          loadingYTD: true,
        });
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  getPend() {
    ApiClient.get({
      url: "/api/pend"
    }).then((json) => {
      this.setState({
        pended45daysLevelName: json.data.map((d) => d.Lvl),
        pended45daysLevelCount: json.data.map((d) => d.lvlCount),
        loadingPend: true,
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  getGoals() {
    ApiClient.post({
      url: "/api/salesYTDgoals",
      body: {
        dt: this.props.day.format()
      }
    }).then((json) => {
      this.setState({
        salesgoals: json.data.map((d) => d.goal),
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  getHoliday() {
    ApiClient.post({
      url: "/api/holiday",
      body: {
        dt: this.props.day.format()
      }
    }).then((json) => {
      this.setState({
        holidays: json.data.map((d) => {
          return (moment().utc(d.holiday).month() + 1 + "/" + moment.utc(d.holiday).date() + "/" + moment(d.holiday).year())
        }),
      });
    }).catch((err) => {
      console.log(err)
    });
  }

  xlsxDownloader = () => {
    console.log("xlsxDownloader...");
    let val = this.state.exportData;
    var new_workbook = XLSX.utils.book_new();
    for (var i = 0; i < val.length; i++) {
      let data = val[i];
      if (Array.isArray(data)) {
        var worksheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(
          new_workbook,
          worksheet,
          "Sheet " + i.toString()
        );
      }

      if (
        i === val.length - 1 &&
        Object.entries(new_workbook.Sheets).length !== 0 &&
        new_workbook.Sheets.constructor === Object
      ) {
        XLSX.writeFile(
          new_workbook,
          "Dashboard " + moment().format("MMM D YYYY kk:mm:ss") + ".xlsx"
        );
      }
    }
  };

  // getSubmittedToday() {
  //   // debugger;
  //   axios
  //     .post(`${process.env.REACT_APP_HOSTNAME}:${process.env.REACT_APP_SERVERPORT}/api/submittedToday`)
  //     // .then(res => res.json())
  //     .then(json => {
  //       // debugger;
  //       this.setState({
  //         submittedTodayData: json.data
  //       });
  //     });
  // }
  //
  // getApprovedToday() {
  //   // debugger;
  //   axios
  //     .post(`${process.env.REACT_APP_HOSTNAME}:${process.env.REACT_APP_SERVERPORT}/api/approvedToday`)
  //     // .then(res => res.json())
  //     .then(json => {
  //       // debugger;
  //
  //       this.setState({
  //         approvedTodayData: json.data
  //       });
  //     });
  // }

  getTodayDataByStatus = () => {
    ApiClient.post({
      url: "/api/approvedToday"
    }).then((json) => {
          ApiClient.post({
        url: "/api/submittedToday"
      }).then((json2) => {
          this.setState({
          submittedTodayData: json2.data,
          approvedTodayData: json.data,
          exportData: [json2.data, json.data],
        });
      }).catch((err) => {
        console.log(err);
      });
    }).catch((err) => {
      console.log(err);
    });
  };

  getTodayDataByStatusInHouse = () => {
    ApiClient.post({
      url: "/api/approvedTodayInHouse"
    }).then((json) => {
          ApiClient.post({
        url: "/api/submittedTodayInHouse"
      }).then((json2) => {
          this.setState({
          submittedTodayInHouseData: json2.data,
          approvedTodayInHouseData: json.data,
          exportData: [json2.data, json.data],
        });
      }).catch((err) => {
        console.log(err);
      });
    }).catch((err) => {
      console.log(err);
    });
  };

  getTodayDataByProfile = () => {
    ApiClient.post({
      url: "/api/approvedTodayByProfile"
    }).then((json) => {
          ApiClient.post({
        url: "/api/submittedTodayByProfile"
      }).then((json2) => {
        this.setState({
          submittedTodayByProfileData: json2.data,
          approvedTodayByProfileData: json.data,
          loadingToday: true,
        });
      }).catch((err) => {
        console.log(err);
      });
    }).catch((err) => {
      console.log(err);
    });
  };

  // getSubmitted() {
  //   // debugger;
  //   axios
  //     .post(`${process.env.REACT_APP_HOSTNAME}:${process.env.REACT_APP_SERVERPORT}/api/submittedYest`)
  //     // .then(res => res.json())
  //     .then(json => {
  //       // debugger;
  //
  //       this.setState({
  //         submittedYestData: json.data
  //       });
  //     });
  // }
  //
  // getApproved() {
  //   // debugger;
  //
  //   axios
  //     .post(`${process.env.REACT_APP_HOSTNAME}:${process.env.REACT_APP_SERVERPORT}/api/approvedYest`)
  //     // .then(res => res.json())
  //     .then(json => {
  //       // debugger;
  //
  //       this.setState({
  //         approvedYestData: json.data
  //       });
  //     });
  // }

  getYestDataByStatus = () => {
      ApiClient.post({
        url: "/api/approvedYest"
      }).then((json) => {
        ApiClient.post({
          url: "/api/submittedYest"
        }).then((json2) => {
          this.setState({
            submittedYestData: json2.data,
            approvedYestData: json.data,
            exportData: [json2.data, json.data],
        });
        }).catch((err) => {
          console.log(err);
        });
      }).catch((err) => {
        console.log(err);
      });
  };

  getYestDataByProfile = () => {
    ApiClient.post({
      url: "/api/approvedYestByProfile"
    }).then((json) => {
      ApiClient.post({
        url: "/api/submittedYestByProfile"
      }).then((json2) => {
        this.setState({
          submittedYestByProfileData: json2.data,
          approvedYestByProfileData: json.data,
          loadingYest: true,
      });
      }).catch((err) => {
        console.log(err);
      });
    }).catch((err) => {
      console.log(err);
    });
};

  // getSubmittedMTD() {
  //   // debugger;
  //   let cntr = this.state.counter;
  //   axios
  //     .post(`${process.env.REACT_APP_HOSTNAME}:${process.env.REACT_APP_SERVERPORT}/api/submittedMTD`, {
  //       cntr: cntr
  //     })
  //     // .then(res => res.json())
  //     .then(json => {
  //       // debugger;
  //
  //       this.setState({
  //         submittedMTDData: json.data
  //       });
  //     });
  // }
  //
  // getApprovedMTD() {
  //   // debugger;
  //   let cntr = this.state.counter;
  //   axios
  //     .post(`${process.env.REACT_APP_HOSTNAME}:${process.env.REACT_APP_SERVERPORT}/api/approvedMTD`, {
  //       cntr: cntr
  //     })
  //     // .then(res => res.json())
  //     .then(json => {
  //       // debugger;
  //
  //       this.setState({
  //         approvedMTDData: json.data
  //       });
  //     });
  // }

  getApprovedMTDPartner = () => {
    let cntr = this.state.counter;
    ApiClient.post({
      url: "/api/approvedMTDPartner",
      body: { 
        cntr: cntr 
      }
    }).then((json) => {
      this.setState({
        approvedMTDPartnerName: json.data.map((d) => d.ISO),
        approvedMTDPartnerCount: json.data.map((d) => d.appCount),
        loadingPartner: true,
      });
    }).catch((err) => {
      console.log(err);
    });
  };

  getApprovedMTDInHousePartner = () => {
    let cntr = this.state.counter;
    ApiClient.post({
      url: "/api/approvedMTDInHousePartner",
      body: { 
        cntr: cntr 
      }
    }).then((json) => {
      this.setState({
        approvedMTDInHousePartnerName: json.data.map((d) => d.ISO),
        approvedMTDInHousePartnerCount: json.data.map((d) => d.appCount),
        loadingInHousePartner: true,
      });
    }).catch((err) => {
      console.log(err);
    });
  };

  getClosedMTDPartner = () => {
    let cntr = this.state.counter;
    ApiClient.post({
      url: "/api/ClosedMTDPartner",
      body: { 
        cntr: cntr 
      }
    }).then((json) => {
      this.setState({
        closedMTDPartnerName: json.data.map((d) => d.ISO),
        closedMTDPartnerCount: json.data.map((d) => d.closureCount),
        loadingPartner: true,
      });
    }).catch((err) => {
      console.log(err);
    });
  };


  getSubmittedApprovedTodayPartner = () => {
    ApiClient.post({
      url: "/api/submittedApprovedTodayPartner"
    })
    .then((json) => {
      this.setState({
        subApprTodayPartnerName: json.data.map((d) => d.ISO),
        apprTodayPartnerCount: json.data.map((d) => d.appCount),
        subTodayPartnerCount: json.data.map((d) => d.subCount),
        loadingTodayPartner: true,
      });
    }).catch((err) => {
      console.log(err);
    });
  };

  getSubmittedApprovedTodayInHousePartner = () => {
    ApiClient.post({
      url: "/api/submittedApprovedTodayInHousePartner"
    })
    .then((json) => {
      this.setState({
        subApprTodayInHousePartnerName: json.data.map((d) => d.ISO),
        apprTodayInHousePartnerCount: json.data.map((d) => d.appCount),
        subTodayInHousePartnerCount: json.data.map((d) => d.subCount),
        loadingTodayInHousePartner: true,
      });
    }).catch((err) => {
      console.log(err);
    });
  };

  getPended45days = () => {
    ApiClient.post({
      url: "/api/pending45days"
    }).then((json) => {
      this.setState({
        pended45daysData: json.data,
        exportData: [json.data],
      });
    }).catch((err) => {
      console.log(err);
    });
  };

  getMthDataByStatus = () => {
    let cntr = this.state.counter;
    ApiClient.post({
      url: "/api/approvedMTD",
      body: {
        cntr: cntr,
      }
    }).then((json) => {
      ApiClient.post({
        url: "/api/submittedMTD",
        body: {
          cntr: cntr,
        }
      }).then((json2) => {
        ApiClient.post({
          url: "/api/closedMTD",
          body: {
            cntr: cntr,
          }
        }).then((json3) => {
          this.setState({
            closedMTDData: json3.data,
            submittedMTDData: json2.data,
            approvedMTDData: json.data,
            exportData: [json2.data, json.data, json3.data],
          });
        }).catch((err) => {
          console.log(err);
        });
      }).catch((err) => {
        console.log(err);
      });
    }).catch((err) => {
      console.log(err);
    });
  };

  getMthDataByStatusInHouse = () => {
    let cntr = this.state.counter;
    ApiClient.post({
      url: "/api/approvedMTDInHouse",
      body: {
        cntr: cntr,
      }
    }).then((json) => {
      ApiClient.post({
        url: "/api/submittedMTDInHouse",
        body: {
          cntr: cntr,
        }
      }).then((json2) => {
        this.setState({
          submittedMTDInHouseData: json2.data,
          approvedMTDInHouseData: json.data,
          exportData: [json2.data, json.data],
        });
      }).catch((err) => {
        console.log(err);
      });
    }).catch((err) => {
      console.log(err);
    });
  };

  getApprovedYTD_Details = () => {
    ApiClient.post({
      url: "/api/approvedYTD_Details",
      body: {
        dt: this.props.day.format(),
      }
    }).then((json) => {
      this.setState({
        approvedYTD_Data: json.data,
        exportData: [json.data],
      });
    }).catch((err) => {
      console.log(err);
    });
  };

  toggleMonthByPartner = () => {
    this.setState({
      isMonthByPartner: this.state.isMonthByPartner === 2 ? 0 : this.state.isMonthByPartner + 1
    }, () => {
      if (this.state.isMonthByPartner === 2) {
        this.getSubmittedApprovedTodayPartner();
      }
    });
  };

  toggleMonthByInHousePartner = () => {
    this.setState({
      isMonthByInHousePartner: !this.state.isMonthByInHousePartner
    }, () => {
      if (this.state.isMonthByInHousePartner === false) {
        this.getSubmittedApprovedTodayInHousePartner();
      }
    });
  };

  constructor(props) {
    super(props);

    this.state = {
      counter: this.props.visibility,
      exportData: "",
      salesgoals: "",
      holidays: [],
      submittedTodayData: "",
      submittedTodayInHouseData: "",
      submittedTodayCount: 0,
      submittedTodayInHouseCount: 0,
      approvedTodayData: "",
      approvedTodayInHouseData: "",
      approvedTodayCount: 0,
      approvedTodayInHouseCount: 0,
      loadingToday: false,
      submittedYestData: "",
      submittedYestCount: 0,
      approvedYestData: "",
      approvedYestCount: 0,
      loadingYest: false,
      approvedMTDData: "",
      approvedMTDInHouseData: "",
      closedMTDData: "",
      approvedMTDCount: 0,
      approvedMTDInHouseCount: 0,
      closedMTDCount: 0,
      submittedMTDData: "",
      submittedMTDInHouseData: "",
      submittedMTDCount: 0,
      submittedMTDInHouseCount: 0,
      loadingMTD: false,
      apprHighriskCount: 0,
      apprPayloCount: 0,
      apprTraditionalCount: 0,
      apprUnassignedCount: 0,
      apprCbcalCount: 0,
      apprPpsCount: 0,
      apprEsquireCount: 0,
      apprChesapeakeCount: 0,
      apprFirstDataCount: 0,
      approvedMTDPartnerName: "",
      closedMTDPartnerName: "",
      approvedMTDInHousePartnerName: "",
      loadingPartner: false,
      loadingInHousePartner: false,
      loadingTodayPartner: false,
      loadingTodayInHousePartner: false,
      approvedMTDPartnerCount: 0,
      approvedMTDInHousePartnerCount: 0,
      closedMTDPartnerCount: 0,
      pended45daysData: "",
      pended45daysCount: 0,
      pended45daysLevelName: "",
      pended45daysLevelCount: 0,
      loadingPend: false,
      approvedYTD_Data: "",
      approvedYTDCount: 0,
      loadingYTD: false,
      approvedMTDPlatformName: "",
      approvedMTDPlatformCount: 0,
      approvedMTDBankByProfile: 0,
      loadingPlatform: false,
      approvedMTDProfileName: "",
      approvedMTDProfileCount: 0,
      subApprTodayPartnerName: "",
      subApprTodayInHousePartnerName: "",
      apprTodayPartnerCount: 0,
      apprTodayInHousePartnerCount: 0,
      subTodayPartnerCount: 0,
      subTodayInHousePartnerCount: 0,
      isMonthByPartner: 0,
      isMonthByInHousePartner: true,
      loadingProfile: false,
      submittedTodayByProfileData: [],
      approvedTodayByProfileData: [],
      submittedYestByProfileData: [],
      approvedYestByProfileData: [],
    };
  }

  updateChart() {
    this.getGoals();
    this.getHoliday();
    this.getToday();
    this.getYest();
    this.getMth();
    this.getYr();
    this.getPend();
    this.getApprovedMTDPartner();
    this.getApprovedMTDInHousePartner();
    this.getClosedMTDPartner();
    this.getTodayDataByProfile();
    this.getYestDataByProfile();
    // this.getSubmittedApprovedTodayPartner();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.visibility !== prevProps.visibility) {
      this.setState({
        counter: this.props.visibility,
      });
    }
  }

  componentDidMount() {
    this.updateChart();
    this.interval = setInterval(() => {
      this.updateChart();
    }, 60000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    if (this.props.visibility !== 0) {
      return null;
    } else {
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      var goals = this.state.salesgoals;
      var holidays = this.state.holidays;
      var today = new Date();
      var todayDate = today.getDate();
      var todayMonth = today.getMonth();
      var todayYear = today.getFullYear();
      var todayWeekday = today.toLocaleDateString("en-US", { weekday: "long" });

      var yest = new Date(today.setDate(today.getDate() - 1));
      var yestDate = yest.getDate();
      var yestMonth = yest.getMonth();
      var yestYear = yest.getFullYear();
      var yestWeekday = yest.toLocaleDateString("en-US", { weekday: "long" });

      var endD = new Date(todayYear, todayMonth + 1, 0);
      var firstD = new Date(todayYear, todayMonth, 1);
      var endDate = endD.getDate();
      var firstDate = firstD.getDate();

      var busDays = getBusinessDatesCount(
        new Date(todayMonth + 1 + "/" + firstDate + "/" + todayYear),
        new Date(todayMonth + 1 + "/" + endDate + "/" + todayYear),
        holidays
      );

      
      // var busDaysLeft = getBusinessDatesCount(
      //   new Date(todayMonth + 1 + "/" + todayDate + "/" + todayYear),
      //   new Date(todayMonth + 1 + "/" + endDate + "/" + todayYear),
      //   holidays
      // );

      var busDaysOver = getBusinessDatesCount(
        new Date(todayMonth + 1 + "/" + firstDate + "/" + todayYear),
        new Date(todayMonth + 1 + "/" + todayDate + "/" + todayYear),
        holidays
      );

      if(busDaysOver > 2) busDaysOver -= 1;
      else busDaysOver = 1;

      // function getBusinessDatesCount(startDate, endDate) {
      //   var count = 0;
      //   var curDate = startDate;
      //   while (curDate <= endDate) {
      //     var dayOfWeek = curDate.getDay();
      //     if (!(dayOfWeek === 6 || dayOfWeek === 0)) count++;
      //     curDate.setDate(curDate.getDate() + 1);
      //   }
      //   // alert(count);
      //   return count;
      // };

      function getBusinessDatesCount(startDate, endDate, holidayDates) {
        var count = 0;
        var curDate = startDate;
        while (curDate <= endDate) {
            let tmp = (curDate.getMonth() + 1) + "/" + curDate.getDate() + "/" + curDate.getFullYear()
            if (holidayDates !== undefined && holidayDates.includes(tmp)) {
                curDate.setDate(curDate.getDate() + 1);
                continue;
            }
            var dayOfWeek = curDate.getDay();
            if (!(dayOfWeek === 6 || dayOfWeek === 0)) count++;
            curDate.setDate(curDate.getDate() + 1);
        }
        // console.log(count);
        return count;
    }

      var ytdTotal = 0;
      for (var j = 0; j < this.state.approvedYTDCount.length; j++) {
        ytdTotal += this.state.approvedYTDCount[j];
      }

      var pendTotal = 0;
      for (var k = 0; k < this.state.pended45daysLevelCount.length; k++) {
        pendTotal += this.state.pended45daysLevelCount[k];
      }

      // console.log(busDays - busDaysLeft, busDays, busDaysLeft);
      var approvedMTDTodayDiff = 0;
      if (this.state.approvedMTDCount - this.state.approvedTodayCount < 0) {
        approvedMTDTodayDiff = 0;
      } else {
        approvedMTDTodayDiff =
          this.state.approvedMTDCount - this.state.approvedTodayCount;
      }

      var trendPercent = 0;
      var trendVal = 0;
      // var busDaysOver = 0;
      var progressData = 0;
      // if (busDays - busDaysLeft === 0) {
      //   busDaysOver = 1;
      //   trendPercent =
      //     (this.state.approvedMTDCount * 100) /
      //     ((goals[todayMonth] / (busDays - holidayCnt)) * busDaysOver);
      //   trendVal = (trendPercent * goals[todayMonth]) / 100;
      // } else if (busDays - busDaysLeft === busDays - 1) {
      //   busDaysOver = busDays - holidayCnt;
      //   trendPercent =
      //     (this.state.approvedMTDCount * 100) /
      //     ((goals[todayMonth] / (busDays - holidayCnt)) * busDaysOver);
      //   trendVal = this.state.approvedMTDCount;
      // } else {
      //   busDaysOver = (busDays - holidayCnt) - (busDaysLeft);
      //   if (busDaysOver === 0) {
      //     busDaysOver = holidayCnt;
      //   } else if (busDaysOver < 0) {
      //     busDaysOver = busDaysOver * -1;
      //   }
      //   trendPercent =
      //     (approvedMTDTodayDiff * 100) /
      //     ((goals[todayMonth] / (busDays - holidayCnt)) * busDaysOver);
      //   trendPercent =   (approvedMTDTodayDiff * 100) /150;
      //   trendVal = (trendPercent * goals[todayMonth]) / 100;
      // }

      if(busDaysOver < busDays - 1){
        trendPercent =
            (this.state.approvedMTDCount * 100) /
            ((goals[todayMonth] / busDays) * busDaysOver);
        trendVal = (trendPercent * goals[todayMonth]) / 100;
      } else {
        trendPercent = (this.state.approvedMTDCount * 100) / goals[todayMonth];
        trendVal = this.state.approvedMTDCount;
      }
      progressData = Math.round(trendPercent);

      // if (Math.round(trendPercent) < 100) {
      //   progressData = [
      //     Math.round(trendPercent),
      //     100 - Math.round(trendPercent)
      //   ];
      // } else {
      //   progressData = [Math.round(trendPercent)];
      // }

      var data = [
        // {
        //   type: "progress",
        //   loading: this.state.loadingMTD,
        //   id: months[todayMonth] + " Goal",
        //   fdata: [this.state.approvedMTDData],
        //   title:
        //     moment().format("MMM") +
        //     " Goal " +
        //     goals[todayMonth] +
        //     " Trending " +
        //     Math.round(trendVal),
        //   graph: {
        //     // labels: "Progress%",
        //     datasets: [
        //       {
        //         // label: "Progress",
        //         backgroundColor: [
        //           "rgba(88, 214, 141,1)",
        //           "rgba(204, 209, 209,1)"
        //         ],
        //         borderColor: ["rgba(88, 214, 141,1)", "rgba(204, 209, 209,1)"],
        //         data: progressData
        //       }
        //     ]
        //   }
        // },
        {
          type: "progress",
          loading: this.state.loadingMTD,
          id: months[todayMonth] + " Goal",
          fdata: [this.state.approvedMTDData],
          title: moment().format("dddd, MMM D YYYY"),
          graph: {
            // labels: "Progress%",
            datasets: [
              {
                // label: "Progress",
                backgroundColor: [
                  "rgba(88, 214, 141,1)",
                  "rgba(204, 209, 209,1)",
                ],
                borderColor: ["rgba(88, 214, 141,1)", "rgba(204, 209, 209,1)"],
              },
            ],
            data: [
              Math.round(trendVal),
              goals[todayMonth],
              progressData,
              this.state.approvedMTDPlatformName,
              this.state.approvedMTDPlatformCount,
              this.state.approvedMTDProfileName,
              this.state.approvedMTDProfileCount,
              ["Level1", "Level2", "Level3"],
              this.state.pended45daysLevelCount,
              this.state.approvedMTDBankByProfile,
            ],
          },
        },
        {
          type: "ty_progress",
          loading: this.state.loadingToday,
          id: "Today Submitted Approved",
          fdata: [this.state.submittedTodayData, this.state.approvedTodayData],
          gdata: [this.state.submittedYestData, this.state.approvedYestData],
          tdata: {
              submitted: {
                 total: this.state.submittedTodayByProfileData[0]?.Submitted, 
                 pl: this.state.submittedTodayByProfileData[0]?.PLSubmitted, 
                 tr: this.state.submittedTodayByProfileData[0]?.TRSubmitted, 
                 hr: this.state.submittedTodayByProfileData[0]?.HRSubmitted, 
              },
              approved: {
                total: this.state.approvedTodayByProfileData[0]?.Approved,
                pl: this.state.approvedTodayByProfileData[0]?.PLApproved,
                tr: this.state.approvedTodayByProfileData[0]?.TRApproved,
                hr: this.state.approvedTodayByProfileData[0]?.HRApproved,
              },
              title: "Today\t" +
                "Submitted " +
                (this.state.submittedTodayByProfileData[0]?.Submitted || 0) +
                " Approved " +
                (this.state.approvedTodayByProfileData[0]?.Approved || 0),
          },
          ydata: {
            submitted: {
              total: this.state.submittedYestByProfileData[0]?.Submitted, 
              pl: this.state.submittedYestByProfileData[0]?.PLSubmitted, 
              tr: this.state.submittedYestByProfileData[0]?.TRSubmitted, 
              hr: this.state.submittedYestByProfileData[0]?.HRSubmitted, 
           },
           approved: {
             total: this.state.approvedYestByProfileData[0]?.Approved,
             pl: this.state.approvedYestByProfileData[0]?.PLApproved,
             tr: this.state.approvedYestByProfileData[0]?.TRApproved,
             hr: this.state.approvedYestByProfileData[0]?.HRApproved,
           },
           title: "Yesterday\t" +
                "Submitted " +
                (this.state.submittedYestByProfileData[0]?.Submitted || 0) +
                " Approved " +
                (this.state.approvedYestByProfileData[0]?.Approved || 0),
        },
          title: null
          // "Today\t" +
          //   "Submitted " +
          //   (this.state.submittedTodayByProfileData[0]?.Submitted || 0) +
          //   " Approved " +
          //   (this.state.approvedTodayByProfileData[0]?.Approved || 0),
        },
        // {
        //   type: "ty_progress",
        //   loading: this.state.loadingToday,
        //   id: "Yesterday Submitted Approved",
        //   fdata: [this.state.submittedYestData, this.state.approvedYestData],
        //   tdata: {
        //       submitted: {
        //          total: this.state.submittedYestByProfileData[0]?.Submitted, 
        //          pl: this.state.submittedYestByProfileData[0]?.PLSubmitted, 
        //          tr: this.state.submittedYestByProfileData[0]?.TRSubmitted, 
        //          hr: this.state.submittedYestByProfileData[0]?.HRSubmitted, 
        //       },
        //       approved: {
        //         total: this.state.approvedYestByProfileData[0]?.Approved,
        //         pl: this.state.approvedYestByProfileData[0]?.PLApproved,
        //         tr: this.state.approvedYestByProfileData[0]?.TRApproved,
        //         hr: this.state.approvedYestByProfileData[0]?.HRApproved,
        //       }
        //   },
        //   title:
        //   "Yesterday\t" +
        //   "Submitted " +
        //   (this.state.submittedYestByProfileData[0]?.Submitted || 0) +
        //   " Approved " +
        //   (this.state.approvedYestByProfileData[0]?.Approved || 0),
        // },
        // {
        //   type: "bar",
        //   id: "Today Submitted Approved",
        //   loading: this.state.loadingToday,
        //   fdata: [this.state.submittedTodayData, this.state.approvedTodayData],
          // title:
          //   "Today\t" +
          //   "Submitted " +
          //   this.state.submittedTodayCount +
          //   " Approved " +
          //   this.state.approvedTodayCount,
        //   graph: {
        //     labels: [
        //       // days[todayDate.getDay()] +
        //       //   " " +
        //       todayWeekday +
        //         ", " +
        //         months[todayMonth] +
        //         " " +
        //         todayDate +
        //         " " +
        //         todayYear,
        //     ],
        //     datasets: [
        //       {
        //         label: "Submitted",
        //         backgroundColor: "rgba(52, 152, 219,1)",
        //         borderColor: "rgba(52, 152, 219,1)",
        //         data: [this.state.submittedTodayCount],
        //       },
        //       {
        //         label: "Approved",
        //         backgroundColor: "rgba(88, 214, 141,1)",
        //         borderColor: "rgba(88, 214, 141,1)",
        //         data: [this.state.approvedTodayCount],
        //       },
        //     ],
        //   },
        // },
        // {
        //   type: "bar",
        //   id: "Yesterday Submitted Approved",
        //   loading: this.state.loadingYest,
        //   fdata: [this.state.submittedYestData, this.state.approvedYestData],
        //   title:
            // "Yesterday\t" +
            // "Submitted " +
            // this.state.submittedYestCount +
            // " Approved " +
            // this.state.approvedYestCount,
        //   graph: {
        //     labels: [
        //       // days[yestDate.getDay()] +
        //       //   " "
        //       yestWeekday +
        //         ", " +
        //         months[yestMonth] +
        //         " " +
        //         yestDate +
        //         " " +
        //         yestYear,
        //     ],
        //     datasets: [
        //       {
        //         label: "Submitted",
        //         backgroundColor: "rgba(52, 152, 219,1)",
        //         borderColor: "rgba(52, 152, 219,1)",
        //         data: [this.state.submittedYestCount],
        //       },
        //       {
        //         label: "Approved",
        //         backgroundColor: "rgba(88, 214, 141,1)",
        //         borderColor: "rgba(88, 214, 141,1)",
        //         data: [this.state.approvedYestCount],
        //       },
        //     ],
        //   },
        // },
        {
          type: "bar",
          id: months[todayMonth] + "Submitted Approved",
          loading: this.state.loadingMTD,
          fdata: [this.state.submittedMTDData, this.state.approvedMTDData, this.state.closedMTDData],
          title:
            moment().format("MMM") +
            "\t" +
            "Submitted " +
            this.state.submittedMTDCount +
            " Approved " +
            this.state.approvedMTDCount + 
            " Closed " +
            this.state.closedMTDCount,
          graph: {
            labels: [months[todayMonth]],
            datasets: [
              {
                label: "Submitted",
                backgroundColor: "rgba(52, 152, 219,1)",
                borderColor: "rgba(52, 152, 219,1)",
                data: [this.state.submittedMTDCount],
              },
              {
                label: "Approved",
                backgroundColor: "rgba(88, 214, 141,1)",
                borderColor: "rgba(88, 214, 141,1)",
                data: [this.state.approvedMTDCount],
              },
              {
                label: "Closed",
                backgroundColor: "rgba(222, 135, 109,1)",
                borderColor: "rgba(222, 135, 109,1)",
                data: [this.state.closedMTDCount],
              }
            ],
          },
        },

        {
          type: "pie",
          id: months[todayMonth] + " Approvals by Platform",
          loading: this.state.loadingMTD,
          fdata: [this.state.approvedMTDData],
          title: moment().format("MMM") + " Approvals by Platform",
          graph: {
            labels: this.state.approvedMTDPlatformName,
            datasets: [
              {
                label: "Approved",
                backgroundColor: [
                  "rgba(88, 214, 141,1)",
                  "rgba(52, 152, 219,1)",
                  "rgba(255,215,0,1)",
                  "rgba(204, 209, 209,1)",
                ],
                borderColor: [
                  "rgba(88, 214, 141,1)",
                  "rgba(52, 152, 219,1)",
                  "rgba(255,215,0,1)",
                  "rgba(204, 209, 209,1)",
                ],
                data: this.state.approvedMTDPlatformCount,
              },
            ],
          },
        },
        {
          type: "pie",
          id: months[todayMonth] + " Approvals by Profile",
          loading: this.state.loadingMTD,
          fdata: [this.state.approvedMTDData],
          title: moment().format("MMM") + " Approvals by Profile",
          graph: {
            labels: this.state.approvedMTDProfileName,
            datasets: [
              {
                label: "Approved",
                backgroundColor: [
                  "rgba(88, 214, 141,1)",
                  "rgba(52, 152, 219,1)",
                  "rgba(255,215,0,1)",
                  "rgba(204, 209, 209,1)",
                ],
                borderColor: [
                  "rgba(88, 214, 141,1)",
                  "rgba(52, 152, 219,1)",
                  "rgba(255,215,0,1)",
                  "rgba(204, 209, 209,1)",
                ],
                data: this.state.approvedMTDProfileCount,
              },
            ],
          },
        },
        {
          type: "bar_stacked",
          id: todayYear + " Approved",
          loading: this.state.loadingYTD,
          fdata: [this.state.approvedYTD_Data],
          title: moment().format("YYYY") + " Approved\t" + ytdTotal,
          graph: {
            labels: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            datasets: [
              {
                label: "Approved",
                fill: false,
                backgroundColor: "rgba(88, 214, 141,1)",
                borderColor: "rgba(88, 214, 141,1)",
                data: this.state.approvedYTDCount,
              },
              {
                label: "Goals",
                fill: false,
                backgroundColor: "rgba(204, 209, 209,0.4)",
                borderColor: "rgba(204, 209, 209,0.4)",
                // data: this.state.salesYTDGoals
                data: goals,
              },
            ],
          },
        },
        {
          type: "table",
          id: months[todayMonth] + " Approvals by Partner",
          loading: this.state.loadingPartner,
          fdata: [this.state.approvedMTDData],
          title: moment().format("MMM") + " Approvals by Partner",
          graph: [
            {
              ISOName: this.state.approvedMTDPartnerName,
              count: this.state.approvedMTDPartnerCount,
              data: this.state.approvedMTDData,
            },
          ],
        },
        {
          type: "table",
          id: months[todayMonth] + " Closures by Partner",
          loading: this.state.loadingPartner,
          fdata: [this.state.closedMTDData],
          title: moment().format("MMM") + " Closures by Partner",
          graph: [
            {
              ISOName: this.state.closedMTDPartnerName,
              count: this.state.closedMTDPartnerCount,
              data: this.state.closedMTDData,
            },
          ],
        },
        {
          type: "table",
          id: "Today Submitted & Approved by Partner",
          loading: this.state.loadingTodayPartner,
          fdata: [this.state.approvedTodayData],
          title: "Today Submitted & Approvals by Partner",
          graph: [
            {
              ISOName: this.state.subApprTodayPartnerName,
              count: this.state.apprTodayPartnerCount,
              count2: this.state.subTodayPartnerCount,
              data: this.state.approvedTodayData,
            },
          ],
        },
        {
          type: "table",
          id: months[todayMonth] + " Approvals by SGPY",
          loading: this.state.loadingInHousePartner,
          fdata: [this.state.approvedMTDInHouseData],
          title: moment().format("MMM") + " Approvals by SGPY",
          graph: [
            {
              ISOName: this.state.approvedMTDInHousePartnerName,
              count: this.state.approvedMTDInHousePartnerCount,
              data: this.state.approvedMTDInHouseData,
            },
          ],
        },
        {
          type: "table",
          id: "Today Submitted & Approved by SGPY",
          loading: this.state.loadingTodayInHousePartner,
          fdata: [this.state.approvedTodayInHouseData],
          title: "Today Submitted & Approvals by SGPY",
          graph: [
            {
              ISOName: this.state.subApprTodayInHousePartnerName,
              count: this.state.apprTodayInHousePartnerCount,
              count2: this.state.subTodayInHousePartnerCount,
              data: this.state.approvedTodayInHouseData,
            },
          ],
        },
        // {
        //   type: "bar",
        //   id: "Pended",
        //   loading: this.state.loadingPend,
        //   fdata: [this.state.pended45daysData],
        //   title: "Pended for 45 days\t" + pendTotal,
        //   graph: {
        //     // labels: ["Level 1", "Level 2", "Level 3", "NULL"],
        //     labels: this.state.pended45daysLevelName,
        //     datasets: [
        //       {
        //         label: "Pended",
        //         backgroundColor: "rgba(255,215,0,1)",
        //         borderColor: "rgba(255,215,0,1)",
        //         data: this.state.pended45daysLevelCount,
        //       },
        //     ],
        //   },
        // },

        
      ];

      var options = {
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                precision: 0,
                scaleIntegersOnly: true,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                precision: 0,
                scaleIntegersOnly: true,
              },
            },
          ],
        },
      };

      var progressoptions = {
        cutoutPercentage: 99.5,
        elements: {
          center: {
            text: progressData[0],
            color: "rgba(0, 0, 0, 0.7)", //Default black
            fontStyle: "Roboto", //Default Arial
            sidePadding: 15, //Default 20 (as a percentage)
          },
        },
      };

      var stackedoptions = {
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                precision: 0,
                scaleIntegersOnly: true,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                precision: 0,
                scaleIntegersOnly: true,
              },
            },
          ],
        },
      };

      var cards1 = [];
      var cards2 = [];
      var cards3 = [];
      for (var i = 0; i < data.length; i++) {
        if (data[i].type === "bar_stacked") {
          cards2.push(
            <CustomSalesCard
              type={data[i].type}
              id={data[i].id}
              title={data[i].title}
              data={data[i].fdata}
              graph={data[i].graph}
              options={stackedoptions}
              loading={data[i].loading}
              func={this.getApprovedYTD_Details}
              exportfunc={this.xlsxDownloader}
            />
          );
        } else if (data[i].id === months[todayMonth] + " Goal") {
          cards1.push(
            <CustomSalesCard
              type={data[i].type}
              id={data[i].id}
              title={data[i].title}
              data={data[i].fdata}
              graph={data[i].graph}
              options={options}
              loading={data[i].loading}
              func={this.getMthDataByStatus}
              exportfunc={this.xlsxDownloader}
            />
          );
        } else if (data[i].id === "Today Submitted Approved") {
          cards2.push(
            <div className="todayYestCards">
            <CustomSalesCard
              type={data[i].type}
              id={data[i].id}
              title={data[i].title}
              data={data[i].fdata}
              tdata={data[i].tdata}
              // graph={data[i].graph}
              options={options}
              loading={data[i].loading}
              func={this.getTodayDataByStatus}
              exportfunc={this.xlsxDownloader}
            />
            <CustomSalesCard
              type={data[i].type}
              id={data[i].id}
              title={data[i].title}
              data={data[i].gdata}
              tdata={data[i].ydata}
              // graph={data[i].graph}
              options={options}
              loading={data[i].loading}
              func={this.getYestDataByStatus}
              exportfunc={this.xlsxDownloader}
            />
            </div>
          );

        } else if (data[i].id === "Yesterday Submitted Approved") {
          cards2.push(
            <CustomSalesCard
              type={data[i].type}
              id={data[i].id}
              title={data[i].title}
              data={data[i].fdata}
              tdata={data[i].tdata}
              // graph={data[i].graph}
              options={options}
              loading={data[i].loading}
              func={this.getYestDataByStatus}
              exportfunc={this.xlsxDownloader}
            />
          );
        } else if (data[i].id === months[todayMonth] + "Submitted Approved") {
          cards1.push(
            <CustomSalesCard
              type={data[i].type}
              id={data[i].id}
              title={data[i].title}
              data={data[i].fdata}
              graph={data[i].graph}
              options={options}
              loading={data[i].loading}
              func={this.getMthDataByStatus}
              exportfunc={this.xlsxDownloader}
            />
          );
        }
        // else if (
        //   data[i].id ===
        //   months[todayMonth] + " Approvals by Platform"
        // ) {
        //   cards2.push(
        //     <CustomSalesCard
        //       type={data[i].type}
        //       id={data[i].id}
        //       title={data[i].title}
        //       data={data[i].fdata}
        //       graph={data[i].graph}
        //       options={options}
        //       loading={data[i].loading}
        //       func={this.getMthDataByStatus}
        //       exportfunc={this.xlsxDownloader}
        //     />
        //   );
        // } else if (
        //   data[i].id ===
        //   months[todayMonth] + " Approvals by Profile"
        // ) {
        //   cards3.push(
        //     <CustomSalesCard
        //       type={data[i].type}
        //       id={data[i].id}
        //       title={data[i].title}
        //       data={data[i].fdata}
        //       graph={data[i].graph}
        //       options={options}
        //       loading={data[i].loading}
        //       func={this.getMthDataByStatus}
        //       exportfunc={this.xlsxDownloader}
        //     />
        //   );
        // }
        else if (data[i].id === months[todayMonth] + " Approvals by Partner" && this.state.isMonthByPartner === 0) {
          cards1.push(
            <CustomSalesCard
              type={data[i].type}
              id={data[i].id}
              title={data[i].title}
              data={data[i].fdata}
              graph={data[i].graph}
              options={options}
              loading={data[i].loading}
              func={this.getMthDataByStatus}
              exportfunc={this.xlsxDownloader}
              isMonth={this.state.isMonthByPartner}
              toggler={this.toggleMonthByPartner}
            />
          );
        }
        else if (data[i].id === months[todayMonth] + " Closures by Partner" && this.state.isMonthByPartner === 1) {
          cards1.push(
            <CustomSalesCard
              type={data[i].type}
              id={data[i].id}
              title={data[i].title}
              data={data[i].fdata}
              graph={data[i].graph}
              options={options}
              loading={data[i].loading}
              func={this.getMthDataByStatus}
              exportfunc={this.xlsxDownloader}
              isMonth={this.state.isMonthByPartner}
              toggler={this.toggleMonthByPartner}
            />
          );
        }
        else if (data[i].id === "Today Submitted & Approved by Partner" && this.state.isMonthByPartner === 2) {
          cards1.push(
            <CustomSalesCard
              type={data[i].type}
              id={data[i].id}
              title={data[i].title}
              data={data[i].fdata}
              graph={data[i].graph}
              options={options}
              loading={data[i].loading}
              func={this.getTodayDataByStatus}
              exportfunc={this.xlsxDownloader}
              isMonth={this.state.isMonthByPartner}
              toggler={this.toggleMonthByPartner}
            />
          )
        }
        else if (data[i].id === months[todayMonth] + " Approvals by SGPY" && this.state.isMonthByInHousePartner) {
          cards2.push(
            <CustomSalesCard
              type={data[i].type}
              id={data[i].id}
              title={data[i].title}
              data={data[i].fdata}
              graph={data[i].graph}
              options={options}
              loading={data[i].loading}
              func={this.getMthDataByStatusInHouse}
              exportfunc={this.xlsxDownloader}
              isMonth={this.state.isMonthByInHousePartner}
              toggler={this.toggleMonthByInHousePartner}
            />
          );
        }
        else if (data[i].id === "Today Submitted & Approved by SGPY" && this.state.isMonthByInHousePartner === false) {
          cards2.push(
            <CustomSalesCard
              type={data[i].type}
              id={data[i].id}
              title={data[i].title}
              data={data[i].fdata}
              graph={data[i].graph}
              options={options}
              loading={data[i].loading}
              func={this.getTodayDataByStatusInHouse}
              exportfunc={this.xlsxDownloader}
              isMonth={this.state.isMonthByInHousePartner}
              toggler={this.toggleMonthByInHousePartner}
            />
          )
        }
        // else if (data[i].id === "Pended") {
        //   cards2.push(
        //     <CustomSalesCard
        //       type={data[i].type}
        //       id={data[i].id}
        //       title={data[i].title}
        //       data={data[i].fdata}
        //       graph={data[i].graph}
        //       options={options}
        //       loading={data[i].loading}
        //       func={this.getPended45days}
        //       exportfunc={this.xlsxDownloader}
        //     />
        //   );
        // }
      }
      return (
        <div>
          <div>
            <CardDeck> {cards1}</CardDeck>
            <CardDeck> {cards2}</CardDeck>
            <CardDeck> {cards3}</CardDeck>
          </div>
        </div>
      );
    }
  }
}

export default CurrentMth;
