import React, { useEffect, useState } from "react"
import { Form, InputGroup, Button, DropdownButton, Dropdown, ListGroup, Row, Col, Toast } from "react-bootstrap"
import { withCookies } from "react-cookie"
import "../Style/Style.scss"
import { ApiClient } from "../../ApiClient"
import Exception from "./BillingTypeException"
import { useException } from "./BillingTypesContext"

function BillingTypes(props) {
    const [defaultBillingType, setDefaultBillingType] = useState()
    const [billingTypes, setBillingTypes] = useState()
    const [filters, setFilters] = useState()
    const [filterOperators, setFilterOperators] = useState()
    const [exceptions, setExceptions] = useState()
    const [flags, setFlags] = useState([])
    const { saveExceptions, setExceptionList, setExceptionFrequency, addNewException, removeExceptionFilter, addNewExceptionFilter } = useException()
    const [saved, setSaved] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [message, setMessage] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [canEdit, setCanEdit] = useState(false)
    const editors = ['daniellec@signapay.com', 'bahramb@signapay.com', 'katherineh@signapay.com']

    const onSaveDefault = () => {
        setSaved(false)
        setIsLoading(true)
        ApiClient.post({
            url: "/api/pci/UpdateDefaultFrequency",
            body: {
                flag: defaultBillingType
            }
        }).then((json) => {
            setSaved(true)
            setHasError(false)
            setMessage("Saved successfully")
            setIsLoading(false)
        }).catch(err => {
            setSaved(true)
            setHasError(true)
            setMessage("ERROR! try again")
            setIsLoading(false)
            console.log(err)
        })
    }

    const onSaveExceptions = async () => {
        setSaved(false)
        setIsLoading(true)
        const result = await saveExceptions()
        setSaved(true)
        setHasError(!result)
        setMessage(result ? "Saved successfully" : "Please either fill up empty fields or remove them!")
        if(result) getBillingTypes()
        setIsLoading(false)
    }

    const getDefaultBillingTypes = () => {
        ApiClient.get({
            url: "/api/pci/GetDefaultFrequencies",
        }).then((json) => {
            setDefaultBillingType(json.data[0].frequency)
        }).catch(err => {
            console.log(err)
        })
    }

    const getBillingTypes = () => {
        ApiClient.get({
            url: "/api/pci/GetFrequencies",
        }).then((json) => {
            setBillingTypes(json.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const getFilters = () => {
        ApiClient.get({
            url: "/api/pci/GetFilters",
        }).then((json) => {
            setFilters(json.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const getFilterOperatpors = () => {
        ApiClient.get({
            url: "/api/pci/GetFilterOperators",
        }).then((json) => {
            setFilterOperators(json.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const setFrequency = (index, frequency_group, value) => {
        setExceptions(exceptions =>
            exceptions.map((exception, i) => 
                i === index ?
                [
                    ...exception.map((exc) =>
                        exc.frequency_group === frequency_group ? {...exc, frequency: value} : {...exc}    
                    )
                    
                ] : [...exception]
            )
        )
        setExceptionFrequency(index, frequency_group, value)
    }

    const addException = () => {
        const temp_id = new Date().getTime()
        const temp_frequency_group = new Date().getTime()
        setExceptions(exceptions => 
            [...exceptions, 
            [{
                id: temp_id,
                frequency: null,
                filter_id: null,
                filter_value: null,
                filter_operator_id: null,
                frequency_group: temp_frequency_group
            }]]
        )
        addNewException(temp_id, temp_frequency_group)
    }

    const addFilter = (index, frequency_group, frequency) => {
        const temp_id = new Date().getTime()
        setExceptions(exceptions =>
            exceptions.map((exception, i) => 
                index === i
                ?   [
                        ...exception,
                        {
                            id: temp_id,
                            frequency: frequency,
                            filter_id: null,
                            filter_value: null,
                            filter_operator_id: null,
                            frequency_group: frequency_group,
                        }
                    ]
                :   [ ...exception ]
            )
        )
        addNewExceptionFilter(index, frequency_group, frequency, temp_id)
    }

    const removeFilter = (index, id) => {
        setExceptions(exceptions =>
            exceptions.map((exception, i) => 
                index === i    
                ?   [ ...exception.filter(exc => exc.id !== id) ]
                :   [ ...exception ]
            ).filter(exception => exception.length !== 0)
        )
        removeExceptionFilter(index, id)
    }

    useEffect(() => {
        if(!billingTypes) return
        let frequency_group = []
        let groups = []
        for (const obj of billingTypes) {
            if(frequency_group.includes(obj.frequency_group)) continue
                frequency_group.push(obj.frequency_group)

            const group = billingTypes.filter(type => type.frequency_group == obj.frequency_group)
            groups.push(group)
        }
        setExceptions(groups)
        setExceptionList(groups)
        setIsLoading(false)
    }, [billingTypes])

    useEffect(() => {
        if(!filters || !filterOperators) return
        getBillingTypes()
    }, [filters, filterOperators])

    useEffect(() => {
        if(flags.length === 0) return
        getDefaultBillingTypes()
        getFilters()
        getFilterOperatpors()
        setSaved(false)
        setHasError(false)
    }, [flags])

    useEffect(() => {
        if(props.flags.length === 0) return
        setFlags([...props.flags])
    }, [props])

    useEffect(() => {
        const { cookies } = props;
        const email = cookies.get("email");
        setCanEdit(editors.includes(email));
    }, [])

    return (
        <>
        <Form inline>
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text>Default Billing Type</InputGroup.Text>
                </InputGroup.Prepend>
                <InputGroup.Append>
                <DropdownButton variant="outline-primary" 
                                title={flags && {...flags.find(f => f.flag === defaultBillingType)}.description || 'Select'}
                                onSelect={(v) => setDefaultBillingType(v)}
                >
                    <Dropdown.Item eventKey="MTF">{flags && {...flags.find(f => f.flag === "MTF")}.description}</Dropdown.Item>
                    <Dropdown.Item eventKey="ANF">{flags && {...flags.find(f => f.flag === "ANF")}.description}</Dropdown.Item>
                </DropdownButton>
                </InputGroup.Append>
            </InputGroup>
            <Button className="mb-3 ml-2" onClick={onSaveDefault} disabled={isLoading || !canEdit}>
                Save
            </Button>
        </Form>
        <div className="billingException">
            {isLoading && <div className="exceptionLoadingLayer"></div>}
            <ListGroup>
            {exceptions && exceptions.map((exception, i) => {
                return <ListGroup.Item key={i}>
                    <Row key={i}>
                        <Col md="auto">
                            <Form.Control as="select" custom value={exception[0].frequency || ''} onChange={e => setFrequency(i, exception[0].frequency_group, e.target.value)}>
                                {!exception[0].frequency && <option></option>}
                                {flags && flags.filter(f => f.flag !== 'NCF').map(f => 
                                    <option value={f.flag} key={f.flag}>{f.description}</option>
                                )}
                            </Form.Control>
                        </Col>
                        <Col md="auto">
                            {exception.map((exc, j) => {
                                    return <Row className="mb-1" key={exc.id}> 
                                        <Exception index={i} data={exc} filters={filters} filterOperators={filterOperators} />
                                        <Button variant="link" className="text-danger" onClick={() => removeFilter(i, exc.id)}>&#x2716;</Button>
                                    </Row>
                            })}
                            <Row>
                                <Button size="sm" variant="link" onClick={() => addFilter(i, exception[0].frequency_group, exception[0].frequency)}>
                                    Add Filter
                                </Button>
                            </Row>
                        </Col>
                        <Col>
                            
                        </Col>
                    </Row>
                </ListGroup.Item>
            })}
            </ListGroup>
            <Button size="sm" className="mt-2" variant="outline-primary" onClick={() => addException()}>Add Exception</Button>
        </div>
        <div className="mt-2">
            <Form inline>
                <Button className="mr-2" onClick={() => getBillingTypes()} disabled={isLoading}>Reset</Button>
                <Button onClick={() => onSaveExceptions()} disabled={isLoading || !canEdit}>Save Exceptions</Button>
            </Form>
        </div>
        <div className="configToast">
            <Toast onClose={() => setSaved(false)} show={saved} delay={3000} autohide>
            <Toast.Body>
                <div className={`alert alert-${hasError ? "danger " : "success"} mb-0 py-2`} role="alert">
                    <small style={{ fontSize: ".7rem"}}>{message}</small>
                </div>
            </Toast.Body>
            </Toast>
        </div>
        </>
    )
}

export default withCookies(BillingTypes);
