import React, { Component } from "react";
import {
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  FormText
} from "react-bootstrap";
import "./Style/register.css";
import { withCookies } from "react-cookie";
import { Auth } from "../Auth"
 
class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      department: "",
      password: "",
      confirmpassword: "",
      msg:
        "min 8 char; atleast one uppercase, lowercase, numeric and special char",
      isLoading: false
    };
    this.onChangeUp = this.onChangeUp.bind(this);
  }

  validateForm = () => {
    return (
      this.state.email.length > 0 &&
      this.state.department.length > 0 &&
      this.state.password.length > 0 &&
      this.state.confirmpassword.length > 0 &&
      this.state.password === this.state.confirmpassword
    );
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
  };

  onChangeUp(event) {
    const { email, department, password, confirmpassword } = this.state;
    this.setState({
      isLoading: true
    });
    Auth.register(email, department, password, confirmpassword)
    .then(json => {
      if (json.data.success) {
        this.setState({
          email: "",
          isLoading: false,
          msg: json.data.message,
          department: "",
          password: "",
          confirmpassword: ""
        });
        event.preventDefault();
      } else {
        this.setState({
          isLoading: false,
          msg: json.data.message
        });
      }
    }).catch(error => {
      this.setState({
        isLoading: false,
        msg: error.response.data.message
      });
    });
  }

  // DO NOT CHANGE THE CONTROLID OF THE FORMGROUP
  // ALSO KEEP THE NAME OF CONTROLID & CONSTRUCTOR VARIABLE EXACTLY SAME
  render() {

    return (
      <div>
        <div className="Login">
          <form onSubmit={this.handleSubmit}>
            <FormGroup controlId="email">
              <FormLabel>Email</FormLabel>
              <FormControl
                value={this.state.email}
                onChange={this.handleChange}
                type="email"
              />
            </FormGroup>
            <FormGroup controlId="department">
              <FormLabel>Department</FormLabel>
              <FormControl
                value={this.state.department}
                onChange={this.handleChange}
                as="select"
              >
                <option value="">Please select...</option>
                <option value="sales">Sales</option>
                <option value="finance">Finance</option>
                <option value="admin">Admin</option>
              </FormControl>
            </FormGroup>
            <FormGroup controlId="password">
              <FormLabel>New Password</FormLabel>
              <FormControl
                value={this.state.password}
                onChange={this.handleChange}
                type="password"
              />
            </FormGroup>
            <FormGroup controlId="confirmpassword">
              <FormLabel>Confirm Password</FormLabel>
              <FormControl
                value={this.state.confirmpassword}
                onChange={this.handleChange}
                type="password"
              />
            </FormGroup>
            <Button
              block
              disabled={!this.validateForm()}
              variant="outline-warning"
              type="submit"
              onClick={this.onChangeUp}
            >
              Register
            </Button>
            <FormText className="text-muted">{this.state.msg}</FormText>
          </form>
        </div>
      </div>
    );
  }
}
export default withCookies(Register);
