import React, { useEffect, useState } from "react"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { Form, Modal, Button, Col, InputGroup, Dropdown, Alert } from "react-bootstrap"
import { ApiClient } from "../ApiClient"
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

export default function AddGroupModal(props) {

    const [error, setError] = useState(null)
    const [loading, setLoading] = React.useState(false)

    const onSubmit = (e) => {
        e.persist()
        e.preventDefault()
        setError(null)
        setLoading(true)

        const formData = new FormData(e.target)

        ApiClient.post({
            url: "/api/partners/AddGroupId",
            body: {
                accountid: props.accountId,
                bank: formData.get("bank"),
                effectivedate: formData.get("effective-date"),
                groupname: formData.get("group-name"),
                profile: formData.get("profile"),
                revshare: formData.get("rev-share"),
            }
        }).then(json => {
            props.onAdd({
                accountid: props.accountId,
                bank: formData.get("bank"),
                effectivedate: formData.get("effective-date"),
                groupname: formData.get("group-name"),
                profile: formData.get("profile"),
                revshare: formData.get("rev-share"),
                processor: 'TSYS',
                ...json.data
            })
            props.onHide()
            setError(null)
        }).catch(err => {
            console.log(err)
            setError(err)
        }).finally(() => setLoading(false));
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <small>Add Group ID</small>
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={onSubmit}>
                <Modal.Body>
                    <Form.Group className="mph-add-group-id">
                        <Form.Row>
                            <Col column="md" md={3} className="text-right pt-2">
                                Group Name
                            </Col>
                            <Col>
                                <FormControl size="small" style={{ width: "100%" }}>
                                    <TextField name="group-name" defaultValue={props.accountName} required size="small" variant="outlined" />
                                </FormControl>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col column="md" md={3} className="text-right pt-2">
                                Rev Share
                            </Col>
                            <Col>
                                <FormControl size="small" style={{ width: "100%" }}>
                                    <TextField name="rev-share" defaultValue={props.isc} type="number" required size="small" variant="outlined" />
                                </FormControl>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col column="md" md={3} className="text-right pt-2">
                                Bank
                            </Col>
                            <Col>
                                <FormControl variant="outlined" size="small" style={{ width: "200px" }}>
                                    <Select required name="bank">
                                        <MenuItem value="CBCAL">CBCAL</MenuItem>
                                        <MenuItem value="Esquire">Esquire</MenuItem>
                                        <MenuItem value="Chesapeake">Chesapeake</MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col column="md" md={3} className="text-right pt-2">
                                Profile
                            </Col>
                            <Col>
                                <FormControl variant="outlined" size="small" style={{ width: "200px" }}>
                                    <Select name="profile">
                                        <MenuItem value="PayLo">PayLo</MenuItem>
                                        <MenuItem value="Traditional">Traditional</MenuItem>
                                        <MenuItem value="High Risk">High Risk</MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col column="md" md={3} className="text-right pt-2">
                                Effective Date
                            </Col>
                            <Col>
                                <FormControl>
                                    <TextField name="effective-date" required variant="outlined" type="date" size="small" />
                                </FormControl>
                            </Col>
                        </Form.Row>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" disabled={loading}>Save</Button>
                    {!!error && <div className="mph-modal-error">
                        <Alert variant="danger" className="text-center">
                            <small>{error}</small>
                        </Alert></div>}
                </Modal.Footer>
            </form>
        </Modal>
    )
}