//Created by Katherine Hartman
//Handles the rendering and data retrieval of the financial dashboard.

import React, { Component } from "react";
import { Card } from "react-bootstrap";
import moment from "moment";
import { withCookies } from "react-cookie";
import Loader from "react-loader-spinner";
import Select, { components } from 'react-select';
import ApprovalsGraph from "./graphs/ApprovalsGraph";
import "./Style/isoanalytics.scss";
import ISOMap from "./ISOMap";
import CusTable from "./CusTable";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
    MinMaxNumColumnFilter,
    AutoMultiSelectColumnFilter,
  } from "../SelfServe/Filters";
import { ApiClient } from "../ApiClient";


class ISOAnalytics extends Component {
    constructor() {
        super();
        this.state = {
            davg: "12 Month",
            gselected: null,
            groups: [],
            inputValue: "",
            selectedOptions: [],
            bankOptions: [],
            partnerOptions: [],
            mccOptions: [],
            profileOptions: [],
            omonthOptions: [],
            oyearOptions: [],
            graph: [],
            mapData: [],
            isoStats: [],
            savolume: 0,
            satransactions: 0,
            saincome: 0,
            saage: 0,
            avolume: 0,
            atransactions: 0,
            aincome: 0,
            aage: 0,
            where: "",
            linegraph: "",
            loaded: false,
            time: {
                min: moment().subtract(13, 'months'),
                max: moment().subtract(0, 'months')
            },
        };
        if (typeof (Storage) !== "undefined") {
            if (sessionStorage.getItem("selectedOptions")) {
                try {
                    this.state.selectedOptions = JSON.parse(sessionStorage.getItem("selectedOptions"));
                }
                catch (error) {
                    console.log(sessionStorage.getItem("selectedOptions"));
                    sessionStorage.removeItem("selectedOptions");
                }
            }
            else {
                console.log("no options found");
            }
        }
        else {
            console.log("storage not supported");
        }
        this.getInfo();
    }

    //gets data from the server to display on the graphs
    getInfo = () => {
        console.log("getting info");
        ApiClient
            .get({
                url: "/api/isoanalyticsoptions"
            })
            .then(res => {
                // var profileo = (res.data.profile.map(val => val.Merchant_Profile));
                // var banko = (res.data.bank.map(val => val.Bank));
                // var mcco = (res.data.mcc.map(val => val.MCC));
                // var mccd = (res.data.descript.map(val => val.Code + " - " + val.Description));
                var partnero = (res.data.partner.map(val => val.ISO));
                // var mido = (res.data.mid.map(val => val.MID));
                // var dbao = (res.data.mid.map(val => val.MID + " - " + val.DBA));
                // var omontho = (res.data.omonth.map(val => val.open_month));
                // var oyearo = (res.data.oyear.map(val => val.open_year));

                // var mcc = [];
                // for (var i = 0; i < mcco.length; i++){
                //   for (var j = 0; j < mccd.length; j++){
                //     if (res.data.descript[j].Code === mcco[i]){
                //       mcc.push(mccd[j]);
                //       break;
                //     }
                //   }
                // }
                // omontho.sort(function(a, b){return a-b});
                // oyearo.sort(function(a, b){return a-b});

                this.setState({
                    //   profileOptions: profileo,
                    //   bankOptions: banko,
                    //   mccOptions: mcc,
                    partnerOptions: partnero,
                    //   midOptions: mido,
                    //   dbaOptions: dbao,
                    //   omonthOptions: omontho,
                    //   oyearOptions: oyearo

                });
                // var bank = [];
                // for (i = 0 ; i < this.state.bankOptions.length; i++){
                //   if (this.state.bankOptions[i] === "NULL"){
                //     bank.push({label: "Not Specified", value: this.state.bankOptions[i]});
                //   }
                //   else{
                //     bank.push({label: this.state.bankOptions[i], value: this.state.bankOptions[i]});
                //   }
                // }
                // var profile = [];
                // for (var i = 0 ; i < this.state.profileOptions.length; i++){
                //   if (this.state.profileOptions[i] === "NULL"){
                //     profile.push({label: "Not Specified", value: this.state.profileOptions[i]});
                //   }
                //   else{
                //     profile.push({label: this.state.profileOptions[i], value: this.state.profileOptions[i]});
                //   }
                // }
                // var mcc = [];
                // for (var i = 0 ; i < this.state.mccOptions.length; i++){
                //   if (this.state.mccOptions[i] === "NULL"){
                //     mcc.push({label: "Not Specified", value: this.state.mccOptions[i]});
                //   }
                //   else{
                //     mcc.push({label: this.state.mccOptions[i], value: this.state.mccOptions[i]});
                //   }
                // }
                var partner = [];
                for (var i = 0; i < this.state.partnerOptions.length; i++) {
                    if (this.state.partnerOptions[i] === "NULL") {
                        partner.push({ label: "Not Specified", value: this.state.partnerOptions[i] });
                    }
                    else {
                        partner.push({ label: this.state.partnerOptions[i], value: this.state.partnerOptions[i] });
                    }
                }
                // var mid = [];
                // for (var i = 0 ; i < this.state.midOptions.length; i++){
                //   if (this.state.midOptions[i] === "NULL"){
                //     mid.push({label: "Not Specified", value: this.state.midOptions[i]});
                //   }
                //   else{
                //     mid.push({label: this.state.dbaOptions[i], value: this.state.midOptions[i]});
                //   }
                // }
                // var monthname = ["January","February","March","April","May","June","July","August","September","October","November","December"]
                // var omonth = [];
                // for (var i = 0 ; i < this.state.omonthOptions.length; i++){
                //   if (this.state.omonthOptions[i] === null){
                //     omonth.push({label: "Not Specified", value: this.state.omonthOptions[i]});
                //   }
                //   else{
                //     omonth.push({label: monthname[this.state.omonthOptions[i]-1], value: this.state.omonthOptions[i]});
                //   }
                // }
                // var oyear = [];
                // for (var i = 0 ; i < this.state.oyearOptions.length; i++){
                //   if (this.state.oyearOptions[i] === null){
                //     oyear.push({label: "Not Specified", value: this.state.oyearOptions[i]});
                //   }
                //   else{
                //     oyear.push({label: this.state.oyearOptions[i], value: this.state.oyearOptions[i]});
                //   }
                // }
                var group = [
                    //   {
                    //     label: "Profile",
                    //     options: profile
                    //   },
                    //   {
                    //     label: "Bank",
                    //     options: bank
                    //   },
                    //   {
                    //     label: "Open Month",
                    //     options: omonth
                    //   },
                    //   {
                    //     label: "Open Year",
                    //     options: oyear
                    //   },
                    //   {
                    //     label: "MCC",
                    //     options: mcc
                    //   },
                    {
                        label: "Partner",
                        options: partner
                    }
                    // ,
                    // {
                    //   label: "Merchant",
                    //   options: mid
                    // }
                ]
                this.setState({
                    // loaded: true,
                    groups: group
                });
                this.getData(this.state.selectedOptions, moment().startOf('month').subtract(12, 'months').format("YYYY-MM-DD"));
            }).catch(err => {
                console.log(err);
            });
    };

    getData = (selectedOptions, date) => {
        console.log("reached getData");
        // var pr = [];
        // var ba = [];
        // var mc = [];
        var pa = [];
        // var mid = [];
        // var om = [];
        // var oy = [];

        if (selectedOptions) {
            for (var i = 0; i < selectedOptions.length; i++) {
                // if (this.state.profileOptions.includes(selectedOptions[i].value)) {
                //     pr.push(selectedOptions[i].value);
                // }
                // else if (this.state.bankOptions.includes(selectedOptions[i].value)) {
                //     ba.push(selectedOptions[i].value);
                // }
                // else if (this.state.omonthOptions.includes(selectedOptions[i].value)) {
                //     om.push(selectedOptions[i].value);
                // }
                // else if (this.state.oyearOptions.includes(selectedOptions[i].value)) {
                //     oy.push(selectedOptions[i].value);
                // }
                // else if (this.state.mccOptions.includes(selectedOptions[i].value)) {
                //     mc.push(selectedOptions[i].value);
                // }
                if (this.state.partnerOptions.includes(selectedOptions[i].value)) {
                    pa.push(selectedOptions[i].value);
                }
                // else if (this.state.midOptions.includes(selectedOptions[i].value)) {
                //     mid.push(selectedOptions[i].value);
                // }
                else {
                    console.log(
                        "ERROR OPTION NOT RECOGNIZED",
                        { Merchant_Profile: selectedOptions[i].value }
                    );
                }
            }
        }
        var wheres = "";
        var continuing = false;
        // if (pr.length > 0 || ba.length > 0 || om.length > 0 || oy.length > 0 || mc.length > 0 || pa.length > 0 || mid.length > 0) {
        if (pa.length > 0) {
            wheres = "where "
            // if (pr.length > 0) {
            //     wheres = wheres + "(";
            //     for (var i = 0; i < pr.length; i++) {
            //         if (i !== 0) {
            //             wheres = wheres + " or";
            //         }
            //         wheres = wheres + " Merchant_Profile like '" + pr[i] + "'";
            //         continuing = true;
            //     }
            //     if (continuing) {
            //         wheres = wheres + ")"
            //     }
            // }
            // if (ba.length > 0) {
            //     for (var i = 0; i < ba.length; i++) {
            //         if (continuing && i === 0) {
            //             wheres = wheres + " and (";
            //         }
            //         else if (i === 0) {
            //             wheres = wheres + "("
            //         }
            //         if (i !== 0) {
            //             wheres = wheres + " or";
            //         }
            //         wheres = wheres + " Bank like '" + ba[i] + "'";
            //         continuing = true;
            //     }
            //     if (continuing) {
            //         wheres = wheres + ")"
            //     }
            // }
            // if (om.length > 0) {
            //     for (var i = 0; i < om.length; i++) {
            //         if (continuing && i === 0) {
            //             wheres = wheres + " and (";
            //         }
            //         else if (i === 0) {
            //             wheres = wheres + "("
            //         }
            //         if (i !== 0) {
            //             wheres = wheres + " or";
            //         }
            //         wheres = wheres + " extract(month from open_date) = " + om[i];
            //         continuing = true;
            //     }
            //     if (continuing) {
            //         wheres = wheres + ")"
            //     }
            // }
            // if (oy.length > 0) {
            //     for (var i = 0; i < oy.length; i++) {
            //         if (continuing && i === 0) {
            //             wheres = wheres + " and (";
            //         }
            //         else if (i === 0) {
            //             wheres = wheres + "("
            //         }
            //         if (i !== 0) {
            //             wheres = wheres + " or";
            //         }
            //         wheres = wheres + " extract(year from open_date) = " + oy[i];
            //         continuing = true;
            //     }
            //     if (continuing) {
            //         wheres = wheres + ")"
            //     }
            // }
            // if (mc.lenght > 0) {
            //     for (var i = 0; i < mc.length; i++) {
            //         if (continuing && i === 0) {
            //             wheres = wheres + " and (";
            //         }
            //         else if (i === 0) {
            //             wheres = wheres + "("
            //         }
            //         if (i !== 0) {
            //             wheres = wheres + " or";
            //         }
            //         wheres = wheres + " MCC like '" + mc[i].substring(0, 4) + "'";
            //         continuing = true;
            //     }
            //     if (continuing) {
            //         wheres = wheres + ")"
            //     }
            // }
            if (pa.length > 0) {
                for (i = 0; i < pa.length; i++) {
                    if (continuing && i === 0) {
                        wheres = wheres + " and (";
                    }
                    else if (i === 0) {
                        wheres = wheres + "("
                    }
                    if (i !== 0) {
                        wheres = wheres + " or";
                    }
                    wheres = wheres + " GroupLevel1Name like '" + pa[i] + "'";
                    continuing = true;
                }
                if (continuing) {
                    wheres = wheres + ")"
                }
            }
            // if (mid.length > 0) {
            //     for (var i = 0; i < mid.length; i++) {
            //         if (continuing && i === 0) {
            //             wheres = wheres + " and (";
            //         }
            //         else if (i === 0) {
            //             wheres = wheres + "("
            //         }
            //         if (i !== 0) {
            //             wheres = wheres + " or";
            //         }
            //         wheres = wheres + " MID like '" + mid[i] + "'";
            //         continuing = true;
            //     }
            //     if (continuing) {
            //         wheres = wheres + ")"
            //     }
            // }
        }
        var whereav = "";
        if (wheres) {
            whereav = wheres + "and Year_Months >= '" + date + "'";
        }
        else {
            whereav = " where Year_Months >= '" + date + "'";
        }
        // console.log(
        //     `where = '",${wheres},"'`
        // );
        //   axiosInstance
        //     .post(`${process.env.REACT_APP_HOSTNAME}:${process.env.REACT_APP_SERVERPORT}/api/finaverage`, {
        //       sql: whereav
        //     })
        //     .then(resl => {
        //       if (this.state.loaded){
        //         this.setState({
        //           avolume: resl.data.output[0].Volume,
        //           atransactions: resl.data.output[0].Transactions,
        //           aincome: resl.data.output[0].Income,
        //           aage: resl.data.output[0].Average_Age,
        //         });
        //       }
        //       else{
        //         this.setState({
        //           savolume: resl.data.output[0].Volume,
        //           satransactions: resl.data.output[0].Transactions,
        //           saincome: resl.data.output[0].Income,
        //           saage: resl.data.output[0].Average_Age,
        //           avolume: resl.data.output[0].Volume,
        //           atransactions: resl.data.output[0].Transactions,
        //           aincome: resl.data.output[0].Income,
        //           aage: resl.data.output[0].Average_Age,
        //           loaded: true
        //         });
        //       }
        //     });
        ApiClient
            .post({
                url: "/api/isoanalyticsdata",
                body: {
                    sql: wheres
                }
            })
            .then(res => {
                this.setState({
                    graph: res.data.output[0],
                    mapData: res.data.output[1],
                    isoStats: res.data.output[2],
                    where: wheres,
                    loaded: true
                }, () => {
                    this.setState({
                        linegraph: this.handleData()
                    })
                });
            }).catch(err => {
                console.log(err);
            });
    };

    getRandomColor = () => {
        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    handleData = () => {
        var d = this.state.graph;
        var res = [];

        for (let i = 0; i < this.state.partnerOptions.length; i++) {
            let isoarr = {};
            let iso = this.state.partnerOptions[i]
            let tmp = d.filter((e) => e.GroupLevel1Name === iso);
            var arr = []
            for (let j = 0; j < tmp.length; j++) {
                arr.push({ x: moment(tmp[j].OpenDate).format('YYYY-MM-DD'), y: tmp[j].ApprovalCount });
            }

            var color = this.getRandomColor();

            isoarr = {
                'data': arr,
                'label': iso,
                'type': 'line',
                'fill': false,
                'backgroundColor': color,
                'borderColor': color
            };
            res.push(isoarr);
        }
        return { 'datasets': res };
    }

    //allows graphs to pan and zoom in unison
    handleMove = (newMin, newMax) => {
        this.setState({ time: { min: newMin, max: newMax } });
    };

    handleChange = (selectedOptions) => {
        this.setState({ selectedOptions });
        sessionStorage.setItem("selectedOptions", JSON.stringify(selectedOptions));
        this.setState({ davg: "12 Month", gselected: null });
        this.getData(selectedOptions, moment().startOf('month').subtract(12, 'months').format("YYYY-MM-DD"));
    }

    onInputChange = (option, { action }) => {
        if (action === "input-change") {
            const optionLength = option.length;
            const inputValue = this.state.inputValue;
            const inputValueLength = inputValue.length;

            const newInputValue =
                optionLength < inputValueLength
                    ? option
                    : this.state.inputValue + option[option.length - 1];
            this.setState({
                inputValue: newInputValue
            });
        }
    };

    render() {
        const groupStyles = {
            color: 'white',
            background: "rgba(88, 214, 141, .5)",
            padding: '5px 0px',
            display: 'flex'
        };

        const columns = [
            {
              Header: "ISO",
              id: "groupname",
              accessor: "groupname",
              Filter: AutoMultiSelectColumnFilter,
              filter: "includesSome",
            },
            {
              Header: "First Deal",
              id: "first",
              accessor: (d) => {
                if (d.first) {
                  return moment.utc(d.first).format("YYYY-MM-DD");
                }
              },
            },
            {
              Header: "Last Deal",
              id: "last",
              accessor: (d) => {
                if (d.last) {
                  return moment.utc(d.last).format("YYYY-MM-DD");
                }
              },
            },
            {
              Header: "Avg Days Between Deal",
              id: "avgdaysbw",
              accessor: "avgdaysbw",
              Filter: MinMaxNumColumnFilter,
              filter: "between",
            },
            {
              Header: "Open Deals",
              id: "openCount",
              accessor: "openCount",
              Filter: MinMaxNumColumnFilter,
              filter: "between",
            },
            {
              Header: "Approved Deals",
              id: "approvedCount",
              accessor: "approvedCount",
              Filter: MinMaxNumColumnFilter,
              filter: "between",
            },
            {
                Header: "Retention %",
                id: "retentionPercent",
                accessor: "retentionPercent",
                Filter: MinMaxNumColumnFilter,
                filter: "between",
            },
            {
                Header: "Total Income Per Deal($)",
                id: "TotalIncomePerDeal",
                accessor: "TotalIncomePerDeal",
                Filter: MinMaxNumColumnFilter,
                filter: "between",
            },
            {
                Header: "ISO Profit Per Deal($)",
                id: "ISOProfitPerDeal",
                accessor: "ISOProfitPerDeal",
                Filter: MinMaxNumColumnFilter,
                filter: "between",
            },
            {
                Header: "SignaPay Profit Per Deal($)",
                id: "SignaPayProfitPerDeal",
                accessor: "SignaPayProfitPerDeal",
                Filter: MinMaxNumColumnFilter,
                filter: "between",
            },
            {
                Header: "SignaPay Profit Per ISO($)",
                id: "SignaPayProfitPerISO",
                accessor: "SignaPayProfitPerISO",
                Filter: MinMaxNumColumnFilter,
                filter: "between",
            },
          ];

        const GroupHeading = props => (
            <div style={groupStyles}>
                <components.GroupHeading {...props} />
            </div>
        );

        return (
            <div className="Dashboard">
                {this.state.loaded ? (
                    <Card className="financial">
                        <Card.Header className="Filters">
                            <h1>Filters: </h1>
                            <Select
                                value={this.state.selectedOptions}
                                inputValue={this.state.inputValue}
                                onInputChange={this.onInputChange}
                                isMulti
                                onChange={this.handleChange}
                                options={this.state.groups}
                                components={{ GroupHeading }}
                                closeMenuOnSelect={false}
                            />
                        </Card.Header>
                        <Card>
                            <Card.Header>ISO Map of Active Merchants</Card.Header>
                            <Card.Body>
                                <ISOMap data={this.state.mapData} />
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Header>ISO Stats</Card.Header>
                            <Card.Body>
                                <CssBaseline />
                                <CusTable
                                    columns={columns}
                                    data={this.state.isoStats}
                                    email={null}
                                    getData={null}
                                    loading={false}
                                />
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Header>ISO Monthly Approvals</Card.Header>
                            <Card.Body className="analytics">
                                <div className="flex">
                                    <div className="linegraph">
                                        <div className="graphs">
                                            <ApprovalsGraph
                                                classname="line"
                                                id="approvalline"
                                                data={this.state.linegraph}
                                                states={this.state.SelectedOption}
                                                handleMove={(min, max) => this.handleMove(min, max)}
                                                time={this.state.time}
                                                iselected={this.state.gselected}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Card>
                ) : (
                    <div style={{ height: '100%', width: '100%', justifyContent: "center", display: "flex" }}>
                        <Loader color="green" width={125} heigth={125} type="Oval" margin="auto" />
                    </div>
                )}
            </div>
        );
    }
}

export default withCookies(ISOAnalytics);
