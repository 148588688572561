import React, { Component } from "react";
import CardColumns from "react-bootstrap/CardColumns";
import CustomSalesCard from "./CustomSalesCard";
import moment from "moment";
import XLSX from "xlsx";
import { ApiClient } from "../ApiClient"


class PreviousMth extends Component {
  getMth() {
    ApiClient
      .post({
        url: "/api/month",
        body: {
          dt: this.props.day.format()
        }
      })
      .then(json => {
        if (Object.keys(json.data).length === 0) {
          this.setState({
            submittedMTDCount: "0",
            approvedMTDCount: "0",
            closedMTDCount: "0",
            apprHighriskCount: "0",
            apprPayloCount: "0",
            apprTraditionalCount: "0",
            apprUnassignedCount: "0",
            apprCbcalCount: "0",
            apprPpsCount: "0",
            apprEsquireCount: "0",
            apprChesapeakeCount: "0",
            apprFirstDataCount: "0",
            approvedMTDProfileName: [
              "HighRisk",
              "PayLo",
              "Traditional",
              "Unassigned"
            ],
            approvedMTDPlatformName: ["CBCAL", "PPS", "Esquire", "Chesapeake", "FirstData"],
            approvedMTDProfileCount: [0, 0, 0, 0],
            approvedMTDPlatformCount: [0, 0, 0, 0, 0],
            loadingMTD: true,
            approvedMTDBankByProfile: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          });
        } else {
          this.setState(
            {
              submittedMTDCount: json.data[0][0].Submitted,
              approvedMTDCount: json.data[0][0].Approved,
              closedMTDCount: json.data[0][0].Closed,
              apprHighriskCount: json.data[0][0].HighRisk,
              apprPayloCount: json.data[0][0].PayLo,
              apprTraditionalCount: json.data[0][0].Traditional,
              apprUnassignedCount: json.data[0][0].Unassigned,
              apprCbcalCount: json.data[0][0].CBCAL,
              apprPpsCount: json.data[0][0].PPS,
              apprEsquireCount: json.data[0][0].Esquire,
              apprChesapeakeCount: json.data[0][0].Chesapeake,
              apprFirstDataCount: json.data[0][0].FirstData,
              approvedMTDProfileName: [
                "HighRisk",
                "PayLo",
                "Traditional",
                "Unassigned"
              ],
              approvedMTDPlatformName: ["CBCAL", "PPS", "Esquire", "Chesapeake", "FirstData"],
              loadingMTD: true,
              apprCBCAL_HR: json.data[1][0].CBCAL_HR,
              apprCBCAL_PL: json.data[1][0].CBCAL_PL,
              apprCBCAL_TR: json.data[1][0].CBCAL_TR,
              apprPPS_HR: json.data[1][0].PPS_HR,
              apprPPS_PL: json.data[1][0].PPS_PL,
              apprPPS_TR: json.data[1][0].PPS_TR,
              apprEsquire_HR: json.data[1][0].Esquire_HR,
              apprEsquire_PL: json.data[1][0].Esquire_PL,
              apprEsquire_TR: json.data[1][0].Esquire_TR,
              apprChesapeake_HR: json.data[1][0].Chesapeake_HR,
              apprChesapeake_PL: json.data[1][0].Chesapeake_PL,
              apprChesapeake_TR: json.data[1][0].Chesapeake_TR,
              apprFirstData_HR: json.data[1][0].FirstData_HR,
              apprFirstData_PL: json.data[1][0].FirstData_PL,
              apprFirstData_TR: json.data[1][0].FirstData_TR,
            },
            function() {
              this.setState({
                approvedMTDProfileCount: [
                  this.state.apprHighriskCount,
                  this.state.apprPayloCount,
                  this.state.apprTraditionalCount,
                  this.state.apprUnassignedCount
                ],
                approvedMTDPlatformCount: [
                  this.state.apprCbcalCount,
                  this.state.apprPpsCount,
                  this.state.apprEsquireCount,
                  this.state.apprChesapeakeCount,
                  this.state.apprFirstDataCount
                ],
                approvedMTDBankByProfile: [
                  this.state.apprCBCAL_HR,
                  this.state.apprCBCAL_PL,
                  this.state.apprCBCAL_TR,
                  this.state.apprPPS_HR,
                  this.state.apprPPS_PL,
                  this.state.apprPPS_TR,
                  this.state.apprEsquire_HR,
                  this.state.apprEsquire_PL,
                  this.state.apprEsquire_TR,
                  this.state.apprChesapeake_HR,
                  this.state.apprChesapeake_PL,
                  this.state.apprChesapeake_TR,
                  this.state.apprFirstData_HR,
                  this.state.apprFirstData_PL,
                  this.state.apprFirstData_TR,
                ],
              });
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getYr() {
    ApiClient
      .post({
        url: "/api/year",
        body: {
          dt: this.props.day.format()
        }
      })
      // .then(res => res.json())
      .then(json => {
        // debugger;
        if (Object.keys(json.data).length === 0) {
          this.setState({
            approvedYTDCount: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            loadingYTD: true
          });
        } else {
          this.setState({
            // approvedMTDPlatformName: ["CBCAL", "PPS", "Esquire", "Chesapeake"],
            approvedYTDCount: json.data.map(d => d.Approved),
            loadingYTD: true
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getGoals() {
    ApiClient
      .post({
        url: "/api/salesYTDgoals",
        body: { dt: this.props.day.format() }
      })
      .then(json => {
        this.setState({
          salesgoals: json.data.map(d => d.goal)
        });
      }).catch((err) => {
        console.log(err);
      });
  }

  // getSubmittedMTD() {
  //   // debugger;
  //   let cntr = this.state.counter;
  //   axios
  //     .post(`${process.env.REACT_APP_HOSTNAME}:${process.env.REACT_APP_SERVERPORT}/api/submittedMTD`, {
  //       cntr: cntr
  //     })
  //     // .then(res => res.json())
  //     .then(json => {
  //       // debugger;
  //
  //       this.setState({
  //         submittedMTDData: json.data
  //       });
  //     });
  // }
  //
  // getApprovedMTD() {
  //   // debugger;
  //   let cntr = this.state.counter;
  //   axios
  //     .post(`${process.env.REACT_APP_HOSTNAME}:${process.env.REACT_APP_SERVERPORT}/api/approvedMTD`, {
  //       cntr: cntr
  //     })
  //     // .then(res => res.json())
  //     .then(json => {
  //       // debugger;
  //
  //       this.setState({
  //         approvedMTDData: json.data
  //       });
  //     });
  // }

  getMthDataByStatus = () => {
    let cntr = this.state.counter;
    ApiClient.post({
      url: "/api/approvedMTD",
      body: {
        cntr: cntr,
      }
    }).then((json) => {
      ApiClient.post({
        url: "/api/submittedMTD",
        body: {
          cntr: cntr,
        }
      }).then((json2) => {
        ApiClient.post({
          url: "/api/closedMTD",
          body: {
            cntr: cntr,
          }
        }).then((json3) => {
          this.setState({
            closedMTDData: json3.data,
            submittedMTDData: json2.data,
            approvedMTDData: json.data,
            exportData: [json2.data, json.data, json3.data],
          });
        }).catch((err) => {
          console.log(err);
        });
      }).catch((err) => {
        console.log(err);
      });
    }).catch((err) => {
      console.log(err);
    });
  };

  getApprovedMTDPartner = () => {
    let cntr = this.state.counter;
    ApiClient
      .post({
        url: "/api/approvedMTDPartner",
        body: { cntr: cntr }
      })
      .then(json => {
        this.setState({
          approvedMTDPartnerName: json.data.map(d => d.ISO),
          approvedMTDPartnerCount: json.data.map(d => d.appCount),
          loadingPartner: true
        });
      }).catch((err) => {
        console.log(err);
      });
  };

  getClosedMTDPartner = () => {
    let cntr = this.state.counter;
    ApiClient.post({
      url: "/api/ClosedMTDPartner",
      body: { 
        cntr: cntr 
      }
    }).then((json) => {
      this.setState({
        closedMTDPartnerName: json.data.map((d) => d.ISO),
        closedMTDPartnerCount: json.data.map((d) => d.closureCount),
        loadingPartner: true,
      });
    }).catch((err) => {
      console.log(err);
    });
  };

  getApprovedYTD_Details = () => {
    ApiClient
      .post({
        url: "/api/approvedYTD_Details",
        body: { dt: this.props.day.format() }
      })
      .then(json => {
        this.setState({
          approvedYTD_Data: json.data,
          exportData: [json.data]
        });
      }).catch((err) => {
        console.log(err);
      });
  };

  toggleMonthByPartner = () => {
    this.setState({
      isMonthByPartner: this.state.isMonthByPartner === 1 ? 0 : this.state.isMonthByPartner + 1
    })
  }

  xlsxDownloader = () => {
    console.log("xlsxDownloader...");
    let val = this.state.exportData;
    var new_workbook = XLSX.utils.book_new();
    for (var i = 0; i < val.length; i++) {
      let data = val[i];
      if (Array.isArray(data)) {
        var worksheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(
          new_workbook,
          worksheet,
          "Sheet " + i.toString()
        );
      }

      if (
        i === val.length - 1 &&
        Object.entries(new_workbook.Sheets).length !== 0 &&
        new_workbook.Sheets.constructor === Object
      ) {
        XLSX.writeFile(
          new_workbook,
          "Dashboard " + moment().format("MMM D YYYY kk:mm:ss") + ".xlsx"
        );
      }
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      counter: this.props.visibility,
      exportData: "",
      salesgoals: "",
      approvedMTDData: "",
      approvedMTDCount: 0,
      submittedMTDData: "",
      submittedMTDCount: 0,
      closedMTDData: "",
      closedMTDCount: 0,
      loadingMTD: false,
      apprHighriskCount: 0,
      apprPayloCount: 0,
      apprTraditionalCount: 0,
      apprUnassignedCount: 0,
      apprCbcalCount: 0,
      apprPpsCount: 0,
      apprEsquireCount: 0,
      apprChesapeakeCount: 0,
      apprFirstDataCount: 0,
      approvedMTDPartnerName: "",
      closedMTDPartnerName: "",
      loadingPartner: false,
      approvedMTDPartnerCount: 0,
      closedMTDPartnerCount: 0,
      approvedYTD_Data: "",
      approvedYTDCount: 0,
      loadingYTD: false,
      approvedMTDPlatformName: "",
      approvedMTDPlatformCount: 0,
      approvedMTDBankByProfile: 0,
      loadingPlatform: false,
      approvedMTDProfileName: "",
      approvedMTDProfileCount: 0,
      isMonthByPartner: 0,
      loadingProfile: false

      // sampleJSON: ""
    };
    // this.updateChart();
  }

  updateChart() {
    this.getGoals();
    this.getMth();
    this.getYr();
    this.getApprovedMTDPartner();
    this.getClosedMTDPartner();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.visibility !== prevProps.visibility) {
      this.setState(
        {
          counter: this.props.visibility
        },
        () => this.updateChart()
      );
    }
  }

  componentDidMount() {
    this.updateChart();
  }

  render() {
    if (this.props.visibility === 0) {
      return null;
    } else {
      var today = new Date();
      // var todayDate = today.getDate();
      today = new Date(
        today.setMonth(today.getMonth() + this.props.visibility)
      );
      var todayMonth = today.getMonth();
      var todayYear = today.getFullYear();
      // var todayWeekday = today.toLocaleDateString("en-US", { weekday: "long" });

      var ytdTotal = 0;
      for (var j = 0; j < this.state.approvedYTDCount.length; j++) {
        ytdTotal += this.state.approvedYTDCount[j];
      }

      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];

      var goals = this.state.salesgoals;

      // var progressData = [];
      // if (
      //   Math.round((this.state.approvedMTDCount * 100) / goals[todayMonth]) <
      //   100
      // ) {
      //   progressData = [
      //     Math.round((this.state.approvedMTDCount * 100) / goals[todayMonth]),
      //     100 -
      //       Math.round((this.state.approvedMTDCount * 100) / goals[todayMonth])
      //   ];
      // } else {
      //   progressData = [
      //     Math.round((this.state.approvedMTDCount * 100) / goals[todayMonth])
      //   ];
      // }

      var progressData = Math.round(
        (this.state.approvedMTDCount * 100) / goals[todayMonth]
      );

      var data = [
        // {
        //   type: "progress",
        //   id: months[todayMonth] + " Goal",
        //   loading: this.state.loadingMTD,
        //   fdata: [this.state.approvedMTDData],
        //   title:
        //     this.props.day.format("MMM") +
        //     " Goal " +
        //     goals[todayMonth] +
        //     " Actual " +
        //     this.state.approvedMTDCount,
        //   graph: {
        //     datasets: [
        //       {
        //         // label: "Progress",
        //         backgroundColor: [
        //           "rgba(88, 214, 141,1)",
        //           "rgba(204, 209, 209,1)"
        //         ],
        //         borderColor: ["rgba(88, 214, 141,1)", "rgba(204, 209, 209,1)"],
        //         data: progressData
        //       }
        //     ]
        //   }
        //   // percent: 100
        // },
        {
          type: "progress",
          loading: this.state.loadingMTD,
          id: months[todayMonth] + " Goal",
          fdata: [this.state.approvedMTDData],
          title: "Quick Summary",
          graph: {
            // labels: "Progress%",
            datasets: [
              {
                // label: "Progress",
                backgroundColor: [
                  "rgba(88, 214, 141,1)",
                  "rgba(204, 209, 209,1)"
                ],
                borderColor: ["rgba(88, 214, 141,1)", "rgba(204, 209, 209,1)"]
              }
            ],
            data: [
              this.state.approvedMTDCount,
              goals[todayMonth],
              progressData,
              this.state.approvedMTDPlatformName,
              this.state.approvedMTDPlatformCount,
              this.state.approvedMTDProfileName,
              this.state.approvedMTDProfileCount,
              "",
              "",
              this.state.approvedMTDBankByProfile,
            ]
          }
        },
        {
          type: "bar",
          id: months[todayMonth] + "Submitted Approved",
          loading: this.state.loadingMTD,
          fdata: [this.state.submittedMTDData, this.state.approvedMTDData, this.state.closedMTDData],
          title:
            this.props.day.format("MMM") +
            "\t" +
            "Submitted " +
            this.state.submittedMTDCount +
            " Approved " +
            this.state.approvedMTDCount +
            "     Closed " +
            this.state.closedMTDCount,
          graph: {
            labels: [months[todayMonth]],
            datasets: [
              {
                label: "Submitted",
                backgroundColor: "rgba(52, 152, 219,1)",
                borderColor: "rgba(52, 152, 219,1)",
                data: [this.state.submittedMTDCount]
              },
              {
                label: "Approved",
                backgroundColor: "rgba(88, 214, 141,1)",
                borderColor: "rgba(88, 214, 141,1)",
                data: [this.state.approvedMTDCount]
              },
              {
                label: "Closed",
                backgroundColor: "rgba(222, 135, 109,1)",
                borderColor: "rgba(222, 135, 109,1)",
                data: [this.state.closedMTDCount],
              }
            ]
          }
        },

        {
          type: "pie",
          id: months[todayMonth] + " Approvals by Platform",
          loading: this.state.loadingMTD,
          fdata: [this.state.approvedMTDData],
          title: this.props.day.format("MMM") + " Approvals by Platform",
          graph: {
            labels: this.state.approvedMTDPlatformName,
            datasets: [
              {
                label: "Approved",
                backgroundColor: [
                  "rgba(88, 214, 141,1)",
                  "rgba(52, 152, 219,1)",
                  "rgba(255,215,0,1)",
                  "rgba(204, 209, 209,1)"
                ],
                borderColor: [
                  "rgba(88, 214, 141,1)",
                  "rgba(52, 152, 219,1)",
                  "rgba(255,215,0,1)",
                  "rgba(204, 209, 209,1)"
                ],
                data: this.state.approvedMTDPlatformCount
              }
            ]
          }
        },
        {
          type: "pie",
          id: months[todayMonth] + " Approvals by Profile",
          loading: this.state.loadingMTD,
          fdata: [this.state.approvedMTDData],
          title: this.props.day.format("MMM") + " Approvals by Profile",
          graph: {
            labels: this.state.approvedMTDProfileName,
            datasets: [
              {
                label: "Approved",
                backgroundColor: [
                  "rgba(88, 214, 141,1)",
                  "rgba(52, 152, 219,1)",
                  "rgba(255,215,0,1)",
                  "rgba(204, 209, 209,1)"
                ],
                borderColor: [
                  "rgba(88, 214, 141,1)",
                  "rgba(52, 152, 219,1)",
                  "rgba(255,215,0,1)",
                  "rgba(204, 209, 209,1)"
                ],
                data: this.state.approvedMTDProfileCount
              }
            ]
          }
        },
        {
          type: "table",
          id: months[todayMonth] + " Approvals by Partner",
          loading: this.state.loadingPartner,
          fdata: [this.state.approvedMTDData],
          title: this.props.day.format("MMM") + " Approvals by Partner",
          graph: [
            {
              ISOName: this.state.approvedMTDPartnerName,
              count: this.state.approvedMTDPartnerCount,
              data: this.state.approvedMTDData
            }
          ]
        },
        {
          type: "table",
          id: months[todayMonth] + " Closures by Partner",
          loading: this.state.loadingPartner,
          fdata: [this.state.closedMTDData],
          title: moment().format("MMM") + " Closures by Partner",
          graph: [
            {
              ISOName: this.state.closedMTDPartnerName,
              count: this.state.closedMTDPartnerCount,
              data: this.state.closedMTDData,
            },
          ],
        },
        {
          type: "bar_stacked",
          id: todayYear + " Approved",
          loading: this.state.loadingYTD,
          fdata: [this.state.approvedYTD_Data],
          title: this.props.day.format("YYYY") + " Approved\t" + ytdTotal,
          graph: {
            labels: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec"
            ],
            datasets: [
              {
                label: "Approved",
                fill: false,
                backgroundColor: "rgba(88, 214, 141,1)",
                borderColor: "rgba(88, 214, 141,1)",
                data: this.state.approvedYTDCount
              },
              {
                label: "Goals",
                fill: false,
                backgroundColor: "rgba(204, 209, 209,0.4)",
                borderColor: "rgba(204, 209, 209,0.4)",
                // data: this.state.salesYTDGoals
                data: goals
              }
            ]
          }
        }
      ];

      var options = {
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                beginAtZero: true,
                precision: 0,
                scaleIntegersOnly: true
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                beginAtZero: true,
                precision: 0,
                scaleIntegersOnly: true
              }
            }
          ]
        }
      };

      var progressoptions = {
        cutoutPercentage: 99.5,
        elements: {
          center: {
            text: progressData[0],
            color: "rgba(0, 0, 0, 0.7)", //Default black
            fontStyle: "Roboto", //Default Arial
            sidePadding: 15 //Default 20 (as a percentage)
          }
        }
      };

      var stackedoptions = {
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false
              },
              ticks: {
                beginAtZero: true,
                precision: 0,
                scaleIntegersOnly: true
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                beginAtZero: true,
                precision: 0,
                scaleIntegersOnly: true
              }
            }
          ]
        }
      };

      var cards = [];
      for (var i = 0; i < data.length; i++) {
        if (data[i].type === "bar_stacked") {
          cards.push(
            <CustomSalesCard
              type={data[i].type}
              id={data[i].id}
              title={data[i].title}
              data={data[i].fdata}
              graph={data[i].graph}
              options={stackedoptions}
              loading={data[i].loading}
              func={this.getApprovedYTD_Details}
              exportfunc={this.xlsxDownloader}
            />
          );
        } else if (data[i].id === months[todayMonth] + " Goal") {
          cards.push(
            <CustomSalesCard
              type={data[i].type}
              id={data[i].id}
              title={data[i].title}
              data={data[i].fdata}
              graph={data[i].graph}
              options={progressoptions}
              loading={data[i].loading}
              func={this.getMthDataByStatus}
              exportfunc={this.xlsxDownloader}
            />
          );
        } else if (data[i].id === months[todayMonth] + "Submitted Approved") {
          cards.push(
            <CustomSalesCard
              type={data[i].type}
              id={data[i].id}
              title={data[i].title}
              data={data[i].fdata}
              graph={data[i].graph}
              options={options}
              loading={data[i].loading}
              func={this.getMthDataByStatus}
              exportfunc={this.xlsxDownloader}
            />
          );
        } else if (
          data[i].id ===
          months[todayMonth] + " Approvals by Platform"
        ) {
          cards.push(
            <CustomSalesCard
              type={data[i].type}
              id={data[i].id}
              title={data[i].title}
              data={data[i].fdata}
              graph={data[i].graph}
              options={options}
              loading={data[i].loading}
              func={this.getMthDataByStatus}
              exportfunc={this.xlsxDownloader}
            />
          );
        } else if (
          data[i].id ===
          months[todayMonth] + " Approvals by Profile"
        ) {
          cards.push(
            <CustomSalesCard
              type={data[i].type}
              id={data[i].id}
              title={data[i].title}
              data={data[i].fdata}
              graph={data[i].graph}
              options={options}
              loading={data[i].loading}
              func={this.getMthDataByStatus}
              exportfunc={this.xlsxDownloader}
            />
          );
        } else if (
          data[i].id ===
          months[todayMonth] + " Approvals by Partner"  && this.state.isMonthByPartner === 0
        ) {
          cards.push(
            <CustomSalesCard
              type={data[i].type}
              id={data[i].id}
              title={data[i].title}
              data={data[i].fdata}
              graph={data[i].graph}
              options={options}
              loading={data[i].loading}
              func={this.getMthDataByStatus}
              exportfunc={this.xlsxDownloader}
              isMonth={this.state.isMonthByPartner}
              toggler={this.toggleMonthByPartner}
            />
          );
        }
        else if (data[i].id === months[todayMonth] + " Closures by Partner" && this.state.isMonthByPartner === 1) {
          cards.push(
            <CustomSalesCard
              type={data[i].type}
              id={data[i].id}
              title={data[i].title}
              data={data[i].fdata}
              graph={data[i].graph}
              options={options}
              loading={data[i].loading}
              func={this.getMthDataByStatus}
              exportfunc={this.xlsxDownloader}
              isMonth={this.state.isMonthByPartner}
              toggler={this.toggleMonthByPartner}
            />
          );
        }
      }
      return (
        <div>
          <div>
            <CardColumns>{cards}</CardColumns>
          </div>
        </div>
      );
    }
  }
}

export default PreviousMth;
